import React from 'react';

export const NewMessageDescriptionNoProvider = (props: any) => {
    const { item, role, texts } = props;

    let result = <div>-</div>;

    if (item.order) {
        const tab = item.type === 'MESSAGE' ? '&tab=messages' : '&tab=comments';
        const orderLink = `/${role}/order-details?id=${item.order.id}&revision=latest&notification=${item.id}${tab}`;
        result = (
            <>
                <div>
                    {`${texts['order.patient'].value}: `}
                    <a href={orderLink} target="_blank" rel="noreferrer">
                        {item.order ? item.order.patient : ''}
                    </a>
                    {`; ${texts['order.bill'].value}: `}
                    <a href={orderLink} target="_blank" rel="noreferrer">
                        {item.order.bill ? item.order.bill : '-'}
                    </a>
                </div>
                <div>{`${texts.from.value}: ${item.from}`}</div>
            </>
        );
    }

    return result;
};
