import { find } from 'lodash';
import { actionTypes } from '../actions';
import { isEmpty } from '../../utils/common';

export const primaryInitialState = {
    messageIsSending: false,
    internalMessageIsSending: false,
    orders: [],
    ordersLoading: false,
    selectedOrder: null,
    newMessage: null,
    newComment: null,
    image: [],
    isUploading: '',
    internalImage: [],
    internalIsUploading: ''
};

const MessageReducer = (state = primaryInitialState, action) => {
    switch (action.type) {
        case actionTypes.UPLOAD_MESSAGE_IMAGE: {
            const newState = { ...state };
            newState.image = action.fileList;
            newState.isUploading = action.status;
            return newState;
        }
        case actionTypes.UPLOAD_INTERNAL_MESSAGE_IMAGE: {
            const newState = { ...state };
            newState.internalImage = action.fileList;
            newState.internalIsUploading = action.status;
            return newState;
        }
        case actionTypes.NEW_MESSAGE:
            return {
                ...state,
                newMessage: JSON.parse(action.message)
            };
        case actionTypes.NEW_COMMENT:
            return {
                ...state,
                newComment: JSON.parse(action.message)
            };
        case actionTypes.SAVE_MESSAGE:
            return {
                ...state,
                messageIsSending: true
            };
        case actionTypes.SAVE_MESSAGE_SUCCESS:
            return {
                ...state,
                messageIsSending: false,
                image: [],
                isUploading: ''
            };
        case actionTypes.SAVE_INTERNAL_MESSAGE:
            return {
                ...state,
                internalMessageIsSending: true
            };
        case actionTypes.SAVE_INTERNAL_MESSAGE_SUCCESS:
            return {
                ...state,
                internalMessageIsSending: false,
                internalImage: [],
                internalIsUploading: ''
            };
        case actionTypes.SELECT_MESSAGE_ORDER:
            return {
                ...state,
                selectedOrder: action.order
            };
        case actionTypes.LOAD_ORDERS_MESSAGES_SUCCESS: {
            let { selectedOrder } = state;
            if (!isEmpty(selectedOrder)) {
                selectedOrder = find(
                    action.data,
                    (o) => o.id === selectedOrder.id
                );
            }
            return {
                ...state,
                orders: action.data,
                ordersLoading: false,
                selectedOrder
            };
        }
        case actionTypes.LOAD_ORDERS_MESSAGES:
            return {
                ...state,
                ordersLoading: true
            };
        default:
            return state;
    }
};

export default MessageReducer;
