import React, { Component } from 'react';
import { Badge, Col, Row, Tag } from 'antd';
import styled from 'styled-components';
import getStatus from '../../utils/status';
import OrderVersionPopover from './OrderVersionPopover';

const Field = styled.div`
    font-size: 14px;
    font-weight: 300;

    /* span {
        padding: 0 16px;
    } */
`;

const FieldLabel = styled.div`
    min-width: 128px;
    display: inline-block;
`;

const RowGutteredInner = styled(Row)`
    margin-bottom: 8px;
`;

class OrderCardBody extends Component {
    static getFieldLabel(label) {
        return `${label}: `;
    }

    render() {
        const { order, scope, texts } = this.props;
        const statusMinivints = order.status_minivints;
        const statusTemplate = order.status_template;
        const statusEquipment = order.status_equipment;
        const statusEquipment2d = order.status_equipment_2d;
        const statusBraces = order.status_braces;
        const statusAligners = order.status_aligners;
        const statusTray = order.status_tray;
        const statusSplint = order.status_splint;
        const { status, orderversions } = order;
        const contentHidden =
            statusMinivints !== 'NONE' ||
            statusEquipment !== 'NONE' ||
            statusSplint !== 'NONE' ||
            statusEquipment2d !== 'NONE' ||
            statusBraces !== 'NONE' ||
            statusAligners !== 'NONE' ||
            statusTemplate !== 'NONE' ||
            statusTray !== 'NONE' ||
            statusSplint !== 'NONE';
        return (
            <>
                <RowGutteredInner>
                    <Col md={24} sm={24} xs={24}>
                        <Field>
                            <FieldLabel>
                                {OrderCardBody.getFieldLabel(
                                    texts.status.value
                                )}
                            </FieldLabel>
                            <Badge
                                color={getStatus(status).color}
                                text={
                                    texts[
                                        `order.status.${status.toLowerCase()}`
                                    ].value
                                }
                            />
                        </Field>
                    </Col>
                </RowGutteredInner>
                <RowGutteredInner>
                    <Col md={24} sm={24} xs={24}>
                        <Field>
                            <FieldLabel>
                                {OrderCardBody.getFieldLabel(
                                    texts['version.count'].value
                                )}
                            </FieldLabel>
                            <OrderVersionPopover
                                scope={scope}
                                orderId={order.id}
                                orderversions={orderversions}
                            />
                        </Field>
                    </Col>
                </RowGutteredInner>
                <RowGutteredInner hidden={!contentHidden}>
                    <Field>
                        <FieldLabel>
                            {OrderCardBody.getFieldLabel(texts.structure.value)}
                        </FieldLabel>
                        <Tag color="cyan" hidden={statusMinivints === 'NONE'}>
                            {texts.minivints.value}
                        </Tag>
                        <Tag color="blue" hidden={statusEquipment === 'NONE'}>
                            {texts.equipment.value}
                        </Tag>
                        <Tag
                            color="blue"
                            hidden={
                                statusEquipment2d === 'NONE' ||
                                !statusEquipment2d
                            }
                        >
                            {texts.equipment2d.value}
                        </Tag>
                        <Tag
                            color="blue"
                            hidden={statusBraces === 'NONE' || !statusBraces}
                        >
                            {texts.braces.value}
                        </Tag>
                        <Tag
                            color="blue"
                            hidden={
                                statusAligners === 'NONE' || !statusAligners
                            }
                        >
                            {texts.aligners.value}
                        </Tag>
                        <Tag
                            color="blue"
                            hidden={statusSplint === 'NONE' || !statusSplint}
                        >
                            {texts.splint.value}
                        </Tag>
                        <Tag
                            color="geekblue"
                            hidden={statusTemplate === 'NONE'}
                        >
                            {texts.template.value}
                        </Tag>
                        <Tag color="green" hidden={statusTray === 'NONE'}>
                            {texts.tray.value}
                        </Tag>
                    </Field>
                </RowGutteredInner>
            </>
        );
    }
}

export default OrderCardBody;
