import React, { Component } from 'react';
import { Card, List, Spin } from 'antd';
import styled from 'styled-components';
import OrderCardBody from './OrderCardBody';
import OrderCardTitle from './OrderCardTitle';

const { Meta } = Card;

const CardMeta = styled(Meta)`
    .ant-card-meta-title {
        font-weight: 300;
    }
`;

const SpinStyled = styled(Spin)`
    margin-left: auto;
    margin-right: auto;
    padding: 128px;
    width: 100%;
`;

class OrderCardList extends Component {
    render() {
        const { orders, ordersLoading, scope, texts } = this.props;
        if (ordersLoading) {
            return <SpinStyled />;
        }
        return (
            <List
                dataSource={orders}
                renderItem={(item) => (
                    <Card
                        // hoverable
                        style={{ width: '100%', marginTop: 16 }}
                        // actions={[<Icon type="setting" />, <Icon type="edit" />, <Icon type="ellipsis" />]}
                    >
                        <CardMeta
                            title={
                                <OrderCardTitle scope={scope} order={item} />
                            }
                            description={
                                <OrderCardBody
                                    texts={texts}
                                    order={item}
                                    scope={scope}
                                />
                            }
                        />
                    </Card>
                )}
            />
            // <Card>OrderCard</Card>
        );
    }
}

export default OrderCardList;
