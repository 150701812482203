import { ICellRendererParams } from 'ag-grid-community';
import { getUserName } from '../../utils/user';

export const UsernameRenderer = (props: ICellRendererParams) => {
    const { value } = props;
    if (!value) {
        return null;
    }
    return getUserName(value);
};
