import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// @ts-ignore
import qs from 'qs';
import { Layout, Spin } from 'antd';
import styled from 'styled-components';
import { orderBy, find } from 'lodash';
// import Head from 'next/head';
import { selectOrder } from '../redux/actions';
import { GifViewerRaw } from '../components/Order/GifViewerRaw';
import { isEmpty } from '../utils/common';

const { Content } = Layout;

const ContentSpinStyled = styled(Content)`
    padding: 16px;
    margin: 128px auto;
    max-width: 900px;
    border-radius: 4px;
`;

export const GifPage = () => {
    const location = useLocation();
    const params = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const {
        id: idFromQuery,
        revision: revisionFromQuery,
        mode: modelFromQuery,
        theme: themeFromQuery,
        token: tokenFromQuery
    } = params;
    const dispatch = useDispatch();
    const order = useSelector((state: any) => state.OrderReducer.selectedOrder);
    const [revision, setRevision] = useState(revisionFromQuery);
    const [id, setId] = useState(idFromQuery);

    useEffect(() => {
        const newParams = qs.parse(location.search, {
            ignoreQueryPrefix: true
        });
        setId(newParams.id);
        setRevision(newParams.revision);
    }, [location]);

    useEffect(() => {
        dispatch(selectOrder(id, revision, true, tokenFromQuery));
    }, [id, revision, dispatch]);

    let content = (
        <Layout
            style={{
                background: themeFromQuery === 'dark' ? '#222b45' : '#ffffff'
            }}
        >
            <ContentSpinStyled>
                <Spin
                    size="large"
                    style={{ display: 'block', margin: '0 auto' }}
                />
            </ContentSpinStyled>
        </Layout>
    );
    if (order) {
        const orderedVersions = orderBy(
            order.orderversions,
            ['design_date'],
            ['desc']
        );
        const orderVersion =
            revision === 'latest'
                ? orderedVersions[0]
                : find(orderedVersions, (o) => `${o.id}` === revision);
        // equipment gif
        const gif = isEmpty(orderVersion.gif) ? '' : orderVersion.gif.rawUrl;
        // equipment gif 2d
        const gif2d = isEmpty(orderVersion.gif2d)
            ? ''
            : orderVersion.gif2d.rawUrl;
        // equipment gif splint
        const gifSplint = isEmpty(orderVersion.gif_splint)
            ? ''
            : orderVersion.gif_splint.rawUrl;

        let model = gif;
        if (modelFromQuery === '2d') {
            model = gif2d;
        } else if (modelFromQuery === 'splint') {
            model = gifSplint;
        }
        content = <GifViewerRaw src={model} theme={themeFromQuery} />;
    }
    return <>{content}</>;
};
