import { put, select } from 'redux-saga/effects';
import { selectToken, selectOrdersState } from '../selector';
import { onAllOrdersLoadSuccess, failure } from '../actions';
import { GATEWAY_HOST } from '../../utils/properties';
import { isEmpty } from '../../utils/common';

export default function* loadAllOrdersSaga() {
    try {
        const token = yield select(selectToken);
        const ordersState = yield select(selectOrdersState);
        const pagination = `_start=${(ordersState.pagination.current - 1) *
            ordersState.pagination.pageSize}&_limit=${
            ordersState.pagination.pageSize
        }`;
        let sorter = '';
        let filter = '';

        if (!isEmpty(ordersState.search)) {
            filter += `&search_contains=${ordersState.search}`;
        }

        if (!isEmpty(ordersState.sorter)) {
            sorter = `&_sort=${ordersState.sorter.field}:${
                ordersState.sorter.order === 'descend' ? 'DESC' : 'ASC'
            }`;
        }

        if (!isEmpty(ordersState.filters)) {
            let i = 0;
            for (i = 0; i < ordersState.filters.length; i += 1) {
                const f = ordersState.filters[i];
                filter += `&${f.field}_${f.operator}=${f.value}`;
            }
        }

        const res = yield fetch(
            `${GATEWAY_HOST}/orders?${pagination}${sorter}${filter}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        const data = yield res.json();
        const res2 = yield fetch(`${GATEWAY_HOST}/orders/count`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const data2 = yield res2.json();
        yield put(onAllOrdersLoadSuccess({ orders: data, ordersCount: data2 }));
    } catch (err) {
        console.log(err);
        yield put(failure(err));
    }
}
