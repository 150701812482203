import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

export interface InputPropsExtended extends InputProps {
    error?: boolean;
    success?: boolean;
}

export interface SearchProps extends InputPropsExtended {
    onSearch: (searchValue: string, submit?: boolean) => void;
}

interface PressEnterEvent extends React.KeyboardEvent<HTMLInputElement> {
    target: any;
}

const Search = (props: SearchProps) => {
    const {
        onSearch,
        placeholder,
        defaultValue,
        className,
        disabled,
        value: propsValue,
        suffix
    } = props;
    return (
        <Input
            className={className}
            allowClear
            disabled={disabled}
            suffix={suffix}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={({ target: { value = '' } }) => onSearch(value)}
            onPressEnter={(e: PressEnterEvent) => {
                onSearch(e.target.value, true);
            }}
            value={propsValue}
        />
    );
};

export default Search;
