import React, { Component } from 'react';
import { Button, Checkbox, Form, Input, Icon, Select } from 'antd';
import styled from 'styled-components';
import DoctorOrganisationsEditableList from '../Doctor/DoctorOrganisationsEditableList';

const { Option } = Select;

const StyledForm = styled(Form)`
    .ant-form-item-label {
        font-weight: 300;

        > label {
            color: rgba(0, 0, 0, 0.5);
        }
    }
`;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class InternalProfileForm extends Component {
    componentDidMount() {
        const { form } = this.props;
        // To disabled submit button at the beginning.
        form.validateFields();
    }

    handleSubmit = (e) => {
        const { props } = this;
        const { form } = this.props;
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                props.submit(values);
            }
        });
    };

    render() {
        const {
            getFieldDecorator,
            getFieldsError,
            getFieldError,
            isFieldTouched
        } = this.props.form;
        const { texts, profile, organisations } = this.props;

        // Only show error after a field is touched.
        const usernameError =
            isFieldTouched('username') && getFieldError('username');
        const passwordError =
            isFieldTouched('password') && getFieldError('password');
        return (
            <StyledForm layout="vertical" onSubmit={this.handleSubmit}>
                <Form.Item
                    label={texts['profile.last_name'].value}
                    validateStatus={usernameError ? 'error' : ''}
                >
                    {getFieldDecorator('last_name', {
                        initialValue: profile.lastName,
                        rules: [
                            { required: true, message: texts.required.value }
                        ]
                    })(
                        <Input
                            prefix={
                                <Icon
                                    type="user"
                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                />
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item
                    label={texts['profile.first_name'].value}
                    validateStatus={passwordError ? 'error' : ''}
                >
                    {getFieldDecorator('first_name', {
                        initialValue: profile.firstName,
                        rules: [
                            { required: true, message: texts.required.value }
                        ]
                    })(
                        <Input
                            prefix={
                                <Icon
                                    type="user"
                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                />
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item
                    label={texts['profile.middle_name'].value}
                    validateStatus={passwordError ? 'error' : ''}
                >
                    {getFieldDecorator('middle_name', {
                        initialValue: profile.middleName,
                        rules: [
                            { required: true, message: texts.required.value }
                        ]
                    })(
                        <Input
                            prefix={
                                <Icon
                                    type="user"
                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                />
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item
                    label={texts['profile.phone'].value}
                    validateStatus={passwordError ? 'error' : ''}
                >
                    {getFieldDecorator('phone', {
                        initialValue: profile.phone
                    })(
                        <Input
                            prefix={
                                <Icon
                                    type="mobile"
                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                />
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item
                    label={texts['profile.email'].value}
                    validateStatus={passwordError ? 'error' : ''}
                >
                    {getFieldDecorator('email', {
                        initialValue: profile.email,
                        rules: [
                            { required: true, message: texts.required.value },
                            {
                                type: 'email',
                                message: texts['profile.email.error'].value
                            }
                        ]
                    })(
                        <Input
                            prefix={
                                <Icon
                                    type="mail"
                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                />
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item label={texts['profile.locale'].value}>
                    {getFieldDecorator('locale', {
                        initialValue: profile.locale,
                        rules: [
                            { required: true, message: texts.required.value }
                        ]
                    })(
                        <Select style={{ width: 100 }}>
                            <Option value="ru">ru</Option>
                            <Option value="en">eng</Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={texts.organisations.value}>
                    <DoctorOrganisationsEditableList texts={texts} />
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('notifications', {
                        valuePropName: 'checked',
                        initialValue: profile.notifications
                    })(
                        <Checkbox>
                            {texts['profile.notifications'].value}
                        </Checkbox>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                    >
                        {texts.save.value}
                    </Button>
                </Form.Item>
            </StyledForm>
        );
    }
}
const ProfileForm = Form.create({ name: 'profile_form' })(InternalProfileForm);

export default ProfileForm;
