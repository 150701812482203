import React from 'react';
import { useSelector } from 'react-redux';
import { DoctorNotificationsPage } from '../components/Notification/DoctorNotificator';
import { NotificationsComplexPage } from '../components/Notification/Notificator';

export const NotificationsPage = () => {
    const profile = useSelector((state: any) => state.ProfileReducer.profile);
    return profile.role.name === 'doctor' ? (
        <DoctorNotificationsPage />
    ) : (
        <NotificationsComplexPage />
    );
};
