import React from 'react';
import { Button, Dropdown, Icon, Menu } from 'antd';
import { ICellRendererParams } from 'ag-grid-community';
import OrderVersionPopover from '../Order/OrderVersionPopover';

interface VersionsCellRendererParams extends ICellRendererParams {
    texts: any;
    onEditClick: any;
    onEditDateClick: any;
    onDeleteClick: any;
    onNewVersionClick: any;
    scope: any;
}

export const OrderVersionsRenderer = (props: VersionsCellRendererParams) => {
    const {
        data: row,
        value,
        texts,
        onEditClick,
        onEditDateClick,
        onDeleteClick,
        onNewVersionClick,
        scope
    } = props;
    if (!row) {
        return null;
    }

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={(e) => onEditClick(e, row)}>
                <Icon type="edit" />
                {texts.edit.value}
            </Menu.Item>
            <Menu.Item
                key="2"
                hidden={scope !== 'manager'}
                onClick={(e) => onEditDateClick(e, row)}
            >
                <Icon type="calendar" />
                {texts.editdate.value}
            </Menu.Item>
            <Menu.Item key="3" onClick={(e) => onNewVersionClick(e, row)}>
                <Icon type="plus" />
                {texts.create.value}
            </Menu.Item>
            <Menu.Item
                hidden={scope !== 'manager'}
                key="4"
                onClick={(e) => onDeleteClick(e, row)}
            >
                <Icon type="delete" />
                {texts.delete.value}
            </Menu.Item>
        </Menu>
    );
    return (
        <>
            <OrderVersionPopover
                scope={scope}
                orderId={row.id}
                orderversions={value}
            />
            {/* <Button size="small" icon="edit" onClick={e => this.onEditClick(e, record)} /> */}
            <Dropdown overlay={menu}>
                <Button size="small">
                    <Icon type="ellipsis" />
                </Button>
            </Dropdown>
        </>
    );
};
