import { forEach } from 'lodash';
import { isEmpty } from './common';

const statusDict: any = {
    NEW: { color: 'volcano', text: 'Новый' },
    APPROVED: { color: 'cyan', text: 'Подтверждён' },
    NONE: { color: '#f3f3f3', text: 'Не требуется' },
    ON_APPROVAL: { color: 'gold', text: 'На согласовании' },
    CORRECTION: { color: 'red', text: 'Исправление' },
    REWORK: { color: 'red', text: 'Переделка' },
    UNPAID: { color: 'red', text: 'Не оплачен' },
    READYTOSEND: { color: 'orange', text: 'Готов к отгрузке' },
    READY: { color: '#319766', text: 'Одобрен' },
    DESIGNED: { color: 'geekblue', text: 'Спроектирован' },
    PRINTING: { color: 'purple', text: 'В печати' },
    PRINTED: { color: 'purple', text: 'Напечатан' },
    MOLDED: { color: 'purple', text: 'Отлит' },
    DECLINED: { color: 'red', text: 'Отменён' },
    CLOSED: { color: 'green', text: 'Обработан' },
    PLANNED: { color: 'green', text: 'Спланирован' },
    OTK: { color: '#319766', text: 'Проверено ОТК' },
    PAID: { color: 'green', text: 'Оплачен' }
};

const getStatus = (statusKey: string) => {
    let result;
    if (isEmpty(statusKey)) {
        result = { color: '#f3f3f3', text: 'Не требуется' };
    } else {
        result = statusDict[statusKey];
    }
    return result;
};
const getFilters = (keys: any[], texts: any) => {
    const filters: any[] = [];
    forEach(keys, (value) => {
        filters.push({
            text: texts[`order.status.${value.toLowerCase()}`].value,
            value,
            checked: true
        });
    });
    return filters;
};

export const getStatusFilters = (texts: any) =>
    getFilters(
        [
            'NEW',
            'UNPAID',
            'PAID',
            'READYTOSEND',
            'REWORK',
            'READY',
            'CLOSED',
            'DECLINED'
        ],
        texts
    );
export const getVintsFilters = (texts: any) =>
    getFilters(
        [
            'NONE',
            'NEW',
            'PLANNED',
            'ON_APPROVAL',
            'CORRECTION',
            'APPROVED',
            'DECLINED'
        ],
        texts
    );
export const getTemplateFilters = (texts: any) =>
    getFilters(
        [
            'NONE',
            'NEW',
            'DESIGNED',
            'ON_APPROVAL',
            'CORRECTION',
            'APPROVED',
            'DECLINED',
            'PRINTING',
            'PRINTED',
            'OTK',
            'READYTOSEND',
            'READY',
            'CLOSED'
        ],
        texts
    );
export const getToolFilters = (texts: any) =>
    getFilters(
        [
            'NONE',
            'NEW',
            'DESIGNED',
            'ON_APPROVAL',
            'CORRECTION',
            'APPROVED',
            'DECLINED',
            'PRINTING',
            'PRINTED',
            'MOLDED',
            'OTK',
            'READYTOSEND',
            'READY',
            'CLOSED'
        ],
        texts
    );

export default getStatus;
