import React from 'react';
import { Icon, Badge } from 'antd';
import styled from 'styled-components';
import qs from 'qs';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { GATEWAY_HOST } from '../../utils/properties';

const NewBadge = styled(Badge)`
    .ant-badge-count {
        background-color: #3cbb9e;
    }
    padding-left: 8px;
    /* .ant-badge-status-dot {
        top: 0;
    }

    .ant-badge-status-processing {
        background-color: #e39029;

        &::after {
            border: 1px solid #e39029;
        }
    } */
`;

export const NotificationCounter = (props: any) => {
    const profile = useSelector((state: any) => state.ProfileReducer.profile);
    const jwt = useSelector((state: any) => state.AuthReducer.token);
    const { data } = useQuery('notificationsUnreadCount', () => {
        let url = `${GATEWAY_HOST}/notifications/count?user=${profile.id}&read=false&_sort=created_at:DESC`;
        if (profile.role.name !== 'doctor') {
            if (profile.notifications) {
                const query = qs.stringify({
                    _where: [
                        {
                            _or: [
                                { 'order.technician': profile.id },
                                { 'order.manager': profile.id }
                            ]
                        },
                        {
                            user: profile.id
                        },
                        {
                            read: false
                        }
                    ]
                });
                url = `${GATEWAY_HOST}/notifications/count?${query}&_sort=created_at:DESC`;
            }
        }
        return fetch(url, {
            headers: {
                Authorization: `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json());
    });
    return (
        <NewBadge
            overflowCount={99}
            // count={<Icon type="loading" style={{ paddingLeft: 24 }} />}
            count={data}
        />
    );
};
