import React from 'react';
import { Col, Button, Row } from 'antd';
import styled from 'styled-components';

const RightPart = styled.div`
    float: right;

    @media (max-width: 767px) {
        float: right;
        margin-top: 16px;
    }
`;

const Toolbar = styled.div``;

const ToolbarItem = styled.div`
    display: inline-block;
    padding: 0 8px;
`;

interface Props {
    defaultText: string;
    saveText: string;
    onDefaultClick: any;
    onSaveClick: any;
}

export const OrderStlViewSettingsToolbar = (props: Props) => {
    const { defaultText, saveText, onDefaultClick, onSaveClick } = props;
    return (
        <Toolbar>
            <Row>
                <Col md={24} xs={24}>
                    <RightPart>
                        <ToolbarItem>
                            <Button type="dashed" onClick={onDefaultClick}>
                                {defaultText}
                            </Button>
                        </ToolbarItem>
                        <ToolbarItem>
                            <Button type="primary" onClick={onSaveClick}>
                                {saveText}
                            </Button>
                        </ToolbarItem>
                    </RightPart>
                </Col>
            </Row>
        </Toolbar>
    );
};
