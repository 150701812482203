import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tag, Input, Icon } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import { organisationCreate, organisationRemove } from '../../redux/actions';

class DoctorOrganisationsEditableList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputVisible: false,
            inputValue: ''
        };
    }

    handleClose = (removedTag) => {
        const { dispatch } = this.props;
        dispatch(organisationRemove(removedTag.id));
    };

    showInput = () => {
        this.setState({ inputVisible: true }, () => this.input.focus());
    };

    handleInputChange = (e) => {
        this.setState({ inputValue: e.target.value });
    };

    handleInputConfirm = () => {
        const { inputValue } = this.state;
        const { dispatch, organisations } = this.props;
        if (
            inputValue &&
            organisations.map((item) => item.name).indexOf(inputValue) === -1
        ) {
            dispatch(organisationCreate(inputValue));
        }
        this.setState({
            inputVisible: false,
            inputValue: ''
        });
    };

    saveInputRef = (input) => (this.input = input);

    forMap = (tag) => {
        const tagElem = (
            <Tag
                closable
                onClose={(e) => {
                    e.preventDefault();
                    this.handleClose(tag);
                }}
            >
                {tag.name}
            </Tag>
        );
        return (
            <span key={tag.name} style={{ display: 'inline-block' }}>
                {tagElem}
            </span>
        );
    };

    render() {
        const { inputVisible, inputValue } = this.state;
        const { organisations, texts } = this.props;
        const tagChild = organisations.map(this.forMap);
        return (
            <div>
                <div style={{ marginBottom: 16 }}>
                    <TweenOneGroup
                        enter={{
                            scale: 0.8,
                            opacity: 0,
                            type: 'from',
                            duration: 100,
                            onComplete: (e) => {
                                e.target.style = '';
                            }
                        }}
                        leave={{
                            opacity: 0,
                            width: 0,
                            scale: 0,
                            duration: 200
                        }}
                        appear={false}
                    >
                        {tagChild}
                    </TweenOneGroup>
                </div>
                {inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="small"
                        style={{ width: 78 }}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                    />
                )}
                {!inputVisible && (
                    <Tag
                        onClick={this.showInput}
                        style={{ background: '#fff', borderStyle: 'dashed' }}
                    >
                        <Icon type="plus" />
                        {texts['organisations.add'].value}
                    </Tag>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        profile: state.ProfileReducer.profile,
        organisations: state.ProfileReducer.organisations
    };
}

export default connect(mapStateToProps)(DoctorOrganisationsEditableList);
