import { keyBy } from 'lodash';
import { actionTypes } from '../actions';
import { isEmpty } from '../../utils/common';

export const primaryInitialState = {
    error: null,
    currentRoute: '/',
    backTitle: null,
    backHref: null,
    texts: null,
    locale: null
};

const CommonReducer = (state = primaryInitialState, action) => {
    switch (action.type) {
        case actionTypes.PROFILE_SAVE: {
            return {
                ...state,
                ...{
                    locale: action.profile.locale
                }
            };
        }
        case actionTypes.LOAD_USER_DATA_SUCCESS: {
            return {
                ...state,
                ...{
                    locale: action.data.locale
                }
            };
        }
        case actionTypes.LOAD_LOCALE_SUCCESS: {
            console.log('LOAD_LOCALE_SUCCESS REDUCER');
            return {
                ...state,
                ...{ texts: keyBy(action.texts, 'key') }
            };
        }
        case actionTypes.SAVE_ROUTE: {
            let backTitle = null;
            let backHref = null;
            if (action.route === '/manager/client') {
                if (!isEmpty(state.texts)) {
                    backTitle = state.texts['menu.clients'].value;
                }
                backHref = '/manager/clients';
            } else if (action.route === '/technician/client') {
                if (!isEmpty(state.texts)) {
                    backTitle = state.texts['menu.clients'].value;
                }
                backHref = '/technician/clients';
            } else if (action.route === '/otk/client') {
                if (!isEmpty(state.texts)) {
                    backTitle = state.texts['menu.clients'].value;
                }
                backHref = '/otk/clients';
            } else if (action.route === '/doctor/order-details') {
                if (!isEmpty(state.texts)) {
                    backTitle = state.texts.orders.value;
                }
                backHref = '/';
            }

            if (
                state.currentRoute === '/manager/messages' &&
                action.route === '/manager/order-details'
            ) {
                if (!isEmpty(state.texts)) {
                    backTitle = state.texts['menu.messages'].value;
                }
                backHref = '/manager/messages';
            } else if (
                state.currentRoute === '/technician/messages' &&
                action.route === '/technician/order-details'
            ) {
                if (!isEmpty(state.texts)) {
                    backTitle = state.texts['menu.messages'].value;
                }
                backHref = '/technician/messages';
            } else if (
                state.currentRoute === '/otk/messages' &&
                action.route === '/otk/order-details'
            ) {
                if (!isEmpty(state.texts)) {
                    backTitle = state.texts['menu.messages'].value;
                }
                backHref = '/otk/messages';
            }

            return {
                ...state,
                ...{
                    currentRoute: action.route,
                    backTitle,
                    backHref
                }
            };
        }
        case actionTypes.FAILURE:
            return {
                ...state,
                ...{ error: action.error }
            };
        default:
            return state;
    }
};

export default CommonReducer;
