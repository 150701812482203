import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import fetch from 'isomorphic-unfetch';
import { useQuery } from 'react-query';
import { Pagination } from 'antd';
import { GridApi, SortChangedEvent } from 'ag-grid-community';
import { GATEWAY_HOST } from '../../utils/properties';
import { StatusRenderer } from '../Renderer/StatusRenderer';
import { DateRenderer } from '../Renderer/DateRenderer';
import { ColumnListFilter } from '../Filter/list-filter';
import { ColumnTextFilter } from '../Filter/text-filter';
import { localeText } from '../../utils/grid-locale';

import { applySearchToUser } from '../../redux/actions';
import { ColumnTextFilterClient } from '../Filter/text-filter-client';
import { AppRenderer } from '../Renderer/AppRenderer';
import { CampaignLinkRenderer } from '../Renderer/CampaignLinkRenderer';
import { CampaignStatusRenderer } from '../Renderer/CampaignStatusRenderer';
import { CampaignActionRenderer } from '../Renderer/CampaignActionRenderer';
import { CampaignPreviewRenderer } from '../Renderer/CampaignPreviewRenderer';

export interface SortModelItem {
    colId: string;
    sort: 'asc' | 'desc';
}

export interface InFilterValue {
    operator: 'in';
    value: string[];
    property: string;
}

export interface LikeFilterValue {
    operator: 'like';
    value: string;
    property: string;
}

export interface EqFilterValue {
    operator: 'eq';
    value: string;
    property: string;
}

export interface DateFilterValue {
    operator: 'lt' | 'gt';
    value: number;
    property: string;
}

export type TableFilter =
    | InFilterValue
    | LikeFilterValue
    | DateFilterValue
    | EqFilterValue;

export type TableFilters = string[];

interface ICampaignTableProps {
    onEditClick: any;
    onDeleteClick: any;
    onPreviewClick: any;
}

export const CampaignTable = (props: ICampaignTableProps) => {
    const { onEditClick, onDeleteClick, onPreviewClick } = props;
    const dispatch = useDispatch();
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const token = useSelector((state: any) => state.AuthReducer.token);
    const role = useSelector((state: any) => state.UserReducer.role);
    const userRole = useSelector(
        (state: any) => state.ProfileReducer.profile.role.name
    );
    const searchValue = useSelector((state: any) => state.UserReducer.search);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);

    // const onEditClick = (e: any, record: any) => {
    //     // dispatch(selectOrder(record.id, 'latest'));
    //     // dispatch(openNewOrder(record.id));
    // };

    const columns = [
        {
            headerName: texts.name.value,
            field: 'name',
            colId: 'name',
            minWidth: 150,
            resizable: true,
            sortable: true,
            // filter: 'textFilterClient',
            filterParams: {
                texts
            },
            cellRenderer: 'campaignLinkRenderer',
            cellRendererParams: {
                role: userRole,
                token
            }
        },
        {
            headerName: texts['campaign.created'].value,
            field: 'created_at',
            colId: 'created_at',
            resizable: true,
            sortable: true,
            cellRenderer: 'dateRenderer'
        },
        {
            headerName: texts['campaign.start'].value,
            field: 'start',
            colId: 'start',
            resizable: true,
            sortable: true,
            cellRenderer: 'dateRenderer'
        },
        {
            headerName: texts['campaign.end'].value,
            field: 'end',
            colId: 'end',
            resizable: true,
            sortable: true,
            cellRenderer: 'dateRenderer'
        },
        {
            headerName: texts.preview.value,
            field: 'html',
            colId: 'html',
            resizable: true,
            sortable: true,
            width: 100,
            cellRenderer: 'campaignPreviewRenderer',
            cellRendererParams: {
                role: userRole,
                onPreview: onPreviewClick
            }
        },
        {
            headerName: texts.status.value,
            field: 'enabled',
            colId: 'enabled',
            resizable: true,
            sortable: true,
            width: 100,
            cellRenderer: 'campaignStatusRenderer',
            cellRendererParams: {
                role: userRole,
                yesText: texts['campaign.enabled'].value,
                noText: texts['campaign.disabled'].value
            }
        },
        {
            headerName: '',
            field: 'enabled',
            colId: 'enabled',
            resizable: true,
            sortable: true,
            width: 80,
            cellRenderer: 'campaignActionRenderer',
            cellRendererParams: {
                role: userRole,
                texts,
                onEditClick,
                onDeleteClick
            }
        }
    ];

    const pageSize = 100;
    const [page, setPage] = useState(1);

    // filters
    const [sorter, setSorter] = useState('_sort=created_at:DESC&');

    useEffect(() => {
        setPage(1);
        if (gridApi) {
            gridApi.setFilterModel(null);
            gridApi.onFilterChanged();
        }
    }, [role, gridApi]);

    useEffect(() => {
        setPage(1);
    }, [searchValue]);

    const getFilteredUrl = (url: string, nameFilter: string) => {
        let newUrl = url;
        if (nameFilter) {
            newUrl = `${newUrl}&name_contains=${nameFilter}`;
        }
        return newUrl;
    };

    const { data } = useQuery(
        ['campaigns-table', role, page, searchValue, sorter],
        () => {
            let url = `${GATEWAY_HOST}/campaigns?${sorter}&_limit=${pageSize}&_start=${(page -
                1) *
                pageSize}`;
            url = getFilteredUrl(url, searchValue);
            return fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }).then((res) => res.json());
        }
    );

    useEffect(() => {
        if (gridApi && data) {
            if (searchValue) {
                gridApi.refreshClientSideRowModel('filter');
            }
        }
    }, [data, gridApi, searchValue]);

    const { isLoading: isTotalDataLoading, data: totalData } = useQuery(
        ['campaings-table-total', role, page, searchValue],
        () => {
            const url = getFilteredUrl(
                `${GATEWAY_HOST}/campaigns/count`,
                searchValue
            );
            return fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }).then((res) => res.json());
        }
    );

    const frameworkComponents = {
        statusRenderer: StatusRenderer,
        dateRenderer: DateRenderer,
        appRenderer: AppRenderer,
        listFilter: ColumnListFilter,
        textFilter: ColumnTextFilter,
        textFilterClient: ColumnTextFilterClient,
        // listRenderer: DoctorOrganisationsPopover,
        campaignLinkRenderer: CampaignLinkRenderer,
        campaignStatusRenderer: CampaignStatusRenderer,
        campaignActionRenderer: CampaignActionRenderer,
        campaignPreviewRenderer: CampaignPreviewRenderer
        // orgTooltip: DoctorOrganisationsTooltip
    };

    return (
        <div
            className="ag-theme-alpine"
            style={{
                height: '100%',
                width: '100%'
            }}
        >
            <AgGridReact
                localeText={localeText}
                columnDefs={columns}
                suppressMultiSort
                // enableBrowserTooltips
                multiSortKey="ctrl"
                animateRows
                enableCellTextSelection
                pagination={false}
                // paginationPageSize={pageSize}
                onSortChanged={(e: SortChangedEvent) => {
                    const columnStateLocal = e.columnApi.getColumnState();
                    console.log(columnStateLocal);
                    let result = '';
                    for (let i = 0; i < columnStateLocal.length; i += 1) {
                        if (columnStateLocal[i].sort) {
                            result = `_sort=${
                                columnStateLocal[i].colId
                            }:${columnStateLocal[i].sort?.toUpperCase()}&`;
                        }
                    }
                    setSorter(result);
                }}
                onFilterChanged={(e: any) => {
                    if (e.columns.length === 0) {
                        dispatch(applySearchToUser(''));
                        return;
                    }
                    const { colId } = e.columns[0];
                    const model = e.api
                        .getFilterInstance(e.columns[0].colId)
                        .getModel();
                    if (colId === 'last_name') {
                        let search = '';
                        if (model) {
                            search = model.value;
                        }
                        dispatch(applySearchToUser(search));
                    }
                }}
                onGridReady={(e) => {
                    setGridApi(e.api);
                }}
                // onDragStopped={(e) => {
                //     updateColumns(e.columnApi.getAllGridColumns());
                // }}
                // onFirstDataRendered={(e) => {
                //     e.columnApi.autoSizeAllColumns();
                // }}
                frameworkComponents={frameworkComponents}
                // // TODO: strange error appeared when use custom Loading
                // // loadingOverlayComponent="customLoadingOverlay"
                // noRowsOverlayComponent="customEmptyOverlay"
                // noRowsOverlayComponentParams={{
                //     image: Empty.PRESENTED_IMAGE_SIMPLE
                // }}
                // suppressPaginationPanel
                // onPaginationChanged={() => {
                //     if (gridApi) {
                //         setTotal(gridApi.paginationGetRowCount());
                //     }
                // }}
                // rowModelType="infinite"
                rowData={data}
                tooltipShowDelay={0}
                // tooltipMouseTrack
                // cacheOverflowSize={2}
                // maxConcurrentDatasourceRequests={2}
                // infiniteInitialRowCount={1}
                // maxBlocksInCache={2}
                // cacheBlockSize={paginationPageSize}
            />
            <Pagination
                style={{
                    backgroundColor: '#fff',
                    height: '36px',
                    lineHeight: '36px',
                    textAlign: 'right',
                    verticalAlign: 'middle',
                    border: '1px solid #babfc7',
                    borderTop: 0
                }}
                size="small"
                current={page}
                total={totalData}
                pageSize={pageSize}
                disabled={isTotalDataLoading}
                showTotal={(total: number) => `Всего: ${total}`}
                onChange={(newPage: number) => {
                    setPage(newPage);
                }}
            />
        </div>
    );
};
