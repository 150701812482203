import { ICellRendererParams } from 'ag-grid-community';

interface CustomCellRendererParams extends ICellRendererParams {
    yesText: string;
    noText: string;
}

export const AppRenderer = (props: CustomCellRendererParams) => {
    const { value, yesText, noText } = props;

    let result = noText;
    console.log(value);
    if (value.length > 0) {
        result = yesText;
    }
    return result;
};
