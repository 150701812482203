import React, { Component } from 'react';
import { orderBy } from 'lodash';
import { List, Popover } from 'antd';
import styled from 'styled-components';
import { isEmpty } from '../../utils/common';

const StyledList = styled(List)`
    padding: 8px;
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
    white-space: pre-wrap; */
`;

class DoctorOrganisationsTooltip extends Component {
    getReactContainerClasses() {
        return ['custom-tooltip'];
    }

    render() {
        const { api, rowIndex } = this.props;
        const { data } = api.getDisplayedRowAtIndex(rowIndex);
        let { organisations } = data;
        if (isEmpty(organisations)) {
            return null;
        }
        organisations = orderBy(organisations, 'name');

        const content = (
            <StyledList
                size="small"
                dataSource={organisations}
                renderItem={(item) => <List.Item>{item.name}</List.Item>}
            />
        );
        return <div className="custom-tooltip">{content}</div>;
    }
}

export default DoctorOrganisationsTooltip;
