import React from 'react';
import { Button, Card, Row, Col, Spin, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ProfileForm from '../components/Profile/ProfileForm';
import { updateProfile } from '../redux/actions';
import { GATEWAY_HOST } from '../utils/properties';
import { logout } from '../utils/auth';

export const ProfilePage = () => {
    const dispatch = useDispatch();
    const profile = useSelector((state: any) => state.ProfileReducer.profile);
    const token = useSelector((state: any) => state.AuthReducer.token);
    const organisations = useSelector(
        (state: any) => state.ProfileReducer.organisations
    );
    const texts = useSelector((state: any) => state.CommonReducer.texts);

    const onSubmit = (values: any) => {
        dispatch(updateProfile(values));
    };

    if (!profile) {
        return <Spin />;
    }

    const onConfirm = async () => {
        await fetch(
            `${GATEWAY_HOST}/users-permissions/users/${profile.id}/block`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                method: 'GET'
            }
        );
        logout();
    };

    const onDelete = () => {
        Modal.confirm({
            title: texts['profile.deletion'].value,
            content: texts['profile.deletion.content'].value,
            okText: texts.apply.value,
            cancelText: texts.cancel.value,
            onOk: onConfirm
        });
    };

    return (
        <Card
            title={texts['profile.card.title'].value}
            extra={
                <Button type="danger" onClick={onDelete}>
                    {texts.delete.value}
                </Button>
            }
        >
            <Row>
                <Col sm={24} md={12} lg={12} xl={6}>
                    <ProfileForm
                        // @ts-ignore
                        submit={(e: any) => onSubmit(e)}
                        profile={profile}
                        texts={texts}
                        organisations={organisations}
                    />
                </Col>
            </Row>
        </Card>
    );
};
