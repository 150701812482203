import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import InternalStatusRenderer from './InternalStatusRenderer';

export const StatusRenderer = (props: ICellRendererParams) => {
    const { data: row, value } = props;
    const valueStr = value;
    let result = <span />;

    if (row) {
        result = <InternalStatusRenderer status={valueStr} />;
    }
    return result;
};
