export const localeText = {
    page: 'Страница',
    more: 'Больше',
    to: '-',
    of: 'из',
    next: 'След.',
    last: 'Послед.',
    first: 'Первая',
    previous: 'Предыдущая',
    loadingOoo: 'Загрузка...',
    noRowsToShow: 'Данные не найдены...'
};
