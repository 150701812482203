import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';

import {
    auth,
    getPriceData,
    getRefreshToken,
    getUserData
} from '../utils/auth';
import {
    saveToken,
    saveRefreshToken,
    saveProfile,
    loadUserData,
    connectToBus,
    saveRoute
} from '../redux/actions';
// import { ErrorPage } from './404';
import { AppNavigation } from '../components/App/AppNavigation';
import { ClientsPage } from './manager/clients';
import { ClientPage } from './manager/client';
import { ClientPage as CClientPage } from './index-page';
import { OrdersPage } from './manager/orders';
import { OrderPage } from './manager/order-details';
import { DoctorMessagesPage } from './manager/messages';

import { ClientsPage as TClientsPage } from './technician/clients';
import { ClientPage as TClientPage } from './technician/client';
import { OrdersPage as TOrdersPage } from './technician/orders';
import { OrderPage as TOrderPage } from './technician/order-details';
import { DoctorMessagesPage as TDoctorMessagesPage } from './technician/messages';

import { ClientsPage as OClientsPage } from './otk/clients';
import { ClientPage as OClientPage } from './otk/client';
import { OrdersPage as OOrdersPage } from './otk/orders';
import { OrderPage as OOrderPage } from './otk/order-details';
import { DoctorMessagesPage as ODoctorMessagesPage } from './otk/messages';

import { OrderPage as COrderPage } from './doctor/order-details';
// import { OrderPage as SOrderPage } from './shared';
import { DoctorMessagesPage as CDoctorMessagesPage } from './doctor/messages';

import { ProfilePage } from './profile';
import { NotificationsPage } from './notificator';
import { isEmpty } from '../utils/common';
// import { AdvBraces } from '../components/Adv/AdvBraces';
// import { GATEWAY_HOST } from '../utils/properties';
// import { ADV_ID } from '../utils/adv';
import { CampaignPage } from './manager/campaign';
import { CampaignDetails } from './manager/campaign-details';

const queryClient = new QueryClient();

export const App = () => {
    const token = auth();
    const dispatch = useDispatch();
    // const [profileId, setProfileId] = useState(null);
    // const texts = useSelector((state: any) => state.CommonReducer.texts);
    const profile = useSelector((state: any) => state.ProfileReducer.profile);
    const currentLocation = useLocation();
    const refreshToken = getRefreshToken();
    let user = getUserData();
    let price = getPriceData();
    if (!price) {
        price = '{"id":-1}';
    }
    if (!user) {
        user = '{}';
    }
    const userObj = JSON.parse(user);
    // const priceObj = JSON.parse(price);

    // useEffect(() => {
    //     if (profile.id) {
    //         setProfileId(profile.id);
    //     }
    // }, [profile]);

    // useEffect(() => {
    //     if (profileId) {
    //         if (!priceObj.price) {
    //             Modal.info({
    //                 // title: `${ADV_TITLE}`,
    //                 okText: 'Закрыть',
    //                 okType: 'default',
    //                 width: 1200,
    //                 icon: null,
    //                 content: <AdvBraces token={token} profile={profile} />,
    //                 onOk: () => {
    //                     // window.location.href = ;
    //                     fetch(`${GATEWAY_HOST}/prices`, {
    //                         headers: {
    //                             Authorization: `Bearer ${token}`,
    //                             'Content-Type': 'application/json'
    //                         },
    //                         method: 'POST',
    //                         body: JSON.stringify({
    //                             user: profileId,
    //                             read: true,
    //                             adv: ADV_ID
    //                         })
    //                     });
    //                     // setPriceData();
    //                 }
    //             });
    //         }
    //     }
    // }, [profileId]);

    // if (token) {
    useEffect(() => {
        if (token) {
            dispatch(saveToken(token));
            dispatch(saveRefreshToken(refreshToken));
            dispatch(saveProfile(userObj));
            dispatch(connectToBus());
            dispatch(saveRoute(currentLocation.pathname));
            if (!isEmpty(userObj) && isEmpty(profile.id)) {
                dispatch(loadUserData(userObj.id));
            }
            // if (texts === null) {
            //     dispatch(loadLocale());
            // }
        }
    }, []);
    return (
        <QueryClientProvider client={queryClient}>
            <AppNavigation>
                <Switch>
                    <Route path="/manager/messages">
                        <DoctorMessagesPage />
                    </Route>
                    <Route path="/manager/clients">
                        <ClientsPage />
                    </Route>
                    <Route path="/manager/client">
                        <ClientPage />
                    </Route>
                    <Route path="/manager/orders">
                        <OrdersPage />
                    </Route>
                    <Route path="/manager/order-details">
                        <OrderPage />
                    </Route>
                    <Route path="/manager/campaigns">
                        <CampaignPage />
                    </Route>
                    <Route path="/manager/campaign">
                        <CampaignDetails />
                    </Route>
                    <Route path="/technician/messages">
                        <TDoctorMessagesPage />
                    </Route>
                    <Route path="/technician/clients">
                        <TClientsPage />
                    </Route>
                    <Route path="/technician/client">
                        <TClientPage />
                    </Route>
                    <Route path="/technician/orders">
                        <TOrdersPage />
                    </Route>
                    <Route path="/technician/order-details">
                        <TOrderPage />
                    </Route>
                    <Route path="/otk/messages">
                        <ODoctorMessagesPage />
                    </Route>
                    <Route path="/otk/clients">
                        <OClientsPage />
                    </Route>
                    <Route path="/otk/client">
                        <OClientPage />
                    </Route>
                    <Route path="/otk/orders">
                        <OOrdersPage />
                    </Route>
                    <Route path="/otk/order-details">
                        <OOrderPage />
                    </Route>
                    <Route path="/profile">
                        <ProfilePage />
                    </Route>
                    <Route path="/doctor/order-details">
                        <COrderPage />
                    </Route>
                    <Route path="/doctor/messages">
                        <CDoctorMessagesPage />
                    </Route>
                    <Route path="/notificator">
                        <NotificationsPage />
                    </Route>
                    <Route path="/">
                        <CClientPage />
                    </Route>
                    {/* <PrivateRoute path="*">
                    <ErrorPage />
                </PrivateRoute> */}
                </Switch>
            </AppNavigation>
        </QueryClientProvider>
    );
};
