import { isEmpty } from '../../utils/common';
import { actionTypes } from '../actions';

export const primaryInitialState = {
    profile: {
        id: '',
        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        phone: '',
        role: null,
        locale: null,
        unread: 0
    },
    organisations: []
};

const getUsername = (firstName, lastName, middleName) => {
    let username = lastName;
    if (!isEmpty(firstName)) {
        username += ` ${firstName[0]}.`;
    }

    if (!isEmpty(middleName)) {
        username += ` ${middleName[0]}.`;
    }
    return username;
};

const ProfileReducer = (state = primaryInitialState, action) => {
    switch (action.type) {
        case actionTypes.PROFILE_UPDATE: {
            const organisations = isEmpty(state.profile.organisations)
                ? []
                : state.profile.organisations;
            return {
                ...state,
                ...{
                    profile: {
                        ...{
                            id: state.profile.id,
                            firstName: action.firstName,
                            lastName: action.lastName,
                            middleName: action.middleName,
                            email: action.email,
                            phone: action.phone,
                            locale: action.locale,
                            notifications: action.notifications,
                            username: getUsername(
                                action.firstName,
                                action.lastName,
                                action.middleName
                            ),
                            role: state.profile.role,
                            unread: state.profile.unread,
                            stl: state.profile.stl
                        }
                    },
                    organisations
                }
            };
        }
        case actionTypes.PROFILE_SAVE: {
            const organisations = isEmpty(action.profile.organisations)
                ? []
                : action.profile.organisations;
            return {
                ...state,
                ...{
                    profile: {
                        id: action.profile.id,
                        firstName: action.profile.first_name,
                        lastName: action.profile.last_name,
                        middleName: action.profile.middle_name,
                        email: action.profile.email,
                        phone: action.profile.phone,
                        notifications: action.profile.notifications,
                        username: getUsername(
                            action.profile.first_name,
                            action.profile.last_name,
                            action.profile.middle_name
                        ),
                        role: action.profile.role,
                        locale: action.profile.locale
                    },
                    organisations
                }
            };
        }
        case actionTypes.LOAD_USER_DATA_SUCCESS: {
            const organisations = isEmpty(action.data.organisations)
                ? []
                : action.data.organisations;
            return {
                ...state,
                ...{
                    profile: {
                        id: action.data.id,
                        firstName: action.data.first_name,
                        lastName: action.data.last_name,
                        middleName: action.data.middle_name,
                        email: action.data.email,
                        phone: action.data.phone,
                        stl: action.data.stl,
                        notifications: action.data.notifications,
                        username: getUsername(
                            action.data.first_name,
                            action.data.last_name,
                            action.data.middle_name
                        ),
                        role: action.data.role,
                        locale: action.data.locale,
                        unread: action.data.unread
                    },
                    organisations
                }
            };
        }
        case actionTypes.ORGANISATION_LOAD_SUCCESS: {
            const organisations = isEmpty(action.organisations)
                ? []
                : action.organisations;
            return {
                ...state,
                ...{
                    organisations
                }
            };
        }
        case actionTypes.LOAD_ORDERS_MESSAGES_SUCCESS: {
            return {
                ...state,
                ...{
                    profile: {
                        ...state.profile,
                        unread: action.unread
                    }
                }
            };
        }
        default:
            return state;
    }
};

export default ProfileReducer;
