import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Input, Modal, Row, Spin, Switch } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { GATEWAY_HOST } from '../../utils/properties';

interface ICampaignCreationModalProps {
    title: string;
    visible: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    campaignId?: number;
}

export const updateCampaign = async (data: any) => {
    const { campaignId, name, start, end, enabled, jwt } = data;
    const method = campaignId ? 'PUT' : 'POST';
    const url = campaignId
        ? `${GATEWAY_HOST}/campaigns/${campaignId}`
        : `${GATEWAY_HOST}/campaigns`;

    const body: any = {
        name,
        start,
        end,
        enabled
    };

    return fetch(url, {
        method,
        body: JSON.stringify(body),
        headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
        }
    });
};

export const CampaignCreationModal = (props: ICampaignCreationModalProps) => {
    const { campaignId, title, visible, handleOk, handleCancel } = props;
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const [startDate, setStartDate] = useState<moment.Moment | null>();
    const [endDate, setEndDate] = useState<moment.Moment | null>();
    const [error, setError] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [changed, setChanged] = useState(false);
    const [name, setName] = useState('');
    const [enabled, setEnabled] = useState(false);
    const token = useSelector((state: any) => state.AuthReducer.token);

    const { data } = useQuery(
        ['campaigns-details', campaignId],
        () => {
            const url = `${GATEWAY_HOST}/campaigns/${campaignId}`;
            return fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }).then((res) => res.json());
        },
        {
            enabled: !!campaignId,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            retry: false
        }
    );

    useEffect(() => {
        if (data) {
            console.log(data);
            setName(data.name);
            setEnabled(data.enabled);
            setStartDate(data.start ? moment(data.start) : undefined);
            setEndDate(data.end ? moment(data.end) : undefined);
        }
    }, [data]);

    const resetData = () => {
        setName('');
        setEnabled(false);
        setError(false);
        setChanged(false);
        setStartDate(undefined);
        setEndDate(undefined);
    };

    const { mutate } = useMutation('update-campaign', updateCampaign, {
        onSuccess: () => {
            // setQueryEnabled(false);
            setIsSaving(false);
            resetData();
            handleOk();
            // queryClient.refetchQueries(['order-messages', orderId]);
            // setIsSending(false);
            // dispatch(onSaveMessageSuccess());
        },
        onError: () => {
            setIsSaving(false);
            // message.error('Возникла ошибка, попробуйте позже.');
            // setIsSending(false);
        },
        onSettled: () => {
            // queryClient.invalidateQueries('create');
        }
    });

    const onOk = () => {
        setIsSaving(true);
        const body = {
            name,
            start: startDate,
            end: endDate,
            enabled,
            jwt: token,
            campaignId
        };
        mutate(body);
        // handleOk();
        // resetData();
    };
    const onCancel = () => {
        handleCancel();
        resetData();
    };

    useEffect(() => {
        if (!name) {
            setError(true);
        } else {
            setChanged(true);
            setError(false);
        }
    }, [name]);

    return (
        <Modal
            title={title}
            visible={visible}
            onOk={onOk}
            okButtonProps={{ loading: isSaving }}
            onCancel={onCancel}
        >
            {campaignId && !data ? (
                <Spin />
            ) : (
                <>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <div>{`${texts['campaign.new.name'].value}:*`}</div>
                            <Input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            {error && changed && (
                                <div style={{ color: 'red' }}>
                                    {`${texts.required.value}`}
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <div>{`${texts['campaign.start'].value}:`}</div>
                            <DatePicker
                                value={startDate}
                                format="DD.MM.YYYY"
                                onChange={(value) => setStartDate(value)}
                            />
                        </Col>
                        <Col span={12}>
                            <div>{`${texts['campaign.end'].value}:`}</div>
                            <DatePicker
                                value={endDate}
                                format="DD.MM.YYYY"
                                onChange={(value) => setEndDate(value)}
                            />
                        </Col>
                        <Col span={12}>
                            <div>{`${texts['campaign.new.enabled'].value}:`}</div>
                            <Switch
                                checked={enabled}
                                onChange={(value) => setEnabled(value)}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </Modal>
    );
};
