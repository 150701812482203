import React from 'react';
import Badge from '../Badge/Badge';
import { SimpleMap } from '../Common/simple-map';

interface StatusProps {
    status: string;
    valueToIconColor?: SimpleMap<string>;
}
const statusToBadgeColorMap: SimpleMap<string> = {
    online: '#41ba92',
    'в сети': '#41ba92',
    offline: '#ced3d9',
    'не в сети': '#ced3d9'
};

const InternalStatusRenderer = (props: StatusProps) => {
    const { status, valueToIconColor } = props;
    const convertedStatus = status
        .split('[-_ ]')
        .map(
            (value) => value[0].toUpperCase() + value.substring(1).toLowerCase()
        )
        .reduce((prev, curr) => `${prev} ${curr}`)
        .trim();
    let color;
    if (valueToIconColor) {
        color = valueToIconColor[status.toLowerCase()];
    } else if (statusToBadgeColorMap[status.toLowerCase()]) {
        color = statusToBadgeColorMap[status.toLowerCase()];
    }

    return (
        <Badge
            color={color || statusToBadgeColorMap.undefined}
            text={convertedStatus}
        />
    );
};

export default InternalStatusRenderer;
