import React from 'react';
import { NavLink } from 'react-router-dom';
import { ICellRendererParams } from 'ag-grid-community';
import { getUserName } from '../../utils/user';

interface CustomCellRendererParams extends ICellRendererParams {
    role: string;
}

export const UsernameLinkRenderer = (props: CustomCellRendererParams) => {
    const { data: row, value, role } = props;
    if (!row || !value) {
        return null;
    }
    return (
        <NavLink to={`/${role}/client?id=${value.id}`}>
            {getUserName(value)}
        </NavLink>
    );
};
