import React, { Component } from 'react';
import { filter, orderBy, map, uniq } from 'lodash';
import { NavLink } from 'react-router-dom';
import { List, Popover, Tag } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { prepareOrderVersionsForDoctor } from '../../utils/user';

const PopoverWithPointer = styled(Popover)`
    cursor: pointer;
`;

class OrderVersionPopover extends Component {
    render() {
        const { scope, orderId } = this.props;
        const { orderversions } = this.props;
        const preparedOrederVersions =
            scope === 'doctor'
                ? prepareOrderVersionsForDoctor(
                      orderversions,
                      'latest',
                      'doctor'
                  )
                : orderversions;
        const versions = uniq(map(preparedOrederVersions, 'version'));

        const content = (
            <List
                size="small"
                dataSource={versions}
                renderItem={(item) => {
                    const localOrderVersions = orderBy(
                        filter(preparedOrederVersions, { version: item }),
                        ['design_date'],
                        ['desc']
                    );
                    // if (scope === 'doctor') {
                    //     localOrderVersions = filter(
                    //         preparedOrederVersions,
                    //         (it) => it.public
                    //     );
                    // }

                    const localVersion = localOrderVersions[0];

                    const href = `/${scope}/order-details?id=${orderId}&revision=${localVersion.id}`;
                    const result = (
                        <List.Item>
                            <NavLink to={href}>{`${item}: ${moment(
                                localVersion.design_date
                            ).format('DD/MM/YY HH:mm:ss')}`}</NavLink>
                        </List.Item>
                    );

                    return result;
                }}
            />
        );
        return (
            <PopoverWithPointer
                content={content}
                trigger="hover"
                placement="bottom"
            >
                <Tag color="purple">{versions.length}</Tag>
            </PopoverWithPointer>
        );
    }
}

export default OrderVersionPopover;
