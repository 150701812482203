import { actionTypes } from '../actions';

export const primaryInitialState = {
    token: null,
    refreshToken: null
};

const AuthReducer = (state = primaryInitialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_REFRESH_TOKEN:
            return {
                ...state,
                ...{ refreshToken: action.token }
            };
        case actionTypes.SAVE_TOKEN:
            return {
                ...state,
                ...{ token: action.token }
            };
        default:
            return state;
    }
};

export default AuthReducer;
