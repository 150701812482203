// const GATEWAY_HOST = 'https://depo.better-call-soul.com';
// const GATEWAY_API = 'http://84.201.151.40:1338';
// const AUTH_HOST = 'http://84.201.151.40:1338';

const GRID_CONFIG_KEY = 'order-table-config';
const UI_HOST = 'https://lk.pumpalab.ru';
const GATEWAY_HOST = 'https://lk.pumpalab.ru';
const GATEWAY_API = 'http://212.113.123.222:1887';
const AUTH_HOST = 'http://212.113.123.222:1887';
const AUTH_PROVIDER = 'keycloak3';
const EDITOR_HOST = `${UI_HOST}/editor`;
const PRINTER_HOST = `${UI_HOST}/printer`;
// LOGOUT
const realm = 'ortodepo';
const redirectUri = UI_HOST;
const AUTH_HOST_LOGOUT = `https://auth.pumpalab.ru/realms/${realm}/protocol/openid-connect/logout?post_logout_redirect_uri=${redirectUri}&client_id=proddepo`;

export {
    GATEWAY_HOST,
    AUTH_HOST,
    GATEWAY_API,
    AUTH_PROVIDER,
    GRID_CONFIG_KEY,
    AUTH_HOST_LOGOUT,
    UI_HOST,
    EDITOR_HOST,
    PRINTER_HOST
};
