import { actionTypes } from '../actions';

export const primaryInitialState = {
    users: [],
    usersLoading: false,
    usersCount: 0,
    pagination: { current: 1, pageSize: 100 },
    role: 'doctor',
    search: '',
    sorter: {
        order: 'descend',
        field: 'latest',
        columnKey: 'latest'
    },
    filters: []
};

const UserReducer = (state = primaryInitialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_USERS: {
            return {
                ...state,
                ...{
                    usersLoading: true,
                    users: []
                }
            };
        }
        case actionTypes.USER_ENTRY: {
            return {
                ...state,
                ...{
                    usersLoading: true
                }
            };
        }
        case actionTypes.LOAD_USERS_ROLE_CHANGE: {
            return {
                ...state,
                ...{
                    usersLoading: true,
                    role: action.role
                }
            };
        }
        case actionTypes.LOAD_USERS_SEARCH_CHANGE: {
            return {
                ...state,
                ...{
                    usersLoading: true,
                    search: action.search
                }
            };
        }
        case actionTypes.LOAD_USERS_FILTERS: {
            return {
                ...state,
                ...{
                    pagination: action.pagination,
                    sorter: action.sorter,
                    filters: action.filters,
                    usersLoading: true
                }
            };
        }
        case actionTypes.LOAD_USERS_SUCCESS: {
            return {
                ...state,
                ...{
                    usersLoading: false,
                    users: action.users.users,
                    pagination: {
                        current: state.pagination.current,
                        pageSize: state.pagination.pageSize,
                        total: action.users.usersCount
                    }
                }
            };
        }
        default:
            return state;
    }
};

export default UserReducer;
