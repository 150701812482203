import React from 'react';
import { Select, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { getUserName } from '../../utils/user';

const { Option } = Select;

export const UserSelector = (props: any) => {
    const { onChange, type, defaultValue, data } = props;
    const texts = useSelector((state: any) => state.CommonReducer.texts);

    return (
        <Tooltip placement="top" title={texts[type].value}>
            <Select
                showSearch
                style={{ width: 220, marginRight: 4 }}
                optionFilterProp="children"
                onChange={onChange}
                value={defaultValue}
            >
                <Option value="all">
                    {texts[`notification_${type}`].value}
                </Option>
                {data &&
                    data
                        .filter((item: any) => item.role.name === type)
                        .map((t: any) => (
                            <Option key={t.id}>{getUserName(t)}</Option>
                        ))}
                {/* <Option value="none">Не указан</Option> */}
            </Select>
        </Tooltip>
    );
};
