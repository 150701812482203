import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const StatusDescription = (props: any) => {
    const { item, role } = props;
    const texts = useSelector((state: any) => state.CommonReducer.texts);

    let result = <div>-</div>;

    if (item.order) {
        const orderLink = `/${role}/order-details?id=${item.order.id}&revision=latest&notification=${item.id}`;
        result = (
            <>
                <div>
                    {`${texts['order.patient'].value}: `}
                    <Link to={orderLink}>
                        {item.order ? item.order.patient : ''}
                    </Link>
                    {`; ${texts['order.bill'].value}: `}
                    <Link to={orderLink}>
                        {item.order.bill ? item.order.bill : '-'}
                    </Link>
                </div>
                {role !== 'doctor' && (
                    <div>{`${texts.user.value}: ${item.from}`}</div>
                )}
            </>
        );
    }

    return result;
};
