import React from 'react';
import { NavLink } from 'react-router-dom';
import { ICellRendererParams } from 'ag-grid-community';

interface CustomCellRendererParams extends ICellRendererParams {
    role: string;
}

export const ClientLinkRenderer = (props: CustomCellRendererParams) => {
    const { data: row, value, role } = props;
    if (!row) {
        return null;
    }
    return <NavLink to={`/${role}/client?id=${row.id}`}>{value}</NavLink>;
};
