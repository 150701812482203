import React from 'react';
import moment from 'moment';
import 'moment/min/locales';

interface DateTimeRendererProps {
    date: number;
    pattern?: string;
    relative?: boolean;
    displayTime?: boolean;
}
const InternalDateRenderer = (props: DateTimeRendererProps) => {
    const { date, pattern } = props;
    const formattedDate = moment(date).format(pattern);
    return <span>{formattedDate}</span>;
};

export default InternalDateRenderer;
