import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Comment,
    Empty,
    Form,
    Icon,
    Input,
    List,
    message,
    Modal
} from 'antd';
import styled from 'styled-components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    changeUploadInternalMessageImage,
    onSaveInternalMessageSuccess
} from '../../redux/actions';
import OrderAuthor from './OrderAuthor';
import SimpleUpload from '../NewOrder/SimpleUpload';
import OrderImageGallery from './OrderImageGallery';
import { GATEWAY_HOST } from '../../utils/properties';
import { CenteredSpinner } from '../Spinner/CenteredSpinner';
import { isEmpty } from '../../utils/common';

const EmptyIcon = styled(Icon)`
    svg {
        width: 64px;
        height: auto;
    }
`;

const ChatIcon = styled(Icon)`
    svg {
        width: 32px;
        height: auto;
    }
`;

const EmptyBlock = styled(Empty)`
    margin: 32px 0;
    .ant-empty-image {
        height: auto;
    }
`;

const CommentStyled = styled(Comment)`
    .ant-comment-content-author {
        height: 38px;
    }
`;

const SubmitContainer = styled.div`
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
`;

const { TextArea } = Input;

const Editor = ({
    onChange,
    onSubmit,
    submitting,
    value,
    title,
    texts,
    image,
    token,
    onHandleChangeImage,
    onHandlePreview
}: any) => (
    <div>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} />
        </Form.Item>
        <SubmitContainer>
            <SimpleUpload
                texts={texts}
                image={image}
                handleChangeImage={onHandleChangeImage}
                handlePreview={onHandlePreview}
                token={token}
            />
            <Button
                htmlType="submit"
                loading={submitting}
                onClick={onSubmit}
                type="primary"
            >
                {title}
            </Button>
        </SubmitContainer>
    </div>
);

export const sendMessage = async (data: any) => {
    const {
        jwt,
        orderId,
        value,
        author,
        date,
        doctor,
        authorId,
        authorRole,
        image
    } = data;
    // jwt: token,
    //         orderId,
    //         revisionId,
    //         value,
    //         author,
    //         date: moment.utc().format(),
    //         doctor: order.doctor.id

    const body: any = {
        author,
        comment: value,
        date,
        order: orderId,
        order_id: `${orderId}`,
        recepient_id: `${doctor}`,
        author_id: `${authorId}`,
        role: authorRole
    };
    try {
        const imageToSend =
            image[0].response[0].hash + image[0].response[0].ext;
        body.image = imageToSend;
    } catch (e) {
        console.debug(e);
    }

    return fetch(`${GATEWAY_HOST}/comments`, {
        headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(body)
    });
};

export const OrderInternalComments = (props: any) => {
    const { orderId } = props;
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [value, setValue] = useState('');
    const [previewVisible, setPreviewVisible] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const image = useSelector(
        (state: any) => state.MessageReducer.internalImage
    );
    const order = useSelector((state: any) => state.OrderReducer.selectedOrder);
    const token = useSelector((state: any) => state.AuthReducer.token);
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const profile = useSelector((state: any) => state.ProfileReducer.profile);
    const isUploading = useSelector(
        (state: any) => state.MessageReducer.internalIsUploading
    );

    const { isLoading, data } = useQuery(['order-comments', orderId], () =>
        fetch(`${GATEWAY_HOST}/comments?order=${orderId}&_sort=date:ASC`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }).then((res) => res.json())
    );

    const { mutate } = useMutation('send-message', sendMessage, {
        onSuccess: () => {
            queryClient.refetchQueries(['order-comments', orderId]);
            setIsSending(false);
            dispatch(onSaveInternalMessageSuccess());
        },
        onError: () => {
            message.error('Возникла ошибка, попробуйте позже.');
            setIsSending(false);
        },
        onSettled: () => {
            // queryClient.invalidateQueries('create');
        }
    });

    if (isLoading) return <CenteredSpinner />;

    const handlePreview = (file: any) => {
        setPreviewImage(file.url || file.thumbUrl);
        setPreviewVisible(true);
    };

    const handleCancel = () => {
        setPreviewImage('');
        setPreviewVisible(false);
    };

    const handleSubmit = () => {
        setIsSending(true);
        let author = '';

        if (!isEmpty(profile.lastName)) {
            author += profile.lastName;
        }

        if (!isEmpty(profile.firstName)) {
            author += ` ${profile.firstName}`;
        }

        if (!isEmpty(profile.middleName)) {
            author += ` ${profile.middleName}`;
        }

        const body = {
            jwt: token,
            orderId,
            value,
            author,
            date: moment.utc().format(),
            doctor: order.doctor.id,
            authorId: profile.id,
            authorRole: profile.role.name,
            image
        };

        mutate(body);
        setValue('');
    };

    const handleChange = (e: any) => {
        setValue(e.target.value);
    };

    const onHandleChangeImage = (p: any) => {
        const { file, fileList } = p;
        dispatch(changeUploadInternalMessageImage(fileList, file.status));
    };

    let content = (
        <EmptyBlock
            description={texts['order.feedback.start'].value}
            image={
                <EmptyIcon
                    type="message"
                    theme="twoTone"
                    twoToneColor="#eb2f96"
                />
            }
        />
    );
    if (data.length > 0) {
        content = (
            <List
                className="comment-list"
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item: any) => {
                    let color = item.role === 'doctor' ? '#3C9FD9' : '#3CBB9E';
                    if (item.role === 'technician' || item.role === 'otk') {
                        color = '#eb2f96';
                    }
                    let messageImage;
                    if (item.image) {
                        const images = [
                            {
                                original: `${GATEWAY_HOST}/uploads/${item.image}`,
                                thumbnail: `${GATEWAY_HOST}/uploads/${item.image}`,
                                title: '',
                                index: 0
                            }
                        ];
                        messageImage = <OrderImageGallery items={images} />;
                    }
                    return (
                        <li>
                            <CommentStyled
                                actions={item.actions}
                                author={
                                    <OrderAuthor
                                        author={item.author}
                                        role={texts[`role.${item.role}`].value}
                                    />
                                }
                                avatar={
                                    <ChatIcon
                                        type="message"
                                        theme="twoTone"
                                        twoToneColor={color}
                                    />
                                }
                                content={item.comment}
                                datetime={moment(item.date).format(
                                    'DD/MM/YY HH:mm:ss'
                                )}
                            />
                            {messageImage}
                        </li>
                    );
                }}
            />
        );
    }
    return (
        <>
            {content}
            <Comment
                content={
                    <Editor
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        onHandlePreview={handlePreview}
                        onHandleChangeImage={onHandleChangeImage}
                        submitting={isSending}
                        value={value}
                        texts={texts}
                        token={token}
                        image={image}
                        title={texts['order.feedback.add'].value}
                    />
                }
            />
            <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handleCancel}
            >
                <img
                    alt="example"
                    style={{ width: '100%', height: 'auto' }}
                    src={previewImage}
                />
            </Modal>
        </>
    );
};
