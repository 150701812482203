import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spin } from 'antd';
import styled from 'styled-components';
import MessageList from '../../components/Message/MessageList';
import MessageComments from '../../components/Message/MessageComments';
import {
    updateProfile,
    loadOrdersMessages,
    onSelectMessageOrder
} from '../../redux/actions';

const FitRow = styled(Row)`
    position: absolute;
    top: 16px;
    bottom: 16px;
    right: 16px;
    left: 16px;
`;

const OverflowCol = styled(Col)`
    overflow: auto;
    height: 100%;
`;

export const DoctorMessagesPage = () => {
    const dispatch = useDispatch();
    const doctor = useSelector((state: any) => state.ProfileReducer.profile);
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const orders = useSelector((state: any) => state.MessageReducer.orders);
    const ordersLoading = useSelector(
        (state: any) => state.MessageReducer.ordersLoading
    );
    const selectedOrder = useSelector(
        (state: any) => state.MessageReducer.selectedOrder
    );

    useEffect(() => {
        dispatch(loadOrdersMessages());
    }, []);

    if (doctor === null) {
        return <Spin />;
    }

    const onSelectOrder = (order: any) => {
        dispatch(onSelectMessageOrder(order));
    };

    return (
        <FitRow gutter={16}>
            <OverflowCol sm={24} md={12} lg={12} xl={6}>
                <MessageList
                    texts={texts}
                    scope="doctor"
                    orders={orders}
                    profile={doctor}
                    ordersLoading={ordersLoading}
                    onSelectOrder={(item: any) => onSelectOrder(item)}
                    selectedOrder={selectedOrder}
                />
            </OverflowCol>
            <OverflowCol sm={24} md={12} lg={12} xl={18}>
                <MessageComments />
            </OverflowCol>
        </FitRow>
    );
};
