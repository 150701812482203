import React, { useEffect, useState } from 'react';
import { Modal, Radio, Select, InputNumber, message } from 'antd';
import { useSelector } from 'react-redux';
import { keys } from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { GATEWAY_HOST } from '../../utils/properties';
import { CenteredSpinner } from '../Spinner/CenteredSpinner';

const { Option, OptGroup } = Select;

export const updateOrderAligners = async (data: any) => {
    const { orderId, acase, atype, adata, adatalower, jwt } = data;

    const body: any = {
        acase,
        atype,
        adata,
        adatalower
    };

    return fetch(`${GATEWAY_HOST}/orders/dates/${orderId}`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
        }
    });
};

const getOptions = (numberOfSteps: number) => {
    const result: any[] = [];
    for (let i = 0; i < numberOfSteps; i += 1) {
        result.push(
            <Option key={`step${i + 1}`} value={`${i + 1}`}>
                {i + 1}
            </Option>
        );
    }
    return result;
};

const getTemplates = (
    alignersData: any,
    numberOfTemplates: number | undefined,
    onChange: any,
    numberOfSteps: number,
    templateTitle: string,
    beforeTitle: string,
    jaw: string
) => {
    const result: any[] = [];
    if (numberOfTemplates) {
        for (let i = 1; i <= numberOfTemplates; i += 1) {
            result.push(
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        paddingLeft: 90,
                        paddingTop: 4
                    }}
                    key={i}
                >
                    <div>{`${templateTitle} №${i} ${beforeTitle}: `}</div>
                    <Select
                        value={alignersData[i]}
                        style={{ width: 100 }}
                        onChange={(value: any) => onChange(i, value, jaw)}
                    >
                        {getOptions(numberOfSteps)}
                    </Select>
                </div>
            );
        }
    }
    return result;
};

const getCaseType = (
    acase: string | undefined,
    title: string,
    onChange: any
) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                paddingTop: 12
            }}
        >
            <div>{`${title}: `}</div>
            <Select
                value={acase}
                style={{ width: 300 }}
                onChange={(value: any) => onChange(value)}
            >
                <Option key="aligner" value="aligner">
                    Элайнер
                </Option>
                <Option key="template" value="template">
                    Шаблон для аттачментов
                </Option>
                <Option key="fitfree10" value="fitfree10">
                    ФитФри 10
                </Option>
                <Option key="fitfree20" value="fitfree20">
                    ФитФри 20
                </Option>
                <Option key="pro" value="pro">
                    ФитФри ПРО
                </Option>
                <OptGroup label="Устаревшие">
                    <Option key="fast" value="fast">
                        ФитФри Фаст
                    </Option>
                    <Option key="simple" value="simple">
                        ФитФри Симпл
                    </Option>
                    <Option key="basic" value="basic">
                        ФитФри Бэйсик
                    </Option>
                    <Option key="complex" value="complex">
                        ФитФри Комплекс
                    </Option>
                    <Option key="expert" value="expert">
                        ФитФри Эксперт
                    </Option>
                </OptGroup>
            </Select>
        </div>
    );
};

export const AlignersInfoModal = (props: any) => {
    const { visible, handleOk, handleCancel, orderId, slices } = props;
    const [queryEnabled, setQueryEnabled] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const token = useSelector((state: any) => state.AuthReducer.token);
    const [alignersType, setAlignersType] = useState<string | undefined>(
        'main'
    );
    const [alignersCase, setAlignersCase] = useState<string | undefined>(
        'aligner'
    );
    const [alignersData, setAlignersData] = useState<any>({});
    const [alignersDataLower, setAlignersDataLower] = useState<any>({});
    const [numberOfTemplates, setNumberOfTemplates] = useState<
        number | undefined
    >(0);
    const [numberOfTemplatesLower, setNumberOfTemplatesLower] = useState<
        number | undefined
    >(0);
    const [numberOfSteps] = useState<number>(keys(slices).length - 1);

    const onTemplateConfigChange = (
        key: string,
        value: string,
        jaw: string
    ) => {
        if (jaw === 'upper') {
            const newState = { ...alignersData, [key]: value };
            setAlignersData(newState);
        } else if (jaw === 'lower') {
            const newState = { ...alignersDataLower, [key]: value };
            setAlignersDataLower(newState);
        }
    };

    const { isLoading, data } = useQuery(
        ['order-aligners-details', orderId],
        () =>
            fetch(`${GATEWAY_HOST}/orders?id=${orderId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }).then((res) => res.json()),
        { enabled: queryEnabled }
    );

    useEffect(() => {
        if (data) {
            setAlignersType(data[0].atype ? data[0].atype : undefined);
            setAlignersCase(data[0].acase ? data[0].acase : undefined);
            setAlignersData(data[0].adata ? data[0].adata : {});
            setAlignersDataLower(data[0].adatalower ? data[0].adatalower : {});
            setNumberOfTemplates(
                data[0].adata ? keys(data[0].adata).length : 0
            );
            setNumberOfTemplatesLower(
                data[0].adatalower ? keys(data[0].adatalower).length : 0
            );
            // setBilldate(data[0].bill_date ? moment(data[0].bill_date) : null);
            // setDeadline(data[0].deadline ? moment(data[0].deadline) : null);
        }
    }, [data]);

    useEffect(() => {
        if (orderId) {
            setQueryEnabled(true);
        } else {
            setQueryEnabled(false);
        }
    }, [orderId]);

    const { mutate } = useMutation(
        'update-order-aligners',
        updateOrderAligners,
        {
            onSuccess: () => {
                setQueryEnabled(false);
                setIsSaving(false);
                message.success(texts['order.labels.aligners.success'].value);
                handleOk();
            },
            onError: () => {
                message.error(texts['order.labels.aligners.error'].value);
            },
            onSettled: () => {}
        }
    );

    return (
        <Modal
            title={texts['order.aligners.addition.full'].value}
            visible={visible}
            maskClosable={false}
            onOk={() => {
                setIsSaving(true);
                const body = {
                    jwt: token,
                    orderId,
                    acase: alignersCase,
                    adata: alignersData,
                    adatalower: alignersDataLower,
                    atype: alignersType
                };
                mutate(body);
            }}
            onCancel={() => {
                setQueryEnabled(false);
                handleCancel();
            }}
            okButtonProps={{ loading: isSaving }}
            okText={texts.save.value}
            cancelText={texts.cancel.value}
        >
            {isLoading || !orderId || !data ? (
                <CenteredSpinner />
            ) : (
                <div>
                    <div>
                        <div>
                            {`${texts['order.labels.aligners.type'].value}:`}
                        </div>
                        <Radio.Group
                            style={{ paddingTop: 8 }}
                            onChange={(e) => {
                                setAlignersType(e.target.value);
                            }}
                            value={alignersType}
                        >
                            <Radio value="main">
                                {texts['order.labels.aligners.main'].value}
                            </Radio>
                            <Radio value="addition">
                                {texts['order.labels.aligners.addition'].value}
                            </Radio>
                        </Radio.Group>
                    </div>
                    {getCaseType(
                        alignersCase,
                        texts['order.labels.aligners.case'].value,
                        (acase: string) => {
                            setAlignersCase(acase);
                        }
                    )}
                    <div style={{ paddingTop: 12, fontWeight: 600 }}>
                        {`${texts['order.labels.aligners.upper'].value}`}
                    </div>
                    <div
                        style={{
                            paddingTop: 4,
                            marginBottom: 4,
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px'
                        }}
                    >
                        <div>
                            {`${texts['order.labels.aligners.numberoftemplates'].value}:`}
                        </div>
                        <InputNumber
                            min={0}
                            max={30}
                            value={numberOfTemplates}
                            defaultValue={0}
                            width={100}
                            style={{ width: 100 }}
                            onChange={(newValue) => {
                                setNumberOfTemplates(newValue);
                                if (newValue) {
                                    onTemplateConfigChange(
                                        `${newValue}`,
                                        '1',
                                        'upper'
                                    );
                                } else {
                                    setAlignersData({});
                                }
                            }}
                        />
                    </div>
                    {getTemplates(
                        alignersData,
                        numberOfTemplates,
                        onTemplateConfigChange,
                        numberOfSteps,
                        texts.template.value,
                        texts['order.labels.aligners.beforestep'].value,
                        'upper'
                    )}
                    <div style={{ paddingTop: 12, fontWeight: 600 }}>
                        {`${texts['order.labels.aligners.lower'].value}`}
                    </div>
                    <div
                        style={{
                            paddingTop: 4,
                            marginBottom: 4,
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px'
                        }}
                    >
                        <div>
                            {`${texts['order.labels.aligners.numberoftemplates'].value}:`}
                        </div>
                        <InputNumber
                            min={0}
                            max={30}
                            value={numberOfTemplatesLower}
                            defaultValue={0}
                            width={100}
                            style={{ width: 100 }}
                            onChange={(newValue) => {
                                setNumberOfTemplatesLower(newValue);
                                if (newValue) {
                                    onTemplateConfigChange(
                                        `${newValue}`,
                                        '1',
                                        'lower'
                                    );
                                } else {
                                    setAlignersDataLower({});
                                }
                            }}
                        />
                    </div>
                    {getTemplates(
                        alignersDataLower,
                        numberOfTemplatesLower,
                        onTemplateConfigChange,
                        numberOfSteps,
                        texts.template.value,
                        texts['order.labels.aligners.beforestep'].value,
                        'lower'
                    )}
                </div>
            )}
        </Modal>
    );
};
