import { Badge as AntBadge } from 'antd';
import styled from 'styled-components';

const Badge = styled(AntBadge)`
    &.ant-badge {
        display: inline;
    }

    .ant-badge-status-text {
        font-size: 14px;
        color: ${(props) => props.theme.textColor};
    }
`;

export default Badge;
