import React, { ReactNode, Component } from 'react';
import styled from 'styled-components';
import { Button, Checkbox } from 'antd';
import { IAfterGuiAttachedParams } from 'ag-grid-community';
import { GridFilterParams } from './grid-filter-params';
import { SimpleMap } from '../Common/simple-map';

type FilterState = {
    values: SimpleMap<boolean>;
    hideFilter: any;
};

const ColumnsSettingsPopoverContent = styled.div`
    background: #fff;
    padding: 12px 16px;
    width: 200px;
`;

const ColumnSelectionDropdown = styled.div`
    margin-top: 8px;
    margin-bottom: 16px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    > :not(:last-child) {
        margin-right: 8px;
    }
`;

export class ColumnListFilter extends Component<GridFilterParams, FilterState> {
    constructor(props: GridFilterParams) {
        super(props);
        this.state = {
            values: {},
            hideFilter: null
        };
    }

    onFilterChange(name: string) {
        const { values } = this.state;
        const newValues = { ...values };
        const prevValue = name in values ? values[name] : false;
        if (prevValue) {
            delete newValues[name];
        } else {
            newValues[name] = true;
        }
        this.setState({ values: newValues });
    }

    setModel(value: string) {
        this.setState({
            values: {
                [value]: true
            }
        });
    }

    getModel() {
        const { values } = this.state;
        return Object.keys(values).length > 0 ? { values } : null;
    }

    isFilterActive() {
        const { values } = this.state;
        return Object.keys(values).length > 0;
    }

    afterGuiAttached(params: IAfterGuiAttachedParams) {
        this.setState({
            hideFilter: params.hidePopup
        });
    }

    render() {
        const { values: allValues, filterChangedCallback } = this.props;
        const { values, hideFilter } = this.state;
        const columnCheckboxes = [] as ReactNode[];
        let filterContent;
        if (allValues) {
            Object.keys(allValues)
                .sort()
                .forEach((key) => {
                    columnCheckboxes.push(
                        <div key={key}>
                            <Checkbox
                                checked={key in values ? values[key] : false}
                                onChange={() => {
                                    this.onFilterChange(key);
                                }}
                            >
                                {allValues[key]}
                            </Checkbox>
                        </div>
                    );
                });
            filterContent = (
                <ColumnSelectionDropdown>
                    {columnCheckboxes}
                </ColumnSelectionDropdown>
            );
        }
        return (
            <ColumnsSettingsPopoverContent>
                {filterContent}
                <ButtonsContainer>
                    <Button
                        onClick={() => {
                            this.setState(
                                {
                                    values: {}
                                },
                                () => {
                                    filterChangedCallback();
                                    hideFilter();
                                }
                            );
                        }}
                    >
                        Clear
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            filterChangedCallback();
                            hideFilter();
                        }}
                    >
                        Apply
                    </Button>
                </ButtonsContainer>
            </ColumnsSettingsPopoverContent>
        );
    }
}
