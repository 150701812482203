import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

const CardTitle = styled.div`
    font-weight: 300;
    /* text-transform: uppercase; */
`;

const FieldRight = styled.div`
    font-size: 14px;
    font-weight: 300;
    float: right;
    color: rgba(0, 0, 0, 0.45);
`;

const RowGuttered = styled(Row)`
    margin-bottom: 16px;
`;

class OrderCardTitle extends Component {
    render() {
        const { order, scope } = this.props;
        const { id, latest } = order;
        const href = `/${scope}/order-details?id=${id}&revision=latest`;
        return (
            <>
                <RowGuttered type="flex" justify="space-between">
                    <Col span={12}>
                        <CardTitle>
                            <NavLink to={href}>{order.patient}</NavLink>
                        </CardTitle>
                    </Col>
                    <Col span={12}>
                        <FieldRight>
                            <span>
                                {moment(latest).format('DD/MM/YY') || '-'}
                            </span>
                        </FieldRight>
                    </Col>
                </RowGuttered>
            </>
        );
    }
}

export default OrderCardTitle;
