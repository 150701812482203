import React, { Component } from 'react';
import { Avatar, Button, Icon, Menu } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import HeaderDropdown from './HeaderDropdown';
import HeaderItem from './HeaderItem';
import { logout } from '../../utils/auth';

const StyledIcon = styled(Icon)`
    position: relative;
    top: 26px;
    vertical-align: top;
`;

const UserAvatar = styled(Avatar)`
    background: none;
    color: #101a4c;

    i {
        color: #101a4c;
    }
`;

const UserName = styled.span`
    padding-right: 16px;
`;

class UserAvatarDropdown extends Component {
    logout = () => {
        logout();
    };

    render() {
        const { profile, profileText, logoutText } = this.props;

        const body = (
            <HeaderItem>
                <UserAvatar color="red" icon="user" />
                <UserName>{profile.username}</UserName>
                <StyledIcon type="down" />
            </HeaderItem>
        );
        const menu = (
            <Menu>
                <Menu.Item>
                    <Link to="/profile">
                        <span>{profileText}</span>
                    </Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item>
                    <Button onClick={this.logout}>{logoutText}</Button>
                </Menu.Item>
            </Menu>
        );
        return (
            <HeaderDropdown
                placement="bottomRight"
                // overlay={notificationBox}
                // overlayClassName={styles.popover}
                trigger={['click']}
                // visible={true}
                onVisibleChange={this.handleVisibleChange}
                body={body}
                overlay={menu}
                // {...popoverProps}
                // ref={node => (this.popover = ReactDOM.findDOMNode(node))} // eslint-disable-line
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        profile: state.ProfileReducer.profile,
        profileText: state.CommonReducer.texts['menu.profile'].value,
        logoutText: state.CommonReducer.texts.logout.value
    };
}

export default connect(mapStateToProps)(UserAvatarDropdown);
