import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import InternalDateTimeRenderer from './InternalDateRenderer';

export const DateRenderer = (props: ICellRendererParams) => {
    const { data: row, value } = props;
    const dateMapper = {
        relative: false,
        displayTime: false,
        pattern: 'DD.MM.YYYY'
    };
    let result = <span />;
    if (value) {
        result = (
            <InternalDateTimeRenderer
                date={value}
                relative={dateMapper.relative}
                displayTime={dateMapper.displayTime}
                pattern={dateMapper.pattern}
            />
        );
    }
    return result;
};
