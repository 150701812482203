import React, { Component } from 'react';
import { Badge } from 'antd';
import { find } from 'lodash';
import styled from 'styled-components';
import { isEmpty } from '../../utils/common';

const MessagePreview = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

class MessageCardBody extends Component {
    static takeLatest(order, profile, texts) {
        const { messages, viewers } = order;
        const viewer = find(viewers, (o) => o.user_id === profile.id);
        let unread = true;
        if (!isEmpty(viewer)) {
            unread = viewer.when < order.latest_message;
        }
        let result = {
            unread: false,
            text: ''
        };
        if (isEmpty(messages)) {
            result = {
                unread: false,
                text: texts['messages.no'].value
            };
        } else {
            const latestMessage = messages.slice(-1)[0];
            result = {
                unread,
                text: `${latestMessage.author}: ${latestMessage.comment}`
            };
        }
        return result;
    }

    render() {
        const { profile, order, selected, texts } = this.props;
        const badgeColor = selected ? '#fff' : '#3cbb9e';
        const message = MessageCardBody.takeLatest(order, profile, texts);
        return (
            <MessagePreview>
                <Badge hidden={!message.unread} color={badgeColor} />
                {message.text}
            </MessagePreview>
        );
    }
}

export default MessageCardBody;
