import React, {
    Suspense,
    useRef,
    useState,
    useEffect,
    useReducer
} from 'react';
import { Canvas, useFrame, useThree } from 'react-three-fiber';
import { keys } from 'lodash';
import { TrackballControls, useHelper } from 'drei';
import * as THREE from 'three';
import styled from 'styled-components';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import BgImage from '../../static/bg2-od.png';
import BgImageDark from '../../static/bg2-od-dark.png';
import { GATEWAY_HOST } from '../../utils/properties';

interface ShapeProperties {
    before?: boolean; // before or after
    after?: boolean; // before or after
    upper?: boolean;
    lower?: boolean;
    // Extra Addition
    extra: string;
    extraVisible: boolean;
    extraColor: string;
    extraShineness: number;
    extraOpacity: number;
    // Model
    model: string; // upper jaw before
    modelAfter?: string; // lower jaw before
    modelLower?: string; // upper jaw after
    modelLowerAfter?: string; // lower jaw after
    modelVisible: boolean;
    modelColor: string;
    modelShineness: number;
    modelAfterColor: string;
    modelAfterShineness: number;
    // Maxillary
    maxillaryUpper?: string; // upper maxillary before
    maxillaryUpperAfter?: string; // lower maxillary before
    maxillaryLower?: string; // upper maxillary after
    maxillaryLowerAfter?: string; // lower maxillary after
    maxillaryVisible?: boolean;
    maxillaryColor?: string;
    maxillaryShineness?: number;
    maxillaryOpacity?: number;
    // Minivints
    minivints: string;
    minivintsVisible: boolean;
    minivintsColor: string;
    minivintsShineness: number;
    // Tool
    tool: string;
    toolVisible: boolean;
    toolColor: string;
    toolShineness: number;
    // braces
    bracesUpper?: string;
    bracesLower?: string;
    bracesVisible?: boolean;
    bracesColor?: string;
    bracesShineness?: number;
    // template
    template: string;
    templateVisible: boolean;
    templateColor: string;
    templateShineness: number;
    // tray
    tray: string;
    trayVisible: boolean;
    trayColor: string;
    trayShineness: number;
    trayOpacity: number;
    // splint
    splint: string;
    splintVisible: boolean;
    splintColor: string;
    splintShineness: number;
    splintOpacity: number;
    bordersVisible: boolean;
    theme?: string;
    slices?: any;
    sliceIndex?: string;
    sceneView?: any;
    showOverLayer?: boolean;
    onCompletelyLoaded?: any;
}

const Container = styled.div`
    /* background: url(${BgImage}); */
    height: 600px;
    background: url(${(props) =>
        props.theme === 'dark' ? BgImageDark : BgImage});
    background-size: contain;
`;

const sliceReducer = (state: any, action: any) => {
    const newState = { ...state };
    const a = new THREE.Geometry().fromBufferGeometry(action.geometry);
    a.mergeVertices();
    a.computeVertexNormals();
    a.computeMorphNormals();
    // a.computeFaceNormals();
    // newState[action.index] = a;
    newState[action.index] = new THREE.BufferGeometry().fromGeometry(a);
    // newState[action.index] = action.geometry;
    return newState;
};

function CustomScene(props: ShapeProperties) {
    // const bg = useLoader(THREE.TextureLoader, BgImage);
    const controls: any = useRef();
    const {
        before,
        after,
        upper,
        lower,
        extra,
        extraVisible,
        extraColor,
        extraShineness,
        extraOpacity,
        minivints,
        minivintsVisible,
        minivintsColor,
        minivintsShineness,
        template,
        templateVisible,
        templateColor,
        templateShineness,
        maxillaryUpper,
        maxillaryUpperAfter,
        maxillaryLower,
        maxillaryLowerAfter,
        maxillaryColor,
        maxillaryShineness,
        maxillaryOpacity,
        maxillaryVisible,
        // upper jaw model (before)
        model,
        // lower jaw model (before)
        modelLower,
        // upper jaw model (after)
        modelAfter,
        // lower jaw model (after)
        modelLowerAfter,
        modelVisible,
        modelColor,
        modelShineness,
        modelAfterColor,
        modelAfterShineness,
        tool,
        toolVisible,
        toolColor,
        toolShineness,
        bracesUpper,
        bracesLower,
        bracesVisible,
        bracesColor,
        bracesShineness,
        tray,
        trayVisible,
        trayColor,
        trayShineness,
        trayOpacity,
        // splint
        splint,
        splintVisible,
        splintColor,
        splintShineness,
        splintOpacity,
        slices,
        sliceIndex,
        sceneView,
        showOverLayer,
        onCompletelyLoaded
    } = props;

    const overLayerOpacity = 0.8;
    const overLayerColor = new THREE.Color(0x6b7198);

    const [templateGeometry, setTemplateGeometry] = useState<any>(undefined);
    // maxillary
    const [maxillaryUpperGeometry, setMaxillaryUpperGeometry] = useState<any>(
        undefined
    );
    const [
        maxillaryUpperAfterGeometry,
        setMaxillaryUpperAfterGeometry
    ] = useState<any>(undefined);
    const [
        maxillaryUpperAfterGeometryOver,
        setMaxillaryUpperAfterGeometryOver
    ] = useState<any>(undefined);
    const [maxillaryLowerGeometry, setMaxillaryLowerGeometry] = useState<any>(
        undefined
    );
    const [
        maxillaryLowerAfterGeometry,
        setMaxillaryLowerAfterGeometry
    ] = useState<any>(undefined);
    const [
        maxillaryLowerAfterGeometryOver,
        setMaxillaryLowerAfterGeometryOver
    ] = useState(undefined);
    // model
    const [modelGeometry, setModelGeometry] = useState<any>(undefined);
    const [modelLowerGeometry, setModelLowerGeometry] = useState<any>(
        undefined
    );
    const [modelAfterGeometry, setModelAfterGeometry] = useState<any>(
        undefined
    );
    const [modelAfterGeometryOver, setModelAfterGeometryOver] = useState<any>(
        undefined
    );
    const [modelLowerAfterGeometry, setModelLowerAfterGeometry] = useState<any>(
        undefined
    );
    const [
        modelLowerAfterGeometryOver,
        setModelLowerAfterGeometryOver
    ] = useState(undefined);
    const [extraGeometry, setExtraGeometry] = useState<any>(undefined);
    const [minivintsGeometry, setMinivintsGeometry] = useState<any>(undefined);
    const [toolGeometry, setToolGeometry] = useState<any>(undefined);
    const [bracesUpperGeometry, setbracesUpperGeometry] = useState<any>(
        undefined
    );
    const [bracesUpperGeometryOver, setbracesUpperGeometryOver] = useState<any>(
        undefined
    );
    const [bracesLowerGeometry, setbracesLowerGeometry] = useState<any>(
        undefined
    );
    const [bracesLowerGeometryOver, setbracesLowerGeometryOver] = useState<any>(
        undefined
    );
    const [trayGeometry, setTrayGeometry] = useState(undefined);
    const [splintGeometry, setSplintGeometry] = useState(undefined);
    const [sliceGeometry, dispatchSliceGeometry] = useReducer(sliceReducer, {});
    const hemisphereLightRef = useRef();
    const directionalLightRef1 = useRef();
    const directionalLightRef2 = useRef();
    const directionalLightRef3 = useRef();
    const directionalLightRef4 = useRef();
    const directionalLightRef5 = useRef();
    const directionalLightRef6 = useRef();
    const directionalLightRef7 = useRef();
    const directionalLightRef8 = useRef();
    // useHelper(hemisphereLightRef, THREE.HemisphereLightHelper, 70);
    // useHelper(directionalLightRef1, THREE.DirectionalLightHelper, 20, 0x404040);
    // useHelper(directionalLightRef2, THREE.DirectionalLightHelper, 20, 0x404040);
    // useHelper(directionalLightRef3, THREE.DirectionalLightHelper, 20, 0x404040);
    // useHelper(directionalLightRef4, THREE.DirectionalLightHelper, 20, 0x404040);
    // useHelper(directionalLightRef5, THREE.DirectionalLightHelper, 20, 0x404040);
    // useHelper(directionalLightRef6, THREE.DirectionalLightHelper, 20, 0x404040);
    // useHelper(directionalLightRef7, THREE.DirectionalLightHelper, 20, 0x404040);
    // useHelper(directionalLightRef8, THREE.DirectionalLightHelper, 20, 0x404040);
    const ambientLightColor = new THREE.Color(0x404040);
    const hemisphereLightSkyColor = new THREE.Color(0x404040);
    const hemisphereLightGroundColor = new THREE.Color(0x404040);
    const directionalLightColor = new THREE.Color(0xffffff);
    const directionalLightDarkColor = new THREE.Color(0xb1b1b1);
    const distIntensity = 1.0;

    const emissiveColor = new THREE.Color(0x0);
    const loader = new STLLoader();

    const calculateCenter = () => {
        const maxTrans = { x: 0, y: 0, z: 0 };
        const minTrans = { x: 0, y: 0, z: 0 };

        const group: any[] = slices
            ? [maxillaryUpperGeometry, maxillaryLowerGeometry]
            : [
                  templateGeometry,
                  modelGeometry,
                  modelLowerGeometry,
                  modelAfterGeometry,
                  modelLowerAfterGeometry,
                  maxillaryUpperGeometry,
                  maxillaryLowerGeometry,
                  maxillaryUpperAfterGeometry,
                  maxillaryLowerAfterGeometry,
                  extraGeometry,
                  minivintsGeometry,
                  toolGeometry,
                  bracesUpperGeometry,
                  bracesLowerGeometry,
                  trayGeometry,
                  splintGeometry
              ];
        for (let i = 0; i < group.length; i += 1) {
            if (group[i]) {
                group[i].computeBoundingBox();
                const bbox = group[i].boundingBox;
                if (bbox.min.x < minTrans.x) {
                    minTrans.x = bbox.min.x;
                }
                if (bbox.min.y < minTrans.y) {
                    minTrans.y = bbox.min.y;
                }
                if (bbox.min.z < minTrans.z) {
                    minTrans.z = bbox.min.z;
                }
                if (bbox.max.x > maxTrans.x) {
                    maxTrans.x = bbox.max.x;
                }
                if (bbox.max.y > maxTrans.y) {
                    maxTrans.y = bbox.max.y;
                }
                if (bbox.max.z > maxTrans.z) {
                    maxTrans.z = bbox.max.z;
                }
            }
        }
        const x = (minTrans.x + maxTrans.x) / 2;
        const y = (minTrans.y + maxTrans.y) / 2;
        const z = (minTrans.z + maxTrans.z) / 2;
        // const x = minTrans.x;
        // const y = minTrans.y;
        // const z = minTrans.z;
        if (controls.current) {
            if (controls.current.target) {
                controls.current.target.set(x, y, z);
            }
        }
    };

    useEffect(() => {
        const oldZoom = controls.current.object.zoom;
        controls.current.reset();
        controls.current.object.zoom = oldZoom;
        controls.current.zoomCamera();
        calculateCenter();
        controls.current.object.updateProjectionMatrix();
    }, [sceneView]);

    useEffect(() => {
        calculateCenter();
    }, [
        templateGeometry,
        minivintsGeometry,
        modelGeometry,
        modelLowerGeometry,
        modelAfterGeometry,
        modelLowerAfterGeometry,
        maxillaryUpperGeometry,
        maxillaryLowerGeometry,
        maxillaryUpperAfterGeometry,
        maxillaryLowerAfterGeometry,
        extraGeometry,
        toolGeometry,
        bracesUpperGeometry,
        bracesLowerGeometry,
        trayGeometry,
        splintGeometry
    ]);

    useEffect(() => {
        // console.log(slices);
        const sliceLevels = keys(slices).sort((a: any, b: any) => a - b);
        // console.log(sliceLevels);
        for (let i = 0; i < sliceLevels.length; i += 1) {
            const slice = slices[sliceLevels[i]];
            const modelKeys = [
                '0-down',
                '1-down',
                '2-down',
                '0-upper',
                '1-upper',
                '2-upper'
            ];
            for (let mi = 0; mi < modelKeys.length; mi += 1) {
                if (slice[modelKeys[mi]]) {
                    const modelUrl = `${GATEWAY_HOST}${slice[modelKeys[mi]]}`;
                    loader.load(modelUrl, (geometry: any) => {
                        dispatchSliceGeometry({
                            index: `${sliceLevels[i]}-${modelKeys[mi]}`,
                            geometry
                        });
                    });
                }
            }
            // 0-down - braces
            // 1-down - maxillary
            // 2-down - jaw
            // 0-upper - braces
            // 1-upper - maxillary
            // 2-upper - jaw
        }
    }, [slices]);

    useEffect(() => {
        if (sliceGeometry && slices) {
            // console.log(slices);
            const sliceLevels = keys(slices).sort((a: any, b: any) => a - b);
            // console.log(sliceLevels);
            let completelyLoaded = true;
            for (let i = 0; i < sliceLevels.length; i += 1) {
                const slice = slices[sliceLevels[i]];
                const modelKeys = [
                    // '0-down',
                    '1-down',
                    '2-down',
                    // '0-upper',
                    '1-upper',
                    '2-upper'
                ];
                for (let mi = 0; mi < modelKeys.length; mi += 1) {
                    if (slice[modelKeys[mi]]) {
                        if (
                            // `${sliceIndexStr}-0-down` in sliceGeometry &&
                            // `${sliceIndexStr}-0-upper` in sliceGeometry &&
                            !(`${sliceLevels[i]}-1-down` in sliceGeometry) ||
                            !(`${sliceLevels[i]}-1-upper` in sliceGeometry) ||
                            !(`${sliceLevels[i]}-2-down` in sliceGeometry) ||
                            !(`${sliceLevels[i]}-2-upper` in sliceGeometry)
                        ) {
                            completelyLoaded = false;
                            break;
                        }
                        // const modelUrl = `${GATEWAY_HOST}${
                        //     slice[modelKeys[mi]]
                        // }`;
                        // loader.load(modelUrl, (geometry: any) => {
                        //     dispatchSliceGeometry({
                        //         index: `${sliceLevels[i]}-${modelKeys[mi]}`,
                        //         geometry
                        //     });
                        // });
                    }
                    if (!completelyLoaded) {
                        break;
                    }
                }
                // 0-down - braces
                // 1-down - maxillary
                // 2-down - jaw
                // 0-upper - braces
                // 1-upper - maxillary
                // 2-upper - jaw
            }
            if (completelyLoaded) {
                onCompletelyLoaded();
            }
        }
    }, [sliceGeometry, slices]);

    useEffect(() => {
        if (sliceGeometry && sliceIndex) {
            const sliceIndexStr = `${sliceIndex}`.padStart(2, '0');
            if (
                // `${sliceIndexStr}-0-down` in sliceGeometry &&
                // `${sliceIndexStr}-0-upper` in sliceGeometry &&
                `${sliceIndexStr}-1-down` in sliceGeometry &&
                `${sliceIndexStr}-1-upper` in sliceGeometry &&
                `${sliceIndexStr}-2-down` in sliceGeometry &&
                `${sliceIndexStr}-2-upper` in sliceGeometry
            ) {
                if (`${sliceIndexStr}-0-down` in sliceGeometry) {
                    setbracesLowerGeometry(
                        sliceGeometry[`${sliceIndexStr}-0-down`]
                    );
                } else {
                    setbracesLowerGeometry(undefined);
                }

                if (`${sliceIndexStr}-0-upper` in sliceGeometry) {
                    setbracesUpperGeometry(
                        sliceGeometry[`${sliceIndexStr}-0-upper`]
                    );
                } else {
                    setbracesUpperGeometry(undefined);
                }
                setMaxillaryLowerAfterGeometry(
                    sliceGeometry[`${sliceIndexStr}-1-down`]
                );
                setMaxillaryUpperAfterGeometry(
                    sliceGeometry[`${sliceIndexStr}-1-upper`]
                );
                setModelLowerAfterGeometry(
                    sliceGeometry[`${sliceIndexStr}-2-down`]
                );
                setModelAfterGeometry(
                    sliceGeometry[`${sliceIndexStr}-2-upper`]
                );
            }
        }
    }, [sliceGeometry, sliceIndex]);

    useEffect(() => {
        if (sliceGeometry && slices) {
            const sliceLevels = keys(slices).sort((a: any, b: any) => a - b);
            // not last but first
            // const lastSliceIndex = sliceLevels[sliceLevels.length - 1];
            const lastSliceIndex = sliceLevels[0];
            const sliceIndexStr = `${lastSliceIndex}`.padStart(2, '0');
            if (
                // `${sliceIndexStr}-0-down` in sliceGeometry &&
                // `${sliceIndexStr}-0-upper` in sliceGeometry &&
                `${sliceIndexStr}-1-down` in sliceGeometry &&
                `${sliceIndexStr}-1-upper` in sliceGeometry &&
                `${sliceIndexStr}-2-down` in sliceGeometry &&
                `${sliceIndexStr}-2-upper` in sliceGeometry
            ) {
                if (showOverLayer) {
                    setbracesLowerGeometryOver(
                        sliceGeometry[`${sliceIndexStr}-0-down`]
                    );
                    setbracesUpperGeometryOver(
                        sliceGeometry[`${sliceIndexStr}-0-upper`]
                    );
                    setMaxillaryLowerAfterGeometryOver(
                        sliceGeometry[`${sliceIndexStr}-1-down`]
                    );
                    setMaxillaryUpperAfterGeometryOver(
                        sliceGeometry[`${sliceIndexStr}-1-upper`]
                    );
                    setModelLowerAfterGeometryOver(
                        sliceGeometry[`${sliceIndexStr}-2-down`]
                    );
                    setModelAfterGeometryOver(
                        sliceGeometry[`${sliceIndexStr}-2-upper`]
                    );
                } else {
                    setbracesLowerGeometryOver(undefined);
                    setbracesUpperGeometryOver(undefined);
                    setMaxillaryLowerAfterGeometryOver(undefined);
                    setMaxillaryUpperAfterGeometryOver(undefined);
                    setModelLowerAfterGeometryOver(undefined);
                    setModelAfterGeometryOver(undefined);
                }
            }
        }
    }, [showOverLayer, slices, sliceGeometry]);

    useEffect(() => {
        if (template) {
            loader.load(template, (geometry: any) => {
                const a = new THREE.Geometry().fromBufferGeometry(geometry);
                a.mergeVertices();
                a.computeVertexNormals();
                a.computeMorphNormals();
                // a.computeFaceNormals();
                // newState[action.index] = a;
                const g = new THREE.BufferGeometry().fromGeometry(a);
                // @ts-ignore
                // setTemplateGeometry(g);
                setTemplateGeometry(geometry);
            });
        }
    }, []);

    useEffect(() => {
        if (maxillaryUpper) {
            // console.log(maxillaryUpper);
            loader.load(maxillaryUpper, (geometry: any) => {
                if (bracesLower || bracesUpper || true) {
                    const a = new THREE.Geometry().fromBufferGeometry(geometry);
                    a.mergeVertices();
                    a.computeVertexNormals();
                    a.computeMorphNormals();
                    const g = new THREE.BufferGeometry().fromGeometry(a);
                    setMaxillaryUpperGeometry(g);
                } else {
                    setMaxillaryUpperGeometry(geometry);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (maxillaryUpperAfter) {
            loader.load(maxillaryUpperAfter, (geometry: any) => {
                if (bracesLower || bracesUpper || true) {
                    const a = new THREE.Geometry().fromBufferGeometry(geometry);
                    a.mergeVertices();
                    a.computeVertexNormals();
                    a.computeMorphNormals();
                    const g = new THREE.BufferGeometry().fromGeometry(a);
                    setMaxillaryUpperAfterGeometry(g);
                } else {
                    setMaxillaryUpperAfterGeometry(geometry);
                }
                // setMaxillaryUpperAfterGeometry(geometry);
            });
        }
    }, []);

    useEffect(() => {
        if (maxillaryLower) {
            loader.load(maxillaryLower, (geometry: any) => {
                if (bracesLower || bracesUpper || true) {
                    const a = new THREE.Geometry().fromBufferGeometry(geometry);
                    a.mergeVertices();
                    a.computeVertexNormals();
                    a.computeMorphNormals();
                    const g = new THREE.BufferGeometry().fromGeometry(a);
                    setMaxillaryLowerGeometry(g);
                } else {
                    setMaxillaryLowerGeometry(geometry);
                }
                // setMaxillaryLowerGeometry(geometry);
            });
        }
    }, []);

    useEffect(() => {
        if (maxillaryLowerAfter) {
            loader.load(maxillaryLowerAfter, (geometry: any) => {
                if (bracesLower || bracesUpper || true) {
                    const a = new THREE.Geometry().fromBufferGeometry(geometry);
                    a.mergeVertices();
                    a.computeVertexNormals();
                    a.computeMorphNormals();
                    const g = new THREE.BufferGeometry().fromGeometry(a);
                    setMaxillaryLowerAfterGeometry(g);
                } else {
                    setMaxillaryLowerAfterGeometry(geometry);
                }
                // setMaxillaryLowerAfterGeometry(geometry);
            });
        }
    }, []);

    useEffect(() => {
        if (model) {
            loader.load(model, (geometry: any) => {
                const a = new THREE.Geometry().fromBufferGeometry(geometry);
                a.mergeVertices();
                a.computeVertexNormals();
                a.computeMorphNormals();
                // a.computeFaceNormals();
                // newState[action.index] = a;
                const g = new THREE.BufferGeometry().fromGeometry(a);
                // @ts-ignore
                setModelGeometry(g);
                // setModelGeometry(geometry);
            });
        }
    }, []);

    useEffect(() => {
        if (modelLower) {
            loader.load(modelLower, (geometry: any) => {
                const a = new THREE.Geometry().fromBufferGeometry(geometry);
                a.mergeVertices();
                a.computeVertexNormals();
                a.computeMorphNormals();
                // a.computeFaceNormals();
                // newState[action.index] = a;
                const g = new THREE.BufferGeometry().fromGeometry(a);
                // @ts-ignore
                setModelLowerGeometry(g);
                // setModelLowerGeometry(geometry);
            });
        }
    }, []);

    useEffect(() => {
        if (modelAfter) {
            // console.log('MOdel after loading');
            loader.load(modelAfter, (geometry: any) => {
                if (bracesLower || bracesUpper || true) {
                    const a = new THREE.Geometry().fromBufferGeometry(geometry);
                    a.mergeVertices();
                    a.computeVertexNormals();
                    a.computeMorphNormals();
                    const g = new THREE.BufferGeometry().fromGeometry(a);
                    setModelAfterGeometry(g);
                } else {
                    setModelAfterGeometry(geometry);
                }
                // setModelAfterGeometry(geometry);
            });
        }
    }, []);

    useEffect(() => {
        if (modelLowerAfter) {
            loader.load(modelLowerAfter, (geometry: any) => {
                if (bracesLower || bracesUpper || true) {
                    const a = new THREE.Geometry().fromBufferGeometry(geometry);
                    a.mergeVertices();
                    a.computeVertexNormals();
                    a.computeMorphNormals();
                    const g = new THREE.BufferGeometry().fromGeometry(a);
                    setModelLowerAfterGeometry(g);
                } else {
                    setModelLowerAfterGeometry(geometry);
                }
                // setModelLowerAfterGeometry(geometry);
            });
        }
    }, []);

    useEffect(() => {
        if (minivints) {
            loader.load(minivints, (geometry: any) => {
                const a = new THREE.Geometry().fromBufferGeometry(geometry);
                a.mergeVertices();
                a.computeVertexNormals();
                a.computeMorphNormals();
                // a.computeFaceNormals();
                // newState[action.index] = a;
                const g = new THREE.BufferGeometry().fromGeometry(a);
                // @ts-ignore
                setMinivintsGeometry(g);
                // setMinivintsGeometry(geometry);
            });
        }
    }, []);

    useEffect(() => {
        if (tool) {
            loader.load(tool, (geometry: any) => {
                const a = new THREE.Geometry().fromBufferGeometry(geometry);
                a.mergeVertices();
                a.computeVertexNormals();
                a.computeMorphNormals();
                // a.computeFaceNormals();
                // newState[action.index] = a;
                const g = new THREE.BufferGeometry().fromGeometry(a);
                // @ts-ignore
                setToolGeometry(g);
                // setToolGeometry(geometry);
            });
        }
    }, []);

    useEffect(() => {
        if (splint) {
            loader.load(splint, (geometry: any) => {
                setSplintGeometry(geometry);
            });
        }
    }, []);

    useEffect(() => {
        if (tray) {
            loader.load(tray, (geometry: any) => {
                setTrayGeometry(geometry);
            });
        }
    }, []);

    useEffect(() => {
        if (extra) {
            loader.load(extra, (geometry: any) => {
                setExtraGeometry(geometry);
            });
        }
    }, []);

    useEffect(() => {
        if (bracesUpper) {
            loader.load(bracesUpper, (geometry: any) => {
                // if (bracesLower || bracesUpper) {
                //     const a = new THREE.Geometry().fromBufferGeometry(geometry);
                //     a.mergeVertices();
                //     a.computeVertexNormals();
                //     a.computeMorphNormals();
                //     const g = new THREE.BufferGeometry().fromGeometry(a);
                //     setbracesUpperGeometry(g);
                // } else {
                setbracesUpperGeometry(geometry);
                // }
                // setbracesUpperGeometry(geometry);
            });
        }
    }, []);

    useEffect(() => {
        if (bracesLower) {
            loader.load(bracesLower, (geometry: any) => {
                // if (bracesLower || bracesUpper) {
                //     const a = new THREE.Geometry().fromBufferGeometry(geometry);
                //     a.mergeVertices();
                //     a.computeVertexNormals();
                //     a.computeMorphNormals();
                //     const g = new THREE.BufferGeometry().fromGeometry(a);
                //     setbracesLowerGeometry(g);
                // } else {
                setbracesLowerGeometry(geometry);
                // }
                // setbracesLowerGeometry(geometry);
            });
        }
    }, []);

    // useEffect(() => {
    //     debugger;
    //     controls.current.rotateCamera(sceneView);
    // }, [sceneView]);

    const { camera } = useThree();
    // this.addShadowedLight(0, 200, 200, 0x666666, 0.7);

    const getDirectLight = (
        currentCamera: any,
        dx: number,
        dy: number,
        dz: number
    ) => {
        // this.addShadowedLight(0, 200, 200, 0x666666, 0.7);
        // this.addShadowedLight(0, -200, 200, 0x666666, 0.7);
        // this.addShadowedLight(0, 0, -200, 0x666666, 0.7);
        const directionalLight = new THREE.DirectionalLight(0x666666, 0.7);
        directionalLight.position.set(
            currentCamera.position.x + dx,
            currentCamera.position.y + dy,
            currentCamera.position.z + dz
        );
        directionalLight.castShadow = true;
        const d2 = 200;
        directionalLight.shadow.camera.left = -d2;
        directionalLight.shadow.camera.right = d2;
        directionalLight.shadow.camera.top = d2;
        directionalLight.shadow.camera.bottom = -d2;
        directionalLight.shadow.camera.near = 1;
        directionalLight.shadow.camera.far = 2;
        directionalLight.shadow.mapSize.width = 1024;
        directionalLight.shadow.mapSize.height = 1024;
        directionalLight.shadow.bias = -0.002;
        return directionalLight;
    };

    const d3 = 1000;
    if (camera.children.length === 0) {
        // camera.add(getDirectLight(camera, 0, 0, 0));
        camera.add(getDirectLight(camera, 0, -d3, d3));
        camera.add(getDirectLight(camera, 0, 0, -d3));
        camera.add(getDirectLight(camera, 0, d3, d3));
    }

    let templateMesh;
    let minivintsMesh;
    let modelMesh;
    let modelLowerMesh;
    let modelAfterMesh;
    let modelAfterMeshOver;
    let modelLowerAfterMesh;
    let modelLowerAfterMeshOver;
    let maxillaryUpperMesh;
    let maxillaryLowerMesh;
    let maxillaryUpperAfterMesh;
    let maxillaryUpperAfterMeshOver;
    let maxillaryLowerAfterMesh;
    let maxillaryLowerAfterMeshOver;
    let extraMesh;
    let toolMesh;
    let bracesUpperMesh;
    let bracesUpperMeshOver;
    let bracesLowerMesh;
    let bracesLowerMeshOver;
    let trayMesh;
    let splintMesh;

    // const stl = useLoader(STLLoader, model);
    const castShadow = true;
    const flatShading = false;
    // const flatShading = !(slices || bracesUpper || bracesLower);
    if (templateGeometry) {
        templateMesh = (
            <mesh
                castShadow={castShadow}
                geometry={templateGeometry}
                visible={templateVisible}
            >
                <meshPhongMaterial
                    flatShading={flatShading}
                    attach="material"
                    color={templateColor}
                    shininess={templateShineness}
                />
            </mesh>
        );
    }

    if (minivintsGeometry) {
        minivintsMesh = (
            <mesh
                castShadow={castShadow}
                geometry={minivintsGeometry}
                visible={minivintsVisible}
            >
                <meshPhongMaterial
                    flatShading={flatShading}
                    attach="material"
                    color={minivintsColor}
                    shininess={minivintsShineness}
                />
            </mesh>
        );
    }

    if (modelGeometry) {
        modelMesh = (
            <mesh
                castShadow={castShadow}
                geometry={modelGeometry}
                visible={modelVisible && before && upper}
            >
                <meshPhongMaterial
                    flatShading={flatShading}
                    attach="material"
                    color={modelColor}
                    shininess={modelShineness}
                />
            </mesh>
        );
    }

    if (modelLowerGeometry) {
        modelLowerMesh = (
            <mesh
                castShadow={castShadow}
                geometry={modelLowerGeometry}
                visible={modelVisible && before && lower}
            >
                <meshPhongMaterial
                    flatShading={flatShading}
                    attach="material"
                    color={modelColor}
                    shininess={modelShineness}
                />
            </mesh>
        );
    }

    if (modelAfterGeometry) {
        modelAfterMesh = (
            <mesh
                castShadow={castShadow}
                geometry={modelAfterGeometry}
                visible={modelVisible && after && upper}
            >
                <meshPhongMaterial
                    flatShading={flatShading}
                    attach="material"
                    color={modelAfterColor}
                    shininess={modelAfterShineness}
                />
            </mesh>
        );
    }
    if (modelAfterGeometryOver) {
        modelAfterMeshOver = (
            <mesh
                castShadow={castShadow}
                geometry={modelAfterGeometryOver}
                visible={modelVisible && after && upper}
            >
                <meshPhongMaterial
                    flatShading={flatShading}
                    attach="material"
                    color={overLayerColor}
                    shininess={modelAfterShineness}
                    opacity={overLayerOpacity}
                    transparent
                />
            </mesh>
        );
    }

    if (modelLowerAfterGeometry) {
        modelLowerAfterMesh = (
            <mesh
                castShadow={castShadow}
                geometry={modelLowerAfterGeometry}
                visible={modelVisible && after && lower}
            >
                <meshPhongMaterial
                    flatShading={flatShading}
                    attach="material"
                    color={modelAfterColor}
                    shininess={modelAfterShineness}
                />
            </mesh>
        );
    }

    if (modelLowerAfterGeometryOver) {
        modelLowerAfterMeshOver = (
            <mesh
                castShadow={castShadow}
                geometry={modelLowerAfterGeometryOver}
                visible={modelVisible && after && lower}
            >
                <meshPhongMaterial
                    flatShading={flatShading}
                    attach="material"
                    color={overLayerColor}
                    opacity={overLayerOpacity}
                    shininess={modelAfterShineness}
                    transparent
                />
            </mesh>
        );
    }

    if (extraGeometry) {
        extraMesh = (
            <mesh
                castShadow={castShadow}
                geometry={extraGeometry}
                visible={extraVisible}
            >
                <meshPhongMaterial
                    flatShading={flatShading}
                    attach="material"
                    color={extraColor}
                    transparent
                    opacity={extraOpacity / 100}
                    shininess={extraShineness}
                />
            </mesh>
        );
    }

    if (toolGeometry) {
        toolMesh = (
            <mesh
                castShadow={castShadow}
                geometry={toolGeometry}
                visible={toolVisible}
            >
                <meshPhysicalMaterial
                    flatShading
                    metalness={toolShineness / 100}
                    emissive={emissiveColor}
                    roughness={0.5}
                    clearcoat={1.0}
                    attach="material"
                    clearcoatRoughness={0.1}
                    color={toolColor}
                />
            </mesh>
        );
    }

    if (bracesUpperGeometry) {
        bracesUpperMesh = (
            <mesh
                castShadow={castShadow}
                geometry={bracesUpperGeometry}
                visible={bracesVisible && upper}
            >
                <meshPhysicalMaterial
                    flatShading
                    metalness={bracesShineness ? bracesShineness / 100 : 0.5}
                    emissive={emissiveColor}
                    roughness={0.5}
                    clearcoat={1.0}
                    attach="material"
                    clearcoatRoughness={0.1}
                    color={bracesColor}
                />
            </mesh>
        );
    }

    if (bracesLowerGeometry) {
        bracesLowerMesh = (
            <mesh
                castShadow={castShadow}
                geometry={bracesLowerGeometry}
                visible={bracesVisible && lower}
            >
                <meshPhysicalMaterial
                    flatShading
                    metalness={bracesShineness ? bracesShineness / 100 : 0.5}
                    emissive={emissiveColor}
                    roughness={0.5}
                    clearcoat={1.0}
                    attach="material"
                    clearcoatRoughness={0.1}
                    color={bracesColor}
                />
            </mesh>
        );
    }

    if (trayGeometry) {
        trayMesh = (
            <mesh
                castShadow={castShadow}
                geometry={trayGeometry}
                visible={trayVisible}
            >
                <meshPhongMaterial
                    flatShading={flatShading}
                    attach="material"
                    color={trayColor}
                    transparent
                    opacity={trayOpacity / 100}
                    shininess={trayShineness}
                />
            </mesh>
        );
    }

    if (splintGeometry) {
        splintMesh = (
            <mesh
                castShadow={castShadow}
                geometry={splintGeometry}
                visible={splintVisible}
            >
                <meshPhongMaterial
                    flatShading={flatShading}
                    attach="material"
                    color={splintColor}
                    transparent
                    opacity={splintOpacity / 100}
                    shininess={splintShineness}
                />
            </mesh>
        );
    }

    if (maxillaryUpperGeometry) {
        maxillaryUpperMesh = (
            <mesh
                castShadow={castShadow}
                geometry={maxillaryUpperGeometry}
                visible={
                    maxillaryVisible && before && (!before || !after) && upper
                }
            >
                <meshPhongMaterial
                    flatShading={flatShading}
                    attach="material"
                    color={maxillaryColor}
                    transparent
                    opacity={maxillaryOpacity ? maxillaryOpacity / 100 : 0.7}
                    shininess={maxillaryShineness}
                />
            </mesh>
        );
    }

    if (maxillaryLowerGeometry) {
        maxillaryLowerMesh = (
            <mesh
                castShadow={castShadow}
                geometry={maxillaryLowerGeometry}
                visible={
                    maxillaryVisible && before && (!before || !after) && lower
                }
            >
                <meshPhongMaterial
                    flatShading={flatShading}
                    attach="material"
                    color={maxillaryColor}
                    transparent
                    opacity={maxillaryOpacity ? maxillaryOpacity / 100 : 0.7}
                    shininess={maxillaryShineness}
                />
            </mesh>
        );
    }

    if (maxillaryUpperAfterGeometry) {
        maxillaryUpperAfterMesh = (
            <mesh
                castShadow={castShadow}
                geometry={maxillaryUpperAfterGeometry}
                visible={maxillaryVisible && after && upper}
            >
                <meshPhongMaterial
                    flatShading={flatShading}
                    attach="material"
                    color={maxillaryColor}
                    transparent
                    opacity={maxillaryOpacity ? maxillaryOpacity / 100 : 0.7}
                    shininess={maxillaryShineness}
                />
            </mesh>
        );
    }

    if (maxillaryLowerAfterGeometry) {
        maxillaryLowerAfterMesh = (
            <mesh
                castShadow={castShadow}
                geometry={maxillaryLowerAfterGeometry}
                visible={maxillaryVisible && after && lower}
            >
                <meshPhongMaterial
                    flatShading={flatShading}
                    attach="material"
                    color={maxillaryColor}
                    transparent
                    opacity={maxillaryOpacity ? maxillaryOpacity / 100 : 0.7}
                    shininess={maxillaryShineness}
                />
            </mesh>
        );
    }

    //  Math.PI / 2, 0, 0  - Верх
    //  0, Math.PI / 2, 0  - Справа
    //  0, 0, 0            - Фронт
    //  0, -Math.PI / 2, 0 - Слева
    // -Math.PI / 2, 0, 0  - Низ
    //  0, Math.PI, 0      - Слева

    const dist = 500;
    const distColor = directionalLightDarkColor;
    const ambientColor = new THREE.Color(0xcccccc);
    return (
        <scene rotation={sceneView}>
            {/* <ambientLight color={ambientLightColor} intensity={1} />
            <hemisphereLight
                ref={hemisphereLightRef}
                groundColor={hemisphereLightGroundColor}
                skyColor={hemisphereLightSkyColor}
                intensity={0.6}
            /> */}
            {/* <hemisphereLight
                ref={hemisphereLightRef}
                // groundColor={hemisphereLightGroundColor}
                // skyColor={hemisphereLightSkyColor}
                groundColor={new THREE.Color(0x222233)}
                skyColor={new THREE.Color(0x222233)}
                intensity={0.5}
                // position={[-20, 20, -20]}
            /> */}
            <hemisphereLight
                groundColor={hemisphereLightGroundColor}
                skyColor={hemisphereLightSkyColor}
                intensity={0.3}
            />
            <ambientLight color={ambientColor} intensity={0.6} />
            {/* <directionalLight
                ref={directionalLightRef1}
                color={distColor}
                position={[500, 500, 500]}
                intensity={distIntensity}
                castShadow={castShadow}
            /> */}
            {/* 
            <directionalLight
                ref={directionalLightRef1}
                color={distColor}
                position={[250, 500, -500]}
                intensity={distIntensity}
                castShadow={castShadow}
            />
            <directionalLight
                ref={directionalLightRef1}
                color={distColor}
                position={[-250, -500, -500]}
                intensity={distIntensity}
                castShadow={castShadow}
            />
            <directionalLight
                ref={directionalLightRef1}
                color={distColor}
                position={[-350, -500, 500]}
                intensity={distIntensity}
                castShadow={castShadow}
            /> */}
            {/* start */}
            {/* <directionalLight
                ref={directionalLightRef1}
                color={distColor}
                position={[-dist, dist, -dist]}
                intensity={distIntensity}
                castShadow={castShadow}
            />
            <directionalLight
                ref={directionalLightRef2}
                color={distColor}
                position={[dist, dist, -dist]}
                intensity={distIntensity}
            />
            <directionalLight
                ref={directionalLightRef3}
                color={distColor}
                position={[-dist, -dist, -dist]}
                intensity={distIntensity}
                castShadow={castShadow}
            />
            <directionalLight
                ref={directionalLightRef4}
                color={distColor}
                position={[dist, -dist, -dist]}
                intensity={distIntensity}
                castShadow={castShadow}
            />
            <directionalLight
                ref={directionalLightRef5}
                color={distColor}
                position={[-dist, dist, dist]}
                intensity={distIntensity}
                castShadow={castShadow}
            />
            <directionalLight
                ref={directionalLightRef6}
                color={distColor}
                position={[dist, dist, dist]}
                intensity={distIntensity}
                castShadow={castShadow}
            />
            <directionalLight
                ref={directionalLightRef7}
                color={distColor}
                position={[-dist, -dist, dist]}
                intensity={distIntensity}
                castShadow={castShadow}
            />
            <directionalLight
                ref={directionalLightRef8}
                color={distColor}
                position={[dist, -dist, dist]}
                intensity={distIntensity}
                castShadow={castShadow}
            /> */}
            {/* end */}
            {/* <directionalLight
                color={directionalLightColor}
                position={[0, 0, -dist]}
                intensity={0.5}
            />
            <directionalLight
                color={directionalLightColor}
                position={[dist, 0, 0]}
                intensity={0.5}
            />
            <directionalLight
                color={directionalLightColor}
                position={[-dist, 0, 0]}
                intensity={0.5}
            /> */}
            {modelLowerAfterMeshOver}
            {modelAfterMeshOver}

            {templateMesh}
            {minivintsMesh}
            {modelMesh}
            {modelLowerMesh}
            {modelAfterMesh}
            {modelLowerAfterMesh}
            {maxillaryUpperMesh}
            {maxillaryLowerMesh}
            {maxillaryUpperAfterMesh}
            {maxillaryLowerAfterMesh}
            {toolMesh}
            {trayMesh}
            {splintMesh}
            {bracesUpperMesh}
            {bracesLowerMesh}
            {extraMesh}
            <TrackballControls
                ref={controls}
                rotateSpeed={5}
                staticMoving
                // noPan
                panSpeed={5}
            />
        </scene>
    );
}

function Camera(props: any) {
    const ref: any = useRef();
    const {
        setDefaultCamera,
        gl: { domElement }
    } = useThree();
    // Make the camera known to the system
    useEffect(() => setDefaultCamera(ref.current), []);
    // Update it every frame
    useFrame(() => ref.current.updateMatrixWorld());
    return <orthographicCamera domElement={domElement} ref={ref} {...props} />;
}

function Shape(props: ShapeProperties) {
    const { theme } = props;
    return (
        <div style={{ background: theme === 'dark' ? '#787878' : '#fff' }}>
            <Container theme={theme}>
                <Canvas
                    gl={{ antialias: true }}
                    style={{
                        height: 600
                    }}
                >
                    <Camera zoom={5} position={[0, 0, 100]} />
                    <Suspense fallback={null}>
                        <CustomScene {...props} />
                    </Suspense>
                </Canvas>
            </Container>
        </div>
    );
}

export default Shape;
