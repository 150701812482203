import React, { Component } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

const CardTitle = styled.div`
    font-weight: 300;
    /* text-transform: uppercase; */
`;

const FieldRight = styled.div`
    font-size: 10px;
    font-weight: 300;
    float: right;
    color: ${(props) =>
        props.selected === true ? '#fffc' : 'rgba(0, 0, 0, 0.45)'};
`;

const RowGuttered = styled(Row)``;

class MessageCardTitle extends Component {
    render() {
        const { order, selected } = this.props;
        const { latest } = order;
        // const href = `/${scope}/order-details?id=${id}&revision=latest`;
        return (
            <>
                <RowGuttered type="flex" justify="space-between">
                    <Col span={18}>
                        <CardTitle>{order.patient}</CardTitle>
                    </Col>
                    <Col span={6}>
                        <FieldRight selected={selected}>
                            {moment(latest).format('DD/MM/YY') || '-'}
                        </FieldRight>
                    </Col>
                </RowGuttered>
            </>
        );
    }
}

export default MessageCardTitle;
