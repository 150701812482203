import React, { useEffect, useState } from 'react';
import {
    Badge,
    Button,
    Card,
    Divider,
    Icon,
    List,
    message,
    Radio,
    Select,
    Tooltip,
    Typography
} from 'antd';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import styled from 'styled-components';
import moment from 'moment';
import { GATEWAY_HOST } from '../../utils/properties';
import { NewMessageDescription } from './NewMessageDescription';
import { StatusDescription } from './StatusDescription ';
import { UserSelector } from '../Selector/UserSelector';
import { CenteredSpinner } from '../Spinner/CenteredSpinner';
import { NotificationLink } from './NotificationLink';

const { Option } = Select;
const { Text } = Typography;

const StyledCard = styled(Card)`
    .ant-card-body {
        padding: 0 16px;
    }
`;

const StyledListItem = styled(List.Item)`
    padding: 4px 0;
    :hover {
        background: #fffbd0;
    }
`;

export const readNotification = async (data: any) => {
    const { jwt, notificationId } = data;

    const body: any = {
        read: true
    };

    return fetch(`${GATEWAY_HOST}/notifications/${notificationId}`, {
        headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
        },
        method: 'PUT',
        body: JSON.stringify(body)
    });
};

export const readAllNotifications = async (data: any) => {
    const { jwt, notificationType, selectedManager, selectedTech } = data;
    const filters: any = [];

    if (selectedManager !== 'all') {
        filters.push(`order.manager=${selectedManager}`);
    }
    if (selectedTech !== 'all') {
        filters.push(`order.technician=${selectedTech}`);
    }

    if (notificationType === 'STATUS') {
        filters.push('type_in=STATUS&type_in=NEW');
    } else {
        filters.push(`type=${notificationType}`);
    }

    const filterParam = filters.length > 0 ? filters.join('&') : '';

    return fetch(
        `${GATEWAY_HOST}/notifications/readall${
            filterParam ? `?${filterParam}` : ''
        }`,
        {
            headers: {
                Authorization: `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            method: 'GET'
        }
    );
};

export const NotificationsComplexPage = () => {
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const profile = useSelector((state: any) => state.ProfileReducer.profile);
    const [selectedTech, setSelectedTech] = useState<any>(null);
    const [selectedManager, setSelectedManager] = useState<any>(null);
    const [notificationType, setNotificationType] = useState<string>('STATUS');
    const token = useSelector((state: any) => state.AuthReducer.token);
    const jwt = useSelector((state: any) => state.AuthReducer.token);
    const [mode, setMode] = useState<'unread' | 'all'>('unread');
    const queryClient = useQueryClient();
    const [processingNotification, setProcessingNotification] = useState('');
    const limit = 100;
    const [readLoading, setReadLoading] = useState(false);
    const [idsToReadCount, setIdsToReadCount] = useState(0);

    useEffect(() => {
        if (profile.notifications) {
            if (profile.role.name === 'technician') {
                setSelectedTech(profile.id);
                setSelectedManager('all');
            } else if (profile.role.name === 'manager') {
                setSelectedTech('all');
                setSelectedManager(profile.id);
            }
        } else {
            setSelectedTech('all');
            setSelectedManager('all');
        }
    }, [profile]);

    const { isLoading: isUsersLoading, data: users } = useQuery(
        [`users-by-role`],
        () =>
            fetch(
                `${GATEWAY_HOST}/users?role.name_in=technician&role.name_in=manager`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            ).then((res) => res.json()),
        {
            // The query will not execute until the userId exists
            enabled: selectedTech !== null && selectedManager !== null,
            initialData: []
        }
    );

    const {
        isLoading: isComplexCounterLoading,
        data: complexCounters
    } = useQuery(
        [
            'notifications-counters-complex',
            mode,
            notificationType,
            selectedManager,
            selectedTech
        ],
        () => {
            const filters: any = [];
            if (selectedManager !== null && selectedManager !== 'all') {
                if (selectedManager === 'none') {
                    filters.push('order.manager_null=true');
                } else {
                    filters.push(`order.manager=${selectedManager}`);
                }
            }
            if (selectedTech !== null && selectedTech !== 'all') {
                if (selectedTech === 'none') {
                    filters.push('order.technician_null=true');
                } else {
                    filters.push(`order.technician=${selectedTech}`);
                }
            }
            const filterParam =
                filters.length > 0 ? `&${filters.join('&')}&` : '';
            return fetch(
                `${GATEWAY_HOST}/notifications/complexcount?${filterParam}user=${
                    profile.id
                }${mode === 'unread' ? '&read=false' : ''}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                        'Content-Type': 'application/json'
                    }
                }
            ).then((res) => res.json());
        },
        {
            // The query will not execute until the userId exists
            enabled: !!users,
            initialData: []
        }
    );

    const { isLoading, data } = useQuery(
        [
            'notifications',
            mode,
            notificationType,
            selectedManager,
            selectedTech
        ],
        () => {
            const filters: any = [];
            if (selectedManager !== null && selectedManager !== 'all') {
                if (selectedManager === 'none') {
                    filters.push('order.manager_null=true');
                } else {
                    filters.push(`order.manager=${selectedManager}`);
                }
            }
            if (selectedTech !== null && selectedTech !== 'all') {
                if (selectedTech === 'none') {
                    filters.push('order.technician_null=true');
                } else {
                    filters.push(`order.technician=${selectedTech}`);
                }
            }
            const filterParam =
                filters.length > 0 ? `&${filters.join('&')}&` : '';
            const typeParam =
                notificationType === 'STATUS'
                    ? '&type_in=STATUS&type_in=NEW'
                    : `&type=${notificationType}`;
            return fetch(
                `${GATEWAY_HOST}/notifications?${filterParam}user=${
                    profile.id
                }${
                    mode === 'unread' ? '&read=false' : ''
                }${typeParam}&_limit=${limit}&_sort=created_at:DESC`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                        'Content-Type': 'application/json'
                    }
                }
            ).then((res) => res.json());
        },
        {
            // The query will not execute until the userId exists
            enabled: !!users,
            initialData: []
        }
    );

    const { mutate: readAllMutate } = useMutation(
        'readAllNotifications',
        readAllNotifications,
        {
            onSuccess: () => {
                queryClient.refetchQueries('notifications');
                queryClient.refetchQueries('notificationsUnreadCount');
                queryClient.refetchQueries('notifications-counters-complex');
                setReadLoading(false);
            },
            onError: () => {
                setProcessingNotification('');
                message.error('Возникла ошибка, попробуйте позже.');
                setReadLoading(false);
            },
            onSettled: () => {
                // queryClient.invalidateQueries('create');
            }
        }
    );

    useEffect(() => {
        if (idsToReadCount <= 0) {
            setReadLoading(false);
        }
    }, [idsToReadCount]);

    const { mutate } = useMutation('readNotification', readNotification, {
        onSuccess: () => {
            queryClient.refetchQueries('notifications');
            queryClient.refetchQueries('notificationsUnreadCount');
            setProcessingNotification('');
            if (idsToReadCount > 0) {
                setIdsToReadCount(idsToReadCount - 1);
            }
        },
        onError: () => {
            setProcessingNotification('');
            message.error('Возникла ошибка, попробуйте позже.');
            if (idsToReadCount > 0) {
                setIdsToReadCount(idsToReadCount - 1);
            }
        },
        onSettled: () => {
            // queryClient.invalidateQueries('create');
        }
    });

    // if (isLoading) return <div>Загрузка...</div>;

    const handleReadClick = (notificationId: any) => {
        const body = {
            jwt,
            notificationId
        };
        mutate(body);
    };

    const getTitleIcon = (item: any) => {
        let result = 'info-circle';
        if (item.type === 'STATUS') {
            result = 'info-circle';
        } else if (item.type === 'MESSAGE') {
            result = 'message';
        } else if (item.type === 'COMMENT') {
            result = 'lock';
        }
        return result;
    };

    const readAll = () => {
        setReadLoading(true);
        const body = {
            jwt,
            notificationType,
            selectedManager,
            selectedTech
        };
        readAllMutate(body);
    };

    return isUsersLoading ? (
        <CenteredSpinner size="default" />
    ) : (
        <StyledCard
            title="Уведомления"
            extra={
                <div>
                    <UserSelector
                        type="technician"
                        defaultValue={selectedTech}
                        data={users}
                        onChange={(value: any) => setSelectedTech(value)}
                    />
                    <UserSelector
                        type="manager"
                        defaultValue={selectedManager}
                        data={users}
                        onChange={(value: any) => setSelectedManager(value)}
                    />
                    <Select
                        value={mode}
                        style={{ width: 160 }}
                        onChange={(value: any) => setMode(value)}
                    >
                        <Option value="unread">{texts.unread.value}</Option>
                        <Option value="all">{texts.all.value}</Option>
                    </Select>
                    <Button
                        loading={readLoading}
                        style={{ marginLeft: 8 }}
                        onClick={() => readAll()}
                    >
                        {texts.read_all.value}
                    </Button>
                </div>
            }
        >
            <Radio.Group
                style={{ margin: '16px 16px' }}
                defaultValue={notificationType}
                buttonStyle="solid"
                onChange={(e: any) => {
                    setNotificationType(e.target.value);
                }}
            >
                <Radio.Button value="STATUS">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>Изменения статусов</div>
                        <Badge
                            count={complexCounters.STATUS}
                            style={{
                                marginLeft: 8,
                                backgroundColor: '#3cbb9e'
                            }}
                        />
                    </div>
                </Radio.Button>
                <Radio.Button value="MESSAGE">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>Сообщения</div>
                        <Badge
                            count={complexCounters.MESSAGE}
                            style={{
                                marginLeft: 8,
                                backgroundColor: '#3cbb9e'
                            }}
                        />
                    </div>
                </Radio.Button>
                <Radio.Button value="COMMENT">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>Служебные комментарии</div>
                        <Badge
                            count={complexCounters.COMMENT}
                            style={{
                                marginLeft: 8,
                                backgroundColor: '#3cbb9e'
                            }}
                        />
                    </div>
                </Radio.Button>
            </Radio.Group>
            {isLoading ? (
                <div>Загрузка...</div>
            ) : (
                <>
                    <List
                        className="demo-loadmore-list"
                        // loading={initLoading}
                        itemLayout="horizontal"
                        // loadMore={loadMore}
                        locale={{ emptyText: texts.unread_empty.value }}
                        dataSource={data}
                        renderItem={(item: any) => (
                            <StyledListItem
                                actions={[
                                    <Tooltip
                                        title={texts.mark_as_read.value}
                                        placement="bottomRight"
                                        mouseEnterDelay={1}
                                    >
                                        <div style={{ minWidth: 50 }}>
                                            <Button
                                                type="default"
                                                hidden={item.read === true}
                                                icon="check"
                                                loading={
                                                    processingNotification ===
                                                    item.id
                                                }
                                                onClick={() => {
                                                    setProcessingNotification(
                                                        item.id
                                                    );
                                                    handleReadClick(item.id);
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Icon
                                            theme="twoTone"
                                            twoToneColor={
                                                item.type === 'MESSAGE' ||
                                                item.type === 'COMMENT'
                                                    ? '#52c41a'
                                                    : '#fa8c16'
                                            }
                                            style={{
                                                fontSize: '20px'
                                            }}
                                            type={getTitleIcon(item)}
                                        />
                                    }
                                    // title={
                                    //     <Text strong={!item.read}>
                                    //         {getTitle(item)}
                                    //     </Text>
                                    // }
                                    // // title=
                                    description={
                                        item.type === 'MESSAGE' ||
                                        item.type === 'COMMENT' ? (
                                            <NewMessageDescription
                                                item={item}
                                                role={profile.role.name}
                                            />
                                        ) : (
                                            <StatusDescription
                                                item={item}
                                                role={profile.role.name}
                                            />
                                        )
                                    }
                                />
                                <div style={{ textAlign: 'right' }}>
                                    <NotificationLink
                                        item={item}
                                        role={profile.role.name}
                                    />
                                    {moment(item.created_at).format(
                                        'DD/MM/YY hh:mm:ss'
                                    )}
                                </div>
                            </StyledListItem>
                        )}
                    />
                    <Divider />
                    {data.length >= limit && (
                        <Text code>
                            Отображаются последние {limit} уведомлений
                        </Text>
                    )}
                </>
            )}
        </StyledCard>
    );
};
