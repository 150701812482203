import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { auth } from '../utils/auth';

export const PrivateRoute = (props: RouteProps) => {
    const { children, ...rest } = props;
    console.log(props);
    const token = auth();

    return (
        <Route
            {...rest}
            render={({ location }) =>
                token ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
};
