import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import {
    Card,
    Col,
    Divider,
    Icon,
    List,
    Row,
    Spin,
    Tabs,
    ConfigProvider
} from 'antd';
import styled from 'styled-components';
import {
    selectDoctor,
    loadOrders,
    onOrdersFiltersChanged,
    onOrdersSorterChanged,
    onOrdersSearchChanged
} from '../../redux/actions';
import NumberedLink from '../../components/Link/NumberedLink';
import OrderCardList from '../../components/Order/OrderCardList';
import OrderCardListToolbar from '../../components/Order/OrderCardListToolbar';
import { isEmpty } from '../../utils/common';

const { TabPane } = Tabs;

const CardTitle = styled.div`
    font-weight: 300;
    text-align: center;

    p {
        margin: 0;
    }
`;

const TabsContainer = styled.div`
    background: #fff;
    border: 1px solid #e8e8e8;

    @media (max-width: 767px) {
        border: none;
    }
`;

const TabsBody = styled(TabPane)`
    padding: 4px 24px 24px;
`;

const CardSubTitle = styled.div`
    font-weight: 300;
    text-transform: uppercase;
    padding-bottom: 8px;
`;

const CardIcon = styled(Icon)`
    /* width: 32px; */
`;

const CardText = styled.span`
    font-weight: 300;
    padding-left: 16px;
`;

const EmptyOrg = styled.div`
    text-align: left;
    margin: -16px;
`;

const customizeRenderEmpty = () => <EmptyOrg>-</EmptyOrg>;

interface ClientPageProps {
    doctor: any;
    orders: any;
    sorter: any;
    ordersLoading: any;
    status: any;
    search: any;
    texts: any;
    ordersText: any;
}

export const ClientPage = () => {
    const dispatch = useDispatch();
    const doctor = useSelector(
        (state: any) => state.DoctorReducer.selectedDoctor
    );
    const orders = useSelector((state: any) => state.OrderReducer.orders);
    const ordersLoading = useSelector(
        (state: any) => state.OrderReducer.ordersLoading
    );
    const search = useSelector((state: any) => state.OrderReducer.search);
    const status = useSelector((state: any) => state.OrderReducer.status);
    const sorter = useSelector((state: any) => state.OrderReducer.sorter);
    const pagination = useSelector(
        (state: any) => state.OrderReducer.pagination
    );
    const ordersText = useSelector(
        (state: any) => state.CommonReducer.texts['tab.orders'].value
    );
    const texts = useSelector((state: any) => state.CommonReducer.texts);

    const params = qs.parse(window.location.search, {
        ignoreQueryPrefix: true
    });

    const { id: doctorId } = params;

    // const {
    //     doctor,
    //     orders,
    //     sorter,
    //     ordersLoading,
    //     status,
    //     search,
    //     texts,
    //     ordersText
    // } = props;
    useEffect(() => {
        dispatch(selectDoctor(doctorId));
        dispatch(loadOrders(pagination, sorter, doctorId));
    }, [sorter, status, search, dispatch, doctorId, pagination]);

    if (doctor === null) {
        return <Spin />;
    }

    let mode = 'ACTIVE';
    if (isEmpty(status)) {
        mode = 'ALL';
    } else {
        mode = status.operator === 'eq' ? 'CLOSED' : 'ACTIVE';
    }

    const title = (
        <CardTitle>
            <p>
                {doctor.last_name} {doctor.first_name}
            </p>
            <p>{doctor.middle_name}</p>
        </CardTitle>
    );

    const onSortChange = (value: any) => {
        dispatch(onOrdersSorterChanged(value));
    };

    const onModeChange = (modeField: any) => {
        const mode = modeField.target.value;
        dispatch(onOrdersFiltersChanged(mode));
        // dispatch(loadOrders(pagination, sorter, newFilters));
    };

    const onSearchChange = (searchValue: any) => {
        // const newFilters = [];
        // let i = 0;
        // for (i = 0; i < filters.length; i += 1) {
        //     if ((filters[i].field === 'status') || (filters[i].field === 'doctor')) {
        //         newFilters.push(filters[i]);
        //     }
        // }
        // newFilters.push(
        //     { field: 'patient', operator: 'contains', value: search },
        // );
        dispatch(onOrdersSearchChanged(searchValue));
    };

    return (
        <Row gutter={16}>
            <Col lg={6} md={24}>
                <Card title={title} bordered>
                    <p>
                        <CardIcon type="mobile" style={{ color: '#3C9FD9' }} />
                        <CardText>{doctor.phone ? doctor.phone : '-'}</CardText>
                    </p>
                    <p>
                        <CardIcon type="mail" style={{ color: '#3C9FD9' }} />
                        <CardText>
                            {isEmpty(doctor.email) ? '-' : doctor.email}
                        </CardText>
                    </p>
                    <Divider dashed />
                    <CardSubTitle>{texts.organisations.value}:</CardSubTitle>
                    <ConfigProvider renderEmpty={customizeRenderEmpty}>
                        <List
                            dataSource={doctor.organisations}
                            renderItem={(object: any) => {
                                const href = `/organisation?id=${object.id}`;
                                return (
                                    <NumberedLink
                                        key={object.id}
                                        text={object.name}
                                        i={1}
                                        href={href}
                                    />
                                );
                            }}
                        />
                    </ConfigProvider>
                </Card>
            </Col>
            <Col lg={18} md={24}>
                <TabsContainer>
                    <Tabs defaultActiveKey="1">
                        <TabsBody tab={ordersText} key="1">
                            <OrderCardListToolbar
                                mode={mode}
                                texts={texts}
                                search={search}
                                sorter={sorter}
                                onSortChange={(value: any) =>
                                    onSortChange(value)
                                }
                                onSearchChange={(value: any) =>
                                    onSearchChange(value)
                                }
                                onModeChange={(value: any) =>
                                    onModeChange(value)
                                }
                            />
                            <OrderCardList
                                texts={texts}
                                scope="otk"
                                orders={orders}
                                ordersLoading={ordersLoading}
                            />
                        </TabsBody>
                    </Tabs>
                </TabsContainer>
            </Col>
        </Row>
    );
};

// class ClientPage extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             doctorId: null,
//             sorter: null,
//             status: null,
//             search: '',
//         };
//     }

//     static async getInitialProps({ ctx }) {
//         return {
//             doctorId: ctx.query.id,
//         };
//     }

//     static getDerivedStateFromProps(props, state) {
//         const { dispatch, doctor, doctorId, pagination, sorter, status, search } = props;
//         const result = {};

//         if (isEmpty(doctorId)) {
//             return null;
//         }

//         if ((state.doctorId !== doctorId)) {
//             result.doctorId = doctorId;
//         }

//         if (state.sorter !== sorter.field) {
//             result.sorter = sorter.field;
//         }

//         if (!isEqual(state.status, status)) {
//             result.status = status;
//         }

//         if (!isEqual(state.search, search)) {
//             result.search = search;
//         }

//         if (!isEqual(result, {})) {
//             dispatch(selectDoctor(doctorId));
//             dispatch(loadOrders(pagination, sorter, doctorId));
//             return result;
//         }
//         // when null is returned no update is made to the state
//         return null;
//     }

//     onSortChange(value) {
//         const { dispatch } = this.props;
//         dispatch(onOrdersSorterChanged(value));
//     }

//     onModeChange(modeField) {
//         const mode = modeField.target.value;
//         const { dispatch } = this.props;
//         dispatch(onOrdersFiltersChanged(mode));
//         // dispatch(loadOrders(pagination, sorter, newFilters));
//     }

//     onSearchChange(search) {
//         const { dispatch } = this.props;
//         // const newFilters = [];
//         // let i = 0;
//         // for (i = 0; i < filters.length; i += 1) {
//         //     if ((filters[i].field === 'status') || (filters[i].field === 'doctor')) {
//         //         newFilters.push(filters[i]);
//         //     }
//         // }
//         // newFilters.push(
//         //     { field: 'patient', operator: 'contains', value: search },
//         // );
//         dispatch(onOrdersSearchChanged(search));
//     }

//     render() {
//         const { doctor, orders, sorter, ordersLoading, status, search, texts, ordersText } = this.props;
//         if (doctor === null) {
//             return (
//                 <Spin />
//             );
//         }

//         let mode = 'ACTIVE';
//         if (isEmpty(status)) {
//             mode = 'ALL';
//         } else {
//             mode = status.operator === 'eq' ? 'CLOSED' : 'ACTIVE';
//         }

//         const title = (
//             <CardTitle>
//                 <p>{doctor.last_name} {doctor.first_name}</p>
//                 <p>{doctor.middle_name}</p>
//             </CardTitle>
//         );
//         <div></div>
//     }
// }

// function mapStateToProps(state, ownProps) {
//     return {
//         doctor: state.DoctorReducer.selectedDoctor,
//         orders: state.OrderReducer.orders,
//         ordersLoading: state.OrderReducer.ordersLoading,
//         search: state.OrderReducer.search,
//         status: state.OrderReducer.status,
//         sorter: state.OrderReducer.sorter,
//         pagination: state.OrderReducer.pagination,
//         ordersText: state.CommonReducer.texts['tab.orders'].value,
//         texts: state.CommonReducer.texts,
//     };
// }

// export default connect(mapStateToProps)(withAuthSync(ClientPage));
