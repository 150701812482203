import React, { useEffect, useState } from 'react';
import Konva from 'konva';
import { Stage, Layer, Image } from 'react-konva';
// gifler will be imported into global window object
import 'gifler';
import { Card, Slider, Spin } from 'antd';
import { SliderValue } from 'antd/lib/slider';
import styled from 'styled-components';
import { useWindowSize } from '../../hooks/useWindowSize';

Konva.hitOnDragEnabled = true;

const StyledCard = styled(Card)`
    .ant-card-head-title {
        font-weight: 400;
    }
    /* margin-bottom: 16px; */
    height: 100vh;
    max-height: 100vh;
    /* max-width: 900px; */
`;

const getDistance = (p1: any, p2: any) => {
    return Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
};

const getCenter = (p1: any, p2: any) => {
    return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2
    };
};

// the first very simple and recommended way:
const GIF = ({ src }: any) => {
    const imageRef = React.useRef(null);
    const stageRef = React.useRef(null);
    const [frames, setFrames] = useState([]);
    const windowSize = useWindowSize();
    const [size, setSize] = useState({ width: 0, height: 0, ratio: 1 });
    const [lastCenter, setLastCenter] = useState<any>(null);
    const [lastDist, setLastDist] = useState<any>(0);
    const [touchStartX, setTouchStartX] = useState(0);
    const [sliderValue, setSliderValue] = useState(0);
    const canvas = React.useMemo(() => {
        const node = document.createElement('canvas');
        return node;
    }, []);

    const absoluteImageWidth = windowSize.width
        ? windowSize.width - 24
        : windowSize.width;
    const absoluteImageHeight = windowSize.height
        ? windowSize.height - 100
        : windowSize.height;

    let absoluteRatio = null;
    if (absoluteImageWidth && absoluteImageHeight) {
        absoluteRatio = absoluteImageWidth / absoluteImageHeight;
    }

    const drawFrame = (frame: any) => {
        if (!size.width || !size.height) return;
        // # Create empty buffer
        const bufferCanvas = document.createElement('canvas');
        const bufferContext = bufferCanvas.getContext('2d');
        bufferCanvas.width = frame.width;
        bufferCanvas.height = frame.height;

        // # Create image date from pixels
        const imageData = bufferContext?.createImageData(
            size.width,
            size.height
        );

        if (imageData && bufferContext) {
            imageData.data.set(frame.pixels);

            // # Fill canvas with image data

            bufferContext.putImageData(imageData, -frame.x, -frame.y);
            const ctx = canvas.getContext('2d');
            ctx?.drawImage(bufferCanvas, frame.x, frame.y);
            // @ts-ignore
            imageRef.current.getLayer().draw();
        }
    };

    useEffect(() => {
        drawFrame(frames[0]);
    }, [size]);

    useEffect(() => {
        // save animation instance to stop it on unmount
        let anim: any;
        // @ts-ignore
        window.gifler(src).get((a) => {
            anim = a;
            setFrames(a._frames);
            setSize({
                width: a.width,
                height: a.height,
                ratio: a.width / a.height
            });
            anim.animateInCanvas(canvas);
            anim.stop();
            drawFrame(a._frames[0]);
            // anim.onDrawFrame = (ctx: any, frame: any) => {
            //     console.log(frame);
            //     ctx.drawImage(frame.buffer, frame.x, frame.y);
            //     // @ts-ignore
            //     imageRef.current.getLayer().draw();
            // };
        });
        return () => {
            if (anim && typeof anim !== 'undefined') {
                anim.stop();
            }
        };
    }, [src, canvas]);

    const onFrameChange = (value: SliderValue) => {
        if (!size.width || !size.height) return;
        const frame: any = frames[value as number];
        drawFrame(frame);
    };

    useEffect(() => {
        onFrameChange(sliderValue);
    }, [sliderValue]);

    // let stageWidth = 0;
    // if (windowSize.width) {
    //     stageWidth = windowSize.width;
    //     // windowSize.width > 991
    //     //     ? ((windowSize.width - 232) * 3) / 4 - 104
    //     //     : windowSize.width - 104;
    // }

    return windowSize.height &&
        windowSize.width &&
        absoluteRatio &&
        absoluteImageWidth &&
        absoluteImageHeight ? (
        <>
            <Stage
                width={absoluteImageWidth}
                ref={stageRef}
                draggable
                // height={600}
                height={absoluteImageHeight}
                onTouchMove={(e) => {
                    console.log('onTouchMove');
                    e.evt.preventDefault();
                    const touch1 = e.evt.touches[0];
                    const touch2 = e.evt.touches[1];
                    if (touch1 && touch2 && stageRef.current) {
                        // if the stage was under Konva's drag&drop
                        // we need to stop it, and implement our own pan logic with two pointers
                        // @ts-ignore
                        if (stageRef.current.isDragging()) {
                            // @ts-ignore
                            stageRef.current.stopDrag();
                        }

                        const p1 = {
                            x: touch1.clientX,
                            y: touch1.clientY
                        };
                        const p2 = {
                            x: touch2.clientX,
                            y: touch2.clientY
                        };

                        if (!lastCenter) {
                            setLastCenter(getCenter(p1, p2));
                            return;
                        }
                        const newCenter = getCenter(p1, p2);

                        const dist = getDistance(p1, p2);

                        // if (!lastDist) {
                        //     setLastDist(dist);
                        // }

                        // local coordinates of center point
                        const pointTo = {
                            x:
                                // @ts-ignore
                                (newCenter.x - stageRef.current.x()) /
                                // @ts-ignore
                                stageRef.current.scaleX(),
                            y:
                                // @ts-ignore
                                (newCenter.y - stageRef.current.y()) /
                                // @ts-ignore
                                stageRef.current.scaleX()
                        };

                        const scale =
                            // @ts-ignore
                            stageRef.current.scaleX() *
                            (dist / lastDist ? lastDist : dist);

                        // @ts-ignore
                        stageRef.current.scaleX(scale);
                        // @ts-ignore
                        stageRef.current.scaleY(scale);

                        // calculate new position of the stage
                        const dx = newCenter.x - lastCenter.x;
                        const dy = newCenter.y - lastCenter.y;

                        const newPos = {
                            x: newCenter.x - pointTo.x * scale + dx,
                            y: newCenter.y - pointTo.y * scale + dy
                        };
                        // @ts-ignore
                        stageRef.current.position(newPos);

                        setLastDist(dist);
                        setLastCenter(newCenter);
                    }
                }}
                onTouchEnd={() => {
                    setLastDist(0);
                    setLastCenter(null);
                }}
                onWheel={(e: any) => {
                    e.evt.preventDefault();
                    const scaleBy = 0.95;
                    if (stageRef && stageRef.current) {
                        const sref = stageRef?.current;
                        // @ts-ignore
                        const oldScale = sref?.scaleX();
                        // @ts-ignore
                        const pointer = sref.getPointerPosition();

                        const mousePointTo = {
                            // @ts-ignore
                            x: (pointer.x - sref.x()) / oldScale,
                            // @ts-ignore
                            y: (pointer.y - sref.y()) / oldScale
                        };

                        // how to scale? Zoom in? Or zoom out?
                        let direction = e.evt.deltaY > 0 ? 1 : -1;

                        // when we zoom on trackpad, e.evt.ctrlKey is true
                        // in that case lets revert direction
                        if (e.evt.ctrlKey) {
                            direction = -direction;
                        }

                        const newScale =
                            direction > 0
                                ? oldScale * scaleBy
                                : oldScale / scaleBy;

                        // @ts-ignore
                        sref.scale({ x: newScale, y: newScale });

                        const newPos = {
                            // @ts-ignore
                            x: pointer.x - mousePointTo.x * newScale,
                            // @ts-ignore
                            y: pointer.y - mousePointTo.y * newScale
                        };
                        // @ts-ignore
                        sref.position(newPos);
                    }
                }}
            >
                <Layer>
                    <Image
                        image={canvas}
                        ref={imageRef}
                        x={
                            (absoluteImageWidth -
                                (absoluteRatio <= size.ratio
                                    ? absoluteImageWidth
                                    : (size.width / size.height) *
                                      absoluteImageHeight)) /
                            2
                        }
                        y={
                            (absoluteImageHeight -
                                (absoluteRatio <= size.ratio
                                    ? (size.height / size.width) *
                                      absoluteImageWidth
                                    : absoluteImageHeight)) /
                            2
                        }
                        onTouchStart={() => {
                            if (stageRef && stageRef.current) {
                                setTouchStartX(
                                    // @ts-ignore
                                    stageRef?.current?.getPointerPosition().x
                                );
                            }
                        }}
                        onTouchEnd={() => {
                            if (stageRef && stageRef.current) {
                                if (
                                    // @ts-ignore
                                    stageRef?.current?.getPointerPosition().x >
                                    touchStartX
                                ) {
                                    setSliderValue(
                                        sliderValue + 1 <= frames.length - 1
                                            ? sliderValue + 1
                                            : frames.length - 1
                                    );
                                } else {
                                    setSliderValue(
                                        sliderValue - 1 >= 0
                                            ? sliderValue - 1
                                            : 0
                                    );
                                }
                                setTouchStartX(0);
                            }
                        }}
                        height={
                            absoluteRatio <= size.ratio
                                ? (size.height / size.width) *
                                  absoluteImageWidth
                                : absoluteImageHeight
                        }
                        width={
                            absoluteRatio <= size.ratio
                                ? absoluteImageWidth
                                : (size.width / size.height) *
                                  absoluteImageHeight
                        }
                    />
                </Layer>
            </Stage>
            <Slider
                value={sliderValue}
                min={0}
                max={frames.length - 1}
                onChange={(value: any) => setSliderValue(value)}
            />
        </>
    ) : (
        <Spin />
    );
};

export const GifViewerRaw = (props: any) => {
    const { src, theme } = props;
    return src ? (
        <StyledCard
            style={{
                background: theme === 'dark' ? '#222b45' : '#ffffff',
                border: 0
            }}
            size="small"
            // title={<Tooltip title={title}>{title}</Tooltip>}
        >
            <GIF src={src} />
        </StyledCard>
    ) : (
        <></>
    );
};
