import React from 'react';

export const NotificationLinkNoProvider = (props: any) => {
    const { item, role, texts } = props;

    const getTitle = (currentItem: any) => {
        let result = texts.notification_new.value;
        if (currentItem.type === 'STATUS') {
            result = texts.notification_status.value;
        } else if (currentItem.type === 'MESSAGE') {
            result = texts.notification_message.value;
        } else if (currentItem.type === 'COMMENT') {
            result = texts.notification_comment.value;
        }
        return result;
    };

    let result = <div>-</div>;

    if (item.order) {
        let tab = '';
        let revision = 'latest';
        let title = getTitle(item);
        if (item.type === 'MESSAGE') {
            tab = '&tab=messages';
        } else if (item.type === 'COMMENT') {
            tab = '&tab=comments';
        } else if (item.type === 'STATUS') {
            const additionStr = item.data ? item.data : '[]';
            const addition = JSON.parse(additionStr);
            if (addition.length > 0) {
                revision = addition[0].orderversion;
                title = `${texts[addition[0].status].value}: ${
                    texts[`order.status.${addition[0].new.toLowerCase()}`].value
                }`;
            }
        }

        const orderLink = `/${role}/order-details?id=${item.order.id}&revision=${revision}&notification=${item.id}${tab}`;
        result = (
            <div>
                <a href={orderLink} target="_blank" rel="noreferrer">
                    {title}
                </a>
            </div>
        );
    }

    return result;
};
