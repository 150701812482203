import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find, filter } from 'lodash';
import moment from 'moment';
import {
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Modal,
    Radio,
    Row,
    Select,
    Spin
} from 'antd';
import styled from 'styled-components';
import {
    onNewFormDoctorChanged,
    onNewFormTechnicianChanged,
    onNewFormManagerChanged,
    onNewFormStatusChanged,
    closeNewOrder,
    createNewOrder,
    searchDoctors,
    searchTechnicians,
    onNewFormOrganisationChanged
} from '../../redux/actions';
import { isEmpty } from '../../utils/common';

const { Option } = Select;

const StyledDivider = styled(Divider)`
    font-size: 14px !important;
    font-weight: 700 !important;

    &.ant-divider-horizontal.ant-divider-with-text-left::before {
        width: 0 !important;
    }

    span {
        padding: 0 10px 0 0 !important;
    }
`;

const StyledItem = styled(Form.Item)`
    /* margin-bottom: 8px; */
    font-size: 14px;
`;

const DoctorName = styled.div`
    font-weight: 500;
`;

const DoctorCompany = styled.div`
    font-weight: 200;
    font-size: 12px;
`;

const CreateOrderModalForm = Form.create({
    name: 'create_order_modal_form'
})(
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                previewVisible: false,
                previewImage: '',
                structure: props.structureDefault
            };
        }

        componentDidMount() {
            const { dispatch } = this.props;
            dispatch(searchDoctors());
            dispatch(searchTechnicians());
        }

        onStatusChange(status, value) {
            const { dispatch } = this.props;
            dispatch(onNewFormStatusChanged(status, value));
        }

        afterCloseHandler = () => {
            const { form } = this.props;
            form.resetFields();
        };

        handleFormCancel = () => {
            const { dispatch } = this.props;
            dispatch(closeNewOrder());
        };

        handleCancel = () => {
            this.setState({ previewVisible: false });
        };

        handlePreview = (file) => {
            this.setState({
                previewImage: file.url || file.thumbUrl,
                previewVisible: true
            });
        };

        handleFormSave = () => {
            const {
                dispatch,
                form,
                organisation,
                status,
                orderId,
                doctor,
                label1,
                label2,
                label3,
                label4,
                label5,
                label6
            } = this.props;

            form.validateFields((err) => {
                if (!err) {
                    const formValues = form.getFieldsValue();
                    const data = {
                        orderId,
                        doctor: formValues.doctor,
                        technician: isEmpty(formValues.technician)
                            ? undefined
                            : formValues.technician,
                        manager: isEmpty(formValues.manager)
                            ? undefined
                            : formValues.manager,
                        doctor_last_name: doctor.last_name,
                        patient: formValues.patient,
                        clinic: formValues.clinic,
                        public: true,
                        design_date: moment.utc().format(),
                        status,
                        bill: formValues.bill,
                        bill_date: isEmpty(formValues.billDate)
                            ? undefined
                            : moment.utc(formValues.billDate).format(),
                        deadline: isEmpty(formValues.deadline)
                            ? undefined
                            : moment.utc(formValues.deadline).format(),
                        status_minivints:
                            formValues.structure.indexOf('minivints') >= 0
                                ? 'NEW'
                                : 'NONE',
                        status_equipment:
                            formValues.structure.indexOf('equipment') >= 0
                                ? 'NEW'
                                : 'NONE',
                        status_equipment_2d:
                            formValues.structure.indexOf('equipment_2d') >= 0
                                ? 'NEW'
                                : 'NONE',
                        status_braces:
                            formValues.structure.indexOf('braces') >= 0
                                ? 'NEW'
                                : 'NONE',
                        status_aligners:
                            formValues.structure.indexOf('aligners') >= 0
                                ? 'NEW'
                                : 'NONE',
                        status_template:
                            formValues.structure.indexOf('template') >= 0
                                ? 'NEW'
                                : 'NONE',
                        status_tray:
                            formValues.structure.indexOf('tray') >= 0
                                ? 'REQUIRED'
                                : 'NONE',
                        status_splint:
                            formValues.structure.indexOf('splint') >= 0
                                ? 'NEW'
                                : 'NONE',
                        organisation: isEmpty(organisation) ? '' : organisation,
                        image1: undefined,
                        image2: undefined,
                        image3: undefined,
                        image4: undefined,
                        image5: undefined,
                        image6: undefined,
                        image7: undefined,
                        image8: undefined,
                        image9: undefined,
                        image10: undefined,
                        label1,
                        label2,
                        label3,
                        label4,
                        label5,
                        label6,
                        stl1: undefined,
                        stl2: undefined,
                        stl3: undefined,
                        stl4: undefined,
                        stl5: undefined,
                        stl6: undefined,
                        stl7: undefined,
                        stl8: undefined,
                        stl9: undefined,
                        stl10: undefined,
                        stl11: undefined,
                        stl12: undefined,
                        stl13: undefined,
                        stl14: undefined,
                        stl15: undefined,
                        stl16: undefined,
                        stl17: undefined,
                        stl18: undefined,
                        stl19: undefined,
                        stl20: undefined
                    };

                    if (status === 'READYTOSEND') {
                        data.status_template =
                            data.status_template !== 'NONE'
                                ? 'READYTOSEND'
                                : 'NONE';
                        data.status_equipment =
                            data.status_equipment !== 'NONE'
                                ? 'READYTOSEND'
                                : 'NONE';
                        data.status_equipment_2d =
                            data.status_equipment_2d !== 'NONE'
                                ? 'READYTOSEND'
                                : 'NONE';
                        data.status_aligners =
                            data.status_aligners !== 'NONE'
                                ? 'READYTOSEND'
                                : 'NONE';
                        data.status_braces =
                            data.status_braces !== 'NONE'
                                ? 'READYTOSEND'
                                : 'NONE';
                        data.status_splint =
                            data.status_splint !== 'NONE'
                                ? 'READYTOSEND'
                                : 'NONE';
                    }
                    dispatch(createNewOrder(data));
                    dispatch(closeNewOrder());
                }
            });
        };

        handleChange(value) {
            const { doctors, dispatch } = this.props;
            const doctor = find(doctors, (o) => o.id === value);
            dispatch(onNewFormDoctorChanged(doctor));
        }

        handleTechChange(value) {
            const { technicians, dispatch } = this.props;
            const technician = find(technicians, (o) => o.id === value);
            dispatch(onNewFormTechnicianChanged(technician));
        }

        handleManagerChange(value) {
            const { technicians, dispatch } = this.props;
            const manager = find(technicians, (o) => o.id === value);
            dispatch(onNewFormManagerChanged(manager));
        }

        handleOrgChange(value) {
            const { doctor, dispatch } = this.props;
            const org = find(doctor.organisations, (o) => o.name === value);
            dispatch(onNewFormOrganisationChanged(org));
        }

        fetchDoctor(value) {
            const { dispatch } = this.props;
            dispatch(searchDoctors(value));
        }

        fetchTechnician(value) {
            const { dispatch } = this.props;
            dispatch(searchTechnicians(value));
        }

        render() {
            const { previewVisible, previewImage } = this.state;
            const {
                isCreateOpen,
                form,
                bill,
                billDate,
                deadline,
                doctor,
                patient,
                technician,
                manager,
                // isPublic,
                doctorsLoading,
                doctors,
                techniciansLoading,
                technicians,
                formIsLoading,
                status,
                orderId,
                organisation,
                texts,
                profile,
                structureDefault
            } = this.props;
            const readonly = profile.role.name === 'technician';
            const title = isEmpty(orderId)
                ? texts['orders.new'].value
                : texts['orders.edit'].value;
            const { getFieldDecorator } = form;

            let organisations = isEmpty(doctor) ? [] : doctor.organisations;
            organisations = isEmpty(organisations) ? [] : organisations;

            const doctorId = isEmpty(doctor) ? '' : doctor.id;
            const technitianId = isEmpty(technician) ? '' : technician.id;
            const managerId = isEmpty(manager) ? '' : manager.id;
            const filteredDoctors = filter(
                doctors,
                (d) => `${d.id}` !== `${doctorId}`
            );
            const filteredTechnicians = filter(
                technicians,
                (d) =>
                    `${d.id}` !== `${technitianId}` &&
                    d.role.name === 'technician'
            );
            const filteredManagers = filter(
                technicians,
                (d) => `${d.id}` !== `${managerId}` && d.role.name === 'manager'
            );

            if (!isEmpty(doctor)) {
                filteredDoctors.unshift(doctor);
            }

            if (!isEmpty(technician)) {
                filteredTechnicians.unshift(technician);
            }
            if (!isEmpty(manager)) {
                filteredManagers.unshift(manager);
            }

            let content = <Spin size="small" />;
            const options = [
                { label: texts.minivints.value, value: 'minivints' },
                { label: texts.equipment.value, value: 'equipment' },
                { label: texts.equipment2d.value, value: 'equipment_2d' },
                // { label: texts.tray.value, value: 'tray' },
                { label: texts.template.value, value: 'template' },
                { label: texts.braces.value, value: 'braces' },
                { label: texts.aligners.value, value: 'aligners' },
                { label: texts.splint.value, value: 'splint' }
            ];
            if (!formIsLoading) {
                content = (
                    <>
                        <StyledDivider orientation="left">
                            {texts['new.common.information'].value}
                        </StyledDivider>
                        <Form layout="vertical">
                            <Row gutter={16}>
                                <Col md={12} sm={24}>
                                    <StyledItem
                                        label={`${texts['order.doctor'].value}:`}
                                    >
                                        {getFieldDecorator('doctor', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message:
                                                        texts.required.value
                                                }
                                            ],
                                            initialValue: isEmpty(doctor)
                                                ? ''
                                                : doctor.id
                                        })(
                                            <Select
                                                // defaultValue={this.state.search}
                                                notFoundContent={
                                                    doctorsLoading ? (
                                                        <Spin size="small" />
                                                    ) : null
                                                }
                                                onSearch={(value) =>
                                                    this.fetchDoctor(value)
                                                }
                                                onChange={(value) =>
                                                    this.handleChange(value)
                                                }
                                                style={{ width: '100%' }}
                                                size="small"
                                                filterOption={(input, option) =>
                                                    option.props.children[0].props.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                                showSearch
                                                optionLabelProp="label"
                                                disabled={readonly}
                                            >
                                                {filteredDoctors.map((d) => {
                                                    const name = `${d.last_name} ${d.first_name} ${d.middle_name}`;
                                                    return (
                                                        <Option
                                                            key={`${d.id}`}
                                                            label={name}
                                                            value={d.id}
                                                        >
                                                            <DoctorName>
                                                                {name}
                                                            </DoctorName>
                                                            <DoctorCompany>
                                                                {d.company}
                                                            </DoctorCompany>
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    </StyledItem>
                                </Col>
                                <Col md={12} sm={24}>
                                    <StyledItem
                                        label={`${texts['profile.organisation'].value}:`}
                                    >
                                        <Select
                                            value={
                                                isEmpty(organisation)
                                                    ? null
                                                    : organisation
                                            }
                                            notFoundContent={
                                                doctorsLoading ? (
                                                    <Spin size="small" />
                                                ) : null
                                            }
                                            style={{ width: '100%' }}
                                            onChange={(value) =>
                                                this.handleOrgChange(value)
                                            }
                                            size="small"
                                            optionLabelProp="label"
                                            disabled={readonly}
                                        >
                                            {organisations.map((d) => (
                                                <Option
                                                    key={d.name}
                                                    label={d.name}
                                                >
                                                    {d.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </StyledItem>
                                </Col>
                                <Col md={12} sm={24}>
                                    <StyledItem
                                        label={`${texts['order.patient'].value}:`}
                                    >
                                        {getFieldDecorator('patient', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message:
                                                        texts.required.value
                                                }
                                            ],
                                            initialValue: patient
                                        })(
                                            <Input
                                                size="small"
                                                disabled={readonly}
                                            />
                                        )}
                                    </StyledItem>
                                </Col>
                                <Col md={12} sm={24}>
                                    <StyledItem
                                        label={`${texts['role.technician'].value}:`}
                                    >
                                        {getFieldDecorator('technician', {
                                            initialValue: isEmpty(technician)
                                                ? ''
                                                : technician.id
                                        })(
                                            <Select
                                                // defaultValue={this.state.search}
                                                notFoundContent={
                                                    techniciansLoading ? (
                                                        <Spin size="small" />
                                                    ) : null
                                                }
                                                onSearch={(value) =>
                                                    this.fetchTechnician(value)
                                                }
                                                onChange={(value) =>
                                                    this.handleTechChange(value)
                                                }
                                                style={{ width: '100%' }}
                                                size="small"
                                                filterOption={(input, option) =>
                                                    option.props.children[0].props.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                                showSearch
                                                optionLabelProp="label"
                                                disabled={readonly}
                                            >
                                                {filteredTechnicians.map(
                                                    (d) => {
                                                        const name = `${d.last_name} ${d.first_name} ${d.middle_name}`;
                                                        return (
                                                            <Option
                                                                key={`${d.id}`}
                                                                value={d.id}
                                                                label={name}
                                                            >
                                                                <DoctorName>
                                                                    {name}
                                                                </DoctorName>
                                                                <DoctorCompany>
                                                                    {d.company}
                                                                </DoctorCompany>
                                                            </Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    </StyledItem>
                                </Col>
                                <Col md={12} sm={24}>
                                    <StyledItem
                                        label={`${texts['role.manager'].value}:`}
                                    >
                                        {getFieldDecorator('manager', {
                                            initialValue: isEmpty(manager)
                                                ? ''
                                                : manager.id
                                        })(
                                            <Select
                                                // defaultValue={this.state.search}
                                                notFoundContent={
                                                    techniciansLoading ? (
                                                        <Spin size="small" />
                                                    ) : null
                                                }
                                                onSearch={(value) =>
                                                    this.fetchTechnician(value)
                                                }
                                                onChange={(value) =>
                                                    this.handleManagerChange(
                                                        value
                                                    )
                                                }
                                                style={{ width: '100%' }}
                                                size="small"
                                                filterOption={(input, option) =>
                                                    option.props.children[0].props.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                                showSearch
                                                optionLabelProp="label"
                                                disabled={readonly}
                                            >
                                                {filteredManagers.map((d) => {
                                                    const name = `${d.last_name} ${d.first_name} ${d.middle_name}`;
                                                    return (
                                                        <Option
                                                            key={`${d.id}`}
                                                            value={d.id}
                                                            label={name}
                                                        >
                                                            <DoctorName>
                                                                {name}
                                                            </DoctorName>
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    </StyledItem>
                                </Col>
                                <Col md={24} sm={24}>
                                    <StyledItem
                                        label={`${texts['order.deadline'].value}:`}
                                    >
                                        {getFieldDecorator('deadline', {
                                            initialValue: deadline
                                                ? moment(deadline)
                                                : null
                                        })(
                                            <DatePicker
                                                size="small"
                                                placeholder={
                                                    texts['date.select'].value
                                                }
                                                disabled={readonly}
                                            />
                                        )}
                                    </StyledItem>
                                </Col>
                                <Col md={12} sm={24}>
                                    <StyledItem
                                        label={`${texts['order.bill'].value}:`}
                                    >
                                        {getFieldDecorator('bill', {
                                            initialValue: bill
                                        })(
                                            <Input
                                                size="small"
                                                // onChange={() => {
                                                //     form.setFieldsValue({
                                                //         structure: ['minivints']
                                                //     });
                                                // }}
                                                disabled={readonly}
                                            />
                                        )}
                                    </StyledItem>
                                </Col>
                                <Col md={12} sm={24}>
                                    <StyledItem
                                        label={`${texts['order.bill.date'].value}:`}
                                    >
                                        {getFieldDecorator('billDate', {
                                            initialValue: billDate
                                                ? moment(billDate)
                                                : null
                                        })(
                                            <DatePicker
                                                size="small"
                                                placeholder={
                                                    texts['date.select'].value
                                                }
                                                disabled={readonly}
                                            />
                                        )}
                                    </StyledItem>
                                </Col>
                                <Col md={24} sm={24}>
                                    <StyledItem
                                        label={`${texts.status.value}:`}
                                    >
                                        <Radio.Group
                                            disabled={readonly}
                                            size="small"
                                            buttonStyle="solid"
                                            onChange={(value) =>
                                                this.onStatusChange(
                                                    'status',
                                                    value.target.value
                                                )
                                            }
                                            defaultValue={status}
                                        >
                                            <Radio.Button value="NEW">
                                                {
                                                    texts['order.status.new']
                                                        .value
                                                }
                                            </Radio.Button>
                                            <Radio.Button value="UNPAID">
                                                {
                                                    texts['order.status.unpaid']
                                                        .value
                                                }
                                            </Radio.Button>
                                            <Radio.Button value="PAID">
                                                {
                                                    texts['order.status.paid']
                                                        .value
                                                }
                                            </Radio.Button>
                                            <Radio.Button
                                                value="READYTOSEND"
                                                disabled
                                            >
                                                {
                                                    texts[
                                                        'order.status.readytosend'
                                                    ].value
                                                }
                                            </Radio.Button>
                                            <Radio.Button value="REWORK">
                                                {
                                                    texts['order.status.rework']
                                                        .value
                                                }
                                            </Radio.Button>
                                            <Radio.Button value="READY">
                                                {
                                                    texts['order.status.ready']
                                                        .value
                                                }
                                            </Radio.Button>
                                            <Radio.Button value="CLOSED">
                                                {
                                                    texts['order.status.closed']
                                                        .value
                                                }
                                            </Radio.Button>
                                            <Radio.Button value="DECLINED">
                                                {
                                                    texts[
                                                        'order.status.declined'
                                                    ].value
                                                }
                                            </Radio.Button>
                                        </Radio.Group>
                                    </StyledItem>
                                </Col>
                                <Col md={24} sm={24}>
                                    <StyledItem
                                        label={`${texts.structure.value}:`}
                                    >
                                        {getFieldDecorator('structure', {
                                            initialValue: structureDefault
                                        })(
                                            <Checkbox.Group
                                                // value={this.state.structure}
                                                options={options}
                                            />
                                        )}
                                    </StyledItem>
                                </Col>
                            </Row>
                        </Form>
                        <Modal
                            visible={previewVisible}
                            footer={null}
                            onCancel={this.handleCancel}
                        >
                            <img
                                alt="example"
                                style={{ width: '100%' }}
                                src={previewImage}
                            />
                        </Modal>
                    </>
                );
            }

            return (
                <Modal
                    visible={isCreateOpen}
                    maskClosable={false}
                    title={title}
                    okText={
                        isEmpty(orderId) ? texts.create.value : texts.save.value
                    }
                    cancelText={texts.cancel.value}
                    onCancel={this.handleFormCancel}
                    onOk={this.handleFormSave}
                    afterClose={this.afterCloseHandler}
                    width={800}
                    destroyOnClose
                >
                    {content}
                </Modal>
            );
        }
    }
);

function mapStateToProps(state) {
    return {
        structureDefault: state.OrderReducer.newOrder.structureDefault,
        isOpen: state.OrderReducer.newOrder.isOpen,
        isCreateOpen: state.OrderReducer.newOrder.isCreateOpen,
        profile: state.ProfileReducer.profile,
        isUploading: state.OrderReducer.newOrder.isUploading,
        token: state.AuthReducer.token,
        bill: state.OrderReducer.newOrder.bill,
        organisation: state.OrderReducer.newOrder.organisation,
        isPublic: state.OrderReducer.newOrder.isPublic,
        billDate: state.OrderReducer.newOrder.bill_date,
        deadline: state.OrderReducer.newOrder.deadline,
        orderId: state.OrderReducer.newOrder.orderId,
        image1: state.OrderReducer.newOrder.image_1,
        image2: state.OrderReducer.newOrder.image_2,
        image3: state.OrderReducer.newOrder.image_3,
        image4: state.OrderReducer.newOrder.image_4,
        image5: state.OrderReducer.newOrder.image_5,
        image6: state.OrderReducer.newOrder.image_6,
        image7: state.OrderReducer.newOrder.image_7,
        image8: state.OrderReducer.newOrder.image_8,
        image9: state.OrderReducer.newOrder.image_9,
        image10: state.OrderReducer.newOrder.image_10,
        label1: state.OrderReducer.newOrder.label_1,
        label2: state.OrderReducer.newOrder.label_2,
        label3: state.OrderReducer.newOrder.label_3,
        label4: state.OrderReducer.newOrder.label_4,
        label5: state.OrderReducer.newOrder.label_5,
        stl1: state.OrderReducer.newOrder.stl_1,
        stl2: state.OrderReducer.newOrder.stl_2,
        stl3: state.OrderReducer.newOrder.stl_3,
        stl4: state.OrderReducer.newOrder.stl_4,
        stl5: state.OrderReducer.newOrder.stl_5,
        stl6: state.OrderReducer.newOrder.stl_6,
        stl7: state.OrderReducer.newOrder.stl_7,
        stl8: state.OrderReducer.newOrder.stl_8,
        stl9: state.OrderReducer.newOrder.stl_9,
        stl10: state.OrderReducer.newOrder.stl_10,
        stl11: state.OrderReducer.newOrder.stl_11,
        stl12: state.OrderReducer.newOrder.stl_12,
        stl13: state.OrderReducer.newOrder.stl_13,
        stl14: state.OrderReducer.newOrder.stl_14,
        stl15: state.OrderReducer.newOrder.stl_15,
        stl16: state.OrderReducer.newOrder.stl_16,
        stl17: state.OrderReducer.newOrder.stl_17,
        stl18: state.OrderReducer.newOrder.stl_18,
        stl19: state.OrderReducer.newOrder.stl_19,
        stl20: state.OrderReducer.newOrder.stl_20,
        doctor: state.OrderReducer.newOrder.doctor,
        patient: state.OrderReducer.newOrder.patient,
        doctors: state.OrderReducer.doctors,
        technicians: state.OrderReducer.technicians,
        doctorsLoading: state.OrderReducer.doctorsLoading,
        techniciansLoading: state.OrderReducer.techniciansLoading,
        formIsLoading: state.OrderReducer.formIsLoading,
        clinic: state.OrderReducer.newOrder.clinic,
        statusMinivints: state.OrderReducer.newOrder.status_minivints,
        statusTemplate: state.OrderReducer.newOrder.status_template,
        statusEquipment: state.OrderReducer.newOrder.status_equipment,
        statusEquipment2d: state.OrderReducer.newOrder.status_equipment_2d,
        statusBraces: state.OrderReducer.newOrder.status_braces,
        statusAligners: state.OrderReducer.newOrder.status_aligners,
        statusTray: state.OrderReducer.newOrder.status_tray,
        statusSplint: state.OrderReducer.newOrder.status_splint,
        status: state.OrderReducer.newOrder.status,
        added: state.OrderReducer.newOrder.added,
        texts: state.CommonReducer.texts
    };
}

export default connect(mapStateToProps)(CreateOrderModalForm);
