import React from 'react';
import { LabelsPrinterItem } from './LabelsPrinterItem';
import { LabelsPrinterItemAligners } from './LabelsPrinterItemAligners';

interface ILabelsPrinterProps {
    order: any;
    texts: any;
    slices: any;
}

export const LabelsPrinter = (props: ILabelsPrinterProps) => {
    const { order, texts, slices } = props;
    return (
        <>
            {order.status_template !== 'NONE' ? (
                <LabelsPrinterItem
                    title={texts['order.labels.template'].value}
                    doctor={order.doctor.last_name}
                    patient={order.patient}
                    printTitle={texts['order.labels.print'].value}
                    subtitle
                />
            ) : (
                <></>
            )}
            {order.status_equipment !== 'NONE' ? (
                <LabelsPrinterItem
                    title={texts['order.labels.equipment_3d'].value}
                    doctor={order.doctor.last_name}
                    patient={order.patient}
                    printTitle={texts['order.labels.print'].value}
                    subtitle
                />
            ) : (
                <></>
            )}
            {order.status_equipment_2d !== 'NONE' ? (
                <LabelsPrinterItem
                    title={texts['order.labels.equipment_2d'].value}
                    doctor={order.doctor.last_name}
                    patient={order.patient}
                    printTitle={texts['order.labels.print'].value}
                />
            ) : (
                <></>
            )}
            {order.status_braces !== 'NONE' ? (
                <LabelsPrinterItem
                    title={texts['order.labels.braces'].value}
                    doctor={order.doctor.last_name}
                    patient={order.patient}
                    printTitle={texts['order.labels.print'].value}
                    subtitle
                />
            ) : (
                <></>
            )}
            {order.status_aligners !== 'NONE' ? (
                <LabelsPrinterItemAligners
                    title={texts['order.labels.aligners'].value}
                    doctor={order.doctor.last_name}
                    patient={order.patient}
                    printTitle={texts['order.labels.print'].value}
                    subtitle
                    slices={slices}
                    texts={texts}
                    order={order}
                />
            ) : (
                <></>
            )}
        </>
    );
};
