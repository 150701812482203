// eslint-disable
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find, filter } from 'lodash';
import moment from 'moment';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Icon,
    Input,
    Modal,
    Radio,
    Row,
    Select,
    Upload,
    Spin
} from 'antd';
import styled from 'styled-components';
import {
    onNewFormLabelChanged,
    onNewFormAddedChanged,
    onNewFormMinivintsAddedChanged,
    onNewFormTemplateAddedChanged,
    onNewFormToolAddedChanged,
    onNewFormTool2dAddedChanged,
    onNewFormVsAddedChanged,
    onNewFormSplintAddedChanged,
    onNewFormDoctorChanged,
    onNewFormTechnicianChanged,
    onNewFormManagerChanged,
    onNewFormStatusChanged,
    onChangeModelMode,
    closeNewOrder,
    changeImageList,
    changeFileList,
    changeVideo,
    changeGif,
    changeGif2d,
    changeWorkorder,
    changeGifSplint,
    changeZip,
    saveOrderData,
    saveInternalMessage,
    searchDoctors,
    searchTechnicians,
    onNewFormOrganisationChanged
} from '../../redux/actions';
import ResourceUpload from './ResourceUpload';
import { GATEWAY_HOST } from '../../utils/properties';
import { isEmpty } from '../../utils/common';

const { Option } = Select;

const StyledDivider = styled(Divider)`
    font-size: 14px !important;
    font-weight: 700 !important;

    &.ant-divider-horizontal.ant-divider-with-text-left::before {
        width: 0 !important;
    }

    span {
        padding: 0 10px 0 0 !important;
    }
`;

const StyledItem = styled(Form.Item)`
    margin-bottom: 8px;
    font-size: 14px;
`;

const DoctorName = styled.div`
    font-weight: 500;
`;

const DoctorCompany = styled.div`
    font-weight: 200;
    font-size: 12px;
`;

const StyledUpload = styled(Upload)`
    .ant-upload-list-picture-card .ant-upload-list-item-info,
    .ant-upload.ant-upload-select-picture-card {
        margin-right: auto;
    }

    .ant-upload-list-picture-card .ant-upload-list-item,
    .ant-upload.ant-upload-select-picture-card > span {
        float: none;
        min-width: 96px;
        max-width: 96px;
        max-height: 96px;
        min-height: 96px;
        height: 96px;
    }

    .ant-upload-list-picture-card {
        float: none;
    }
`;

const NewOrderModalForm = Form.create({ name: 'new_project_modal_form' })(
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                // modelMode: 'simple',
                previewVisible: false,
                previewImage: ''
            };
        }

        componentDidMount() {
            const { dispatch } = this.props;
            dispatch(searchDoctors());
            dispatch(searchTechnicians());
        }

        onAddImageClick = () => {
            const { added, dispatch } = this.props;
            dispatch(onNewFormAddedChanged(added + 1));
        };

        onAddMinivintsImageClick = () => {
            const { minivintsAdded, dispatch } = this.props;
            dispatch(onNewFormMinivintsAddedChanged(minivintsAdded + 1));
        };

        onAddTemplateImageClick = () => {
            const { templateAdded, dispatch } = this.props;
            dispatch(onNewFormTemplateAddedChanged(templateAdded + 1));
        };

        onAddToolImageClick = () => {
            const { toolAdded, dispatch } = this.props;
            dispatch(onNewFormToolAddedChanged(toolAdded + 1));
        };

        onAddTool2dImageClick = () => {
            const { tool2dAdded, dispatch } = this.props;
            dispatch(onNewFormTool2dAddedChanged(tool2dAdded + 1));
        };

        onAddVsImageClick = () => {
            const { vsAdded, dispatch } = this.props;
            dispatch(onNewFormVsAddedChanged(vsAdded + 1));
        };

        onAddSplintImageClick = () => {
            const { splintAdded, dispatch } = this.props;
            dispatch(onNewFormSplintAddedChanged(splintAdded + 1));
        };

        onStatusChange(status, value) {
            const { dispatch } = this.props;
            dispatch(onNewFormStatusChanged(status, value));
        }

        afterCloseHandler = () => {
            const { form } = this.props;
            form.resetFields();
        };

        handleFormCancel = () => {
            const { dispatch } = this.props;
            dispatch(closeNewOrder());
        };

        handleCancel = () => {
            this.setState({ previewVisible: false });
        };

        handlePreview = (file) => {
            this.setState({
                previewImage: file.url || file.thumbUrl,
                previewVisible: true
            });
        };

        onModelModeChange = (value) => {
            const { dispatch } = this.props;
            dispatch(onChangeModelMode(value.target.value));
        };

        handleChangePhoto = (type, index, p) => {
            const { file, fileList } = p;
            const { dispatch } = this.props;
            dispatch(changeImageList(fileList, file.status, index, type));
        };

        handleChangeFile = (index, p) => {
            const { file, fileList } = p;
            const { dispatch } = this.props;
            dispatch(changeFileList(fileList, file.status, index));
        };

        handleChangeVideo = (p) => {
            const { file, fileList } = p;
            const { dispatch } = this.props;
            dispatch(changeVideo(fileList, file.status));
        };

        handleChangeGif = (p) => {
            const { file, fileList } = p;
            const { dispatch } = this.props;
            dispatch(changeGif(fileList, file.status));
        };

        handleChangeGif2d = (p) => {
            const { file, fileList } = p;
            const { dispatch } = this.props;
            dispatch(changeGif2d(fileList, file.status));
        };

        handleChangeWorkorder = (p) => {
            const { file, fileList } = p;
            const { dispatch } = this.props;
            dispatch(changeWorkorder(fileList, file.status));
        };

        handleChangeGifSplint = (p) => {
            const { file, fileList } = p;
            const { dispatch } = this.props;
            dispatch(changeGifSplint(fileList, file.status));
        };

        handleChangeZip = (p) => {
            const { file, fileList } = p;
            const { dispatch } = this.props;
            dispatch(changeZip(fileList, file.status));
        };

        handleFormSave = () => {
            const {
                dispatch,
                profile,
                form,
                image1,
                image2,
                image3,
                image4,
                image5,
                image6,
                image7,
                image8,
                image9,
                image10,
                // minivints
                minivintsImage1,
                minivintsImage2,
                minivintsImage3,
                minivintsImage4,
                minivintsImage5,
                minivintsImage6,
                minivintsImage7,
                minivintsImage8,
                minivintsImage9,
                minivintsImage10,
                minivintsLabel1,
                minivintsLabel2,
                minivintsLabel3,
                minivintsLabel4,
                minivintsLabel5,
                minivintsLabel6,
                minivintsLabel7,
                minivintsLabel8,
                minivintsLabel9,
                minivintsLabel10,
                // template
                templateImage1,
                templateImage2,
                templateImage3,
                templateImage4,
                templateImage5,
                templateImage6,
                templateImage7,
                templateImage8,
                templateImage9,
                templateImage10,
                templateLabel1,
                templateLabel2,
                templateLabel3,
                templateLabel4,
                templateLabel5,
                templateLabel6,
                templateLabel7,
                templateLabel8,
                templateLabel9,
                templateLabel10,
                // tool
                toolImage1,
                toolImage2,
                toolImage3,
                toolImage4,
                toolImage5,
                toolImage6,
                toolImage7,
                toolImage8,
                toolImage9,
                toolImage10,
                toolLabel1,
                toolLabel2,
                toolLabel3,
                toolLabel4,
                toolLabel5,
                toolLabel6,
                toolLabel7,
                toolLabel8,
                toolLabel9,
                toolLabel10,
                // tool
                tool2dImage1,
                tool2dImage2,
                tool2dImage3,
                tool2dImage4,
                tool2dImage5,
                tool2dImage6,
                tool2dImage7,
                tool2dImage8,
                tool2dImage9,
                tool2dImage10,
                tool2dLabel1,
                tool2dLabel2,
                tool2dLabel3,
                tool2dLabel4,
                tool2dLabel5,
                tool2dLabel6,
                tool2dLabel7,
                tool2dLabel8,
                tool2dLabel9,
                tool2dLabel10,
                // virtual setup
                vsImage1,
                vsImage2,
                vsImage3,
                vsImage4,
                vsImage5,
                vsImage6,
                vsImage7,
                vsImage8,
                vsImage9,
                vsImage10,
                vsImage11,
                vsImage12,
                vsImage13,
                vsImage14,
                vsImage15,
                vsLabel1,
                vsLabel2,
                vsLabel3,
                vsLabel4,
                vsLabel5,
                vsLabel6,
                vsLabel7,
                vsLabel8,
                vsLabel9,
                vsLabel10,
                vsLabel11,
                vsLabel12,
                vsLabel13,
                vsLabel14,
                vsLabel15,
                // splint
                splintImage1,
                splintImage2,
                splintImage3,
                splintImage4,
                splintImage5,
                splintImage6,
                splintImage7,
                splintImage8,
                splintImage9,
                splintImage10,
                splintLabel1,
                splintLabel2,
                splintLabel3,
                splintLabel4,
                splintLabel5,
                splintLabel6,
                splintLabel7,
                splintLabel8,
                splintLabel9,
                splintLabel10,
                // stl
                stl1,
                stl2,
                stl3,
                stl4,
                stl5,
                stl6,
                stl7,
                stl8,
                stl9,
                stl10,
                stl11,
                stl12,
                stl13,
                stl14,
                stl15,
                stl16,
                stl17,
                stl18,
                stl19,
                stl20,
                // template video
                video,
                // equipment gif
                gif,
                gif2d,
                workorder,
                gif_splint,
                zip,
                status,
                statusMinivints,
                statusEquipment,
                statusEquipment2d,
                statusBraces,
                statusAligners,
                statusTemplate,
                statusTray,
                statusSplint,
                orderId,
                doctor,
                label1,
                label2,
                label3,
                label4,
                label5,
                label6,
                version,
                organisation,
                versionIncrement,
                currentOrder
            } = this.props;
            const formValues = form.getFieldsValue();

            const data = {
                orderId,
                version: versionIncrement ? version + 1 : version,
                doctor: formValues.doctor,
                technician: isEmpty(formValues.technician)
                    ? undefined
                    : formValues.technician,
                manager: isEmpty(formValues.manager)
                    ? undefined
                    : formValues.manager,
                doctor_last_name: doctor.last_name,
                patient: formValues.patient,
                clinic: formValues.clinic,
                public: true,
                design_date: moment.utc().format(),
                status,
                bill: formValues.bill,
                bill_date: isEmpty(formValues.billDate)
                    ? undefined
                    : moment.utc(formValues.billDate).format(),
                deadline: isEmpty(formValues.deadline)
                    ? undefined
                    : moment.utc(formValues.deadline).format(),
                status_minivints: statusMinivints,
                status_equipment: statusEquipment,
                status_equipment_2d: statusEquipment2d,
                status_braces: statusBraces,
                status_aligners: statusAligners,
                status_template: statusTemplate,
                status_tray: statusTray,
                status_splint: statusSplint,
                organisation: isEmpty(organisation) ? '' : organisation,
                image1: isEmpty(image1)
                    ? undefined
                    : `${image1[0].response[0].id}`,
                image2: isEmpty(image2)
                    ? undefined
                    : `${image2[0].response[0].id}`,
                image3: isEmpty(image3)
                    ? undefined
                    : `${image3[0].response[0].id}`,
                image4: isEmpty(image4)
                    ? undefined
                    : `${image4[0].response[0].id}`,
                image5: isEmpty(image5)
                    ? undefined
                    : `${image5[0].response[0].id}`,
                image6: isEmpty(image6)
                    ? undefined
                    : `${image6[0].response[0].id}`,
                image7: isEmpty(image7)
                    ? undefined
                    : `${image7[0].response[0].id}`,
                image8: isEmpty(image8)
                    ? undefined
                    : `${image8[0].response[0].id}`,
                image9: isEmpty(image9)
                    ? undefined
                    : `${image9[0].response[0].id}`,
                image10: isEmpty(image10)
                    ? undefined
                    : `${image10[0].response[0].id}`,
                // minivints
                minivintsImage1: isEmpty(minivintsImage1)
                    ? undefined
                    : `${minivintsImage1[0].response[0].id}`,
                minivintsImage2: isEmpty(minivintsImage2)
                    ? undefined
                    : `${minivintsImage2[0].response[0].id}`,
                minivintsImage3: isEmpty(minivintsImage3)
                    ? undefined
                    : `${minivintsImage3[0].response[0].id}`,
                minivintsImage4: isEmpty(minivintsImage4)
                    ? undefined
                    : `${minivintsImage4[0].response[0].id}`,
                minivintsImage5: isEmpty(minivintsImage5)
                    ? undefined
                    : `${minivintsImage5[0].response[0].id}`,
                minivintsImage6: isEmpty(minivintsImage6)
                    ? undefined
                    : `${minivintsImage6[0].response[0].id}`,
                minivintsImage7: isEmpty(minivintsImage7)
                    ? undefined
                    : `${minivintsImage7[0].response[0].id}`,
                minivintsImage8: isEmpty(minivintsImage8)
                    ? undefined
                    : `${minivintsImage8[0].response[0].id}`,
                minivintsImage9: isEmpty(minivintsImage9)
                    ? undefined
                    : `${minivintsImage9[0].response[0].id}`,
                minivintsImage10: isEmpty(minivintsImage10)
                    ? undefined
                    : `${minivintsImage10[0].response[0].id}`,
                // template
                templateImage1: isEmpty(templateImage1)
                    ? undefined
                    : `${templateImage1[0].response[0].id}`,
                templateImage2: isEmpty(templateImage2)
                    ? undefined
                    : `${templateImage2[0].response[0].id}`,
                templateImage3: isEmpty(templateImage3)
                    ? undefined
                    : `${templateImage3[0].response[0].id}`,
                templateImage4: isEmpty(templateImage4)
                    ? undefined
                    : `${templateImage4[0].response[0].id}`,
                templateImage5: isEmpty(templateImage5)
                    ? undefined
                    : `${templateImage5[0].response[0].id}`,
                templateImage6: isEmpty(templateImage6)
                    ? undefined
                    : `${templateImage6[0].response[0].id}`,
                templateImage7: isEmpty(templateImage7)
                    ? undefined
                    : `${templateImage7[0].response[0].id}`,
                templateImage8: isEmpty(templateImage8)
                    ? undefined
                    : `${templateImage8[0].response[0].id}`,
                templateImage9: isEmpty(templateImage9)
                    ? undefined
                    : `${templateImage9[0].response[0].id}`,
                templateImage10: isEmpty(templateImage10)
                    ? undefined
                    : `${templateImage10[0].response[0].id}`,
                // tool
                toolImage1: isEmpty(toolImage1)
                    ? undefined
                    : `${toolImage1[0].response[0].id}`,
                toolImage2: isEmpty(toolImage2)
                    ? undefined
                    : `${toolImage2[0].response[0].id}`,
                toolImage3: isEmpty(toolImage3)
                    ? undefined
                    : `${toolImage3[0].response[0].id}`,
                toolImage4: isEmpty(toolImage4)
                    ? undefined
                    : `${toolImage4[0].response[0].id}`,
                toolImage5: isEmpty(toolImage5)
                    ? undefined
                    : `${toolImage5[0].response[0].id}`,
                toolImage6: isEmpty(toolImage6)
                    ? undefined
                    : `${toolImage6[0].response[0].id}`,
                toolImage7: isEmpty(toolImage7)
                    ? undefined
                    : `${toolImage7[0].response[0].id}`,
                toolImage8: isEmpty(toolImage8)
                    ? undefined
                    : `${toolImage8[0].response[0].id}`,
                toolImage9: isEmpty(toolImage9)
                    ? undefined
                    : `${toolImage9[0].response[0].id}`,
                toolImage10: isEmpty(toolImage10)
                    ? undefined
                    : `${toolImage10[0].response[0].id}`,
                // tool 2d
                tool2dImage1: isEmpty(tool2dImage1)
                    ? undefined
                    : `${tool2dImage1[0].response[0].id}`,
                tool2dImage2: isEmpty(tool2dImage2)
                    ? undefined
                    : `${tool2dImage2[0].response[0].id}`,
                tool2dImage3: isEmpty(tool2dImage3)
                    ? undefined
                    : `${tool2dImage3[0].response[0].id}`,
                tool2dImage4: isEmpty(tool2dImage4)
                    ? undefined
                    : `${tool2dImage4[0].response[0].id}`,
                tool2dImage5: isEmpty(tool2dImage5)
                    ? undefined
                    : `${tool2dImage5[0].response[0].id}`,
                tool2dImage6: isEmpty(tool2dImage6)
                    ? undefined
                    : `${tool2dImage6[0].response[0].id}`,
                tool2dImage7: isEmpty(tool2dImage7)
                    ? undefined
                    : `${tool2dImage7[0].response[0].id}`,
                tool2dImage8: isEmpty(tool2dImage8)
                    ? undefined
                    : `${tool2dImage8[0].response[0].id}`,
                tool2dImage9: isEmpty(tool2dImage9)
                    ? undefined
                    : `${tool2dImage9[0].response[0].id}`,
                tool2dImage10: isEmpty(tool2dImage10)
                    ? undefined
                    : `${tool2dImage10[0].response[0].id}`,
                // virtual setup
                vsImage1: isEmpty(vsImage1)
                    ? undefined
                    : `${vsImage1[0].response[0].id}`,
                vsImage2: isEmpty(vsImage2)
                    ? undefined
                    : `${vsImage2[0].response[0].id}`,
                vsImage3: isEmpty(vsImage3)
                    ? undefined
                    : `${vsImage3[0].response[0].id}`,
                vsImage4: isEmpty(vsImage4)
                    ? undefined
                    : `${vsImage4[0].response[0].id}`,
                vsImage5: isEmpty(vsImage5)
                    ? undefined
                    : `${vsImage5[0].response[0].id}`,
                vsImage6: isEmpty(vsImage6)
                    ? undefined
                    : `${vsImage6[0].response[0].id}`,
                vsImage7: isEmpty(vsImage7)
                    ? undefined
                    : `${vsImage7[0].response[0].id}`,
                vsImage8: isEmpty(vsImage8)
                    ? undefined
                    : `${vsImage8[0].response[0].id}`,
                vsImage9: isEmpty(vsImage9)
                    ? undefined
                    : `${vsImage9[0].response[0].id}`,
                vsImage10: isEmpty(vsImage10)
                    ? undefined
                    : `${vsImage10[0].response[0].id}`,
                vsImage11: isEmpty(vsImage11)
                    ? undefined
                    : `${vsImage11[0].response[0].id}`,
                vsImage12: isEmpty(vsImage12)
                    ? undefined
                    : `${vsImage12[0].response[0].id}`,
                vsImage13: isEmpty(vsImage13)
                    ? undefined
                    : `${vsImage13[0].response[0].id}`,
                vsImage14: isEmpty(vsImage14)
                    ? undefined
                    : `${vsImage14[0].response[0].id}`,
                vsImage15: isEmpty(vsImage15)
                    ? undefined
                    : `${vsImage15[0].response[0].id}`,
                // splint
                splintImage1: isEmpty(splintImage1)
                    ? undefined
                    : `${splintImage1[0].response[0].id}`,
                splintImage2: isEmpty(splintImage2)
                    ? undefined
                    : `${splintImage2[0].response[0].id}`,
                splintImage3: isEmpty(splintImage3)
                    ? undefined
                    : `${splintImage3[0].response[0].id}`,
                splintImage4: isEmpty(splintImage4)
                    ? undefined
                    : `${splintImage4[0].response[0].id}`,
                splintImage5: isEmpty(splintImage5)
                    ? undefined
                    : `${splintImage5[0].response[0].id}`,
                splintImage6: isEmpty(splintImage6)
                    ? undefined
                    : `${splintImage6[0].response[0].id}`,
                splintImage7: isEmpty(splintImage7)
                    ? undefined
                    : `${splintImage7[0].response[0].id}`,
                splintImage8: isEmpty(splintImage8)
                    ? undefined
                    : `${splintImage8[0].response[0].id}`,
                splintImage9: isEmpty(splintImage9)
                    ? undefined
                    : `${splintImage9[0].response[0].id}`,
                splintImage10: isEmpty(splintImage10)
                    ? undefined
                    : `${splintImage10[0].response[0].id}`,
                label1,
                label2,
                label3,
                label4,
                label5,
                label6,
                minivintsLabel1,
                minivintsLabel2,
                minivintsLabel3,
                minivintsLabel4,
                minivintsLabel5,
                minivintsLabel6,
                minivintsLabel7,
                minivintsLabel8,
                minivintsLabel9,
                minivintsLabel10,
                templateLabel1,
                templateLabel2,
                templateLabel3,
                templateLabel4,
                templateLabel5,
                templateLabel6,
                templateLabel7,
                templateLabel8,
                templateLabel9,
                templateLabel10,
                toolLabel1,
                toolLabel2,
                toolLabel3,
                toolLabel4,
                toolLabel5,
                toolLabel6,
                toolLabel7,
                toolLabel8,
                toolLabel9,
                toolLabel10,
                tool2dLabel1,
                tool2dLabel2,
                tool2dLabel3,
                tool2dLabel4,
                tool2dLabel5,
                tool2dLabel6,
                tool2dLabel7,
                tool2dLabel8,
                tool2dLabel9,
                tool2dLabel10,
                vsLabel1,
                vsLabel2,
                vsLabel3,
                vsLabel4,
                vsLabel5,
                vsLabel6,
                vsLabel7,
                vsLabel8,
                vsLabel9,
                vsLabel10,
                vsLabel11,
                vsLabel12,
                vsLabel13,
                vsLabel14,
                vsLabel15,
                splintLabel1,
                splintLabel2,
                splintLabel3,
                splintLabel4,
                splintLabel5,
                splintLabel6,
                splintLabel7,
                splintLabel8,
                splintLabel9,
                splintLabel10,
                stl1: isEmpty(stl1) ? undefined : `${stl1[0].response[0].id}`,
                stl2: isEmpty(stl2) ? undefined : `${stl2[0].response[0].id}`,
                stl3: isEmpty(stl3) ? undefined : `${stl3[0].response[0].id}`,
                stl4: isEmpty(stl4) ? undefined : `${stl4[0].response[0].id}`,
                stl5: isEmpty(stl5) ? undefined : `${stl5[0].response[0].id}`,
                stl6: isEmpty(stl6) ? undefined : `${stl6[0].response[0].id}`,
                stl7: isEmpty(stl7) ? undefined : `${stl7[0].response[0].id}`,
                stl8: isEmpty(stl8) ? undefined : `${stl8[0].response[0].id}`,
                stl9: isEmpty(stl9) ? undefined : `${stl9[0].response[0].id}`,
                stl10: isEmpty(stl10)
                    ? undefined
                    : `${stl10[0].response[0].id}`,
                stl11: isEmpty(stl11)
                    ? undefined
                    : `${stl11[0].response[0].id}`,
                stl12: isEmpty(stl12)
                    ? undefined
                    : `${stl12[0].response[0].id}`,
                stl13: isEmpty(stl13)
                    ? undefined
                    : `${stl13[0].response[0].id}`,
                stl14: isEmpty(stl14)
                    ? undefined
                    : `${stl14[0].response[0].id}`,
                stl15: isEmpty(stl15)
                    ? undefined
                    : `${stl15[0].response[0].id}`,
                stl16: isEmpty(stl16)
                    ? undefined
                    : `${stl16[0].response[0].id}`,
                stl17: isEmpty(stl17)
                    ? undefined
                    : `${stl17[0].response[0].id}`,
                stl18: isEmpty(stl18)
                    ? undefined
                    : `${stl18[0].response[0].id}`,
                stl19: isEmpty(stl19)
                    ? undefined
                    : `${stl19[0].response[0].id}`,
                stl20: isEmpty(stl20)
                    ? undefined
                    : `${stl20[0].response[0].id}`,
                video: isEmpty(video)
                    ? undefined
                    : `${video[0].response[0].id}`,
                gif: isEmpty(gif) ? undefined : `${gif[0].response[0].id}`,
                gif2d: isEmpty(gif2d)
                    ? undefined
                    : `${gif2d[0].response[0].id}`,
                workorder: isEmpty(workorder)
                    ? undefined
                    : `${workorder[0].response[0].id}`,
                gif_splint: isEmpty(gif_splint)
                    ? undefined
                    : `${gif_splint[0].response[0].id}`,
                zip: isEmpty(zip) ? undefined : `${zip[0].response[0].id}`
            };

            if (status === 'READYTOSEND') {
                data.status_template =
                    data.status_template !== 'NONE' ? 'READYTOSEND' : 'NONE';
                data.status_equipment =
                    data.status_equipment !== 'NONE' ? 'READYTOSEND' : 'NONE';
                data.status_equipment_2d =
                    data.status_equipment_2d !== 'NONE'
                        ? 'READYTOSEND'
                        : 'NONE';
                data.status_aligners =
                    data.status_aligners !== 'NONE' ? 'READYTOSEND' : 'NONE';
                data.status_braces =
                    data.status_braces !== 'NONE' ? 'READYTOSEND' : 'NONE';
                data.status_splint =
                    data.status_splint !== 'NONE' ? 'READYTOSEND' : 'NONE';
            }

            const isOtk = profile.role.name === 'otk';
            if (isOtk) {
                if (currentOrder.status_aligners !== statusAligners) {
                    dispatch(
                        saveInternalMessage(
                            orderId,
                            'latest',
                            'Элайнеры проверены ОТК',
                            '',
                            moment.utc().format(),
                            formValues.doctor
                        )
                    );
                }
                if (currentOrder.status_braces !== statusBraces) {
                    dispatch(
                        saveInternalMessage(
                            orderId,
                            'latest',
                            'Брекеты проверены ОТК',
                            '',
                            moment.utc().format(),
                            formValues.doctor
                        )
                    );
                }
                if (currentOrder.status_equipment !== statusEquipment) {
                    dispatch(
                        saveInternalMessage(
                            orderId,
                            'latest',
                            'Аппарат проверен ОТК',
                            '',
                            moment.utc().format(),
                            formValues.doctor
                        )
                    );
                }
                if (currentOrder.status_equipment_2d !== statusEquipment2d) {
                    dispatch(
                        saveInternalMessage(
                            orderId,
                            'latest',
                            'Аппарат проверен ОТК',
                            '',
                            moment.utc().format(),
                            formValues.doctor
                        )
                    );
                }
                if (currentOrder.status_splint !== statusSplint) {
                    dispatch(
                        saveInternalMessage(
                            orderId,
                            'latest',
                            'Сплинт проверен ОТК',
                            '',
                            moment.utc().format(),
                            formValues.doctor
                        )
                    );
                }
                if (currentOrder.status_template !== statusTemplate) {
                    dispatch(
                        saveInternalMessage(
                            orderId,
                            'latest',
                            'Шаблон проверен ОТК',
                            '',
                            moment.utc().format(),
                            formValues.doctor
                        )
                    );
                }
            }

            dispatch(saveOrderData(data));
            dispatch(closeNewOrder());
        };

        handleChange(value) {
            const { doctors, dispatch } = this.props;
            const doctor = find(doctors, (o) => o.id === value);
            dispatch(onNewFormDoctorChanged(doctor));
        }

        handleTechChange(value) {
            const { technicians, dispatch } = this.props;
            const technician = find(technicians, (o) => o.id === value);
            dispatch(onNewFormTechnicianChanged(technician));
        }

        handleManagerChange(value) {
            const { technicians, dispatch } = this.props;
            const manager = find(technicians, (o) => o.id === value);
            dispatch(onNewFormManagerChanged(manager));
        }

        handleOrgChange(value) {
            const { doctor, dispatch } = this.props;
            const org = find(doctor.organisations, (o) => o.name === value);
            dispatch(onNewFormOrganisationChanged(org));
        }

        handleChangeImageTitle(type, label, value) {
            const { dispatch } = this.props;
            dispatch(onNewFormLabelChanged(label, value.target.value, type));
        }

        fetchDoctor(value) {
            const { dispatch } = this.props;
            dispatch(searchDoctors(value));
        }

        fetchTechnician(value) {
            const { dispatch } = this.props;
            dispatch(searchTechnicians(value));
        }

        render() {
            const GATEWAY_API = `${GATEWAY_HOST}/upload`;
            const { previewVisible, previewImage } = this.state;
            const {
                isOpen,
                token,
                form,
                bill,
                billDate,
                deadline,
                // image1,
                // image2,
                // image3,
                // image4,
                // image5,
                // label1,
                // label2,
                // label3,
                // label4,
                // label5,
                stl1,
                stl2,
                stl3,
                stl4,
                stl5,
                stl6,
                stl7,
                stl8,
                stl9,
                stl10,
                stl11,
                stl12,
                stl13,
                // stl14,
                // stl15,
                // stl16,
                // stl17,
                stl18,
                stl19,
                stl20,
                // template video
                video,
                // equipment gif
                gif,
                gif2d,
                workorder,
                gif_splint,
                zip,
                modelMode,
                minivintsImage1,
                minivintsImage2,
                minivintsImage3,
                minivintsImage4,
                minivintsImage5,
                minivintsImage6,
                minivintsImage7,
                minivintsImage8,
                minivintsImage9,
                minivintsImage10,
                minivintsLabel1,
                minivintsLabel2,
                minivintsLabel3,
                minivintsLabel4,
                minivintsLabel5,
                minivintsLabel6,
                minivintsLabel7,
                minivintsLabel8,
                minivintsLabel9,
                minivintsLabel10,
                templateImage1,
                templateImage2,
                templateImage3,
                templateImage4,
                templateImage5,
                templateImage6,
                templateImage7,
                templateImage8,
                templateImage9,
                templateImage10,
                templateLabel1,
                templateLabel2,
                templateLabel3,
                templateLabel4,
                templateLabel5,
                templateLabel6,
                templateLabel7,
                templateLabel8,
                templateLabel9,
                templateLabel10,
                toolImage1,
                toolImage2,
                toolImage3,
                toolImage4,
                toolImage5,
                toolImage6,
                toolImage7,
                toolImage8,
                toolImage9,
                toolImage10,
                toolLabel1,
                toolLabel2,
                toolLabel3,
                toolLabel4,
                toolLabel5,
                toolLabel6,
                toolLabel7,
                toolLabel8,
                toolLabel9,
                toolLabel10,
                // tool 2d
                tool2dImage1,
                tool2dImage2,
                tool2dImage3,
                tool2dImage4,
                tool2dImage5,
                tool2dImage6,
                tool2dImage7,
                tool2dImage8,
                tool2dImage9,
                tool2dImage10,
                tool2dLabel1,
                tool2dLabel2,
                tool2dLabel3,
                tool2dLabel4,
                tool2dLabel5,
                tool2dLabel6,
                tool2dLabel7,
                tool2dLabel8,
                tool2dLabel9,
                tool2dLabel10,
                // virtual setup
                vsImage1,
                vsImage2,
                vsImage3,
                vsImage4,
                vsImage5,
                vsImage6,
                vsImage7,
                vsImage8,
                vsImage9,
                vsImage10,
                vsImage11,
                vsImage12,
                vsImage13,
                vsImage14,
                vsImage15,
                vsLabel1,
                vsLabel2,
                vsLabel3,
                vsLabel4,
                vsLabel5,
                vsLabel6,
                vsLabel7,
                vsLabel8,
                vsLabel9,
                vsLabel10,
                vsLabel11,
                vsLabel12,
                vsLabel13,
                vsLabel14,
                vsLabel15,
                // splint setup
                splintImage1,
                splintImage2,
                splintImage3,
                splintImage4,
                splintImage5,
                splintImage6,
                splintImage7,
                splintImage8,
                splintImage9,
                splintImage10,
                splintLabel1,
                splintLabel2,
                splintLabel3,
                splintLabel4,
                splintLabel5,
                splintLabel6,
                splintLabel7,
                splintLabel8,
                splintLabel9,
                splintLabel10,
                doctor,
                patient,
                technician,
                manager,
                // isPublic,
                doctorsLoading,
                doctors,
                techniciansLoading,
                technicians,
                formIsLoading,
                status,
                statusMinivints,
                statusEquipment,
                statusEquipment2d,
                statusBraces,
                statusAligners,
                statusTemplate,
                statusSplint,
                // statusTray,
                minivintsAdded,
                templateAdded,
                toolAdded,
                tool2dAdded,
                vsAdded,
                splintAdded,
                // added,
                orderId,
                organisation,
                texts,
                profile
            } = this.props;
            const isTechnitian = profile.role.name === 'technician';
            const isOtk = profile.role.name === 'otk';
            const readonly = isTechnitian || isOtk;
            const isAdministrator = profile.role.name === 'manager';
            const isOnlyEquipment2d =
                statusEquipment2d !== 'NONE' &&
                statusTemplate === 'NONE' &&
                statusEquipment === 'NONE' &&
                statusSplint === 'NONE' &&
                statusBraces === 'NONE' &&
                statusAligners === 'NONE' &&
                statusMinivints === 'NONE';
            const showEquipment2d =
                typeof statusEquipment2d !== 'undefined' &&
                statusEquipment2d !== 'NONE';
            const showVirtualSetup =
                (statusBraces !== 'NONE' || statusAligners !== 'NONE') &&
                typeof statusBraces !== 'undefined' &&
                typeof statusAligners !== 'undefined';
            const statusVs =
                typeof statusBraces !== 'undefined' && statusBraces !== 'NONE'
                    ? statusBraces
                    : statusAligners;
            const vs =
                typeof statusBraces !== 'undefined' && statusBraces !== 'NONE'
                    ? 'braces'
                    : 'aligners';
            const noBracesOrAligners =
                statusAligners === 'NONE' && statusBraces === 'NONE';
            const title = isEmpty(orderId)
                ? texts['orders.new'].value
                : texts['orders.edit'].value;
            const headers = {
                Authorization: `Bearer ${token}`,
                'X-Requested-With': null
            };
            const { getFieldDecorator } = form;
            const uploadParams = {
                // ref: 'user',
                // refId: id,
                // field: 'avatar',
                // source: 'users-permissions',
            };

            let organisations = isEmpty(doctor) ? [] : doctor.organisations;
            organisations = isEmpty(organisations) ? [] : organisations;

            const uploadButton = (
                <div>
                    <Icon type="plus" />
                    <div className="ant-upload-text">{texts.upload.value}</div>
                </div>
            );

            const doctorId = isEmpty(doctor) ? '' : doctor.id;
            const technitianId = isEmpty(technician) ? '' : technician.id;
            const managerId = isEmpty(manager) ? '' : manager.id;
            // const filteredDoctors = doctors;
            const filteredDoctors = filter(
                doctors,
                (d) => `${d.id}` !== `${doctorId}`
            );
            const filteredTechnicians = filter(
                technicians,
                (d) =>
                    `${d.id}` !== `${technitianId}` &&
                    d.role.name === 'technician'
            );
            const filteredManagers = filter(
                technicians,
                (d) => `${d.id}` !== `${managerId}` && d.role.name === 'manager'
            );

            if (!isEmpty(doctor)) {
                filteredDoctors.unshift(doctor);
            }

            if (!isEmpty(technician)) {
                filteredTechnicians.unshift(technician);
            }
            if (!isEmpty(manager)) {
                filteredManagers.unshift(manager);
            }

            let content = <Spin size="small" />;
            if (!formIsLoading) {
                console.log(doctor);

                content = (
                    <>
                        <StyledDivider orientation="left">
                            {texts['new.common.information'].value}
                        </StyledDivider>
                        <Form layout="vertical">
                            <Row gutter={16}>
                                <Col md={12} sm={24}>
                                    <StyledItem
                                        label={`${texts['order.doctor'].value}:`}
                                    >
                                        {getFieldDecorator('doctor', {
                                            initialValue: isEmpty(doctor)
                                                ? ''
                                                : doctor.id
                                        })(
                                            <Select
                                                // defaultValue={this.state.search}
                                                notFoundContent={
                                                    doctorsLoading ? (
                                                        <Spin size="small" />
                                                    ) : null
                                                }
                                                onSearch={(value) =>
                                                    this.fetchDoctor(value)
                                                }
                                                onChange={(value) =>
                                                    this.handleChange(value)
                                                }
                                                style={{ width: '100%' }}
                                                size="small"
                                                filterOption={(input, option) =>
                                                    option.props.children[0].props.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                                showSearch
                                                optionLabelProp="label"
                                                disabled={readonly}
                                            >
                                                {filteredDoctors.map((d) => {
                                                    const name = `${d.last_name} ${d.first_name} ${d.middle_name}`;
                                                    return (
                                                        <Option
                                                            key={`${d.id}`}
                                                            label={name}
                                                            value={d.id}
                                                        >
                                                            <DoctorName>
                                                                {name}
                                                            </DoctorName>
                                                            <DoctorCompany>
                                                                {d.company}
                                                            </DoctorCompany>
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    </StyledItem>
                                </Col>
                                <Col md={12} sm={24}>
                                    <StyledItem
                                        label={`${texts['profile.organisation'].value}:`}
                                    >
                                        <Select
                                            value={
                                                isEmpty(organisation)
                                                    ? null
                                                    : organisation
                                            }
                                            notFoundContent={
                                                doctorsLoading ? (
                                                    <Spin size="small" />
                                                ) : null
                                            }
                                            style={{ width: '100%' }}
                                            onChange={(value) =>
                                                this.handleOrgChange(value)
                                            }
                                            size="small"
                                            optionLabelProp="label"
                                            disabled={readonly}
                                        >
                                            {organisations.map((d) => (
                                                <Option
                                                    key={d.name}
                                                    label={d.name}
                                                >
                                                    {d.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </StyledItem>
                                </Col>
                                <Col md={12} sm={24}>
                                    <StyledItem
                                        label={`${texts['order.patient'].value}:`}
                                    >
                                        {getFieldDecorator('patient', {
                                            initialValue: patient
                                        })(
                                            <Input
                                                size="small"
                                                disabled={readonly}
                                            />
                                        )}
                                    </StyledItem>
                                </Col>
                                <Col md={12} sm={24}>
                                    <StyledItem
                                        label={`${texts['role.technician'].value}:`}
                                    >
                                        {getFieldDecorator('technician', {
                                            initialValue: isEmpty(technician)
                                                ? ''
                                                : technician.id
                                        })(
                                            <Select
                                                // defaultValue={this.state.search}
                                                notFoundContent={
                                                    techniciansLoading ? (
                                                        <Spin size="small" />
                                                    ) : null
                                                }
                                                onSearch={(value) =>
                                                    this.fetchTechnician(value)
                                                }
                                                onChange={(value) =>
                                                    this.handleTechChange(value)
                                                }
                                                style={{ width: '100%' }}
                                                size="small"
                                                filterOption={(input, option) =>
                                                    option.props.children[0].props.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                                showSearch
                                                optionLabelProp="label"
                                                disabled={isOtk}
                                            >
                                                {filteredTechnicians.map(
                                                    (d) => {
                                                        const name = `${d.last_name} ${d.first_name} ${d.middle_name}`;
                                                        return (
                                                            <Option
                                                                key={`${d.id}`}
                                                                label={name}
                                                                value={d.id}
                                                            >
                                                                <DoctorName>
                                                                    {name}
                                                                </DoctorName>
                                                                <DoctorCompany>
                                                                    {d.company}
                                                                </DoctorCompany>
                                                            </Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    </StyledItem>
                                </Col>
                                <Col md={12} sm={24}>
                                    <StyledItem
                                        label={`${texts['role.manager'].value}:`}
                                    >
                                        {getFieldDecorator('manager', {
                                            initialValue: isEmpty(manager)
                                                ? ''
                                                : manager.id
                                        })(
                                            <Select
                                                // defaultValue={this.state.search}
                                                notFoundContent={
                                                    techniciansLoading ? (
                                                        <Spin size="small" />
                                                    ) : null
                                                }
                                                onSearch={(value) =>
                                                    this.fetchTechnician(value)
                                                }
                                                onChange={(value) =>
                                                    this.handleManagerChange(
                                                        value
                                                    )
                                                }
                                                style={{ width: '100%' }}
                                                size="small"
                                                filterOption={(input, option) =>
                                                    option.props.children[0].props.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                                showSearch
                                                optionLabelProp="label"
                                                disabled={isOtk}
                                            >
                                                {filteredManagers.map((d) => {
                                                    const name = `${d.last_name} ${d.first_name} ${d.middle_name}`;
                                                    return (
                                                        <Option
                                                            key={d.id}
                                                            label={name}
                                                            value={d.id}
                                                        >
                                                            <DoctorName>
                                                                {name}
                                                            </DoctorName>
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    </StyledItem>
                                </Col>
                                <Col md={24} sm={24}>
                                    <StyledItem
                                        label={`${texts['order.deadline'].value}:`}
                                    >
                                        {getFieldDecorator('deadline', {
                                            initialValue: deadline
                                                ? moment(deadline)
                                                : null
                                        })(
                                            <DatePicker
                                                size="small"
                                                placeholder={
                                                    texts['date.select'].value
                                                }
                                                disabled
                                            />
                                        )}
                                    </StyledItem>
                                </Col>
                                <Col md={12} sm={24}>
                                    <StyledItem
                                        label={`${texts['order.bill'].value}:`}
                                    >
                                        {getFieldDecorator('bill', {
                                            initialValue: bill
                                        })(
                                            <Input
                                                size="small"
                                                disabled={readonly}
                                            />
                                        )}
                                    </StyledItem>
                                </Col>
                                <Col md={12} sm={24}>
                                    <StyledItem
                                        label={`${texts['order.bill.date'].value}:`}
                                    >
                                        {getFieldDecorator('billDate', {
                                            initialValue: billDate
                                                ? moment(billDate)
                                                : null
                                        })(
                                            <DatePicker
                                                size="small"
                                                placeholder={
                                                    texts['date.select'].value
                                                }
                                                disabled
                                            />
                                        )}
                                    </StyledItem>
                                </Col>
                                <Col md={24} sm={24}>
                                    <StyledItem
                                        label={`${texts.status.value}:`}
                                    >
                                        <Radio.Group
                                            disabled={isTechnitian}
                                            size="small"
                                            buttonStyle="solid"
                                            onChange={(value) =>
                                                this.onStatusChange(
                                                    'status',
                                                    value.target.value
                                                )
                                            }
                                            defaultValue={status}
                                        >
                                            <Radio.Button
                                                value="NEW"
                                                disabled={readonly}
                                            >
                                                {
                                                    texts['order.status.new']
                                                        .value
                                                }
                                            </Radio.Button>
                                            <Radio.Button
                                                value="UNPAID"
                                                disabled={readonly}
                                            >
                                                {
                                                    texts['order.status.unpaid']
                                                        .value
                                                }
                                            </Radio.Button>
                                            <Radio.Button
                                                value="PAID"
                                                disabled={readonly}
                                            >
                                                {
                                                    texts['order.status.paid']
                                                        .value
                                                }
                                            </Radio.Button>
                                            <Radio.Button
                                                value="READYTOSEND"
                                                disabled={
                                                    isAdministrator ||
                                                    isTechnitian
                                                }
                                            >
                                                {
                                                    texts[
                                                        'order.status.readytosend'
                                                    ].value
                                                }
                                            </Radio.Button>
                                            <Radio.Button
                                                value="REWORK"
                                                disabled={readonly}
                                            >
                                                {
                                                    texts['order.status.rework']
                                                        .value
                                                }
                                            </Radio.Button>
                                            <Radio.Button
                                                value="READY"
                                                disabled={readonly}
                                            >
                                                {
                                                    texts['order.status.ready']
                                                        .value
                                                }
                                            </Radio.Button>
                                            <Radio.Button
                                                value="CLOSED"
                                                disabled={readonly}
                                            >
                                                {
                                                    texts['order.status.closed']
                                                        .value
                                                }
                                            </Radio.Button>
                                            <Radio.Button
                                                value="DECLINED"
                                                disabled={readonly}
                                            >
                                                {
                                                    texts[
                                                        'order.status.declined'
                                                    ].value
                                                }
                                            </Radio.Button>
                                        </Radio.Group>
                                    </StyledItem>
                                </Col>
                            </Row>
                        </Form>
                        {(isAdministrator || isTechnitian) && !isOtk && (
                            <>
                                <StyledDivider orientation="left">
                                    {texts.workorder.value}
                                </StyledDivider>
                                <Row gutter={16}>
                                    <Col md={6} sm={24}>
                                        <StyledItem
                                            label={`${texts.workorderupload.value} (pdf):`}
                                            hidden={isOtk}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={workorder}
                                                onChange={(p) =>
                                                    this.handleChangeWorkorder(
                                                        p
                                                    )
                                                }
                                                // onPreview={this.handlePreview}
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={isOtk}
                                            >
                                                {workorder.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {/* MODEL (hidden for administrator & otk) */}
                        {!isAdministrator && !isOnlyEquipment2d && !isOtk && (
                            <>
                                <StyledDivider orientation="left">
                                    {texts.model.value} (stl)
                                </StyledDivider>
                                <Radio.Group
                                    onChange={this.onModelModeChange}
                                    value={modelMode}
                                >
                                    <Radio value="simple">До/После</Radio>
                                    <Radio value="slices">Серия</Radio>
                                </Radio.Group>
                                <Row
                                    hidden={modelMode === 'slices'}
                                    gutter={16}
                                >
                                    {/* UPPER JAW MODEL (BEFORE) */}
                                    <Col md={6} sm={24}>
                                        <StyledItem
                                            label={`${texts.model_before.value}:`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={stl1}
                                                onPreview={this.handlePreview}
                                                onChange={(p) =>
                                                    this.handleChangeFile(1, p)
                                                }
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={isAdministrator}
                                            >
                                                {stl1.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                    {/* LOWER JAW MODEL (BEFORE) */}
                                    <Col md={6} sm={24}>
                                        <StyledItem
                                            label={`${texts.lower_model_before.value}:`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={stl8}
                                                onPreview={this.handlePreview}
                                                onChange={(p) =>
                                                    this.handleChangeFile(8, p)
                                                }
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={isAdministrator}
                                            >
                                                {stl8.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={noBracesOrAligners}
                                    >
                                        <StyledItem
                                            label={`${texts.model_after.value}:`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={stl7}
                                                onPreview={this.handlePreview}
                                                onChange={(p) =>
                                                    this.handleChangeFile(7, p)
                                                }
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={isAdministrator}
                                            >
                                                {stl7.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={noBracesOrAligners}
                                    >
                                        <StyledItem
                                            label={`${texts.lower_model_after.value}:`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={stl9}
                                                onPreview={this.handlePreview}
                                                onChange={(p) =>
                                                    this.handleChangeFile(9, p)
                                                }
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={isAdministrator}
                                            >
                                                {stl9.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={noBracesOrAligners}
                                    >
                                        <StyledItem
                                            label={`${texts.maxillary_up_before.value}:`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={stl10}
                                                onPreview={this.handlePreview}
                                                onChange={(p) =>
                                                    this.handleChangeFile(10, p)
                                                }
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={isAdministrator}
                                            >
                                                {stl10.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={noBracesOrAligners}
                                    >
                                        <StyledItem
                                            label={`${texts.maxillary_down_before.value}:`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={stl12}
                                                onPreview={this.handlePreview}
                                                onChange={(p) =>
                                                    this.handleChangeFile(12, p)
                                                }
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={isAdministrator}
                                            >
                                                {stl12.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={noBracesOrAligners}
                                    >
                                        <StyledItem
                                            label={`${texts.maxillary_up_after.value}:`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={stl11}
                                                onPreview={this.handlePreview}
                                                onChange={(p) =>
                                                    this.handleChangeFile(11, p)
                                                }
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={isAdministrator}
                                            >
                                                {stl11.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={noBracesOrAligners}
                                    >
                                        <StyledItem
                                            label={`${texts.maxillary_down_after.value}:`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={stl13}
                                                onPreview={this.handlePreview}
                                                onChange={(p) =>
                                                    this.handleChangeFile(13, p)
                                                }
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={isAdministrator}
                                            >
                                                {stl13.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                </Row>
                                <Row hidden={modelMode === 'simple'}>
                                    <StyledItem label="Выберите zip архив:">
                                        <StyledUpload
                                            action={GATEWAY_API}
                                            listType="picture-card"
                                            fileList={zip}
                                            onChange={(p) =>
                                                this.handleChangeZip(p)
                                            }
                                            name="files"
                                            headers={headers}
                                            data={uploadParams}
                                            disabled={isAdministrator}
                                        >
                                            {zip.length >= 1
                                                ? null
                                                : uploadButton}
                                        </StyledUpload>
                                    </StyledItem>
                                </Row>
                            </>
                        )}
                        {/* MINIVINTS */}
                        {(statusMinivints !== 'NONE' || isAdministrator) &&
                            !isOtk && (
                                <>
                                    <StyledDivider orientation="left">
                                        {texts.minivints.value}
                                    </StyledDivider>
                                    <Row gutter={16}>
                                        <Col md={12} sm={24}>
                                            <StyledItem
                                                label={`${texts.status.value}:`}
                                            >
                                                <Select
                                                    size="small"
                                                    defaultValue={
                                                        statusMinivints
                                                    }
                                                    style={{ width: 190 }}
                                                    onChange={(value) =>
                                                        this.onStatusChange(
                                                            'minivints',
                                                            value
                                                        )
                                                    }
                                                >
                                                    <Option value="NONE">
                                                        {
                                                            texts[
                                                                'order.status.none'
                                                            ].value
                                                        }
                                                    </Option>
                                                    <Option value="NEW">
                                                        {
                                                            texts[
                                                                'order.status.new'
                                                            ].value
                                                        }
                                                    </Option>
                                                    <Option
                                                        disabled={
                                                            isAdministrator
                                                        }
                                                        value="PLANNED"
                                                    >
                                                        {
                                                            texts[
                                                                'order.status.planned'
                                                            ].value
                                                        }
                                                    </Option>
                                                    <Option value="ON_APPROVAL">
                                                        {
                                                            texts[
                                                                'order.status.on_approval'
                                                            ].value
                                                        }
                                                    </Option>
                                                    <Option value="CORRECTION">
                                                        {
                                                            texts[
                                                                'order.status.correction'
                                                            ].value
                                                        }
                                                    </Option>
                                                    <Option
                                                        disabled={
                                                            isAdministrator ||
                                                            isTechnitian
                                                        }
                                                        value="APPROVED"
                                                    >
                                                        {
                                                            texts[
                                                                'order.status.approved'
                                                            ].value
                                                        }
                                                    </Option>
                                                    <Option value="DECLINED">
                                                        {
                                                            texts[
                                                                'order.status.declined'
                                                            ].value
                                                        }
                                                    </Option>
                                                </Select>
                                            </StyledItem>
                                        </Col>
                                        <Col
                                            md={6}
                                            sm={24}
                                            hidden={
                                                statusMinivints === 'NONE' ||
                                                isAdministrator
                                            }
                                        >
                                            <StyledItem
                                                label={`${texts.minivints.value} (stl):`}
                                                hidden={isOtk}
                                            >
                                                <StyledUpload
                                                    action={GATEWAY_API}
                                                    listType="picture-card"
                                                    fileList={stl2}
                                                    onPreview={
                                                        this.handlePreview
                                                    }
                                                    onChange={(p) =>
                                                        this.handleChangeFile(
                                                            2,
                                                            p
                                                        )
                                                    }
                                                    name="files"
                                                    headers={headers}
                                                    data={uploadParams}
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                >
                                                    {stl2.length >= 1
                                                        ? null
                                                        : uploadButton}
                                                </StyledUpload>
                                            </StyledItem>
                                        </Col>
                                    </Row>
                                    <Row gutter={16} hidden={isAdministrator}>
                                        <Col md={24} sm={24}>
                                            <ResourceUpload
                                                texts={texts}
                                                title={minivintsLabel1}
                                                hidden={minivintsAdded < 1}
                                                image={minivintsImage1}
                                                handleChangeImage={(p) =>
                                                    this.handleChangePhoto(
                                                        'minivints',
                                                        1,
                                                        p
                                                    )
                                                }
                                                handleChangeImageTitle={(
                                                    value
                                                ) =>
                                                    this.handleChangeImageTitle(
                                                        'minivints',
                                                        'label_1',
                                                        value
                                                    )
                                                }
                                                handlePreview={
                                                    this.handlePreview
                                                }
                                                token={token}
                                                disabled={isAdministrator}
                                            />
                                        </Col>
                                        <Col md={24} sm={24}>
                                            <ResourceUpload
                                                texts={texts}
                                                title={minivintsLabel2}
                                                hidden={minivintsAdded < 2}
                                                image={minivintsImage2}
                                                handleChangeImage={(p) =>
                                                    this.handleChangePhoto(
                                                        'minivints',
                                                        2,
                                                        p
                                                    )
                                                }
                                                handleChangeImageTitle={(
                                                    value
                                                ) =>
                                                    this.handleChangeImageTitle(
                                                        'minivints',
                                                        'label_2',
                                                        value
                                                    )
                                                }
                                                handlePreview={
                                                    this.handlePreview
                                                }
                                                token={token}
                                                disabled={isAdministrator}
                                            />
                                        </Col>
                                        <Col md={24} sm={24}>
                                            <ResourceUpload
                                                texts={texts}
                                                title={minivintsLabel3}
                                                hidden={minivintsAdded < 3}
                                                image={minivintsImage3}
                                                handleChangeImage={(p) =>
                                                    this.handleChangePhoto(
                                                        'minivints',
                                                        3,
                                                        p
                                                    )
                                                }
                                                handleChangeImageTitle={(
                                                    value
                                                ) =>
                                                    this.handleChangeImageTitle(
                                                        'minivints',
                                                        'label_3',
                                                        value
                                                    )
                                                }
                                                handlePreview={
                                                    this.handlePreview
                                                }
                                                token={token}
                                                disabled={isAdministrator}
                                            />
                                        </Col>
                                        <Col md={24} sm={24}>
                                            <ResourceUpload
                                                texts={texts}
                                                title={minivintsLabel4}
                                                hidden={minivintsAdded < 4}
                                                image={minivintsImage4}
                                                handleChangeImage={(p) =>
                                                    this.handleChangePhoto(
                                                        'minivints',
                                                        4,
                                                        p
                                                    )
                                                }
                                                handleChangeImageTitle={(
                                                    value
                                                ) =>
                                                    this.handleChangeImageTitle(
                                                        'minivints',
                                                        'label_4',
                                                        value
                                                    )
                                                }
                                                handlePreview={
                                                    this.handlePreview
                                                }
                                                token={token}
                                                disabled={isAdministrator}
                                            />
                                        </Col>
                                        <Col md={24} sm={24}>
                                            <ResourceUpload
                                                texts={texts}
                                                title={minivintsLabel5}
                                                hidden={minivintsAdded < 5}
                                                image={minivintsImage5}
                                                handleChangeImage={(p) =>
                                                    this.handleChangePhoto(
                                                        'minivints',
                                                        5,
                                                        p
                                                    )
                                                }
                                                handleChangeImageTitle={(
                                                    value
                                                ) =>
                                                    this.handleChangeImageTitle(
                                                        'minivints',
                                                        'label_5',
                                                        value
                                                    )
                                                }
                                                handlePreview={
                                                    this.handlePreview
                                                }
                                                token={token}
                                                disabled={isAdministrator}
                                            />
                                        </Col>
                                        <Col md={24} sm={24}>
                                            <ResourceUpload
                                                texts={texts}
                                                title={minivintsLabel6}
                                                hidden={minivintsAdded < 6}
                                                image={minivintsImage6}
                                                handleChangeImage={(p) =>
                                                    this.handleChangePhoto(
                                                        'minivints',
                                                        6,
                                                        p
                                                    )
                                                }
                                                handleChangeImageTitle={(
                                                    value
                                                ) =>
                                                    this.handleChangeImageTitle(
                                                        'minivints',
                                                        'label_6',
                                                        value
                                                    )
                                                }
                                                handlePreview={
                                                    this.handlePreview
                                                }
                                                token={token}
                                                disabled={isAdministrator}
                                            />
                                        </Col>
                                        <Col md={24} sm={24}>
                                            <ResourceUpload
                                                texts={texts}
                                                title={minivintsLabel7}
                                                hidden={minivintsAdded < 7}
                                                image={minivintsImage7}
                                                handleChangeImage={(p) =>
                                                    this.handleChangePhoto(
                                                        'minivints',
                                                        7,
                                                        p
                                                    )
                                                }
                                                handleChangeImageTitle={(
                                                    value
                                                ) =>
                                                    this.handleChangeImageTitle(
                                                        'minivints',
                                                        'label_7',
                                                        value
                                                    )
                                                }
                                                handlePreview={
                                                    this.handlePreview
                                                }
                                                token={token}
                                                disabled={isAdministrator}
                                            />
                                        </Col>
                                        <Col md={24} sm={24}>
                                            <ResourceUpload
                                                texts={texts}
                                                title={minivintsLabel8}
                                                hidden={minivintsAdded < 8}
                                                image={minivintsImage8}
                                                handleChangeImage={(p) =>
                                                    this.handleChangePhoto(
                                                        'minivints',
                                                        8,
                                                        p
                                                    )
                                                }
                                                handleChangeImageTitle={(
                                                    value
                                                ) =>
                                                    this.handleChangeImageTitle(
                                                        'minivints',
                                                        'label_8',
                                                        value
                                                    )
                                                }
                                                handlePreview={
                                                    this.handlePreview
                                                }
                                                token={token}
                                                disabled={isAdministrator}
                                            />
                                        </Col>
                                        <Col md={24} sm={24}>
                                            <ResourceUpload
                                                texts={texts}
                                                title={minivintsLabel9}
                                                hidden={minivintsAdded < 9}
                                                image={minivintsImage9}
                                                handleChangeImage={(p) =>
                                                    this.handleChangePhoto(
                                                        'minivints',
                                                        9,
                                                        p
                                                    )
                                                }
                                                handleChangeImageTitle={(
                                                    value
                                                ) =>
                                                    this.handleChangeImageTitle(
                                                        'minivints',
                                                        'label_9',
                                                        value
                                                    )
                                                }
                                                handlePreview={
                                                    this.handlePreview
                                                }
                                                token={token}
                                                disabled={isAdministrator}
                                            />
                                        </Col>
                                        <Col md={24} sm={24}>
                                            <ResourceUpload
                                                texts={texts}
                                                title={minivintsLabel10}
                                                hidden={minivintsAdded < 10}
                                                image={minivintsImage10}
                                                handleChangeImage={(p) =>
                                                    this.handleChangePhoto(
                                                        'minivints',
                                                        10,
                                                        p
                                                    )
                                                }
                                                handleChangeImageTitle={(
                                                    value
                                                ) =>
                                                    this.handleChangeImageTitle(
                                                        'minivints',
                                                        'label_10',
                                                        value
                                                    )
                                                }
                                                handlePreview={
                                                    this.handlePreview
                                                }
                                                token={token}
                                                disabled={isAdministrator}
                                            />
                                        </Col>
                                    </Row>
                                    <Button
                                        type="dashed"
                                        icon="plus"
                                        hidden={isAdministrator}
                                        onClick={this.onAddMinivintsImageClick}
                                        disabled={isAdministrator}
                                    >
                                        {texts['image.add'].value}
                                    </Button>
                                </>
                            )}
                        {/* TEMPLATE */}
                        {(statusTemplate !== 'NONE' || isAdministrator) && (
                            <>
                                <StyledDivider orientation="left">
                                    {texts.template.value}
                                </StyledDivider>
                                <Row gutter={16}>
                                    <Col md={12} sm={24}>
                                        <StyledItem
                                            label={`${texts.status.value}`}
                                        >
                                            <Select
                                                size="small"
                                                defaultValue={statusTemplate}
                                                style={{ width: 190 }}
                                                onChange={(value) =>
                                                    this.onStatusChange(
                                                        'template',
                                                        value
                                                    )
                                                }
                                            >
                                                <Option
                                                    value="NONE"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.none'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="NEW"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.new'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                    value="DESIGNED"
                                                >
                                                    {
                                                        texts[
                                                            'order.status.designed'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="ON_APPROVAL"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.on_approval'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="CORRECTION"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.correction'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    disabled={
                                                        isAdministrator ||
                                                        isTechnitian ||
                                                        isOtk
                                                    }
                                                    value="APPROVED"
                                                >
                                                    {
                                                        texts[
                                                            'order.status.approved'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="PRINTING"
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.printing'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="PRINTED"
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.printed'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="OTK"
                                                    disabled={
                                                        isAdministrator ||
                                                        isTechnitian
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.otk'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="READYTOSEND"
                                                    disabled
                                                >
                                                    {
                                                        texts[
                                                            'order.status.readytosend2'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option value="READY" disabled>
                                                    {
                                                        texts[
                                                            'order.status.ready'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    disabled={
                                                        isTechnitian || isOtk
                                                    }
                                                    value="CLOSED"
                                                >
                                                    {
                                                        texts[
                                                            'order.status.closed'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="DECLINED"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.declined'
                                                        ].value
                                                    }
                                                </Option>
                                            </Select>
                                        </StyledItem>
                                    </Col>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={
                                            statusTemplate === 'NONE' ||
                                            isAdministrator ||
                                            isOtk
                                        }
                                    >
                                        <StyledItem
                                            label={`${texts.template.value} (stl):`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={stl4}
                                                onPreview={this.handlePreview}
                                                onChange={(p) =>
                                                    this.handleChangeFile(4, p)
                                                }
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={isAdministrator}
                                            >
                                                {stl4.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={
                                            statusTemplate === 'NONE' ||
                                            isAdministrator
                                        }
                                    >
                                        <StyledItem
                                            label={`${texts['template.video'].value}`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={video}
                                                // onPreview={this.handlePreview}
                                                onChange={(p) =>
                                                    this.handleChangeVideo(p)
                                                }
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={isAdministrator}
                                            >
                                                {video.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                </Row>
                                <Row gutter={16} hidden={isAdministrator}>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={templateLabel1}
                                            hidden={templateAdded < 1}
                                            image={templateImage1}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'template',
                                                    1,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'template',
                                                    'label_1',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={templateLabel2}
                                            hidden={templateAdded < 2}
                                            image={templateImage2}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'template',
                                                    2,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'template',
                                                    'label_2',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={templateLabel3}
                                            hidden={templateAdded < 3}
                                            image={templateImage3}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'template',
                                                    3,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'template',
                                                    'label_3',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={templateLabel4}
                                            hidden={templateAdded < 4}
                                            image={templateImage4}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'template',
                                                    4,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'template',
                                                    'label_4',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={templateLabel5}
                                            hidden={templateAdded < 5}
                                            image={templateImage5}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'template',
                                                    5,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'template',
                                                    'label_5',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={templateLabel6}
                                            hidden={templateAdded < 6}
                                            image={templateImage6}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'template',
                                                    6,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'template',
                                                    'label_6',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={templateLabel7}
                                            hidden={templateAdded < 7}
                                            image={templateImage7}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'template',
                                                    7,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'template',
                                                    'label_7',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={templateLabel8}
                                            hidden={templateAdded < 8}
                                            image={templateImage8}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'template',
                                                    8,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'template',
                                                    'label_8',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={templateLabel9}
                                            hidden={templateAdded < 9}
                                            image={templateImage9}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'template',
                                                    9,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'template',
                                                    'label_9',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={templateLabel10}
                                            hidden={templateAdded < 10}
                                            image={templateImage10}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'template',
                                                    10,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'template',
                                                    'label_10',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                </Row>
                                <Button
                                    type="dashed"
                                    icon="plus"
                                    onClick={this.onAddTemplateImageClick}
                                    hidden={isAdministrator}
                                    disabled={isAdministrator}
                                >
                                    {texts['image.add'].value}
                                </Button>
                            </>
                        )}

                        {/* APPLIANCE 3D */}
                        {(statusEquipment !== 'NONE' || isAdministrator) && (
                            <>
                                <StyledDivider orientation="left">
                                    {texts.equipment.value}
                                </StyledDivider>
                                <Row gutter={16}>
                                    <Col md={12} sm={24}>
                                        <StyledItem
                                            label={`${texts.status.value}`}
                                        >
                                            <Select
                                                size="small"
                                                defaultValue={statusEquipment}
                                                style={{ width: 190 }}
                                                onChange={(value) =>
                                                    this.onStatusChange(
                                                        'equipment',
                                                        value
                                                    )
                                                }
                                            >
                                                <Option
                                                    value="NONE"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.none'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="NEW"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.new'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                    value="DESIGNED"
                                                >
                                                    {
                                                        texts[
                                                            'order.status.designed'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="ON_APPROVAL"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.on_approval'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="CORRECTION"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.correction'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    disabled={
                                                        isAdministrator ||
                                                        isTechnitian ||
                                                        isOtk
                                                    }
                                                    value="APPROVED"
                                                >
                                                    {
                                                        texts[
                                                            'order.status.approved'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="PRINTING"
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.printing'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="PRINTED"
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.printed'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="MOLDED"
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.molded'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="OTK"
                                                    disabled={
                                                        isAdministrator ||
                                                        isTechnitian
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.otk'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="READYTOSEND"
                                                    disabled
                                                >
                                                    {
                                                        texts[
                                                            'order.status.readytosend2'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option value="READY" disabled>
                                                    {
                                                        texts[
                                                            'order.status.ready'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    disabled={
                                                        isTechnitian || isOtk
                                                    }
                                                    value="CLOSED"
                                                >
                                                    {
                                                        texts[
                                                            'order.status.closed'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="DECLINED"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.declined'
                                                        ].value
                                                    }
                                                </Option>
                                            </Select>
                                        </StyledItem>
                                    </Col>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={
                                            statusEquipment === 'NONE' ||
                                            isAdministrator ||
                                            isOtk
                                        }
                                    >
                                        <StyledItem
                                            label={`${texts.equipment.value} (stl)`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={stl3}
                                                onPreview={this.handlePreview}
                                                onChange={(p) =>
                                                    this.handleChangeFile(3, p)
                                                }
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={
                                                    isAdministrator || isOtk
                                                }
                                            >
                                                {stl3.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={
                                            statusEquipment === 'NONE' ||
                                            isAdministrator
                                        }
                                    >
                                        <Row>
                                            <StyledItem
                                                label={`${texts.equipment.value} (gif)`}
                                            >
                                                <StyledUpload
                                                    action={GATEWAY_API}
                                                    listType="picture-card"
                                                    fileList={gif}
                                                    onChange={(p) =>
                                                        this.handleChangeGif(p)
                                                    }
                                                    onPreview={
                                                        this.handlePreview
                                                    }
                                                    name="files"
                                                    headers={headers}
                                                    data={uploadParams}
                                                    disabled={isAdministrator}
                                                >
                                                    {gif.length >= 1
                                                        ? null
                                                        : uploadButton}
                                                </StyledUpload>
                                            </StyledItem>
                                            <StyledItem
                                                label={`${texts.tray.value} (stl)`}
                                                hidden={isOtk}
                                            >
                                                <StyledUpload
                                                    action={GATEWAY_API}
                                                    listType="picture-card"
                                                    fileList={stl5}
                                                    onPreview={
                                                        this.handlePreview
                                                    }
                                                    onChange={(p) =>
                                                        this.handleChangeFile(
                                                            5,
                                                            p
                                                        )
                                                    }
                                                    name="files"
                                                    headers={headers}
                                                    data={uploadParams}
                                                    disabled={isAdministrator}
                                                >
                                                    {stl5.length >= 1
                                                        ? null
                                                        : uploadButton}
                                                </StyledUpload>
                                            </StyledItem>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* <Row gutter={16} hidden={isAdministrator}>
                                    Загрузка GIF
                                </Row> */}
                                <Row gutter={16} hidden={isAdministrator}>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={toolLabel1}
                                            hidden={toolAdded < 1}
                                            image={toolImage1}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool',
                                                    1,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool',
                                                    'label_1',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={toolLabel2}
                                            hidden={toolAdded < 2}
                                            image={toolImage2}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool',
                                                    2,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool',
                                                    'label_2',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={toolLabel3}
                                            hidden={toolAdded < 3}
                                            image={toolImage3}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool',
                                                    3,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool',
                                                    'label_3',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={toolLabel4}
                                            hidden={toolAdded < 4}
                                            image={toolImage4}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool',
                                                    4,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool',
                                                    'label_4',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={toolLabel5}
                                            hidden={toolAdded < 5}
                                            image={toolImage5}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool',
                                                    5,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool',
                                                    'label_5',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={toolLabel6}
                                            hidden={toolAdded < 6}
                                            image={toolImage6}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool',
                                                    6,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool',
                                                    'label_6',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={toolLabel7}
                                            hidden={toolAdded < 7}
                                            image={toolImage7}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool',
                                                    7,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool',
                                                    'label_7',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={toolLabel8}
                                            hidden={toolAdded < 8}
                                            image={toolImage8}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool',
                                                    8,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool',
                                                    'label_8',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={toolLabel9}
                                            hidden={toolAdded < 9}
                                            image={toolImage9}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool',
                                                    9,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool',
                                                    'label_9',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={toolLabel10}
                                            hidden={toolAdded < 10}
                                            image={toolImage10}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool',
                                                    10,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool',
                                                    'label_10',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                </Row>
                                <Button
                                    type="dashed"
                                    icon="plus"
                                    onClick={this.onAddToolImageClick}
                                    hidden={isAdministrator}
                                    disabled={isAdministrator}
                                >
                                    {texts['image.add'].value}
                                </Button>
                            </>
                        )}

                        {/* SPLINT */}
                        {(statusSplint !== 'NONE' || isAdministrator) && (
                            <>
                                <StyledDivider orientation="left">
                                    {texts.splint.value}
                                </StyledDivider>
                                <Row gutter={16}>
                                    <Col md={12} sm={24}>
                                        <StyledItem
                                            label={`${texts.status.value}`}
                                        >
                                            <Select
                                                size="small"
                                                defaultValue={statusSplint}
                                                style={{ width: 190 }}
                                                onChange={(value) =>
                                                    this.onStatusChange(
                                                        'splint',
                                                        value
                                                    )
                                                }
                                            >
                                                <Option
                                                    value="NONE"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.none'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="NEW"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.new'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                    value="DESIGNED"
                                                >
                                                    {
                                                        texts[
                                                            'order.status.designed'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="ON_APPROVAL"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.on_approval'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="CORRECTION"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.correction'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    disabled={
                                                        isAdministrator ||
                                                        isTechnitian ||
                                                        isOtk
                                                    }
                                                    value="APPROVED"
                                                >
                                                    {
                                                        texts[
                                                            'order.status.approved'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="PRINTING"
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.printing'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="PRINTED"
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.printed'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="MOLDED"
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.molded'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="OTK"
                                                    disabled={
                                                        isAdministrator ||
                                                        isTechnitian
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.otk'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="READYTOSEND"
                                                    disabled
                                                >
                                                    {
                                                        texts[
                                                            'order.status.readytosend2'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option value="READY" disabled>
                                                    {
                                                        texts[
                                                            'order.status.ready'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    disabled={
                                                        isTechnitian || isOtk
                                                    }
                                                    value="CLOSED"
                                                >
                                                    {
                                                        texts[
                                                            'order.status.closed'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="DECLINED"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.declined'
                                                        ].value
                                                    }
                                                </Option>
                                            </Select>
                                        </StyledItem>
                                    </Col>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={
                                            statusSplint === 'NONE' ||
                                            isAdministrator ||
                                            isOtk
                                        }
                                    >
                                        <StyledItem
                                            label={`${texts.splint.value} (stl)`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={stl20}
                                                onPreview={this.handlePreview}
                                                onChange={(p) =>
                                                    this.handleChangeFile(20, p)
                                                }
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={
                                                    isAdministrator || isOtk
                                                }
                                            >
                                                {stl20.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={
                                            statusSplint === 'NONE' ||
                                            isAdministrator
                                        }
                                    >
                                        <StyledItem
                                            label={`${texts.splint.value} (gif)`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={gif_splint}
                                                onChange={(p) =>
                                                    this.handleChangeGifSplint(
                                                        p
                                                    )
                                                }
                                                onPreview={this.handlePreview}
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={isAdministrator}
                                            >
                                                {gif_splint.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                </Row>
                                <Row gutter={16} hidden={isAdministrator}>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={splintLabel1}
                                            hidden={splintAdded < 1}
                                            image={splintImage1}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'splint',
                                                    1,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'splint',
                                                    'label_1',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={splintLabel2}
                                            hidden={splintAdded < 2}
                                            image={splintImage2}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'splint',
                                                    2,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'splint',
                                                    'label_2',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={splintLabel3}
                                            hidden={splintAdded < 3}
                                            image={splintImage3}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'splint',
                                                    3,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'splint',
                                                    'label_3',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={splintLabel4}
                                            hidden={splintAdded < 4}
                                            image={splintImage4}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'splint',
                                                    4,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'splint',
                                                    'label_4',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={splintLabel5}
                                            hidden={splintAdded < 5}
                                            image={splintImage5}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'splint',
                                                    5,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'splint',
                                                    'label_5',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={splintLabel6}
                                            hidden={splintAdded < 6}
                                            image={splintImage6}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'splint',
                                                    6,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'splint',
                                                    'label_6',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={splintLabel7}
                                            hidden={splintAdded < 7}
                                            image={splintImage7}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'splint',
                                                    7,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'splint',
                                                    'label_7',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={splintLabel8}
                                            hidden={splintAdded < 8}
                                            image={splintImage8}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'splint',
                                                    8,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'splint',
                                                    'label_8',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={splintLabel9}
                                            hidden={splintAdded < 9}
                                            image={splintImage9}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'splint',
                                                    9,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'splint',
                                                    'label_9',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={splintLabel10}
                                            hidden={splintAdded < 10}
                                            image={splintImage10}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'splint',
                                                    10,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'splint',
                                                    'label_10',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                </Row>
                                <Button
                                    type="dashed"
                                    icon="plus"
                                    onClick={this.onAddSplintImageClick}
                                    hidden={isAdministrator}
                                    disabled={isAdministrator}
                                >
                                    {texts['image.add'].value}
                                </Button>
                            </>
                        )}

                        {/* Virtual Setup */}
                        {(showVirtualSetup || isAdministrator) && (
                            <>
                                <StyledDivider orientation="left">
                                    {texts.virtual_setup.value}
                                </StyledDivider>
                                <Row gutter={16}>
                                    <Col md={12} sm={24}>
                                        <StyledItem
                                            label={`${texts.status.value}`}
                                        >
                                            <Select
                                                size="small"
                                                defaultValue={statusVs}
                                                style={{ width: 190 }}
                                                onChange={(value) =>
                                                    this.onStatusChange(
                                                        vs,
                                                        value
                                                    )
                                                }
                                            >
                                                <Option
                                                    value="NONE"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.none'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="NEW"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.new'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                    value="DESIGNED"
                                                >
                                                    {
                                                        texts[
                                                            'order.status.designed'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="ON_APPROVAL"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.on_approval'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="CORRECTION"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.correction'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    disabled={
                                                        isAdministrator ||
                                                        isTechnitian ||
                                                        isOtk
                                                    }
                                                    value="APPROVED"
                                                >
                                                    {
                                                        texts[
                                                            'order.status.approved'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="PRINTING"
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.printing'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="PRINTED"
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.printed'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="MOLDED"
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.molded'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="OTK"
                                                    disabled={
                                                        isAdministrator ||
                                                        isTechnitian
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.otk'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="READYTOSEND"
                                                    disabled
                                                >
                                                    {
                                                        texts[
                                                            'order.status.readytosend2'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option value="READY" disabled>
                                                    {
                                                        texts[
                                                            'order.status.ready'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    disabled={
                                                        isTechnitian || isOtk
                                                    }
                                                    value="CLOSED"
                                                >
                                                    {
                                                        texts[
                                                            'order.status.closed'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="DECLINED"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.declined'
                                                        ].value
                                                    }
                                                </Option>
                                            </Select>
                                        </StyledItem>
                                    </Col>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={
                                            vs !== 'braces' ||
                                            isAdministrator ||
                                            isOtk
                                        }
                                    >
                                        <StyledItem
                                            label={`${texts.braces_up.value} (stl)`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={stl18}
                                                onPreview={this.handlePreview}
                                                onChange={(p) =>
                                                    this.handleChangeFile(18, p)
                                                }
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={
                                                    isAdministrator || isOtk
                                                }
                                            >
                                                {stl18.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={
                                            vs !== 'braces' ||
                                            isAdministrator ||
                                            isOtk
                                        }
                                    >
                                        <StyledItem
                                            label={`${texts.braces_low.value} (stl)`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={stl19}
                                                onPreview={this.handlePreview}
                                                onChange={(p) =>
                                                    this.handleChangeFile(19, p)
                                                }
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={
                                                    isAdministrator || isOtk
                                                }
                                            >
                                                {stl19.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                </Row>
                                <Row gutter={16} hidden={isAdministrator}>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={vsLabel1}
                                            hidden={vsAdded < 1}
                                            image={vsImage1}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'vs',
                                                    1,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'vs',
                                                    'label_1',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={vsLabel2}
                                            hidden={vsAdded < 2}
                                            image={vsImage2}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'vs',
                                                    2,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'vs',
                                                    'label_2',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={vsLabel3}
                                            hidden={vsAdded < 3}
                                            image={vsImage3}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'vs',
                                                    3,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'vs',
                                                    'label_3',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={vsLabel4}
                                            hidden={vsAdded < 4}
                                            image={vsImage4}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'vs',
                                                    4,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'vs',
                                                    'label_4',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={vsLabel5}
                                            hidden={vsAdded < 5}
                                            image={vsImage5}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'vs',
                                                    5,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'vs',
                                                    'label_5',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={vsLabel6}
                                            hidden={vsAdded < 6}
                                            image={vsImage6}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'vs',
                                                    6,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'vs',
                                                    'label_6',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={vsLabel7}
                                            hidden={vsAdded < 7}
                                            image={vsImage7}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'vs',
                                                    7,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'vs',
                                                    'label_7',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={vsLabel8}
                                            hidden={vsAdded < 8}
                                            image={vsImage8}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'vs',
                                                    8,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'vs',
                                                    'label_8',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={vsLabel9}
                                            hidden={vsAdded < 9}
                                            image={vsImage9}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'vs',
                                                    9,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'vs',
                                                    'label_9',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={vsLabel10}
                                            hidden={vsAdded < 10}
                                            image={vsImage10}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'vs',
                                                    10,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'vs',
                                                    'label_10',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={vsLabel11}
                                            hidden={vsAdded < 11}
                                            image={vsImage11}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'vs',
                                                    11,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'vs',
                                                    'label_11',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={vsLabel12}
                                            hidden={vsAdded < 12}
                                            image={vsImage12}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'vs',
                                                    12,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'vs',
                                                    'label_12',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={vsLabel13}
                                            hidden={vsAdded < 13}
                                            image={vsImage13}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'vs',
                                                    13,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'vs',
                                                    'label_13',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={vsLabel14}
                                            hidden={vsAdded < 14}
                                            image={vsImage14}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'vs',
                                                    14,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'vs',
                                                    'label_14',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={vsLabel15}
                                            hidden={vsAdded < 15}
                                            image={vsImage15}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'vs',
                                                    15,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'vs',
                                                    'label_15',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                </Row>
                                <Button
                                    type="dashed"
                                    icon="plus"
                                    onClick={this.onAddVsImageClick}
                                    hidden={isAdministrator}
                                    disabled={isAdministrator}
                                >
                                    {texts['image.add'].value}
                                </Button>
                            </>
                        )}

                        {/* APPLIANCE 2D */}
                        {(showEquipment2d || isAdministrator) && (
                            <>
                                <StyledDivider orientation="left">
                                    {texts.equipment2d.value}
                                </StyledDivider>
                                <Row gutter={16}>
                                    <Col md={12} sm={24}>
                                        <StyledItem
                                            label={`${texts.status.value}`}
                                        >
                                            <Select
                                                size="small"
                                                defaultValue={statusEquipment2d}
                                                style={{ width: 190 }}
                                                onChange={(value) =>
                                                    this.onStatusChange(
                                                        'equipment_2d',
                                                        value
                                                    )
                                                }
                                            >
                                                <Option
                                                    value="NONE"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.none'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="NEW"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.new'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                    value="DESIGNED"
                                                >
                                                    {
                                                        texts[
                                                            'order.status.designed'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="ON_APPROVAL"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.on_approval'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="CORRECTION"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.correction'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    disabled={
                                                        isAdministrator ||
                                                        isTechnitian ||
                                                        isOtk
                                                    }
                                                    value="APPROVED"
                                                >
                                                    {
                                                        texts[
                                                            'order.status.approved'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="PRINTING"
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.printing'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="PRINTED"
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.printed'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="MOLDED"
                                                    disabled={
                                                        isAdministrator || isOtk
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.molded'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="OTK"
                                                    disabled={
                                                        isAdministrator ||
                                                        isTechnitian
                                                    }
                                                >
                                                    {
                                                        texts[
                                                            'order.status.otk'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="READYTOSEND"
                                                    disabled
                                                >
                                                    {
                                                        texts[
                                                            'order.status.readytosend2'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option value="READY" disabled>
                                                    {
                                                        texts[
                                                            'order.status.ready'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    disabled={
                                                        isTechnitian || isOtk
                                                    }
                                                    value="CLOSED"
                                                >
                                                    {
                                                        texts[
                                                            'order.status.closed'
                                                        ].value
                                                    }
                                                </Option>
                                                <Option
                                                    value="DECLINED"
                                                    disabled={isOtk}
                                                >
                                                    {
                                                        texts[
                                                            'order.status.declined'
                                                        ].value
                                                    }
                                                </Option>
                                            </Select>
                                        </StyledItem>
                                    </Col>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={
                                            !showEquipment2d || isAdministrator
                                        }
                                    >
                                        <StyledItem
                                            label={`${texts.equipment2d.value} (gif)`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={gif2d}
                                                onChange={(p) =>
                                                    this.handleChangeGif2d(p)
                                                }
                                                onPreview={this.handlePreview}
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={isAdministrator}
                                            >
                                                {gif2d.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                </Row>
                                <Row gutter={16} hidden={isAdministrator}>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={tool2dLabel1}
                                            hidden={tool2dAdded < 1}
                                            image={tool2dImage1}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool_2d',
                                                    1,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool_2d',
                                                    'label_1',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={tool2dLabel2}
                                            hidden={tool2dAdded < 2}
                                            image={tool2dImage2}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool_2d',
                                                    2,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool_2d',
                                                    'label_2',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={tool2dLabel3}
                                            hidden={tool2dAdded < 3}
                                            image={tool2dImage3}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool_2d',
                                                    3,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool_2d',
                                                    'label_3',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={tool2dLabel4}
                                            hidden={tool2dAdded < 4}
                                            image={tool2dImage4}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool_2d',
                                                    4,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool_2d',
                                                    'label_4',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={tool2dLabel5}
                                            hidden={tool2dAdded < 5}
                                            image={tool2dImage5}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool_2d',
                                                    5,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool_2d',
                                                    'label_5',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={tool2dLabel6}
                                            hidden={tool2dAdded < 6}
                                            image={tool2dImage6}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool_2d',
                                                    6,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool_2d',
                                                    'label_6',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={tool2dLabel7}
                                            hidden={tool2dAdded < 7}
                                            image={tool2dImage7}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool_2d',
                                                    7,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool_2d',
                                                    'label_7',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={tool2dLabel8}
                                            hidden={tool2dAdded < 8}
                                            image={tool2dImage8}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool_2d',
                                                    8,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool_2d',
                                                    'label_8',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={tool2dLabel9}
                                            hidden={tool2dAdded < 9}
                                            image={tool2dImage9}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool_2d',
                                                    9,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool_2d',
                                                    'label_9',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                    <Col md={24} sm={24}>
                                        <ResourceUpload
                                            texts={texts}
                                            title={tool2dLabel10}
                                            hidden={tool2dAdded < 10}
                                            image={tool2dImage10}
                                            handleChangeImage={(p) =>
                                                this.handleChangePhoto(
                                                    'tool_2d',
                                                    10,
                                                    p
                                                )
                                            }
                                            handleChangeImageTitle={(value) =>
                                                this.handleChangeImageTitle(
                                                    'tool_2d',
                                                    'label_10',
                                                    value
                                                )
                                            }
                                            handlePreview={this.handlePreview}
                                            token={token}
                                            disabled={isAdministrator}
                                        />
                                    </Col>
                                </Row>
                                <Button
                                    type="dashed"
                                    icon="plus"
                                    onClick={this.onAddTool2dImageClick}
                                    hidden={isAdministrator}
                                    disabled={isAdministrator}
                                >
                                    {texts['image.add'].value}
                                </Button>
                            </>
                        )}

                        {/* ADDITION */}
                        {!isOnlyEquipment2d && !isAdministrator && !isOtk && (
                            <>
                                <StyledDivider orientation="left">
                                    {texts.addition.value}
                                </StyledDivider>
                                <Row gutter={16}>
                                    <Col
                                        md={6}
                                        sm={24}
                                        hidden={isAdministrator}
                                    >
                                        <StyledItem
                                            label={`${texts.addition.value} (stl)`}
                                        >
                                            <StyledUpload
                                                action={GATEWAY_API}
                                                listType="picture-card"
                                                fileList={stl6}
                                                onPreview={this.handlePreview}
                                                onChange={(p) =>
                                                    this.handleChangeFile(6, p)
                                                }
                                                name="files"
                                                headers={headers}
                                                data={uploadParams}
                                                disabled={
                                                    isAdministrator || isOtk
                                                }
                                            >
                                                {stl6.length >= 1
                                                    ? null
                                                    : uploadButton}
                                            </StyledUpload>
                                        </StyledItem>
                                    </Col>
                                </Row>
                            </>
                        )}

                        <Modal
                            visible={previewVisible}
                            footer={null}
                            onCancel={this.handleCancel}
                        >
                            <img
                                alt="example"
                                style={{ width: '100%' }}
                                src={previewImage}
                            />
                        </Modal>
                    </>
                );
            }

            return (
                <Modal
                    visible={isOpen}
                    title={title}
                    maskClosable={false}
                    okText={
                        isEmpty(orderId) ? texts.create.value : texts.save.value
                    }
                    cancelText={texts.cancel.value}
                    onCancel={this.handleFormCancel}
                    onOk={this.handleFormSave}
                    afterClose={this.afterCloseHandler}
                    width={800}
                    destroyOnClose
                >
                    {content}
                </Modal>
            );
        }
    }
);

function mapStateToProps(state) {
    return {
        isOpen: state.OrderReducer.newOrder.isOpen,
        profile: state.ProfileReducer.profile,
        isUploading: state.OrderReducer.newOrder.isUploading,
        token: state.AuthReducer.token,
        bill: state.OrderReducer.newOrder.bill,
        organisation: state.OrderReducer.newOrder.organisation,
        isPublic: state.OrderReducer.newOrder.isPublic,
        billDate: state.OrderReducer.newOrder.bill_date,
        deadline: state.OrderReducer.newOrder.deadline,
        orderId: state.OrderReducer.newOrder.orderId,
        version: state.OrderReducer.newOrder.version,
        image1: state.OrderReducer.newOrder.image_1,
        image2: state.OrderReducer.newOrder.image_2,
        image3: state.OrderReducer.newOrder.image_3,
        image4: state.OrderReducer.newOrder.image_4,
        image5: state.OrderReducer.newOrder.image_5,
        image6: state.OrderReducer.newOrder.image_6,
        image7: state.OrderReducer.newOrder.image_7,
        image8: state.OrderReducer.newOrder.image_8,
        image9: state.OrderReducer.newOrder.image_9,
        image10: state.OrderReducer.newOrder.image_10,
        // minivints
        minivintsImage1: state.OrderReducer.newOrder.minivints_image_1,
        minivintsImage2: state.OrderReducer.newOrder.minivints_image_2,
        minivintsImage3: state.OrderReducer.newOrder.minivints_image_3,
        minivintsImage4: state.OrderReducer.newOrder.minivints_image_4,
        minivintsImage5: state.OrderReducer.newOrder.minivints_image_5,
        minivintsImage6: state.OrderReducer.newOrder.minivints_image_6,
        minivintsImage7: state.OrderReducer.newOrder.minivints_image_7,
        minivintsImage8: state.OrderReducer.newOrder.minivints_image_8,
        minivintsImage9: state.OrderReducer.newOrder.minivints_image_9,
        minivintsImage10: state.OrderReducer.newOrder.minivints_image_10,
        minivintsLabel1: state.OrderReducer.newOrder.minivints_label_1,
        minivintsLabel2: state.OrderReducer.newOrder.minivints_label_2,
        minivintsLabel3: state.OrderReducer.newOrder.minivints_label_3,
        minivintsLabel4: state.OrderReducer.newOrder.minivints_label_4,
        minivintsLabel5: state.OrderReducer.newOrder.minivints_label_5,
        minivintsLabel6: state.OrderReducer.newOrder.minivints_label_6,
        minivintsLabel7: state.OrderReducer.newOrder.minivints_label_7,
        minivintsLabel8: state.OrderReducer.newOrder.minivints_label_8,
        minivintsLabel9: state.OrderReducer.newOrder.minivints_label_9,
        minivintsLabel10: state.OrderReducer.newOrder.minivints_label_10,
        // template
        templateImage1: state.OrderReducer.newOrder.template_image_1,
        templateImage2: state.OrderReducer.newOrder.template_image_2,
        templateImage3: state.OrderReducer.newOrder.template_image_3,
        templateImage4: state.OrderReducer.newOrder.template_image_4,
        templateImage5: state.OrderReducer.newOrder.template_image_5,
        templateImage6: state.OrderReducer.newOrder.template_image_6,
        templateImage7: state.OrderReducer.newOrder.template_image_7,
        templateImage8: state.OrderReducer.newOrder.template_image_8,
        templateImage9: state.OrderReducer.newOrder.template_image_9,
        templateImage10: state.OrderReducer.newOrder.template_image_10,
        templateLabel1: state.OrderReducer.newOrder.template_label_1,
        templateLabel2: state.OrderReducer.newOrder.template_label_2,
        templateLabel3: state.OrderReducer.newOrder.template_label_3,
        templateLabel4: state.OrderReducer.newOrder.template_label_4,
        templateLabel5: state.OrderReducer.newOrder.template_label_5,
        templateLabel6: state.OrderReducer.newOrder.template_label_6,
        templateLabel7: state.OrderReducer.newOrder.template_label_7,
        templateLabel8: state.OrderReducer.newOrder.template_label_8,
        templateLabel9: state.OrderReducer.newOrder.template_label_9,
        templateLabel10: state.OrderReducer.newOrder.template_label_10,
        // tool
        toolImage1: state.OrderReducer.newOrder.tool_image_1,
        toolImage2: state.OrderReducer.newOrder.tool_image_2,
        toolImage3: state.OrderReducer.newOrder.tool_image_3,
        toolImage4: state.OrderReducer.newOrder.tool_image_4,
        toolImage5: state.OrderReducer.newOrder.tool_image_5,
        toolImage6: state.OrderReducer.newOrder.tool_image_6,
        toolImage7: state.OrderReducer.newOrder.tool_image_7,
        toolImage8: state.OrderReducer.newOrder.tool_image_8,
        toolImage9: state.OrderReducer.newOrder.tool_image_9,
        toolImage10: state.OrderReducer.newOrder.tool_image_10,
        toolLabel1: state.OrderReducer.newOrder.tool_label_1,
        toolLabel2: state.OrderReducer.newOrder.tool_label_2,
        toolLabel3: state.OrderReducer.newOrder.tool_label_3,
        toolLabel4: state.OrderReducer.newOrder.tool_label_4,
        toolLabel5: state.OrderReducer.newOrder.tool_label_5,
        toolLabel6: state.OrderReducer.newOrder.tool_label_6,
        toolLabel7: state.OrderReducer.newOrder.tool_label_7,
        toolLabel8: state.OrderReducer.newOrder.tool_label_8,
        toolLabel9: state.OrderReducer.newOrder.tool_label_9,
        toolLabel10: state.OrderReducer.newOrder.tool_label_10,
        // tool2d
        tool2dImage1: state.OrderReducer.newOrder.tool_2d_image_1,
        tool2dImage2: state.OrderReducer.newOrder.tool_2d_image_2,
        tool2dImage3: state.OrderReducer.newOrder.tool_2d_image_3,
        tool2dImage4: state.OrderReducer.newOrder.tool_2d_image_4,
        tool2dImage5: state.OrderReducer.newOrder.tool_2d_image_5,
        tool2dImage6: state.OrderReducer.newOrder.tool_2d_image_6,
        tool2dImage7: state.OrderReducer.newOrder.tool_2d_image_7,
        tool2dImage8: state.OrderReducer.newOrder.tool_2d_image_8,
        tool2dImage9: state.OrderReducer.newOrder.tool_2d_image_9,
        tool2dImage10: state.OrderReducer.newOrder.tool_2d_image_10,
        tool2dLabel1: state.OrderReducer.newOrder.tool_2d_label_1,
        tool2dLabel2: state.OrderReducer.newOrder.tool_2d_label_2,
        tool2dLabel3: state.OrderReducer.newOrder.tool_2d_label_3,
        tool2dLabel4: state.OrderReducer.newOrder.tool_2d_label_4,
        tool2dLabel5: state.OrderReducer.newOrder.tool_2d_label_5,
        tool2dLabel6: state.OrderReducer.newOrder.tool_2d_label_6,
        tool2dLabel7: state.OrderReducer.newOrder.tool_2d_label_7,
        tool2dLabel8: state.OrderReducer.newOrder.tool_2d_label_8,
        tool2dLabel9: state.OrderReducer.newOrder.tool_2d_label_9,
        tool2dLabel10: state.OrderReducer.newOrder.tool_2d_label_10,
        // virtual setup
        vsImage1: state.OrderReducer.newOrder.vs_image_1,
        vsImage2: state.OrderReducer.newOrder.vs_image_2,
        vsImage3: state.OrderReducer.newOrder.vs_image_3,
        vsImage4: state.OrderReducer.newOrder.vs_image_4,
        vsImage5: state.OrderReducer.newOrder.vs_image_5,
        vsImage6: state.OrderReducer.newOrder.vs_image_6,
        vsImage7: state.OrderReducer.newOrder.vs_image_7,
        vsImage8: state.OrderReducer.newOrder.vs_image_8,
        vsImage9: state.OrderReducer.newOrder.vs_image_9,
        vsImage10: state.OrderReducer.newOrder.vs_image_10,
        vsImage11: state.OrderReducer.newOrder.vs_image_11,
        vsImage12: state.OrderReducer.newOrder.vs_image_12,
        vsImage13: state.OrderReducer.newOrder.vs_image_13,
        vsImage14: state.OrderReducer.newOrder.vs_image_14,
        vsImage15: state.OrderReducer.newOrder.vs_image_15,
        vsLabel1: state.OrderReducer.newOrder.vs_label_1,
        vsLabel2: state.OrderReducer.newOrder.vs_label_2,
        vsLabel3: state.OrderReducer.newOrder.vs_label_3,
        vsLabel4: state.OrderReducer.newOrder.vs_label_4,
        vsLabel5: state.OrderReducer.newOrder.vs_label_5,
        vsLabel6: state.OrderReducer.newOrder.vs_label_6,
        vsLabel7: state.OrderReducer.newOrder.vs_label_7,
        vsLabel8: state.OrderReducer.newOrder.vs_label_8,
        vsLabel9: state.OrderReducer.newOrder.vs_label_9,
        vsLabel10: state.OrderReducer.newOrder.vs_label_10,
        vsLabel11: state.OrderReducer.newOrder.vs_label_11,
        vsLabel12: state.OrderReducer.newOrder.vs_label_12,
        vsLabel13: state.OrderReducer.newOrder.vs_label_13,
        vsLabel14: state.OrderReducer.newOrder.vs_label_14,
        vsLabel15: state.OrderReducer.newOrder.vs_label_15,
        // splint
        splintImage1: state.OrderReducer.newOrder.splint_image_1,
        splintImage2: state.OrderReducer.newOrder.splint_image_2,
        splintImage3: state.OrderReducer.newOrder.splint_image_3,
        splintImage4: state.OrderReducer.newOrder.splint_image_4,
        splintImage5: state.OrderReducer.newOrder.splint_image_5,
        splintImage6: state.OrderReducer.newOrder.splint_image_6,
        splintImage7: state.OrderReducer.newOrder.splint_image_7,
        splintImage8: state.OrderReducer.newOrder.splint_image_8,
        splintImage9: state.OrderReducer.newOrder.splint_image_9,
        splintImage10: state.OrderReducer.newOrder.splint_image_10,
        splintLabel1: state.OrderReducer.newOrder.splint_label_1,
        splintLabel2: state.OrderReducer.newOrder.splint_label_2,
        splintLabel3: state.OrderReducer.newOrder.splint_label_3,
        splintLabel4: state.OrderReducer.newOrder.splint_label_4,
        splintLabel5: state.OrderReducer.newOrder.splint_label_5,
        splintLabel6: state.OrderReducer.newOrder.splint_label_6,
        splintLabel7: state.OrderReducer.newOrder.splint_label_7,
        splintLabel8: state.OrderReducer.newOrder.splint_label_8,
        splintLabel9: state.OrderReducer.newOrder.splint_label_9,
        splintLabel10: state.OrderReducer.newOrder.splint_label_10,
        // labels
        label1: state.OrderReducer.newOrder.label_1,
        label2: state.OrderReducer.newOrder.label_2,
        label3: state.OrderReducer.newOrder.label_3,
        label4: state.OrderReducer.newOrder.label_4,
        label5: state.OrderReducer.newOrder.label_5,
        stl1: state.OrderReducer.newOrder.stl_1,
        stl2: state.OrderReducer.newOrder.stl_2,
        stl3: state.OrderReducer.newOrder.stl_3,
        stl4: state.OrderReducer.newOrder.stl_4,
        stl5: state.OrderReducer.newOrder.stl_5,
        stl6: state.OrderReducer.newOrder.stl_6,
        stl7: state.OrderReducer.newOrder.stl_7,
        stl8: state.OrderReducer.newOrder.stl_8,
        stl9: state.OrderReducer.newOrder.stl_9,
        stl10: state.OrderReducer.newOrder.stl_10,
        stl11: state.OrderReducer.newOrder.stl_11,
        stl12: state.OrderReducer.newOrder.stl_12,
        stl13: state.OrderReducer.newOrder.stl_13,
        stl14: state.OrderReducer.newOrder.stl_14,
        stl15: state.OrderReducer.newOrder.stl_15,
        stl16: state.OrderReducer.newOrder.stl_16,
        stl17: state.OrderReducer.newOrder.stl_17,
        stl18: state.OrderReducer.newOrder.stl_18,
        stl19: state.OrderReducer.newOrder.stl_19,
        stl20: state.OrderReducer.newOrder.stl_20,
        // template video
        video: state.OrderReducer.newOrder.video,
        gif: state.OrderReducer.newOrder.gif,
        gif2d: state.OrderReducer.newOrder.gif2d,
        workorder: state.OrderReducer.newOrder.workorder,
        gif_splint: state.OrderReducer.newOrder.gif_splint,
        zip: state.OrderReducer.newOrder.zip,
        modelMode: state.OrderReducer.newOrder.modelMode,
        doctor: state.OrderReducer.newOrder.doctor,
        technician: state.OrderReducer.newOrder.technician,
        manager: state.OrderReducer.newOrder.manager,
        patient: state.OrderReducer.newOrder.patient,
        doctors: state.OrderReducer.doctors,
        technicians: state.OrderReducer.technicians,
        doctorsLoading: state.OrderReducer.doctorsLoading,
        techniciansLoading: state.OrderReducer.techniciansLoading,
        formIsLoading: state.OrderReducer.formIsLoading,
        clinic: state.OrderReducer.newOrder.clinic,
        statusMinivints: state.OrderReducer.newOrder.status_minivints,
        statusTemplate: state.OrderReducer.newOrder.status_template,
        statusEquipment: state.OrderReducer.newOrder.status_equipment,
        statusEquipment2d: state.OrderReducer.newOrder.status_equipment_2d,
        statusBraces: state.OrderReducer.newOrder.status_braces,
        statusAligners: state.OrderReducer.newOrder.status_aligners,
        statusTray: state.OrderReducer.newOrder.status_tray,
        statusSplint: state.OrderReducer.newOrder.status_splint,
        status: state.OrderReducer.newOrder.status,
        added: state.OrderReducer.newOrder.added,
        minivintsAdded: state.OrderReducer.newOrder.minivintsAdded,
        templateAdded: state.OrderReducer.newOrder.templateAdded,
        toolAdded: state.OrderReducer.newOrder.toolAdded,
        tool2dAdded: state.OrderReducer.newOrder.tool2dAdded,
        vsAdded: state.OrderReducer.newOrder.vsAdded,
        splintAdded: state.OrderReducer.newOrder.splintAdded,
        versionIncrement: state.OrderReducer.versionIncrement,
        texts: state.CommonReducer.texts,
        currentOrder: state.OrderReducer.selectedOrder
    };
}

export default connect(mapStateToProps)(NewOrderModalForm);
