import React, { Component } from 'react';
import { Badge, Layout, Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { NotificationCounter } from '../Notification/Counter';
import logo from '../../static/logo.svg';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Logo = styled.div`
    height: 63px;
    background-image: url(${logo});
    background-repeat: no-repeat;
    background-size: auto 48px;
    background-position: 24px 8px;
    box-shadow: 1px 1px 0 0 #e8e8e8;
`;

const LogoContainer = styled.div`
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`;

const SubMenuTitle = styled.span`
    font-size: 15px;
    font-weight: 400;
`;

const NavText = styled.span`
    font-weight: 400;
`;

const SiderStyled = styled(Sider)`
    /* position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh; */

    box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);

    .ant-layout-sider-zero-width-trigger {
        top: 16px;
        right: -20px;
        width: 20px;
        height: 32px;
        line-height: 32px;
        font-size: 16px;
        background: #3c9fd9;
    }

    .ant-menu-inline .ant-menu-item:not(:last-child) {
        margin-bottom: 0;
    }

    .ant-menu-inline {
        border: none;
    }

    .ant-menu-item {
        margin-top: 0;
        margin-bottom: 0;
        /* color: rgba(255, 255, 255, 0.65); */
        padding-left: 24px !important;

        > a {
            display: inline-block;
        }

        :hover,
        :focus,
        :active {
            background-color: rgba(255, 255, 255, 0.08) !important;
        }
    }
`;

const NewBadge = styled(Badge)`
    .ant-badge-count {
        background-color: #3cbb9e;
    }
    padding-left: 8px;
    /* .ant-badge-status-dot {
        top: 0;
    }

    .ant-badge-status-processing {
        background-color: #e39029;

        &::after {
            border: 1px solid #e39029;
        }
    } */
`;

class AppSider extends Component {
    render() {
        const { openKeys, selectedKeys, texts, unread, scope } = this.props;
        let localOpenKeys = openKeys;
        console.log(selectedKeys);
        if (selectedKeys[0] === '/profile') {
            localOpenKeys = ['settings'];
        } else if (selectedKeys[0] === '/manager/campaigns') {
            localOpenKeys = ['settings'];
        }

        return (
            <SiderStyled
                breakpoint="lg"
                collapsedWidth={0}
                onBreakpoint={(broken) => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
            >
                <LogoContainer>
                    <Logo />
                </LogoContainer>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={selectedKeys}
                    defaultOpenKeys={localOpenKeys}
                >
                    <Menu.Item key={`/${scope}/clients`}>
                        <Icon type="team" />
                        <Link to={`/${scope}/clients`}>
                            <NavText>{texts['menu.clients'].value}</NavText>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/notificator">
                        <Icon type="bell" />
                        <Link to="/notificator">
                            <NavText>
                                {texts['menu.notifications'].value}
                                <NotificationCounter />
                            </NavText>
                        </Link>
                    </Menu.Item>
                    {/* <Menu.Item key={`/${scope}/messages`}>
                        <Icon type="mail" />
                        <Link to={`/${scope}/messages`}>
                            <NavText>
                                {texts['menu.messages'].value}
                                <NewBadge overflowCount={9} count={unread} />
                            </NavText>
                        </Link>
                    </Menu.Item> */}
                    <Menu.Item key={`/${scope}/orders`}>
                        <Icon type="idcard" />
                        <Link to={`/${scope}/orders`}>
                            <NavText>{texts['menu.orders'].value}</NavText>
                        </Link>
                    </Menu.Item>
                    <SubMenu
                        key="settings"
                        title={
                            <SubMenuTitle>
                                {texts['menu.settings'].value}
                            </SubMenuTitle>
                        }
                    >
                        <Menu.Item key={`/${scope}/campaigns`}>
                            <Icon type="thunderbolt" />
                            <Link to={`/${scope}/campaigns`}>
                                <NavText>
                                    {texts['menu.campaigns'].value}
                                </NavText>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="/profile">
                            <Icon type="user" />
                            <Link to="/profile">
                                <NavText>{texts['menu.profile'].value}</NavText>
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </SiderStyled>
        );
    }
}

export default AppSider;
