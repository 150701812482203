import React, { Component } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { IAfterGuiAttachedParams } from 'ag-grid-community';
import { GridFilterParams } from './grid-filter-params';
import Search from '../Search/Search';

type FilterState = {
    value: string;
    hideFilter: any;
};

const ColumnsSettingsPopoverContent = styled.div`
    background: #fff;
    padding: 12px 16px;
    width: 200px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    > :not(:last-child) {
        margin-right: 8px;
    }
`;

export class ColumnTextFilterClient extends Component<
    GridFilterParams,
    FilterState
> {
    constructor(props: GridFilterParams) {
        super(props);
        this.state = {
            value: '',
            hideFilter: null
        };
    }

    getModel() {
        const { value } = this.state;
        return value ? { value } : null;
    }

    setModel(value: string) {
        this.setState({ value });
    }

    isFilterActive() {
        const { value } = this.state;
        return value !== '' && value;
    }

    doesFilterPass(params: any) {
        return true;
    }

    afterGuiAttached(params: IAfterGuiAttachedParams) {
        this.setState({
            hideFilter: params.hidePopup
        });
    }

    render() {
        // TODO: localization (there is a problem to get intlr context) UMBRUI-2866
        // const { formatMessage } = useIntl();
        const { filterChangedCallback, texts } = this.props;
        const { value, hideFilter } = this.state;

        return (
            <ColumnsSettingsPopoverContent>
                <Search
                    onSearch={(newValue: string, submit: boolean = false) => {
                        if (submit) {
                            this.setState(
                                {
                                    value: newValue
                                },
                                () => {
                                    filterChangedCallback();
                                    hideFilter();
                                }
                            );
                        } else {
                            this.setState({
                                value: newValue
                            });
                        }
                    }}
                    value={value}
                />
                <ButtonsContainer>
                    <Button
                        onClick={() => {
                            this.setState(
                                {
                                    value: ''
                                },
                                () => {
                                    filterChangedCallback();
                                    hideFilter();
                                }
                            );
                        }}
                    >
                        {texts.reset.value}
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            filterChangedCallback();
                            hideFilter();
                        }}
                    >
                        {texts.apply.value}
                    </Button>
                </ButtonsContainer>
            </ColumnsSettingsPopoverContent>
        );
    }
}
