import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootReducer, { primaryInitialState } from './reducer';
import rootSaga from './saga';

const bindMiddleware = (middleware) => {
    if (process.env.NODE_ENV !== 'production') {
        const composeEnhancers = composeWithDevTools({
            trace: true,
            traceLimit: 25,
            realtime: true
        });
        return composeEnhancers(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

function configureStore(initialState = primaryInitialState) {
    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(
        rootReducer,
        initialState,
        bindMiddleware([sagaMiddleware])
    );

    store.sagaTask = sagaMiddleware.run(rootSaga);
    return store;
}

export default configureStore;
