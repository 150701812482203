import { isEmpty as isEmptyObj } from 'lodash';

export const isEmpty = (value: any) => {
    let result = true;
    if (typeof value === 'number') {
        result = false;
    } else {
        result = isEmptyObj(value);
    }
    return result;
};
