import React, { Component } from 'react';
import { Button, Icon, Menu, Dropdown } from 'antd';
import styled from 'styled-components';

const UserName = styled.span`
    padding-right: 4px;
`;

class NewOrderDropdown extends Component {
    render() {
        const { title } = this.props;

        const body = (
            <Button type="primary">
                <UserName>{title}</UserName>
                <Icon type="down" />
            </Button>
        );
        const menu = (
            <Menu>
                <Menu.Item>
                    <a
                        target="__blank"
                        href="https://pumpalab.ru/anketa-doctors/auth.php?utm_source=button&utm_medium=direct&utm_campaign=lk_lab"
                    >
                        <span>Элайнеры FitFree</span>
                    </a>
                </Menu.Item>
                {/* <Menu.Divider /> */}
            </Menu>
        );
        return (
            // <Dropdown
            //     placement="bottomRight"
            //     // overlay={notificationBox}
            //     // overlayClassName={styles.popover}
            //     trigger={['click']}
            //     // visible={true}
            //     onVisibleChange={this.handleVisibleChange}
            //     body={body}
            //     overlay={menu}
            //     // {...popoverProps}
            //     // ref={node => (this.popover = ReactDOM.findDOMNode(node))} // eslint-disable-line
            // />

            <Dropdown
                overlay={menu}
                placement="bottomRight"
                trigger={['click']}
            >
                {body}
            </Dropdown>
        );
    }
}

export default NewOrderDropdown;
