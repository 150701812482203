import React from 'react';
import { NavLink } from 'react-router-dom';
import { ICellRendererParams } from 'ag-grid-community';

interface CustomCellRendererParams extends ICellRendererParams {
    role: string;
}

export const OrderLinkRenderer = (props: CustomCellRendererParams) => {
    const { data: row, value, role } = props;
    if (!row) {
        return null;
    }
    // order-details?id=5ed9fd5b66cb6378fb2bf45f&revision=latest
    return (
        <NavLink to={`/${role}/order-details?id=${row.id}&revision=latest`}>
            {value}
        </NavLink>
    );
};
