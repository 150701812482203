import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { EDITOR_HOST } from '../../utils/properties';

interface CustomCellRendererParams extends ICellRendererParams {
    role: string;
    token: string;
}

export const CampaignLinkRenderer = (props: CustomCellRendererParams) => {
    const { data: row, value, token } = props;
    if (!row) {
        return null;
    }
    return (
        <a
            href={`${EDITOR_HOST}?id=${row.id}&token=${token}`}
            target="_blank"
            rel="noreferrer"
        >
            {value}
        </a>
    );
};
