import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Select, Layout } from 'antd';
import styled from 'styled-components';
import { OrderTable } from '../../components/OrderTable/OrderTable';
import CreateOrderModalForm from '../../components/NewOrder/CreateOrderModalForm';
import NewOrderModalForm from '../../components/NewOrder/NewOrderModalForm';
import {
    onAllOrdersLoad,
    applySearchToAllOrders,
    loadTechs,
    onSelectTech,
    onSelectManager
} from '../../redux/actions';
import { getUserName } from '../../utils/user';
import { EditDatesModal } from '../../components/NewOrder/EditDatesModal';
import { ColumnsPicker } from '../../components/OrderTable/ColumnsPicker';

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;

const ContentStyled = styled(Content)`
    /* padding: 16px; */
    /* background: #fff; */
    height: 100%;
`;

const Toolbar = styled.div`
    padding: 0;
    display: flex;
    justify-content: space-between;
    height: 36px;
`;

const TableContainer = styled.div`
    height: 100%;
`;

export const OrdersPage = () => {
    const dispatch = useDispatch();
    const [editDatesOrderId, setEditDatesOrderId] = useState<string | null>(
        null
    );
    const [updated, setUpdated] = useState<number>(0);
    const techs = useSelector((state: any) => state.OrdersReducer.techs);
    const managers = useSelector((state: any) => state.OrdersReducer.managers);
    const selectedTech = useSelector(
        (state: any) => state.OrdersReducer.selectedTech
    );
    const selectedManager = useSelector(
        (state: any) => state.OrdersReducer.selectedManager
    );
    const texts = useSelector((state: any) => state.CommonReducer.texts);

    useEffect(() => {
        // if (orders.length === 0) {
        // dispatch(applySearchToAllOrders(''));
        dispatch(onAllOrdersLoad());
        // }
        if (techs.length === 0) {
            dispatch(loadTechs());
        }
    }, []);

    const onSearchChange = (search: any) => {
        dispatch(applySearchToAllOrders(search));
    };

    const onTechChange = (techId: any) => {
        dispatch(onSelectTech(techId));
    };
    const onManagerChange = (managerId: any) => {
        dispatch(onSelectManager(managerId));
    };

    const onEditDateClick = (recordId: string) => {
        setEditDatesOrderId(recordId);
    };

    return (
        <TableContainer>
            <ContentStyled>
                {/* <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Заказы</Breadcrumb.Item>
                </Breadcrumb> */}
                <Toolbar>
                    <Search
                        placeholder={texts['orders.search'].value}
                        onSearch={(value) => onSearchChange(value)}
                        style={{ width: 516 }}
                        enterButton
                        allowClear
                    />
                    <div>
                        <Select
                            showSearch
                            style={{ width: 200, marginRight: 4 }}
                            placeholder="Выберите исполнителя"
                            optionFilterProp="children"
                            onChange={onTechChange}
                            value={selectedTech}
                        >
                            <Option value="all">Все исполнители</Option>
                            {techs.map((t: any) => (
                                <Option key={t.id}>{getUserName(t)}</Option>
                            ))}
                            <Option value="none">Не указан</Option>
                        </Select>
                        <Select
                            showSearch
                            style={{ width: 200, marginRight: 4 }}
                            placeholder="Выберите администратора"
                            optionFilterProp="children"
                            onChange={onManagerChange}
                            value={selectedManager}
                        >
                            <Option value="all">Все администраторы</Option>
                            {managers.map((t: any) => (
                                <Option key={t.id}>{getUserName(t)}</Option>
                            ))}
                            <Option value="none">Не указан</Option>
                        </Select>
                        <ColumnsPicker />
                    </div>
                </Toolbar>
                <CreateOrderModalForm />
                <NewOrderModalForm />
                <EditDatesModal
                    visible={editDatesOrderId !== null}
                    orderId={editDatesOrderId}
                    handleCancel={() => setEditDatesOrderId(null)}
                    handleOk={() => {
                        setEditDatesOrderId(null);
                        setUpdated(updated + 1);
                    }}
                />
                <OrderTable
                    updated={updated}
                    onEditDateClick={onEditDateClick}
                />
            </ContentStyled>
        </TableContainer>
    );
};
