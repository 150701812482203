import React, { useState } from 'react';
import { Modal } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { GATEWAY_HOST } from '../../utils/properties';

interface ICampaignPreviewModalProps {
    title: string;
    visible: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    campaignId?: number | null;
}

export const CampaignPreviewModal = (props: ICampaignPreviewModalProps) => {
    const { campaignId, title, visible, handleCancel } = props;
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const token = useSelector((state: any) => state.AuthReducer.token);

    const { data } = useQuery(
        ['campaigns-details', campaignId],
        () => {
            const url = `${GATEWAY_HOST}/campaigns/${campaignId}`;
            return fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }).then((res) => res.json());
        },
        {
            enabled: !!campaignId,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            retry: false
        }
    );

    if (!data) return null;

    return (
        <Modal
            // title={title}
            visible={visible}
            cancelText={texts.close.value}
            okButtonProps={{ hidden: true }}
            onCancel={handleCancel}
            width={1200}
            closable={false}
        >
            <style>{data.css}</style>
            <div dangerouslySetInnerHTML={{ __html: data.html }} />
        </Modal>
    );
};

// Modal.info({
//     // title: `${ADV_TITLE}`,
//     okText: 'Закрыть',
//     okType: 'default',
//     width: 1200,
//     icon: null,
//     content: <AdvBraces token={token} profile={profile} />,
//     onOk: () => {
//         // window.location.href = ;
//         fetch(`${GATEWAY_HOST}/prices`, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'application/json'
//             },
//             method: 'POST',
//             body: JSON.stringify({
//                 user: profileId,
//                 read: true,
//                 adv: ADV_ID
//             })
//         });
//         setPriceData();
//     }
// });
