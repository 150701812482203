import React, { useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    InputNumber,
    List,
    Radio,
    Select
} from 'antd';
import { keys } from 'lodash';
import { PRINTER_HOST } from '../../utils/properties';
import { AlignersInfo } from '../Aligners/AlignersInfo';
import moment from 'moment';

const { Option } = Select;
interface ILabelsPrinterItemAlignersProps {
    title: string;
    doctor: string;
    patient: string;
    printTitle: string;
    subtitle?: boolean;
    slices: any;
    texts: any;
    order: any;
}

const getOptions = (numberOfSteps: number) => {
    const result: any[] = [];
    for (let i = 0; i < numberOfSteps; i += 1) {
        result.push(
            <Option key={`step${i + 1}`} value={`${i + 1}`}>
                {i + 1}
            </Option>
        );
    }
    return result;
};

const getKits = (numberOfKits: number) => {
    const data: any[] = [];
    for (let i = 0; i < numberOfKits; i += 1) {
        data.push({ index: i + 1 });
    }
    return (
        <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
                <List.Item>
                    <List.Item.Meta
                        title={`Набор ${item.index}`}
                        description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                    />
                </List.Item>
            )}
        />
    );
};

const getTemplates = (
    numberOfTemplates: number | undefined,
    onChange: any,
    numberOfSteps: number,
    templateTitle: string,
    beforeTitle: string
) => {
    const result: any[] = [];
    if (numberOfTemplates) {
        for (let i = 1; i <= numberOfTemplates; i += 1) {
            result.push(
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        paddingLeft: 96,
                        paddingTop: 4
                    }}
                    key={i}
                >
                    <div>{`${templateTitle} №${i} ${beforeTitle}: `}</div>
                    <Select
                        defaultValue="1"
                        style={{ width: 100 }}
                        onChange={(value: any) => onChange(i, value)}
                    >
                        {getOptions(numberOfSteps)}
                    </Select>
                </div>
            );
        }
    }
    return result;
};

const getJawConfig = (adata: any) => {
    let result = '';
    if (adata) {
        const templates = keys(adata).sort();
        const values = [];
        for (let i = 0; i < templates.length; i += 1) {
            values.push(`${templates[i]}-${adata[templates[i]]}`);
        }
        result = values.join(',');
    }
    return result;
};

const getBox = (valik: boolean, hook: boolean, box: boolean) => {
    const result = [];
    if (valik) {
        result.push('1');
    }
    if (hook) {
        result.push('2');
    }
    if (box) {
        result.push('3');
    }
    return result.join(',');
};

export const LabelsPrinterItemAligners = (
    props: ILabelsPrinterItemAlignersProps
) => {
    const {
        title,
        doctor,
        patient,
        printTitle,
        subtitle,
        slices,
        texts,
        order
    } = props;
    const [date, setDate] = useState<any>(moment());
    const [useFormula, setUseFormula] = useState(true);
    const [valik, setValik] = useState(true);
    const [hook, setHook] = useState(true);
    const [box, setBox] = useState(true);
    const [numberOfTemplates, setNumberOfTemplates] = useState<
        number | undefined
    >(
        (order.adata ? keys(order.adata).length : 0) +
            (order.adatalower ? keys(order.adatalower).length : 0)
    );
    const [numberOfSteps, setNumberOfSteps] = useState<number>(
        keys(slices).length - 1
    );
    const [numberForKit, setNumberForKit] = useState<number | undefined>(
        keys(slices).length - 1
    );
    const [itemsPerKit, setItemsPerKit] = useState<number | undefined>(10);
    const [calculatedNumberOfKits, setCalculatedNumberOfKits] = useState<
        number | undefined
    >(0);
    const [numberOfKits, setNumberOfKits] = useState<number | undefined>(0);

    useEffect(() => {
        setNumberForKit((numberOfTemplates || 0) + numberOfSteps);
    }, [numberOfTemplates, numberOfSteps]);

    useEffect(() => {
        setCalculatedNumberOfKits(
            Math.ceil((numberForKit || 0) / (itemsPerKit || 1))
        );
    }, [numberForKit, itemsPerKit]);

    return (
        <>
            <div style={{ fontWeight: 600, marginBottom: 8 }}>{title}</div>
            <div style={{ fontWeight: 400, marginBottom: 4 }}>
                {`${texts['order.labels.aligners.numberofsteps'].value}: `}
                <span style={{ fontWeight: 600 }}>{numberOfSteps}</span>
            </div>
            <AlignersInfo order={order} notitle />
            <div
                style={{
                    paddingTop: 12,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '9px'
                }}
            >
                <div>
                    <span style={{ fontWeight: 600 }}>
                        {`${texts['order.labels.aligners.numberofkits'].value}`}
                    </span>
                    <span>{` (`}</span>
                    <Checkbox
                        checked={useFormula}
                        onChange={(e) => {
                            setUseFormula(e.target.checked);
                        }}
                    >
                        по формуле):
                    </Checkbox>
                </div>

                {useFormula ? (
                    <>
                        <InputNumber
                            min={0}
                            max={200}
                            value={numberForKit}
                            width={100}
                            style={{ width: 100 }}
                            // onChange={(newValue) => setNumberForKit(newValue)}
                        />
                        <div>/</div>
                        <InputNumber
                            min={1}
                            max={100}
                            value={itemsPerKit}
                            width={100}
                            style={{ width: 100 }}
                            onChange={(newValue) => setItemsPerKit(newValue)}
                        />
                        <div>=</div>
                        <InputNumber
                            min={0}
                            max={100}
                            value={calculatedNumberOfKits}
                            width={100}
                            style={{ width: 100 }}
                            // onChange={(newValue) => setNumberOfTemplates(newValue)}
                        />
                    </>
                ) : (
                    <InputNumber
                        min={0}
                        max={100}
                        value={numberOfKits}
                        width={100}
                        style={{ width: 100 }}
                        onChange={(newValue) => setNumberOfKits(newValue)}
                    />
                )}
            </div>
            <div>
                <div style={{ fontWeight: 600 }}>
                    {`${texts['order.labels.aligners.kit.title'].value}:`}
                </div>
                <div>
                    <Checkbox
                        checked={valik}
                        onChange={(e) => setValik(e.target.checked)}
                    >
                        {texts['order.labels.aligners.kit.valik'].value}
                    </Checkbox>
                    <br />
                    <Checkbox
                        checked={hook}
                        onChange={(e) => setHook(e.target.checked)}
                    >
                        {texts['order.labels.aligners.kit.tool'].value}
                    </Checkbox>
                    <br />
                    <Checkbox
                        checked={box}
                        onChange={(e) => setBox(e.target.checked)}
                    >
                        {texts['order.labels.aligners.kit.container'].value}
                    </Checkbox>
                </div>
            </div>
            <div>
                <div style={{ fontWeight: 600, paddingTop: 12 }}>
                    {`${texts.date.value}:`}
                </div>
                <div>
                    <DatePicker
                        placeholder={texts['date.select'].value}
                        value={date}
                        onChange={(value) => setDate(value)}
                        format="DD.MM.YYYY"
                    />
                </div>
            </div>

            <Button
                type="primary"
                target="_blank"
                style={{ marginTop: 12 }}
                // title=Хирургический%20шаблон&doctor=Хамхоев&patient=Благонравов&type=aligners&acase=complex
                // &atype=addition
                // &box=1,2,3
                // &n=6
                // &d=22.11.2023
                // &s=100
                // &u=1-3,4-55&l=
                href={`${PRINTER_HOST}?doctor=${doctor}&patient=${patient}&type=aligners&atype=${
                    order.atype
                }&acase=${order.acase}${
                    subtitle ? '&subtitle=true' : ''
                }&box=${getBox(valik, hook, box)}&n=${
                    useFormula ? calculatedNumberOfKits : numberOfKits
                }&d=${moment(date).format(
                    'DD.MM.YYYY'
                )}&s=${numberOfSteps}&u=${getJawConfig(
                    order.adata
                )}&l=${getJawConfig(order.adatalower)}`}
            >
                {printTitle}
            </Button>
            <Divider />
        </>
    );
};
