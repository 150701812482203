import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Button } from 'antd';

interface CustomCellRendererParams extends ICellRendererParams {
    role: string;
    token: string;
    onPreview: any;
}

export const CampaignPreviewRenderer = (props: CustomCellRendererParams) => {
    const { data: row, value, onPreview } = props;
    if (!row) {
        return null;
    }
    // return <Icon type="eye" theme="twoTone" />;
    if (!value) {
        return <span>-</span>;
    }
    console.log(row);
    return (
        <Button
            type="primary"
            icon="eye"
            size="small"
            onClick={() => {
                onPreview(row.id);
            }}
        />
    );
};
