import React, { useEffect } from 'react';
import { Result, notification, Icon, Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import moment from 'moment';
import { useSelector } from 'react-redux';
import AppSider from '../Navigation/AppSider';
import AppSiderClient from '../Navigation/AppSiderClient';
import { CenteredSpinner } from '../Spinner/CenteredSpinner';
import {
    StyledLayout,
    StyledContent,
    StyledFooter,
    StyledContentLayout
} from './Layout';
import GlobalHeader from '../Header/GlobalHeader';
import { checkOrderVisibility } from '../../utils/user';
import { StatusDescriptionNoProvider } from '../Notification/StatusDescriptionNoProvider';
import { NotificationLinkNoProvider } from '../Notification/NotificationLinkNoProvider';
import { NewMessageDescriptionNoProvider } from '../Notification/NewMessageDescriptionNoProvider';
import { isEmpty } from '../../utils/common';
import { GATEWAY_HOST } from '../../utils/properties';
import { getPriceData, setPriceData } from '../../utils/auth';

export const AppNavigation = (props: any) => {
    const { children } = props;
    const queryClient = useQueryClient();
    const queryLocation = useLocation();
    const currentRoute = useSelector(
        (state: any) => state.CommonReducer.currentRoute
    );
    const jwt = useSelector((state: any) => state.AuthReducer.token);
    const backHref = useSelector((state: any) => state.CommonReducer.backHref);
    const profile = useSelector((state: any) => state.ProfileReducer.profile);
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const newMessage = useSelector(
        (state: any) => state.MessageReducer.newMessage
    );
    const newComment = useSelector(
        (state: any) => state.MessageReducer.newComment
    );
    const newOrder = useSelector(
        (state: any) => state.NotificationReducer.newOrder
    );
    const updateOrder = useSelector(
        (state: any) => state.NotificationReducer.updateOrder
    );
    const backTitle = useSelector(
        (state: any) => state.CommonReducer.backTitle
    );

    const { data } = useQuery(
        ['campaign-to-show'],
        () => {
            // ?_sort=created_at:DESC&enabled=true&_limit=100&_start=0&start_gte=2023-11-01T00:00:00Z&end_lt=2023-12-30T19:08:34.502Z
            const currentDate = moment.utc().format('YYYY-MM-DD');
            const url = `${GATEWAY_HOST}/campaigns?_sort=created_at:DESC&enabled=true&_limit=100&_start=0&start_lte=${currentDate}&end_gt=${currentDate}`;
            return fetch(url, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                }
            }).then((res) => res.json());
        },
        {
            enabled: !!jwt,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            retry: false
        }
    );

    useEffect(() => {
        if (data && texts) {
            const status = getPriceData();
            let statusObj = { id: -1 };
            if (status) {
                statusObj = JSON.parse(status);
            }
            if (data.length > 0 && statusObj.id !== data[0].id) {
                Modal.info({
                    // title: `${ADV_TITLE}`,
                    okText: texts.close.value,
                    okType: 'default',
                    width: 1200,
                    icon: null,
                    content: (
                        <>
                            <style>{data[0].css}</style>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data[0].html
                                }}
                            />
                        </>
                    ),
                    onOk: () => {
                        // window.location.href = ;
                        fetch(`${GATEWAY_HOST}/prices`, {
                            headers: {
                                Authorization: `Bearer ${jwt}`,
                                'Content-Type': 'application/json'
                            },
                            method: 'POST',
                            body: JSON.stringify({
                                user: profile.id,
                                read: true,
                                adv: data[0].id
                            })
                        });
                        setPriceData(data[0].id);
                    }
                });
            }
        }
    }, [data, texts]);

    const routeRole = currentRoute.split('/')[1];
    let Sider = AppSider;

    const getTitleIcon = (item: any) => {
        let result = 'info-circle';
        if (item.type === 'STATUS') {
            result = 'info-circle';
        } else if (item.type === 'MESSAGE') {
            result = 'message';
        } else if (item.type === 'COMMENT') {
            result = 'lock';
        }
        return result;
    };

    const openNotification = (texts: any, newMessage: any, profile: any) => {
        const { message, message_data: messageData } = newMessage;
        const item = {
            ...messageData,
            order: {
                id: message.order_id,
                patient: messageData.patient,
                bill: messageData.bill
            }
        };
        // const { author, comment } = message;
        queryClient.refetchQueries('notifications');
        queryClient.refetchQueries('notificationsUnreadCount');

        // if user is located on order-details page
        if (queryLocation.pathname.indexOf('order-details') >= 0) {
            const queryParams = new URLSearchParams(queryLocation.search);
            const orderId = queryParams.get('id');
            if (message.order_id === orderId) {
                queryClient.refetchQueries(['order-messages', orderId]);
            }
        }

        notification.open({
            duration: 0,
            style: { marginBottom: '8px', padding: '8px 24px 8px 0px' },
            message: (
                <div style={{ display: 'flex', fontSize: '14px' }}>
                    <Icon
                        theme="twoTone"
                        twoToneColor={
                            item.type === 'MESSAGE' || item.type === 'COMMENT'
                                ? '#52c41a'
                                : '#fa8c16'
                        }
                        style={{
                            fontSize: '20px',
                            width: '32px'
                        }}
                        type={getTitleIcon(item)}
                    />
                    <div>
                        <NewMessageDescriptionNoProvider
                            item={item}
                            role={profile.role.name}
                            texts={texts}
                        />
                        <NotificationLinkNoProvider
                            item={item}
                            role={profile.role.name}
                            texts={texts}
                        />
                    </div>
                </div>
            )
        });
    };

    const openCommentNotification = (
        texts: any,
        newMessage: any,
        profile: any
    ) => {
        const { comment, message_data: messageData } = newMessage;
        const item = {
            ...messageData,
            order: {
                id: comment.order_id,
                patient: messageData.patient,
                bill: messageData.bill
            }
        };
        queryClient.refetchQueries('notifications');
        queryClient.refetchQueries('notificationsUnreadCount');

        // if user is located on order-details page
        if (queryLocation.pathname.indexOf('order-details') >= 0) {
            const queryParams = new URLSearchParams(queryLocation.search);
            const orderId = queryParams.get('id');

            if (comment.order_id === orderId) {
                queryClient.refetchQueries(['order-comments', orderId]);
            }
        }

        notification.open({
            duration: 0,
            style: { marginBottom: '8px', padding: '8px 24px 8px 0px' },
            message: (
                <div style={{ display: 'flex', fontSize: '14px' }}>
                    <Icon
                        theme="twoTone"
                        twoToneColor={
                            item.type === 'MESSAGE' || item.type === 'COMMENT'
                                ? '#52c41a'
                                : '#fa8c16'
                        }
                        style={{
                            fontSize: '20px',
                            width: '32px'
                        }}
                        type={getTitleIcon(item)}
                    />
                    <div>
                        <NewMessageDescriptionNoProvider
                            item={item}
                            role={profile.role.name}
                            texts={texts}
                        />
                        <NotificationLinkNoProvider
                            item={item}
                            role={profile.role.name}
                            texts={texts}
                        />
                    </div>
                </div>
            )
        });
    };

    const openNewOrderNotification = (
        texts: any,
        newOrder: any,
        profile: any,
        title: any
    ) => {
        // debugger;
        const { message, message_data: messageData } = newOrder;
        const item = { ...messageData, order: message };
        const isVisible = checkOrderVisibility(message, profile.role.name);
        if (!isVisible) {
            return;
        }
        // const { id, patient } = message;
        const key = `open${Date.now()}`;
        // const btn = (
        //     <Button
        //         type="primary"
        //         size="small"
        //         onClick={() => {
        //             notification.close(key);
        //             window.location.href = `/${profile.role.name}/order-details?id=${id}&revision=latest`;
        //         }}
        //     >
        //         {texts['order.goto'].value}
        //     </Button>
        // );
        queryClient.refetchQueries('notifications');
        queryClient.refetchQueries('notificationsUnreadCount');
        notification.open({
            duration: 0,
            style: { marginBottom: '8px', padding: '8px 24px 8px 0px' },
            message: (
                <div style={{ display: 'flex', fontSize: '14px' }}>
                    <Icon
                        theme="twoTone"
                        twoToneColor={
                            item.type === 'MESSAGE' || item.type === 'COMMENT'
                                ? '#52c41a'
                                : '#fa8c16'
                        }
                        style={{
                            fontSize: '20px',
                            width: '32px'
                        }}
                        type={getTitleIcon(item)}
                    />
                    <div>
                        <StatusDescriptionNoProvider
                            item={item}
                            role={profile.role.name}
                            texts={texts}
                        />
                        <NotificationLinkNoProvider
                            item={item}
                            role={profile.role.name}
                            texts={texts}
                        />
                    </div>
                </div>
                // <>
                //     <div>{`${texts['order.patient'].value}:`}</div>
                //     <div>{patient}</div>
                // </>
            ),
            // btn,
            key
        });
    };

    useEffect(() => {
        if (
            !isEmpty(newMessage) &&
            profile.id !== newMessage.message.author_id
        ) {
            openNotification(texts, newMessage, profile);
        }
    }, [newMessage]);

    useEffect(() => {
        if (
            !isEmpty(newComment) &&
            profile.id !== newComment.comment.author_id
        ) {
            openCommentNotification(texts, newComment, profile);
        }
    }, [newComment]);

    useEffect(() => {
        if (!isEmpty(newOrder)) {
            openNewOrderNotification(
                texts,
                newOrder,
                profile,
                texts['messages.new.order'].value
            );
        }
    }, [newOrder]);
    useEffect(() => {
        if (!isEmpty(updateOrder)) {
            openNewOrderNotification(
                texts,
                updateOrder,
                profile,
                texts['messages.update.order'].value
            );
        }
    }, [updateOrder]);

    let content = <Result status="403" title="403" />;
    if (
        currentRoute === '/auth/signed-in' ||
        currentRoute === '/stl' ||
        currentRoute === '/gif' ||
        currentRoute === '/shared'
    ) {
        content = children;
    } else if (profile.role) {
        if (
            routeRole === profile.role.name ||
            (routeRole === '' && profile.role.name === 'doctor') ||
            routeRole === 'profile' ||
            routeRole === 'notificator'
        ) {
            content = children;
            if (profile.role.name === 'doctor') {
                Sider = AppSiderClient;
            }
        } else if (routeRole === '' && profile.role.name) {
            window.location.href = `/${profile.role.name}/orders`;
        }
    }

    let result = <CenteredSpinner size="default" />;

    if (
        texts !== null &&
        !isEmpty(profile.role) &&
        currentRoute !== '/auth/signed-in' &&
        currentRoute !== '/stl' &&
        currentRoute !== '/gif' &&
        currentRoute !== '/shared'
    ) {
        result = (
            <StyledLayout>
                <Sider
                    scope={profile.role.name}
                    unread={profile.unread}
                    texts={texts}
                    selectedKeys={[currentRoute]}
                />
                <StyledContentLayout>
                    <GlobalHeader
                        collapsed={false}
                        backTitle={backTitle}
                        backHref={backHref}
                    />
                    <StyledContent>{content}</StyledContent>
                    <StyledFooter style={{ textAlign: 'center' }}>
                        {texts.copyright.value}
                    </StyledFooter>
                </StyledContentLayout>
            </StyledLayout>
        );
    } else if (
        currentRoute === '/auth/signed-in' ||
        currentRoute === '/stl' ||
        currentRoute === '/gif' ||
        currentRoute === '/shared'
    ) {
        result = (
            <StyledLayout>
                <StyledContent>{content}</StyledContent>
            </StyledLayout>
        );
    } else if (
        currentRoute === '/stl' ||
        currentRoute === '/gif' ||
        currentRoute === '/shared'
    ) {
        result = (
            <StyledLayout>
                <StyledContent>{content}</StyledContent>
            </StyledLayout>
        );
    }

    return result;
};
