import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import OrderStatus from '../Order/OrderStatus';

interface StatusCellRendererParams extends ICellRendererParams {
    texts: any;
}

export const OrderStatusRenderer = (props: StatusCellRendererParams) => {
    const { data: row, value, texts } = props;
    if (!row) {
        return null;
    }
    return <OrderStatus texts={texts} status={value} />;
};
