import React from 'react';
import { Button, Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import UserAvatarDropdown from './UserAvatarDropdown';
import { openCreateOrder } from '../../redux/actions';
import { GATEWAY_HOST } from '../../utils/properties';
import NewOrderDropdown from './NewOrderDropdown';

const RightContentStyled = styled.div`
    float: right;
    height: 100%;
    overflow: hidden;
`;

const NewOrderButton = styled(Button)`
    margin-left: 16px;
    display: inline-block;
`;

const RightContent = () => {
    const dispatch = useDispatch();
    const queryLocation = useLocation();
    const jwt = useSelector((state: any) => state.AuthReducer.token);
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const profile = useSelector((state: any) => state.ProfileReducer.profile);
    // const token = useSelector((state: any) => state.AuthReducer.token);

    const { data } = useQuery(
        ['campaign-to-show'],
        () => {
            // ?_sort=created_at:DESC&enabled=true&_limit=100&_start=0&start_gte=2023-11-01T00:00:00Z&end_lt=2023-12-30T19:08:34.502Z
            const currentDate = moment.utc().format('YYYY-MM-DD');
            const url = `${GATEWAY_HOST}/campaigns?_sort=created_at:DESC&enabled=true&_limit=100&_start=0&start_lte=${currentDate}&end_gt=${currentDate}`;
            return fetch(url, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                }
            }).then((res) => res.json());
        },
        {
            enabled: !!jwt,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            retry: false
        }
    );

    const onNewOrderButtonClick = () => {
        dispatch(openCreateOrder());
    };
    return (
        <RightContentStyled>
            <NewOrderButton
                type="primary"
                onClick={onNewOrderButtonClick}
                hidden={
                    queryLocation.pathname.indexOf('orders') < 0 ||
                    profile.role.name !== 'manager'
                }
            >
                {texts['orders.new'].value}
            </NewOrderButton>
            {profile.role.name === 'doctor' && (
                <NewOrderDropdown title={texts['orders.new'].value} />
            )}
            <Button
                style={{ marginLeft: 16 }}
                type="dashed"
                shape="circle"
                icon="info"
                // disabled={!texts && !data}
                // hidden={profile.role.name !== 'doctor'}
                hidden={!texts || !data || (data && data.length === 0)}
                onClick={() => {
                    // const status = getPriceData();
                    // let statusObj = { id: -1 };
                    // if (status) {
                    //     statusObj = JSON.parse(status);
                    // }
                    // if (data.length > 0 && statusObj.id !== data[0].id) {
                    Modal.info({
                        // title: `${ADV_TITLE}`,
                        okText: texts.close.value,
                        okType: 'default',
                        width: 1200,
                        icon: null,
                        content: (
                            <>
                                <style>{data[0].css}</style>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: data[0].html
                                    }}
                                />
                            </>
                        ),
                        onOk: () => {
                            // window.location.href = ;
                            // fetch(`${GATEWAY_HOST}/prices`, {
                            //     headers: {
                            //         Authorization: `Bearer ${jwt}`,
                            //         'Content-Type': 'application/json'
                            //     },
                            //     method: 'POST',
                            //     body: JSON.stringify({
                            //         user: profile.id,
                            //         read: true,
                            //         adv: data[0].id
                            //     })
                            // });
                            // setPriceData(data[0].id);
                        }
                    });
                    // }
                    // Modal.info({
                    //     // title: `${ADV_TITLE}`,
                    //     okText: 'Закрыть',
                    //     okType: 'default',
                    //     icon: null,
                    //     width: 1200,
                    //     content: <AdvBraces token={token} profile={profile} />,
                    //     onOk: () => {
                    //         // window.location.href = ;
                    //         fetch(`${GATEWAY_HOST}/prices`, {
                    //             headers: {
                    //                 Authorization: `Bearer ${token}`,
                    //                 'Content-Type': 'application/json'
                    //             },
                    //             method: 'POST',
                    //             body: JSON.stringify({
                    //                 user: profile.id,
                    //                 read: true,
                    //                 adv: ADV_ID
                    //             })
                    //         });
                    //     }
                    // });
                }}
            />
            <UserAvatarDropdown />
        </RightContentStyled>
    );
};

export default RightContent;
