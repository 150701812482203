import React, { Component } from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';
import getStatus from '../../utils/status';

const NoneTag = styled(Tag)`
    color: rgba(0, 0, 0, 0.65) !important;
    border: none !important;
    background: none !important;
    padding: 0;
`;

const StyledTag = styled(Tag)`
    background: none !important;
    border: none !important;
    padding: 0;
`;
const OtkTag = styled(Tag)`
    background: none !important;
    color: #319766;
    border: none !important;
    padding: 0;
`;

class OrderStatus extends Component {
    render() {
        const { status, texts } = this.props;
        const tagValue = status ? status.toUpperCase() : 'NONE';
        const statusValue = getStatus(tagValue);
        const statusText =
            texts[`order.status.${tagValue.toLowerCase()}`].value;
        let result = (
            <StyledTag size="small" color={statusValue.color} key={status}>
                {statusText}
            </StyledTag>
        );

        if (status === 'OTK' || status === 'READY') {
            result = (
                <OtkTag size="small" color={statusValue.color} key={status}>
                    {statusText}
                </OtkTag>
            );
        }

        if (status === 'NONE' || typeof status === 'undefined') {
            result = (
                <NoneTag size="small" color={statusValue.color} key={status}>
                    {statusText}
                </NoneTag>
            );
        }
        return result;
    }
}

export default OrderStatus;
