import React, { Component } from 'react';
import styled from 'styled-components';

const Author = styled.div`
    height: 38px;
`;

class OrderAuthor extends Component {
    render() {
        const { author, role } = this.props;
        let roleLocalized = role;
        if (role === 'manager') {
            roleLocalized = 'Администратор';
        } else if (role === 'doctor') {
            roleLocalized = 'Доктор';
        } else if (role === 'technician') {
            roleLocalized = 'Исполнитель';
        }
        return (
            <Author>
                <div>{author}</div>
                <div>{roleLocalized}</div>
            </Author>
        );
    }
}

export default OrderAuthor;
