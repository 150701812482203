import styled from 'styled-components';
import { Layout } from 'antd';

const { Content, Footer: AntFooter } = Layout;

export const Footer = AntFooter;

export const StyledLayout = styled(Layout)`
    min-height: 100vh;
`;

export const StyledFooter = styled(Footer)`
    padding: 80px 50px 16px;
`;

export const StyledContentLayout = styled(Layout)`
    min-height: 100vh;
    /* padding-left: 256px; */
`;

export const StyledContent = styled(Content)`
    padding: 8px;
    position: relative;
    height: 100%;

    @media (max-width: 767px) {
        padding: 0;
    }
`;
