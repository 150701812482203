const theme = {
    backgroundColor: '#f6f4f8',
    baseColor: '#1fa388',
    baseColorSecondary: '#3C9FD9',
    calloutColor: '#F5A623',
    lightColor: '#cbe3fa',
    highlightColor: '#ff7954',
    baseLightColor: '#e6effa',
    purplePrimary: '#639',
    purpleSecondary: '#5f72bd',
    textColor: '#677897',
    textColorAlternative: '#c8c8ef',
    textColorLight: '#91b7dc',
    textColorFooterMenuMain: '#7887a7',
    textColorFooterMenuSimple: '#b0b9ca',
    whiteColor: '#fff',
    backgroundGradient: 'linear-gradient(28deg, #639, #5f72bd)',
    landingGradient: 'linear-gradient(-141deg, #639 0%, #6e6cf9 100%)',
    actionButtonBackgroundColor: '#54d8a3',
    actionButtonBoxShadow: '0 10px 20px 0 rgba(84,216,163,0.3)',
    backgroundDarkColor: '#1c034d'
};

export default theme;
