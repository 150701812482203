import React, { Component } from 'react';
import { Col, Form, Icon, Row, Upload } from 'antd';
import styled from 'styled-components';
import { GATEWAY_HOST } from '../../utils/properties';

const StyledItem = styled(Form.Item)`
    margin-bottom: 8px;
    font-size: 12px;
`;

const StyledUpload = styled(Upload)`
    .ant-upload-list-picture-card .ant-upload-list-item-info,
    .ant-upload.ant-upload-select-picture-card {
        margin-right: auto;
    }

    .ant-upload-list-picture-card .ant-upload-list-item,
    .ant-upload.ant-upload-select-picture-card > span {
        float: none;
        min-width: 96px;
        max-width: 96px;
        max-height: 96px;
        min-height: 96px;
        height: 96px;
    }

    .ant-upload-list-picture-card {
        float: none;
    }
`;

const RowStyled = styled(Row)`
    display: ${(props) => (props.hidden === true ? 'none' : 'block')};
    margin: 0;
    .ant-form-item-label {
        display: none;
    }
`;

class SimpleUpload extends Component {
    render() {
        const GATEWAY_API = `${GATEWAY_HOST}/upload`;
        const {
            image,
            token,
            hidden,
            handlePreview,
            handleChangeImage,
            texts,
            disabled
        } = this.props;

        const headers = {
            Authorization: `Bearer ${token}`,
            'X-Requested-With': null
        };
        const uploadParams = {
            // ref: 'user',
            // refId: id,
            // field: 'avatar',
            // source: 'users-permissions',
        };
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">{texts.upload.value}</div>
            </div>
        );
        return (
            <RowStyled gutter={16} hidden={hidden}>
                <Col md={24} sm={24}>
                    <StyledItem label=" " colon={false}>
                        <StyledUpload
                            action={GATEWAY_API}
                            accept="image/*"
                            listType="picture-card"
                            fileList={image}
                            onPreview={handlePreview}
                            onChange={handleChangeImage}
                            name="files"
                            headers={headers}
                            data={uploadParams}
                            disabled={disabled}
                        >
                            {image.length >= 1 ? null : uploadButton}
                        </StyledUpload>
                    </StyledItem>
                </Col>
            </RowStyled>
        );
    }
}

export default SimpleUpload;
