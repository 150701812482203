import React, { useEffect, useState } from 'react';
import { keys } from 'lodash';
import styled from 'styled-components';
import { Button, InputNumber, Slider, Spin, Switch } from 'antd';
import { useSelector } from 'react-redux';

const ParamsTitle = styled.div`
    margin-top: 8px;
    font-weight: 500;
`;

const SliderStyled = styled(Slider)`
    .ant-slider-mark {
        font-size: 11px;
    }
`;

const getMarks = (maxSlices: number) => {
    const marks: any = {};
    marks['0'] = 0;
    marks[`${maxSlices}`] = maxSlices;
    //for (let i = 0; i <= maxSlices; i += 5) {
    //    // if (i % 5 === 0) {
    //    marks[`${i}`] = i;
    //    // }
    //}
    return marks;
};

export const SliceSlider = (props: any) => {
    const {
        slices,
        showOverLayer,
        onChange,
        onShowOverLayer,
        hidden,
        disabled
    } = props;
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const [sliceIndex, setSliceIndex] = useState(0);
    const [step, setStep] = useState(200);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        onChange(sliceIndex);
    }, [sliceIndex]);

    useEffect(() => {
        let interval: any = null;
        if (isActive) {
            interval = setInterval(() => {
                console.log('step');
                setSliceIndex((oldIndex: number) => {
                    let newSliceIndex = oldIndex + 1;
                    if (newSliceIndex >= keys(slices).length) {
                        newSliceIndex = keys(slices).length - 1;
                        setIsActive(!isActive);
                    }
                    return newSliceIndex;
                });
            }, step);
        } else if (!isActive) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, step]);

    console.log(hidden);

    return (
        <div style={{ display: hidden ? 'none' : 'inline-block' }}>
            <div style={{ display: 'inline-block' }}>
                <ParamsTitle>{`${texts['slices.navigation'].value}:`}</ParamsTitle>
                <Button.Group size="small">
                    <Button
                        icon="fast-backward"
                        disabled={sliceIndex === 0 || disabled}
                        onClick={() => {
                            setSliceIndex(0);
                        }}
                    />
                    <Button
                        icon="step-backward"
                        disabled={sliceIndex === 0 || disabled}
                        onClick={() => {
                            setSliceIndex(sliceIndex - 1);
                        }}
                    />
                    <Button
                        icon={isActive ? 'pause' : 'caret-right'}
                        onClick={() => {
                            if (sliceIndex === keys(slices).length - 1) {
                                setSliceIndex(0);
                            }
                            setIsActive(!isActive);
                        }}
                        disabled={disabled}
                    />
                    <Button
                        icon="step-forward"
                        disabled={
                            sliceIndex === keys(slices).length - 1 || disabled
                        }
                        onClick={() => {
                            setSliceIndex(sliceIndex + 1);
                        }}
                    />
                    <Button
                        icon="fast-forward"
                        disabled={
                            sliceIndex === keys(slices).length - 1 || disabled
                        }
                        onClick={() => {
                            setSliceIndex(keys(slices).length - 1);
                        }}
                    />
                </Button.Group>
            </div>
            <div style={{ display: 'inline-block', marginLeft: '24px' }}>
                <ParamsTitle>{`${texts['slices.delay'].value}:`}</ParamsTitle>
                <InputNumber
                    defaultValue={200}
                    value={step}
                    min={100}
                    max={2000}
                    step={100}
                    formatter={(value) => `${value} мс`}
                    // @ts-ignore
                    parser={(value) => value.replace(' мс', '')}
                    onChange={(newValue: any) => {
                        setStep(newValue);
                    }}
                    size="small"
                />
            </div>
            <div style={{ display: 'inline-block', marginLeft: '24px' }}>
                <ParamsTitle>{`${texts['slices.overlay'].value}:`}</ParamsTitle>
                <Switch checked={showOverLayer} onChange={onShowOverLayer} />
            </div>
            <div
                style={{
                    display: disabled ? 'inline-block' : 'none',
                    marginLeft: '24px'
                }}
            >
                <Spin />
            </div>
            <SliderStyled
                disabled={disabled}
                value={sliceIndex}
                max={keys(slices).length - 1}
                marks={getMarks(keys(slices).length - 1)}
                style={{ maxWidth: 400 }}
                onChange={(sliderValue: any) => {
                    setSliceIndex(sliderValue);
                }}
            />
        </div>
    );
};
