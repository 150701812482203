import { actionTypes } from '../actions';

export const primaryInitialState = {
    newOrder: null,
    updateOrder: null
};

const NotificationReducer = (state = primaryInitialState, action) => {
    switch (action.type) {
        case actionTypes.NEW_ORDER: {
            return {
                ...state,
                newOrder: JSON.parse(action.message)
            };
        }
        case actionTypes.UPDATE_ORDER: {
            return {
                ...state,
                updateOrder: JSON.parse(action.message)
            };
        }
        default:
            return state;
    }
};

export default NotificationReducer;
