import React, { Component } from 'react';
import { Col, Input, Radio, Row, Select } from 'antd';
import styled from 'styled-components';

const { Option } = Select;
const { Search } = Input;

const RightPart = styled.div`
    float: right;

    @media(max-width: 767px) {
        float: left;
        margin-top: 16px;
    }
`;

const LeftPart = styled.div`
    /* float: right; */
`;

const Toolbar = styled.div`
`;

const ToolbarItem = styled.div`
    display: inline-block;
    padding: 0 8px;
`;

class OrderCardListToolbar extends Component {
    render() {
        const { onSearchChange, onModeChange, onSortChange, sorter, mode, search, texts } = this.props;
        return (
            <Toolbar>
                <Row>
                    <Col md={8} xs={24}>
                        <LeftPart>
                            <Search defaultValue={search} size="small" placeholder={texts.search.value} onSearch={onSearchChange} enterButton />
                        </LeftPart>
                    </Col>
                    <Col md={16} xs={24}>
                        <RightPart>
                            <ToolbarItem>
                                <Radio.Group defaultValue={mode} onChange={onModeChange}>
                                    <Radio value="ACTIVE">{texts['orders.mode.active'].value}</Radio>
                                    <Radio value="CLOSED">{texts['orders.mode.closed'].value}</Radio>
                                    <Radio value="ALL">{texts['orders.mode.all'].value}</Radio>
                                </Radio.Group>
                            </ToolbarItem>
                            <ToolbarItem>
                                <Select size="small" defaultValue={sorter.field} onChange={onSortChange}>
                                    <Option value="latest">{texts['orders.sort.latest'].value}</Option>
                                    <Option value="patient">{texts['orders.sort.alpha'].value}</Option>
                                    <Option value="status">{texts['orders.sort.status'].value}</Option>
                                </Select>
                            </ToolbarItem>
                        </RightPart>
                    </Col>
                </Row>
            </Toolbar>
        );
    }
}

export default OrderCardListToolbar;
