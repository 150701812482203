import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Divider,
    Icon,
    List,
    message,
    Select,
    Tooltip,
    Typography
} from 'antd';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import moment from 'moment';
import { GATEWAY_HOST } from '../../utils/properties';
import { NewMessageDescription } from './NewMessageDescription';
import { StatusDescription } from './StatusDescription ';

const { Option } = Select;
const { Text } = Typography;

export const readNotification = async (data: any) => {
    const { jwt, notificationId } = data;

    const body: any = {
        read: true
    };

    return fetch(`${GATEWAY_HOST}/notifications/${notificationId}`, {
        headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
        },
        method: 'PUT',
        body: JSON.stringify(body)
    });
};

export const readAllNotifications = async (data: any) => {
    const { jwt } = data;

    return fetch(`${GATEWAY_HOST}/notifications/readall`, {
        headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
        },
        method: 'GET'
    });
};

export const DoctorNotificationsPage = () => {
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const profile = useSelector((state: any) => state.ProfileReducer.profile);
    const jwt = useSelector((state: any) => state.AuthReducer.token);
    const [mode, setMode] = useState<'unread' | 'all'>('unread');
    const queryClient = useQueryClient();
    const [processingNotification, setProcessingNotification] = useState('');
    const limit = 100;
    const [readLoading, setReadLoading] = useState(false);
    const [idsToReadCount, setIdsToReadCount] = useState(0);

    const { isLoading, data } = useQuery(['notifications', mode], () =>
        fetch(
            `${GATEWAY_HOST}/notifications?user=${profile.id}${
                mode === 'unread' ? '&read=false' : ''
            }&_limit=${limit}&_sort=created_at:DESC`,
            {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                }
            }
        ).then((res) => res.json())
    );

    const { mutate: readAllMutate } = useMutation(
        'readAllNotifications',
        readAllNotifications,
        {
            onSuccess: () => {
                queryClient.refetchQueries('notifications');
                queryClient.refetchQueries('notificationsUnreadCount');
                setReadLoading(false);
            },
            onError: () => {
                setProcessingNotification('');
                message.error('Возникла ошибка, попробуйте позже.');
                setReadLoading(false);
            },
            onSettled: () => {
                // queryClient.invalidateQueries('create');
            }
        }
    );

    useEffect(() => {
        if (idsToReadCount <= 0) {
            setReadLoading(false);
        }
    }, [idsToReadCount]);

    const { mutate } = useMutation('readNotification', readNotification, {
        onSuccess: (data) => {
            queryClient.refetchQueries('notifications');
            queryClient.refetchQueries('notificationsUnreadCount');
            setProcessingNotification('');
            if (idsToReadCount > 0) {
                setIdsToReadCount(idsToReadCount - 1);
            }
        },
        onError: () => {
            setProcessingNotification('');
            message.error('Возникла ошибка, попробуйте позже.');
            if (idsToReadCount > 0) {
                setIdsToReadCount(idsToReadCount - 1);
            }
        },
        onSettled: () => {
            // queryClient.invalidateQueries('create');
        }
    });

    if (isLoading) return <div>Загрузка...</div>;

    const handleReadClick = (notificationId: any) => {
        const body = {
            jwt,
            notificationId
        };
        mutate(body);
    };

    const getTitle = (item: any) => {
        let result = texts.notification_new.value;
        if (item.type === 'STATUS') {
            result = texts.notification_status.value;
        } else if (item.type === 'MESSAGE') {
            result = texts.notification_message.value;
        } else if (item.type === 'COMMENT') {
            result = texts.notification_comment.value;
        } else if (item.type === 'ADV') {
            result = item.message;
        }
        return result;
    };

    const getDescription = (item: any, profileLocal: any) => {
        let result = <div>{item.data}</div>;
        if (item.type === 'MESSAGE' || item.type === 'COMMENT') {
            result = (
                <NewMessageDescription
                    item={item}
                    role={profileLocal.role.name}
                />
            );
        } else if (item.type === 'STATUS') {
            result = (
                <StatusDescription item={item} role={profileLocal.role.name} />
            );
        }
        return result;
    };

    const getTitleIcon = (item: any) => {
        let result = 'info-circle';
        if (item.type === 'STATUS') {
            result = 'info-circle';
        } else if (item.type === 'MESSAGE') {
            result = 'message';
        } else if (item.type === 'COMMENT') {
            result = 'lock';
        }
        return result;
    };

    const readAll = () => {
        setReadLoading(true);
        const body = {
            jwt
        };
        readAllMutate(body);
    };

    return (
        <Card
            // title={texts['menu.notifications'].value}
            title={data.name}
            extra={
                <div>
                    <Select
                        value={mode}
                        style={{ width: 160 }}
                        onChange={(value: any) => setMode(value)}
                    >
                        <Option value="unread">{texts.unread.value}</Option>
                        <Option value="all">{texts.all.value}</Option>
                    </Select>
                    <Button
                        loading={readLoading}
                        style={{ marginLeft: 8 }}
                        onClick={() => readAll()}
                    >
                        {texts.read_all.value}
                    </Button>
                </div>
            }
        >
            <List
                className="demo-loadmore-list"
                // loading={initLoading}
                itemLayout="horizontal"
                // loadMore={loadMore}
                locale={{ emptyText: texts.unread_empty.value }}
                dataSource={data}
                renderItem={(item: any) => (
                    <List.Item
                        actions={[
                            <Tooltip
                                title={texts.mark_as_read.value}
                                placement="bottomRight"
                                mouseEnterDelay={1}
                            >
                                <div style={{ minWidth: 50 }}>
                                    <Button
                                        type="default"
                                        hidden={item.read === true}
                                        icon="check"
                                        loading={
                                            processingNotification === item.id
                                        }
                                        onClick={() => {
                                            setProcessingNotification(item.id);
                                            handleReadClick(item.id);
                                        }}
                                    />
                                </div>
                            </Tooltip>
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <Icon
                                    theme="twoTone"
                                    twoToneColor={
                                        item.type === 'MESSAGE' ||
                                        item.type === 'COMMENT'
                                            ? '#52c41a'
                                            : '#fa8c16'
                                    }
                                    style={{
                                        fontSize: '20px'
                                    }}
                                    type={getTitleIcon(item)}
                                />
                            }
                            title={
                                <Text strong={!item.read}>
                                    {getTitle(item)}
                                </Text>
                            }
                            // title=
                            description={getDescription(item, profile)}
                            //     item.type === 'MESSAGE' ||
                            //     item.type === 'COMMENT' ? (
                            //         <NewMessageDescription
                            //             item={item}
                            //             role={profile.role.name}
                            //         />
                            //     ) : (item.type === 'ADV' ? (
                            //         <div>{item.data}</div>
                            //     ) : (
                            //         <StatusDescription
                            //             item={item}
                            //             role={profile.role.name}
                            //         />
                            //     )
                            // }
                        />
                        <div>
                            {moment(item.created_at).format(
                                'DD/MM/YY hh:mm:ss'
                            )}
                        </div>
                    </List.Item>
                )}
            />
            <Divider />
            {data.length >= limit && (
                <Text code>Отображаются последние {limit} уведомлений</Text>
            )}
        </Card>
    );
};
