import React, { PureComponent } from 'react';
import { Dropdown } from 'antd';
import styled from 'styled-components';

const DropdownStyled = styled(Dropdown)`
    display: inline-block;
    vertical-align: top;
    padding: 0 12px;
    height: 64px;
    cursor: pointer;

    :hover {
        background: rgba(0, 0, 0, 0.025);
    }
`;

class HeaderDropdown extends PureComponent {
    render() {
        const { overlay, body } = this.props;
        return (
            <DropdownStyled
                overlay={overlay}
                placement="bottomRight"
                trigger={['click']}
            >
                {body}
            </DropdownStyled>
        );
    }
}

export default HeaderDropdown;
