import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import fetch from 'isomorphic-unfetch';
import { useQuery } from 'react-query';
import { Pagination } from 'antd';
import {
    GridApi,
    SortChangedEvent,
    ValueGetterParams
} from 'ag-grid-community';
import DoctorOrganisationsPopover from './DoctorOrganisationsPopover';
import DoctorOrganisationsTooltip from './DoctorOrganisationsTooltip';
import { GATEWAY_HOST } from '../../utils/properties';
import { StatusRenderer } from '../Renderer/StatusRenderer';
import { DateRenderer } from '../Renderer/DateRenderer';
import { ClientLinkRenderer } from '../Renderer/ClientLinkRenderer';
import { ColumnListFilter } from '../Filter/list-filter';
import { ColumnTextFilter } from '../Filter/text-filter';
import { localeText } from '../../utils/grid-locale';

import { applySearchToUser } from '../../redux/actions';
import { ColumnTextFilterClient } from '../Filter/text-filter-client';
import { AppRenderer } from '../Renderer/AppRenderer';

export interface SortModelItem {
    colId: string;
    sort: 'asc' | 'desc';
}

export interface InFilterValue {
    operator: 'in';
    value: string[];
    property: string;
}

export interface LikeFilterValue {
    operator: 'like';
    value: string;
    property: string;
}

export interface EqFilterValue {
    operator: 'eq';
    value: string;
    property: string;
}

export interface DateFilterValue {
    operator: 'lt' | 'gt';
    value: number;
    property: string;
}

export type TableFilter =
    | InFilterValue
    | LikeFilterValue
    | DateFilterValue
    | EqFilterValue;

export type TableFilters = string[];

export const AgDoctorClientTable = () => {
    const dispatch = useDispatch();
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const token = useSelector((state: any) => state.AuthReducer.token);
    const role = useSelector((state: any) => state.UserReducer.role);
    const userRole = useSelector(
        (state: any) => state.ProfileReducer.profile.role.name
    );

    const searchValue = useSelector((state: any) => state.UserReducer.search);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const columns = [
        {
            headerName: texts['profile.last_name'].value,
            field: 'last_name',
            colId: 'last_name',
            minWidth: 150,
            resizable: true,
            sortable: true,
            filter: 'textFilterClient',
            filterParams: {
                texts
            },
            cellRenderer: 'clientLinkRenderer',
            cellRendererParams: { role: userRole }
            // defaultSortOrder: 'ascend',
            // sorter: true,
            // render: (text, record) => {
            //     const href = `/${scope}/client?id=${record.id}`;
            //     let result = text;
            //     if (record.role.name === 'doctor') {
            //         result = <Link to={href}>{text}</Link>;
            //     }
            //     return result;
            // }
        },
        {
            headerName: texts['profile.first_name'].value,
            field: 'first_name',
            colId: 'first_name',
            minWidth: 150,
            resizable: true,
            sortable: true,
            filter: 'textFilterClient',
            filterParams: {
                texts
            }
            // sorter: true
        },
        {
            headerName: texts['profile.middle_name'].value,
            field: 'middle_name',
            colId: 'middle_name',
            resizable: true,
            sortable: true,
            filter: 'textFilterClient',
            filterParams: {
                texts
            }
        },
        {
            headerName: texts['profile.email'].value,
            field: 'email',
            colId: 'email',
            minWidth: 200,
            resizable: true,
            sortable: true,
            filter: 'textFilterClient',
            filterParams: {
                texts
            }
        },
        {
            headerName: texts['profile.organisation'].value,
            field: 'organisations',
            colId: 'organisations',
            resizable: true,
            autoHeight: true,
            cellRenderer: 'listRenderer',
            sortable: true,
            tooltipField: 'organisations',
            tooltipComponent: 'orgTooltip'
            // render: (text, record) => {
            //     return <DoctorOrganisationsPopover doctor={record} />;
            // }
        },
        // {
        //     headerName: texts['profile.role'].value,
        //     field: 'role.name',
        //     colId: 'role',
        //     resizable: true,
        //     sortable: true,
        //     valueGetter: (params: ValueGetterParams) => {
        //         let value;
        //         if (texts && params.data) {
        //             value = texts[`role.${params.data.role.name}`].value;
        //         }
        //         return value;
        //     }
        // },
        {
            headerName: texts.status.value,
            field: 'online',
            colId: 'online',
            resizable: true,
            sortable: true,
            cellRenderer: 'statusRenderer',
            minWidth: 150,
            valueGetter: (params: ValueGetterParams) => {
                let value;
                if (texts && params.data) {
                    value = params.data.online
                        ? texts.online.value
                        : texts.offline.value;
                }
                return value;
            }
            // sorter: true,
            // align: 'left',
            // render: (text, record) => {
            //     let status = 'default';
            //     let statusText = texts.offline.value;
            //     if (record.online) {
            //         status = 'success';
            //         statusText = texts.online.value;
            //     }
            //     return <Badge status={status} text={statusText} />;
            // },
        },
        {
            headerName: texts['registration.date'].value,
            field: 'created_at',
            colId: 'created_at',
            resizable: true,
            sortable: true,
            cellRenderer: 'dateRenderer'
            // sorter: true,
            // render: (date) => moment(date).format('DD/MM/YY'),
            // align: 'center'
        },
        {
            headerName: texts.appinstalled.value,
            field: 'tokens',
            colId: 'tokens',
            resizable: true,
            sortable: true,
            cellRenderer: 'appRenderer',
            cellRendererParams: {
                yesText: texts.yes.value,
                noText: texts.no.value
            }
            // sorter: true,
            // render: (date) => moment(date).format('DD/MM/YY'),
            // align: 'center'
        },
        {
            field: 'role.name',
            colId: 'role.name',
            resizable: true,
            sortable: true,
            filter: 'listFilter',
            filterParams: {
                values: {
                    doctor: 'doctor',
                    manager: 'administrator',
                    technician: 'technician'
                }
            },
            hide: true
            // cellRenderer: 'dateRenderer'
            // sorter: true,
            // render: (date) => moment(date).format('DD/MM/YY'),
            // align: 'center'
        }
    ];

    const pageSize = 100;
    const [page, setPage] = useState(1);

    // filters
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [email, setEmail] = useState('');
    const [sorter, setSorter] = useState('_sort=created_at:DESC&');

    useEffect(() => {
        setPage(1);
        // dispatch(applySearchToUser(''));
        // setFirstName('');
        // setMiddleName('');
        // setEmail('');
        if (gridApi) {
            gridApi.setFilterModel(null);
            gridApi.onFilterChanged();
        }
    }, [role, gridApi]);

    useEffect(() => {
        setPage(1);
    }, [searchValue, firstName, middleName, email]);

    const getFilteredUrl = (
        url: string,
        lastNameFilter: string,
        firstNameFilter: string,
        middleNameFilter: string,
        emailFilter: string
    ) => {
        let newUrl = url;
        if (lastNameFilter) {
            newUrl = `${newUrl}&last_name_contains=${lastNameFilter}`;
        }
        if (emailFilter) {
            newUrl = `${newUrl}&email_contains=${emailFilter}`;
        }
        if (firstNameFilter) {
            newUrl = `${newUrl}&first_name_contains=${firstNameFilter}`;
        }
        if (middleNameFilter) {
            newUrl = `${newUrl}&middle_name_contains=${middleNameFilter}`;
        }
        return newUrl;
    };

    const { data } = useQuery(
        [
            'clients-table',
            role,
            page,
            searchValue,
            firstName,
            middleName,
            email,
            sorter
        ],
        () => {
            let url = `${GATEWAY_HOST}/users?${sorter}role.name=${role}&_limit=${pageSize}&_start=${(page -
                1) *
                pageSize}`;
            url = getFilteredUrl(
                url,
                searchValue,
                firstName,
                middleName,
                email
            );
            return fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }).then((res) => res.json());
        }
    );

    useEffect(() => {
        if (gridApi && data) {
            if (searchValue || email || firstName || middleName) {
                gridApi.refreshClientSideRowModel('filter');
            }
        }
    }, [data, gridApi, searchValue, email, firstName, middleName]);

    const { isLoading: isTotalDataLoading, data: totalData } = useQuery(
        [
            'clients-table-total',
            role,
            page,
            searchValue,
            firstName,
            middleName,
            email
        ],
        () => {
            const url = getFilteredUrl(
                `${GATEWAY_HOST}/users/count?role.name=${role}`,
                searchValue,
                firstName,
                middleName,
                email
            );
            return fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }).then((res) => res.json());
        }
    );

    const frameworkComponents = {
        statusRenderer: StatusRenderer,
        dateRenderer: DateRenderer,
        appRenderer: AppRenderer,
        listFilter: ColumnListFilter,
        textFilter: ColumnTextFilter,
        textFilterClient: ColumnTextFilterClient,
        listRenderer: DoctorOrganisationsPopover,
        clientLinkRenderer: ClientLinkRenderer,
        orgTooltip: DoctorOrganisationsTooltip
    };

    return (
        <div
            className="ag-theme-alpine"
            style={{
                height: '100%',
                width: '100%'
            }}
        >
            <AgGridReact
                localeText={localeText}
                debug
                columnDefs={columns}
                suppressMultiSort
                // enableBrowserTooltips
                multiSortKey="ctrl"
                animateRows
                enableCellTextSelection
                pagination={false}
                // paginationPageSize={pageSize}
                onSortChanged={(e: SortChangedEvent) => {
                    const columnStateLocal = e.columnApi.getColumnState();
                    console.log(columnStateLocal);
                    let result = '';
                    for (let i = 0; i < columnStateLocal.length; i += 1) {
                        if (columnStateLocal[i].sort) {
                            result = `_sort=${
                                columnStateLocal[i].colId
                            }:${columnStateLocal[i].sort?.toUpperCase()}&`;
                        }
                    }
                    setSorter(result);
                }}
                onFilterChanged={(e: any) => {
                    if (e.columns.length === 0) {
                        dispatch(applySearchToUser(''));
                        setFirstName('');
                        setMiddleName('');
                        setEmail('');
                        return;
                    }
                    const { colId } = e.columns[0];
                    const model = e.api
                        .getFilterInstance(e.columns[0].colId)
                        .getModel();
                    if (colId === 'last_name') {
                        let search = '';
                        if (model) {
                            search = model.value;
                        }
                        dispatch(applySearchToUser(search));
                    } else if (colId === 'first_name') {
                        setFirstName(model ? model.value : '');
                    } else if (colId === 'middle_name') {
                        setMiddleName(model ? model.value : '');
                    } else if (colId === 'email') {
                        setEmail(model ? model.value : '');
                    }
                }}
                onGridReady={(e) => {
                    setGridApi(e.api);
                }}
                // onDragStopped={(e) => {
                //     updateColumns(e.columnApi.getAllGridColumns());
                // }}
                // onFirstDataRendered={(e) => {
                //     e.columnApi.autoSizeAllColumns();
                // }}
                frameworkComponents={frameworkComponents}
                // // TODO: strange error appeared when use custom Loading
                // // loadingOverlayComponent="customLoadingOverlay"
                // noRowsOverlayComponent="customEmptyOverlay"
                // noRowsOverlayComponentParams={{
                //     image: Empty.PRESENTED_IMAGE_SIMPLE
                // }}
                // suppressPaginationPanel
                // onPaginationChanged={() => {
                //     if (gridApi) {
                //         setTotal(gridApi.paginationGetRowCount());
                //     }
                // }}
                // rowModelType="infinite"
                rowData={data}
                tooltipShowDelay={0}
                // tooltipMouseTrack
                // cacheOverflowSize={2}
                // maxConcurrentDatasourceRequests={2}
                // infiniteInitialRowCount={1}
                // maxBlocksInCache={2}
                // cacheBlockSize={paginationPageSize}
            />
            <Pagination
                style={{
                    backgroundColor: '#fff',
                    height: '36px',
                    lineHeight: '36px',
                    textAlign: 'right',
                    verticalAlign: 'middle',
                    border: '1px solid #babfc7',
                    borderTop: 0
                }}
                size="small"
                current={page}
                total={totalData}
                pageSize={pageSize}
                disabled={isTotalDataLoading}
                showTotal={(total: number) => `Всего: ${total}`}
                onChange={(newPage: number) => {
                    setPage(newPage);
                }}
            />
        </div>
    );
};
