import React, { Component } from 'react';
import { Col, Input, Row, Select } from 'antd';
import styled from 'styled-components';

const { Option } = Select;
const { Search } = Input;

const RightPart = styled.div`
    float: right;

    @media (max-width: 767px) {
        float: left;
        margin-top: 16px;
    }
`;

const LeftPart = styled.div`
    /* float: right; */
`;

const Toolbar = styled.div`
    padding: 0 0 8px 0;
`;

const ToolbarItem = styled.div`
    display: inline-block;
`;

class DoctorTableToolbar extends Component {
    render() {
        const {
            onSearchChange,
            onRoleChange,
            search,
            texts,
            role
        } = this.props;
        return (
            <Toolbar>
                <Row>
                    <Col md={8} xs={24}>
                        <LeftPart>
                            <Search
                                allowClear
                                defaultValue={search}
                                placeholder={texts.search.value}
                                onSearch={onSearchChange}
                                enterButton
                            />
                        </LeftPart>
                    </Col>
                    <Col md={16} xs={24}>
                        <RightPart>
                            <ToolbarItem>
                                <Select
                                    defaultValue={role}
                                    onChange={onRoleChange}
                                    style={{ width: 156 }}
                                >
                                    <Option value="doctor">
                                        {texts['users.clients'].value}
                                    </Option>
                                    <Option value="manager">
                                        {texts['users.managers'].value}
                                    </Option>
                                    <Option value="technician">
                                        {texts['users.technitians'].value}
                                    </Option>
                                    <Option value="otk">
                                        {texts['users.otk'].value}
                                    </Option>
                                </Select>
                            </ToolbarItem>
                        </RightPart>
                    </Col>
                </Row>
            </Toolbar>
        );
    }
}

export default DoctorTableToolbar;
