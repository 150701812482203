import React, { Component } from 'react';
import { orderBy } from 'lodash';
import { List, Popover } from 'antd';
import styled from 'styled-components';
import { isEmpty } from '../../utils/common';

const PopoverWithPointer = styled(Popover)`
    cursor: pointer;
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
    white-space: pre-wrap; */
`;

class DoctorOrganisationsPopover extends Component {
    render() {
        const { data: row, value } = this.props;
        if (isEmpty(value)) {
            return <div>-</div>;
        }
        const organisations = orderBy(value, 'name');

        const content = (
            <List
                size="small"
                dataSource={organisations}
                renderItem={(item) => <List.Item>{item.name}</List.Item>}
            />
        );
        // return (
        //     <PopoverWithPointer
        //         content={content}
        //         trigger="hover"
        //         placement="bottom"
        //     >
        //         {organisations.map((item) => item.name).join(', ')}
        //     </PopoverWithPointer>
        // );
        return (
            <span style={{ cursor: 'pointer' }}>
                {organisations.map((item) => item.name).join(', ')}
            </span>
        );
    }
}

export default DoctorOrganisationsPopover;
