import React, { Component, useEffect } from 'react';
import { Col, Row, Spin, Tabs } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import {
    loadOrders,
    onOrdersFiltersChanged,
    onOrdersSorterChanged,
    onOrdersSearchChanged
} from '../redux/actions';
import OrderCardList from '../components/Order/OrderCardList';
import OrderCardListToolbar from '../components/Order/OrderCardListToolbar';
import { isEmpty } from '../utils/common';

const { TabPane } = Tabs;

const TabsContainer = styled.div`
    background: #fff;
    border: 1px solid #e8e8e8;

    @media (max-width: 767px) {
        border: none;
    }
`;

const TabsBody = styled(TabPane)`
    padding: 4px 24px 24px;
`;

export const ClientPage = () => {
    // static getDerivedStateFromProps(props, state) {
    //     const { dispatch, doctor, pagination, sorter, status, search } = props;
    //     const result = {};

    //     if (isEmpty(doctor)) {
    //         return null;
    //     }

    //     if ((state.doctorId !== doctor.id)) {
    //         result.doctorId = doctor.id;
    //     }

    //     if (state.sorter !== sorter.field) {
    //         result.sorter = sorter.field;
    //     }

    //     if (!isEqual(state.status, status)) {
    //         result.status = status;
    //     }

    //     if (!isEqual(state.search, search)) {
    //         result.search = search;
    //     }

    //     if (!isEqual(result, {})) {
    //         dispatch(loadOrders(pagination, sorter));
    //         return result;
    //     }
    //     // when null is returned no update is made to the state
    //     return null;
    // }
    const dispatch = useDispatch();
    const onSortChange = (value: any) => {
        dispatch(onOrdersSorterChanged(value));
    };

    const onModeChange = (modeField: any) => {
        const mode = modeField.target.value;
        dispatch(onOrdersFiltersChanged(mode));
    };

    const onSearchChange = (search: any) => {
        dispatch(onOrdersSearchChanged(search));
    };

    const doctor = useSelector((state: any) => state.ProfileReducer.profile);
    const orders = useSelector((state: any) => state.OrderReducer.orders);
    const ordersLoading = useSelector(
        (state: any) => state.OrderReducer.ordersLoading
    );
    const search = useSelector((state: any) => state.OrderReducer.search);
    const status = useSelector((state: any) => state.OrderReducer.status);
    const sorter = useSelector((state: any) => state.OrderReducer.sorter);
    const pagination = useSelector(
        (state: any) => state.OrderReducer.pagination
    );
    const ordersText = useSelector(
        (state: any) => state.CommonReducer.texts['tab.orders'].value
    );
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    let mode = 'ACTIVE';
    if (isEmpty(status)) {
        mode = 'ALL';
    } else {
        mode = status.operator === 'eq' ? 'CLOSED' : 'ACTIVE';
    }

    useEffect(() => {
        if (doctor) {
            dispatch(loadOrders(pagination, sorter));
        }
    }, [sorter, pagination, mode]);

    if (doctor === null) {
        return <Spin />;
    }

    return (
        <Row gutter={16}>
            <Col lg={24} md={24}>
                <TabsContainer>
                    <Tabs defaultActiveKey="1">
                        <TabsBody tab={ordersText} key="1">
                            <OrderCardListToolbar
                                mode={mode}
                                texts={texts}
                                search={search}
                                sorter={sorter}
                                onSortChange={(value: any) =>
                                    onSortChange(value)
                                }
                                onSearchChange={(value: any) =>
                                    onSearchChange(value)
                                }
                                onModeChange={(value: any) =>
                                    onModeChange(value)
                                }
                            />
                            <OrderCardList
                                texts={texts}
                                scope="doctor"
                                orders={orders}
                                ordersLoading={ordersLoading}
                            />
                        </TabsBody>
                    </Tabs>
                </TabsContainer>
            </Col>
        </Row>
    );
};
