// import { createSelector } from 'reselect';

/**
 * Direct selector to the languageToggle state domain
 */

const selectProfile = (state) => state.ProfileReducer.profile;
const selectMessageImage = (state) => state.MessageReducer.image;
const selectInternalMessageImage = (state) =>
    state.MessageReducer.internalImage;
const selectUserId = (state) => state.profile.id;
const selectToken = (state) => state.AuthReducer.token;
const selectRefreshToken = (state) => state.AuthReducer.refreshToken;
const selectPagination = (state) => state.OrderReducer.pagination;
const selectSorter = (state) => state.OrderReducer.sorter;
const selectFilters = (state) => state.OrderReducer.filters;
const selectOrderState = (state) => state.OrderReducer;
const selectUsersState = (state) => state.UserReducer;
const selectOrdersState = (state) => state.OrdersReducer;

export default selectToken;
export {
    selectOrdersState,
    selectUsersState,
    selectOrderState,
    selectToken,
    selectRefreshToken,
    selectUserId,
    selectPagination,
    selectSorter,
    selectFilters,
    selectProfile,
    selectMessageImage,
    selectInternalMessageImage
};
