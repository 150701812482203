import React from 'react';
import { Badge } from 'antd';
import { ICellRendererParams } from 'ag-grid-community';

export const CampaignStatusRenderer = (props: ICellRendererParams) => {
    // @ts-ignore
    const { value, yesText, noText } = props;

    // online: '#41ba92',
    // 'в сети': '#41ba92',
    // offline: '#ced3d9',
    // 'не в сети': '#ced3d9'
    return (
        <Badge
            color={value ? '#41ba92' : '#ced3d9'}
            text={value ? yesText : noText}
        />
    );
};
