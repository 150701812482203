import React, { Component } from 'react';
import { Modal, Button, PageHeader, Icon, Card, List, Tooltip } from 'antd';
import { filter } from 'lodash';
import { Player, BigPlayButton } from 'video-react';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import { isEmpty } from '../../utils/common';

// import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const StyledCard = styled(Card)`
    .ant-card-head-title {
        font-weight: 400;
    }
`;

const VideoButton = styled(Button)`
    height: 64px !important;
    width: 128px !important;
    font-size: 46px !important;
`;

const StyledCardBody = styled.div`
    height: 240px;
    background-image: url(${(props) => props.img});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

const StyledCardVideoBody = styled.div`
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledHeader = styled(PageHeader)`
    padding-left: 0;

    .ant-page-header-heading-title {
        font-weight: 500;
    }
`;

class OrderImageGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
            isVideoOpen: false
        };
    }

    render() {
        const { items, title, video } = this.props;
        const { isOpen, isVideoOpen, photoIndex } = this.state;

        const images = filter(items, (item) => !isEmpty(item.original));

        if (images.length === 0 && isEmpty(video)) {
            return null;
        }

        return (
            <div>
                <StyledHeader title={title} />
                {/* <ImageGallery useBrowserFullscreen={false} items={items} /> */}
                {images.length > 0 && (
                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2
                        }}
                        dataSource={images}
                        renderItem={(item) => (
                            <List.Item>
                                <StyledCard
                                    size="small"
                                    title={
                                        <Tooltip title={item.title}>
                                            {item.title}
                                        </Tooltip>
                                    }
                                    extra={
                                        <Icon
                                            type="fullscreen"
                                            onClick={() =>
                                                this.setState({
                                                    isOpen: true,
                                                    photoIndex: item.index
                                                })
                                            }
                                        />
                                    }
                                >
                                    <StyledCardBody
                                        img={item.original.replace(
                                            '/uploads/',
                                            '/uploads/thumbnail_'
                                        )}
                                    />
                                </StyledCard>
                            </List.Item>
                        )}
                    />
                )}
                {video && (
                    <>
                        <List
                            grid={{
                                gutter: 16,
                                xs: 1,
                                sm: 2
                            }}
                            dataSource={[video]}
                            renderItem={() => (
                                <List.Item>
                                    <StyledCard
                                        size="small"
                                        title="Видео проверки"
                                    >
                                        <StyledCardVideoBody>
                                            <VideoButton
                                                onClick={() =>
                                                    this.setState({
                                                        isVideoOpen: true
                                                    })
                                                }
                                                shape="round"
                                                type="link"
                                                icon="play-square"
                                                size="large"
                                            />
                                        </StyledCardVideoBody>
                                    </StyledCard>
                                </List.Item>
                            )}
                        />
                        <Modal
                            title="Видео проверки"
                            visible={isVideoOpen}
                            destroyOnClose
                            width={800}
                            height={600}
                            footer={null}
                            onCancel={() =>
                                this.setState({
                                    isVideoOpen: false
                                })
                            }
                        >
                            <Player playsInline autoPlay src={video}>
                                <BigPlayButton position="center" />
                            </Player>
                        </Modal>
                    </>
                )}
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex].original}
                        nextSrc={
                            images[(photoIndex + 1) % images.length].original
                        }
                        prevSrc={
                            images[
                                (photoIndex + images.length - 1) % images.length
                            ].original
                        }
                        imageTitle={images[photoIndex].title}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex:
                                    (photoIndex + images.length - 1) %
                                    images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </div>
        );
    }
}

export default OrderImageGallery;
