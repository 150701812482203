import React, { useState, useEffect, useDebugValue } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import fetch from 'isomorphic-unfetch';
import { useQuery, useQueryClient } from 'react-query';
import moment from 'moment';
import { IGetRowsParams, GridApi, ColumnApi } from 'ag-grid-community';
import { GATEWAY_HOST, GRID_CONFIG_KEY } from '../../utils/properties';
import {
    getStatusFilters,
    getVintsFilters,
    getTemplateFilters,
    getToolFilters
} from '../../utils/status';
import { StatusRenderer } from '../Renderer/StatusRenderer';
import { DateRenderer } from '../Renderer/DateRenderer';
import { ClientLinkRenderer } from '../Renderer/ClientLinkRenderer';
import { UsernameLinkRenderer } from '../Renderer/UsernameLinkRenderer';
import { UsernameRenderer } from '../Renderer/UsernameRenderer';
import { OrderLinkRenderer } from '../Renderer/OrderLinkRenderer';
import { OrderStatusRenderer } from '../Renderer/OrderStatusRenderer';
import { OrderVersionsRenderer } from '../Renderer/OrderVersionsRenderer';
import { ColumnListArrayFilter } from '../Filter/list-array-filter';
import { ColumnTextFilter } from '../Filter/text-filter';
import { localeText } from '../../utils/grid-locale';
import {
    selectOrder,
    deleteOrder,
    openNewOrder,
    selectOrderBase
} from '../../redux/actions';
import { usePersistedState } from '../../utils/usePersistentState';
import { DateFilter } from '../Filter/date-filter';

const { confirm } = Modal;

export interface SortModelItem {
    colId: string;
    sort: 'asc' | 'desc';
}

export interface InFilterValue {
    operator: 'in';
    value: string[];
    property: string;
}

export interface LikeFilterValue {
    operator: 'like';
    value: string;
    property: string;
}

export interface EqFilterValue {
    operator: 'eq';
    value: string;
    property: string;
}

export interface DateFilterValue {
    operator: 'lt' | 'gt';
    value: number;
    property: string;
}

export type TableFilter =
    | InFilterValue
    | LikeFilterValue
    | DateFilterValue
    | EqFilterValue;

export type TableFilters = string[];

export const OrderTable = (props: any) => {
    const { onEditDateClick, updated } = props;
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const token = useSelector((state: any) => state.AuthReducer.token);
    const role = useSelector(
        (state: any) => state.ProfileReducer.profile.role.name
    );
    const ordersLoading = useSelector(
        (state: any) => state.OrdersReducer.ordersLoading
    );
    const searchValue = useSelector((state: any) => state.OrdersReducer.search);
    const selectedTech = useSelector(
        (state: any) => state.OrdersReducer.selectedTech
    );
    const selectedManager = useSelector(
        (state: any) => state.OrdersReducer.selectedManager
    );
    const [dataIsLoaded, setDataIsLoaded] = useState(false);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);
    const [columnState, setColumnState] = useState<any>(null);
    const pageSize = 50;

    const { isLoading, data: rowData } = useQuery(
        ['orders-table-config'],
        () =>
            new Promise((resolve: any) => {
                console.log('OrderTable');
                resolve(localStorage.getItem(GRID_CONFIG_KEY));
            }).then((res: any) => JSON.parse(res)),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true
        }
    );

    const onEditClick = (e: any, record: any) => {
        dispatch(selectOrder(record.id, 'latest'));
        dispatch(openNewOrder(record.id));
    };

    const onEditDateClickHandler = (e: any, record: any) => {
        onEditDateClick(record.id);
    };

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: texts['order.delete.confirmation'].value,
            content: texts['order.delete.confirmation.details'].value,
            okText: texts.yes.value,
            okType: 'danger',
            cancelText: texts.no.value,
            onOk() {
                dispatch(deleteOrder(record.id));
            }
        });
    };

    const onDeleteClick = (e: any, record: any) => {
        showDeleteConfirm(record);
    };

    const onNewVersionClick = (e: any, record: any) => {
        dispatch(selectOrderBase(record.id, 'latest'));
        dispatch(openNewOrder(record.id, true));
    };

    const calculateColumnState = (persistedState: any) => {
        const defaultColumnState = [
            'bill',
            'search',
            'patient',
            'deadline',
            'status',
            'status_minivints',
            'status_template',
            'status_equipment',
            'status_equipment_2d',
            'status_braces',
            'status_aligners',
            'status_splint',
            'latest',
            'technician',
            'manager',
            'orderversions',
            'organisation'
        ];
        const currentColumns: any = {
            bill: {
                headerName: texts['order.bill'].value,
                field: 'bill',
                colId: 'bill',
                // minWidth: 150,
                resizable: true,
                sortable: true,
                filter: 'textFilter',
                filterParams: {
                    texts
                },
                cellRenderer: 'orderLinkRenderer',
                cellRendererParams: { role }
            },
            search: {
                headerName: texts['order.doctor'].value,
                field: 'doctor',
                colId: 'search',
                sorter: true,
                // minWidth: 150,
                resizable: true,
                sortable: true,
                filter: 'textFilter',
                filterParams: {
                    texts
                },
                cellRenderer: 'usernameLinkRenderer',
                cellRendererParams: { role }
            },
            patient: {
                headerName: texts['order.patient'].value,
                field: 'patient',
                colId: 'patient',
                sorter: true,
                // minWidth: 150,
                resizable: true,
                sortable: true,
                filter: 'textFilter',
                filterParams: {
                    texts
                },
                cellRenderer: 'orderLinkRenderer',
                cellRendererParams: { role }
            },
            deadline: {
                headerName: texts['order.deadline'].value,
                field: 'deadline',
                colId: 'deadline',
                resizable: true,
                sortable: true,
                filter: 'dateFilter',
                filterParams: {
                    texts
                },
                cellRenderer: 'dateRenderer'
            },
            status: {
                headerName: texts.status.value,
                field: 'status',
                colId: 'status',
                resizable: true,
                sortable: true,
                filter: 'listFilter',
                filterParams: {
                    values: getStatusFilters(texts),
                    texts
                },
                cellRenderer: 'orderStatusRenderer',
                cellRendererParams: { texts }
            },
            status_minivints: {
                headerName: texts.minivints.value,
                field: 'status_minivints',
                colId: 'status_minivints',
                resizable: true,
                sortable: true,
                filter: 'listFilter',
                filterParams: {
                    values: getVintsFilters(texts),
                    texts
                },
                cellRenderer: 'orderStatusRenderer',
                cellRendererParams: { texts }
            },
            status_template: {
                headerName: texts.template.value,
                field: 'status_template',
                colId: 'status_template',
                resizable: true,
                sortable: true,
                filter: 'listFilter',
                filterParams: {
                    values: getTemplateFilters(texts),
                    texts
                },
                cellRenderer: 'orderStatusRenderer',
                cellRendererParams: { texts }
            },
            status_equipment: {
                headerName: texts.equipment.value,
                field: 'status_equipment',
                colId: 'status_equipment',
                resizable: true,
                sortable: true,
                filter: 'listFilter',
                filterParams: {
                    values: getToolFilters(texts),
                    texts
                },
                cellRenderer: 'orderStatusRenderer',
                cellRendererParams: { texts }
            },
            status_equipment_2d: {
                headerName: texts.equipment2d.value,
                field: 'status_equipment_2d',
                colId: 'status_equipment_2d',
                resizable: true,
                sortable: true,
                filter: 'listFilter',
                filterParams: {
                    values: getToolFilters(texts),
                    texts
                },
                cellRenderer: 'orderStatusRenderer',
                cellRendererParams: { texts }
            },
            status_braces: {
                headerName: texts.braces.value,
                field: 'status_braces',
                colId: 'status_braces',
                resizable: true,
                sortable: true,
                filter: 'listFilter',
                filterParams: {
                    values: getToolFilters(texts),
                    texts
                },
                cellRenderer: 'orderStatusRenderer',
                cellRendererParams: { texts }
            },
            status_aligners: {
                headerName: texts.aligners.value,
                field: 'status_aligners',
                colId: 'status_aligners',
                resizable: true,
                sortable: true,
                filter: 'listFilter',
                filterParams: {
                    values: getToolFilters(texts),
                    texts
                },
                cellRenderer: 'orderStatusRenderer',
                cellRendererParams: { texts }
            },
            status_splint: {
                headerName: texts.splint.value,
                field: 'status_splint',
                colId: 'status_splint',
                resizable: true,
                sortable: true,
                filter: 'listFilter',
                filterParams: {
                    values: getToolFilters(texts),
                    texts
                },
                cellRenderer: 'orderStatusRenderer',
                cellRendererParams: { texts }
            },
            latest: {
                headerName: texts['order.date.latest'].value,
                field: 'latest',
                colId: 'latest',
                resizable: true,
                sortable: true,
                filter: 'dateFilter',
                filterParams: {
                    texts
                },
                cellRenderer: 'dateRenderer'
            },
            technician: {
                headerName: texts['role.technician'].value,
                field: 'technician',
                colId: 'technician',
                sorter: true,
                minWidth: 150,
                resizable: true,
                // sortable: true,
                filter: 'textFilter',
                filterParams: {
                    texts
                },
                hide: true,
                cellRenderer: 'usernameRenderer'
            },
            manager: {
                headerName: texts['role.manager'].value,
                field: 'manager',
                colId: 'manager',
                sorter: true,
                minWidth: 150,
                resizable: true,
                // sortable: true,
                filter: 'textFilter',
                filterParams: {
                    texts
                },
                cellRenderer: 'usernameRenderer'
            },
            orderversions: {
                headerName: texts['version.count'].value,
                field: 'orderversions',
                colId: 'orderversions',
                resizable: true,
                sortable: false,
                minWidth: 100,
                cellRenderer: 'orderVersionsRenderer',
                cellRendererParams: {
                    texts,
                    onEditClick,
                    onEditDateClick: onEditDateClickHandler,
                    onNewVersionClick,
                    onDeleteClick,
                    scope: role
                },
                pinned: 'right'
            },
            organisation: {
                headerName: texts['profile.organisation'].value,
                field: 'organisation',
                colId: 'organisation',
                resizable: true,
                sortable: true,
                filter: 'textFilter',
                filterParams: {
                    texts
                }
            }
        };

        const newState = [];

        if (persistedState && persistedState.length > 0) {
            for (let i = 0; i < persistedState.length; i += 1) {
                const column = currentColumns[persistedState[i].colId];
                newState.push({
                    ...column,
                    width: persistedState[i].width,
                    hide: persistedState[i].hide
                });
            }
        } else {
            for (let i = 0; i < defaultColumnState.length; i += 1) {
                const column = currentColumns[defaultColumnState[i]];
                newState.push({
                    ...column
                });
            }
        }
        return newState;
    };

    const [columns, setColumns] = useState<any[]>([]);
    useEffect(() => {
        if (!isLoading) {
            setColumns(calculateColumnState(rowData));
        }
    }, [isLoading, rowData]);

    useEffect(() => {
        if (columnState) {
            window.localStorage.setItem(
                GRID_CONFIG_KEY,
                JSON.stringify(columnState)
            );
            queryClient.refetchQueries('orders-table-config-settings');
        }
    }, [columnState]);

    useEffect(() => {
        if (dataIsLoaded) {
            // columnApi?.autoSizeAllColumns();
        }
    }, [columnApi, dataIsLoaded]);

    useEffect(() => {
        if (gridApi && ordersLoading) {
            gridApi.onFilterChanged();
        }
    }, [gridApi, ordersLoading]);

    useEffect(() => {
        if (updated && gridApi) {
            gridApi.onFilterChanged();
        }
    }, [gridApi, updated]);

    useEffect(() => {
        if (gridApi && columnApi) {
            // @ts-ignore
            // if (columnApi.getColumn('latest')) {
            // @ts-ignore
            columnApi.getColumn('latest').setSort('desc');
            // }
            gridApi.getFilterInstance('status', (filter) => {
                filter.setModel([
                    'NEW',
                    'UNPAID',
                    'REWORK',
                    'PAID',
                    'READYTOSEND',
                    'READY'
                ]);
                gridApi.onFilterChanged();
            });
        }
    }, [gridApi, role, columnApi]);

    useEffect(() => {
        if (gridApi && columnApi) {
            gridApi.getFilterInstance('search', (filter) => {
                filter.setModel(searchValue);
                gridApi.onFilterChanged();
            });
        }
    }, [searchValue]);

    useEffect(() => {
        if (gridApi && columnApi) {
            gridApi.getFilterInstance('technician', (filter) => {
                filter.setModel(selectedTech);
                gridApi.onFilterChanged();
            });
        }
    }, [selectedTech]);

    useEffect(() => {
        if (gridApi && columnApi) {
            gridApi.getFilterInstance('manager', (filter) => {
                filter.setModel(selectedManager);
                gridApi.onFilterChanged();
            });
        }
    }, [selectedManager]);

    const getRows = async (params: IGetRowsParams) => {
        const sort = params.sortModel.map((sorter: SortModelItem) => {
            return `${sorter.colId}:${sorter.sort.toUpperCase()}`;
        });
        const filter: TableFilters = [];

        Object.keys(params.filterModel).forEach((property) => {
            if (
                property === 'status' ||
                property === 'status_minivints' ||
                property === 'status_template' ||
                property === 'status_equipment' ||
                property === 'status_equipment_2d' ||
                property === 'status_braces' ||
                property === 'status_aligners'
            ) {
                Object.keys(params.filterModel[property].values).forEach(
                    (item) => {
                        filter.push(`${property}_in=${item}`);
                    }
                );
            } else if (property === 'technician' || property === 'manager') {
                if (params.filterModel[property].value === 'none') {
                    filter.push(`${property}_null=true`);
                } else if (params.filterModel[property].value !== 'all') {
                    filter.push(
                        `${property}=${params.filterModel[property].value}`
                    );
                }
            } else if (property === 'deadline' || property === 'latest') {
                if (params.filterModel[property]) {
                    const startDate = moment(
                        params.filterModel[property].startOf('day')
                    );
                    const endDate = moment(
                        params.filterModel[property].startOf('day')
                    );
                    endDate.add(1, 'days');
                    filter.push(
                        `${property}_gt=${startDate.format('YYYY-MM-DD')}`
                    );
                    filter.push(
                        `${property}_lt=${endDate.format('YYYY-MM-DD')}`
                    );
                }
            } else {
                filter.push(
                    `${property}_contains=${params.filterModel[property].value}`
                );
            }
        });

        const filterParam = filter.join('&');

        const sortParam = sort.length ? `&_sort=${sort.join(',')}` : '';
        const res = await fetch(
            // `${GATEWAY_HOST}/users?${pagination}${sorter}${filter}`,
            `${GATEWAY_HOST}/orders?${filterParam}${sortParam}&_limit=${params.endRow -
                params.startRow}&_start=${params.startRow}&status_nin=DRAFT`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        const data = await res.json();
        const res2 = await fetch(
            `${GATEWAY_HOST}/orders/count?${filterParam}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        const count = await res2.json();

        params.successCallback(data, count);
        setDataIsLoaded(true);
    };

    const frameworkComponents = {
        statusRenderer: StatusRenderer,
        dateRenderer: DateRenderer,
        listFilter: ColumnListArrayFilter,
        dateFilter: DateFilter,
        textFilter: ColumnTextFilter,
        // listRenderer: DoctorOrganisationsPopover,
        clientLinkRenderer: ClientLinkRenderer,
        orderLinkRenderer: OrderLinkRenderer,
        orderStatusRenderer: OrderStatusRenderer,
        orderVersionsRenderer: OrderVersionsRenderer,
        usernameLinkRenderer: UsernameLinkRenderer,
        usernameRenderer: UsernameRenderer
        // orgTooltip: DoctorOrganisationsTooltip
    };

    return (
        <div
            className="ag-theme-alpine"
            style={{
                height: '100%',
                width: '100%'
            }}
        >
            {columns.length > 0 && (
                <AgGridReact
                    localeText={localeText}
                    columnDefs={columns}
                    // reactUi
                    // enableBrowserTooltips
                    multiSortKey="ctrl"
                    animateRows
                    enableCellTextSelection
                    pagination
                    paginationAutoPageSize={false}
                    paginationPageSize={pageSize}
                    // infiniteInitialRowCount={pageSize}
                    cacheOverflowSize={pageSize}
                    rowBuffer={pageSize}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={1}
                    maxBlocksInCache={2}
                    // onFilterChanged={(e) => {
                    //     const searchColumnFilterInstance = e.api.getFilterInstance(
                    //         searchField
                    //     );
                    //     const model = searchColumnFilterInstance
                    //         ? searchColumnFilterInstance.getModel()
                    //         : null;
                    //     const newValue = model ? model.value : '';
                    //     setSearchValue(newValue);
                    // }}
                    onGridReady={(e) => {
                        setGridApi(e.api);
                        setColumnApi(e.columnApi);
                    }}
                    onColumnResized={(e: any) => {
                        if (e.finished) {
                            const state = e.columnApi
                                .getColumnState()
                                .map((item: any) => {
                                    return {
                                        colId: item.colId,
                                        width: item.width,
                                        hide: item.hide
                                    };
                                });
                            setColumnState(state);
                        }
                    }}
                    onColumnVisible={(e: any) => {
                        const state = e.columnApi
                            .getColumnState()
                            .map((item: any) => {
                                return {
                                    colId: item.colId,
                                    width: item.width,
                                    hide: item.hide
                                };
                            });
                        setColumnState(state);
                    }}
                    onColumnMoved={(e: any) => {
                        const state = e.columnApi
                            .getColumnState()
                            .map((item: any) => {
                                return {
                                    colId: item.colId,
                                    width: item.width,
                                    hide: item.hide
                                };
                            });
                        setColumnState(state);
                    }}
                    // onDragStopped={(e) => {
                    //     updateColumns(e.columnApi.getAllGridColumns());
                    // }}
                    // onFirstDataRendered={(e) => {
                    //     e.columnApi.autoSizeAllColumns();
                    // }}
                    frameworkComponents={frameworkComponents}
                    // // TODO: strange error appeared when use custom Loading
                    // // loadingOverlayComponent="customLoadingOverlay"
                    // noRowsOverlayComponent="customEmptyOverlay"
                    // noRowsOverlayComponentParams={{
                    //     image: Empty.PRESENTED_IMAGE_SIMPLE
                    // }}
                    // suppressPaginationPanel
                    // onPaginationChanged={() => {
                    //     if (gridApi) {
                    //         setTotal(gridApi.paginationGetRowCount());
                    //     }
                    // }}
                    // rowData={null}
                    rowModelType="infinite"
                    datasource={{
                        getRows
                    }}
                    tooltipShowDelay={0}
                    tooltipMouseTrack
                    // cacheOverflowSize={2}
                    // maxConcurrentDatasourceRequests={2}
                    // infiniteInitialRowCount={1}
                    // maxBlocksInCache={2}
                    cacheBlockSize={pageSize}
                />
            )}
        </div>
    );
};
