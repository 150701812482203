import React, { Component } from 'react';

class NumberedLink extends Component {
    render() {
        const { text } = this.props;
        return (
            <div>
                {`- ${text}`}
            </div>
        );
    }
}

export default NumberedLink;
