import React from 'react';

export const StatusDescriptionNoProvider = (props: any) => {
    const { item, role, texts } = props;

    let result = <div>-</div>;

    if (item.order) {
        const orderLink = `/${role}/order-details?id=${item.order.id}&revision=latest&notification=${item.id}`;
        result = (
            <>
                <div>
                    {`${texts['order.patient'].value}: `}
                    <a href={orderLink} target="_blank" rel="noreferrer">
                        {item.order ? item.order.patient : ''}
                    </a>
                    {`; ${texts['order.bill'].value}: `}
                    <a href={orderLink} target="_blank" rel="noreferrer">
                        {item.order.bill ? item.order.bill : '-'}
                    </a>
                </div>
                {role !== 'doctor' && (
                    <div>{`${texts.user.value}: ${item.from}`}</div>
                )}
            </>
        );
    }

    return result;
};
