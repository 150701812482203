import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    Button,
    Comment,
    Empty,
    Form,
    Icon,
    Input,
    List,
    PageHeader,
    Modal
} from 'antd';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { orderBy, trim } from 'lodash';
import { saveMessage, changeUploadMessageImage } from '../../redux/actions';
import OrderAuthor from '../Order/OrderAuthor';
import SimpleUpload from '../NewOrder/SimpleUpload';
import OrderImageGallery from '../Order/OrderImageGallery';
import { GATEWAY_HOST } from '../../utils/properties';
import { isEmpty } from '../../utils/common';

const MessagesContainer = styled.div`
    background: #fff;
    padding: 8px 16px;
    border: 1px solid #e8e8e8 !important;
    position: relative;
    overflow: hidden;
    height: 100%;
`;

const EmptyIcon = styled(Icon)`
    svg {
        width: 64px;
        height: auto;
    }
`;

const ChatIcon = styled(Icon)`
    svg {
        width: 32px;
        height: auto;
    }
`;

const EmptyBlock = styled(Empty)`
    margin: 32px 0;
    .ant-empty-image {
        height: auto;
    }
`;

const CommentStyled = styled(Comment)`
    .ant-comment-content-author {
        height: 38px;
    }
`;

const CommentFixed = styled(Comment)`
    position: absolute;
    bottom: 0;
    right: 16px;
    left: 16px;
`;

const CommentList = styled(List)`
    overflow: scroll;
    position: absolute;
    bottom: 186px;
    top: 64px;
    right: 16px;
    left: 16px;
`;

const { TextArea } = Input;

const SubmitButton = styled(Form.Item)`
    width: 101px;
    position: absolute;
    right: 24px;

    .ant-form-item-control {
        line-height: 1;
    }
`;

const SubmitForm = styled(Form.Item)`
    position: absolute;
    right: 148px;
    left: 0;
`;

const EditorInnerContainer = styled.div`
    position: relative;
    height: 156px;
`;

const TextAreaFixed = styled(TextArea)`
    resize: none;
`;

const Editor = ({
    onChange,
    onPressEnter,
    onSubmit,
    submitting,
    value,
    title,
    texts,
    image,
    token,
    onHandleChangeImage,
    onHandlePreview
}) => (
    <EditorInnerContainer>
        <SubmitForm>
            <TextAreaFixed
                rows={7}
                onChange={onChange}
                value={value}
                onPressEnter={onPressEnter}
            />
        </SubmitForm>
        <SubmitButton>
            <SimpleUpload
                texts={texts}
                image={image}
                handleChangeImage={onHandleChangeImage}
                handlePreview={onHandlePreview}
                token={token}
            />
            <Button
                disabled={isEmpty(trim(value)) && isEmpty(image)}
                htmlType="submit"
                loading={submitting}
                onClick={onSubmit}
                type="primary"
            >
                {title}
            </Button>
        </SubmitButton>
    </EditorInnerContainer>
);

const EmptyBlockInitial = styled(Empty)`
    .ant-empty-image {
        height: auto;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const EmptyBlockContainer = styled.div`
    position: relative;
    background: #fff;
    border: 1px solid #e8e8e8 !important;
    height: 100%;
`;

class MessageComments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            previewVisible: false,
            previewImage: ''
        };
    }

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true
        });
    };

    handleCancel = () => {
        this.setState({
            previewImage: '',
            previewVisible: false
        });
    };

    onHandleChangeImage = (p) => {
        const { file, fileList } = p;
        const { dispatch } = this.props;
        dispatch(changeUploadMessageImage(fileList, file.status));
    };

    handleSubmit = () => {
        const { dispatch, order } = this.props;
        const { value } = this.state;

        let author = '';

        if (!isEmpty(order.doctor.last_name)) {
            author = `${order.doctor.last_name}`;
        }

        if (!isEmpty(order.doctor.first_name)) {
            author = ` ${author} ${order.doctor.first_name}`;
        }

        if (!isEmpty(order.doctor.middle_name)) {
            author = ` ${author}${order.doctor.middle_name}`;
        }

        dispatch(
            saveMessage(
                order.id,
                'latest',
                value,
                `${author}`,
                moment.utc().format(),
                order.doctor.id
            )
        );
        this.setState({
            value: ''
        });
    };

    handleChange = (e) => {
        this.setState({
            value: e.target.value
        });
    };

    handlePressEnter = (e) => {
        const { value } = this.state;

        if ((e.ctrlKey || e.metaKey) && !isEmpty(trim(value))) {
            this.handleSubmit();
        }
    };

    render() {
        const { value, previewVisible, previewImage } = this.state;
        const {
            messageIsSending,
            order,
            texts,
            scope,
            image,
            token
        } = this.props;

        if (isEmpty(order)) {
            return (
                <EmptyBlockContainer>
                    <EmptyBlockInitial
                        description={texts['messages.select.chat'].value}
                        image={
                            <EmptyIcon
                                type="wechat"
                                style={{ color: '#3C9FD9' }}
                            />
                        }
                    />
                </EmptyBlockContainer>
            );
        }

        const data = orderBy(order.messages, ['date'], ['asc']);
        let content = (
            <EmptyBlock
                description={texts['order.feedback.start'].value}
                image={
                    <EmptyIcon type="message" style={{ color: '#3C9FD9' }} />
                }
            />
        );
        if (data.length > 0) {
            content = (
                <CommentList
                    className="comment-list"
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item) => {
                        let color =
                            item.role === 'doctor' ? '#3C9FD9' : '#3CBB9E';
                        if (item.role === 'technician' || item.role === 'otk') {
                            color = '#eb2f96';
                        }
                        let messageImage;
                        if (item.image) {
                            const images = [
                                {
                                    original: `${GATEWAY_HOST}/uploads/${item.image}`,
                                    thumbnail: `${GATEWAY_HOST}/uploads/${item.image}`,
                                    title: '',
                                    index: 0
                                }
                            ];
                            messageImage = <OrderImageGallery items={images} />;
                        }
                        return (
                            <li>
                                <CommentStyled
                                    key={item.id}
                                    actions={item.actions}
                                    author={
                                        <OrderAuthor
                                            author={item.author}
                                            role={
                                                texts[`role.${item.role}`].value
                                            }
                                        />
                                    }
                                    avatar={
                                        <ChatIcon
                                            type="message"
                                            theme="twoTone"
                                            twoToneColor={color}
                                        />
                                    }
                                    content={item.comment}
                                    datetime={moment(item.date).format(
                                        'DD/MM/YY HH:mm:ss'
                                    )}
                                />
                                {messageImage}
                            </li>
                        );
                    }}
                />
            );
        }

        const orderHref = `/${scope}/order-details?id=${order.id}&revision=latest`;

        return (
            <MessagesContainer>
                <PageHeader
                    extra={[
                        <NavLink key="1" to={orderHref}>
                            <Button>{texts['order.goto'].value}</Button>
                        </NavLink>
                    ]}
                />
                {content}
                <CommentFixed
                    content={
                        <Editor
                            onChange={this.handleChange}
                            onSubmit={this.handleSubmit}
                            onHandlePreview={this.handlePreview}
                            onHandleChangeImage={this.onHandleChangeImage}
                            onPressEnter={this.handlePressEnter}
                            submitting={messageIsSending}
                            value={value}
                            title={texts['order.feedback.add'].value}
                            texts={texts}
                            token={token}
                            image={image}
                        />
                    }
                />
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img
                        alt="example"
                        style={{ width: '100%', height: 'auto' }}
                        src={previewImage}
                    />
                </Modal>
            </MessagesContainer>
        );
    }
}

function mapStateToProps(state) {
    return {
        messageIsSending: state.MessageReducer.messageIsSending,
        image: state.MessageReducer.image,
        isUploading: state.MessageReducer.isUploading,
        token: state.AuthReducer.token,
        order: state.MessageReducer.selectedOrder,
        texts: state.CommonReducer.texts,
        scope: state.ProfileReducer.profile.role.name
    };
}

export default connect(mapStateToProps)(MessageComments);
