import React from 'react';
import { Card } from 'antd';
import styled from 'styled-components';

const StyledCard = styled(Card)`
    margin-top: 16px;
    .ant-card-head-title {
        font-weight: 400;
    }
`;

const StyledCardBody = styled.div`
    height: 600px;
    width: 100%;
    padding: 0;
`;

export const OrderGifCard = (props: any) => {
    const { title, orderId, revisionId, mode } = props;
    return (
        <StyledCard size="small" title={title}>
            <StyledCardBody>
                <iframe
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    title="gif-3d"
                    src={`/gif?id=${orderId}&revision=${revisionId}&mode=${mode}`}
                />
            </StyledCardBody>
        </StyledCard>
    );
};
