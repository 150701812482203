import React, { Component } from 'react';
import { Button, Layout } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import RightContent from './RightContent';
import { isEmpty } from '../../utils/common';

const { Header } = Layout;
const HeaderStyled = styled(Header)`
    background: #fff;
    padding: 0 16px 0 24px;
    border-bottom: 1px solid rgb(232, 232, 232);
`;

class GlobalHeader extends Component {
    render() {
        const { backTitle, backHref } = this.props;
        let BackLink = <></>;
        if (!isEmpty(backHref)) {
            BackLink = (
                <Link to={backHref}>
                    <Button icon="left" hidden={backHref === null}>
                        {backTitle}
                    </Button>
                </Link>
            );
        }
        return (
            <HeaderStyled>
                {BackLink}
                <RightContent />
            </HeaderStyled>
        );
    }
}

export default GlobalHeader;
