import { combineReducers } from 'redux';
import AuthReducer from './auth';
import CommonReducer from './common';
import DoctorReducer from './doctor';
import MessageReducer from './message';
import OrderReducer from './order';
import OrdersReducer from './orders';
import ProfileReducer from './profile';
import UserReducer from './user';
import NotificationReducer from './notification';

export const primaryInitialState = {};

export default combineReducers({
    AuthReducer,
    CommonReducer,
    DoctorReducer,
    MessageReducer,
    NotificationReducer,
    OrderReducer,
    OrdersReducer,
    ProfileReducer,
    UserReducer
});
