import { actionTypes } from '../actions';

export const primaryInitialState = {
    doctors: [],
    doctorsLoading: false,
    pagination: null,
    sorter: null,
    filters: null,
    doctorsCount: null,
    selectedDoctor: null
};

const DoctorReducer = (state = primaryInitialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_DOCTOR: {
            return {
                ...state,
                doctorIsLoading: true,
                selectedDoctor: null
            };
        }
        case actionTypes.SELECT_DOCTOR_SUCCESS: {
            return {
                ...state,
                doctorIsLoading: false,
                selectedDoctor: action.data
            };
        }
        case actionTypes.LOAD_DOCTORS:
            return {
                ...state,
                ...{
                    doctorsLoading: true
                }
            };
        case actionTypes.LOAD_DOCTORS_SUCCESS:
            return {
                ...state,
                ...{
                    doctors: action.data,
                    doctorsLoading: false
                }
            };
        case actionTypes.LOAD_DOCTORS_COUNT_SUCCESS:
            return {
                ...state,
                ...{
                    doctorsCount: action.data
                }
            };
        default:
            return state;
    }
};

export default DoctorReducer;
