import React, { useEffect, useState } from 'react';
import qs from 'qs';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CenteredSpinner } from '../../components/Spinner/CenteredSpinner';
import { GATEWAY_HOST } from '../../utils/properties';
import { login, setUserData } from '../../utils/auth';
// import {
//     saveToken,
//     saveRefreshToken,
//     saveProfile,
//     loadUserData
// } from '../../redux/actions';

const SpinnerContainer = styled.div`
    height: 100vh;
`;

export const SignedInPage = () => {
    const params = qs.parse(window.location.search, {
        ignoreQueryPrefix: true
    });

    const newParams = {
        access_token: params.access_token
    };

    const requestParams = {
        method: 'GET'
        // mode: 'no-cors',
    };

    const url = `${GATEWAY_HOST}/auth/keycloak/callback?${qs.stringify(
        newParams
    )}`;

    const [user, setUser] = useState(null as any);

    const dispatch = useDispatch();

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            const response = await fetch(url, requestParams);
            const result = await response.json();
            if (result.jwt && isMounted) {
                // Set the user's credentials
                // setToken(result.jwt);
                // setUserInfo(result.user);
                login(result.jwt, params.refresh_token as string);
                setUserData(result.user);
                setUser(result.user);
                // dispatch(saveToken(result.jwt));
                // dispatch(saveRefreshToken(params.refresh_token));
                // dispatch(saveProfile(result.user));
                // dispatch(loadUserData(result.user.id));
            }
        };

        fetchData();
        return () => {
            isMounted = false;
        };
    }, [requestParams, url, params, dispatch]);

    let content = (
        <SpinnerContainer>
            <CenteredSpinner />
        </SpinnerContainer>
    );

    if (user) {
        let newLocation = '/';
        if (user.role.name === 'manager') {
            newLocation = '/manager/orders';
        } else if (user.role.name === 'technician') {
            newLocation = '/technician/orders';
        } else if (user.role.name === 'otk') {
            newLocation = '/otk/orders';
        }
        content = (
            <Redirect
                to={{
                    pathname: newLocation
                }}
            />
        );
    }

    return content;
};
