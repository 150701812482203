import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ruRU from 'antd/es/locale/ru_RU';
import WebFont from 'webfontloader';
import { ThemeProvider } from 'styled-components';
import createStore from './redux/store';
import { PrivateRoute } from './pages/protected-route';
// import SignedInPage from './pages/login/signed-in';
import { SignedInPage } from './pages/login/signed';
import { LoginPage } from './pages/login/login';
import { App } from './pages/app';
import theme from './theme/theme';
import './index.less';
import './App.less';
import 'react-image-lightbox/style.css';
import 'video-react/dist/video-react.css';
import { OrderPage as SOrderPage } from './pages/shared';
import { OrderPage as StlOrderPage } from './pages/stl';
import { GifPage } from './pages/gif';

WebFont.load({
    google: {
        families: ['Rubik:300,400,700', 'sans-serif']
    }
});

// import * as serviceWorker from './serviceWorker';

const store = createStore();

ReactDOM.render(
    <ConfigProvider locale={ruRU}>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Router>
                    <Switch>
                        <Route path="/login">
                            <LoginPage />
                        </Route>
                        <Route path="/auth/signed-in">
                            <SignedInPage />
                        </Route>
                        <Route path="/shared">
                            <SOrderPage />
                        </Route>
                        <Route path="/stl">
                            <StlOrderPage />
                        </Route>
                        <Route path="/gif">
                            <GifPage />
                        </Route>
                        <PrivateRoute path="*">
                            <App />
                        </PrivateRoute>
                    </Switch>
                </Router>
            </ThemeProvider>
        </Provider>
    </ConfigProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
