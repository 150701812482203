import React, { useState } from 'react';
import { Button, Input, Layout, Spin } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { CampaignTable } from '../../components/Campaign/CampaignTable';
import { CampaignCreationModal } from '../../components/Campaign/CampaignCreationModal';
import { CampaignPreviewModal } from '../../components/Campaign/CampaignPreviewModal';
import { GATEWAY_HOST } from '../../utils/properties';

const { Content } = Layout;
const { Search } = Input;

const ContentStyled = styled(Content)`
    /* padding: 16px; */
    /* background: #fff; */
    height: 100%;
`;

const Toolbar = styled.div`
    padding: 0;
    display: flex;
    justify-content: space-between;
    height: 36px;
`;

const TableContainer = styled.div`
    height: 100%;
`;

export const deleteCampaign = async (data: any) => {
    const { campaignId, jwt } = data;
    const method = 'DELETE';
    const url = `${GATEWAY_HOST}/campaigns/${campaignId}`;

    return fetch(url, {
        method,
        headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
        }
    });
};

export const CampaignPage = () => {
    const profile = useSelector((state: any) => state.ProfileReducer.profile);
    const token = useSelector((state: any) => state.AuthReducer.token);
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const [modalVisible, setModalVisible] = useState(false);
    const [previewId, setPreviewId] = useState<number | null>(null);
    const [selected, setSelected] = useState<number | undefined>(undefined);
    const queryClient = useQueryClient();

    const { mutate } = useMutation('delete-campaign', deleteCampaign, {
        onSuccess: () => {
            // setQueryEnabled(false);
            // setIsSaving(false);
            // resetData();
            // handleOk();
            // // queryClient.refetchQueries(['order-messages', orderId]);
            // setIsSending(false);
            // dispatch(onSaveMessageSuccess());
            queryClient.refetchQueries('campaigns-table');
        },
        onError: () => {
            // setIsSaving(false);
            // message.error('Возникла ошибка, попробуйте позже.');
            // setIsSending(false);
        },
        onSettled: () => {
            // queryClient.invalidateQueries('create');
        }
    });

    if (!profile) {
        return <Spin />;
    }

    return (
        <TableContainer>
            <ContentStyled>
                {/* <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Заказы</Breadcrumb.Item>
                </Breadcrumb> */}
                <Toolbar>
                    <Search
                        placeholder={texts['orders.search'].value}
                        style={{ width: 516 }}
                        enterButton
                        allowClear
                    />
                    <div>
                        <Button
                            type="primary"
                            onClick={() => {
                                setSelected(undefined);
                                setModalVisible(true);
                            }}
                        >
                            {texts['campaign.new'].value}
                        </Button>
                    </div>
                </Toolbar>
                <CampaignCreationModal
                    title={texts['campaign.new.title'].value}
                    visible={modalVisible}
                    handleOk={() => {
                        setModalVisible(false);
                        queryClient.refetchQueries('campaigns-table');
                    }}
                    handleCancel={() => {
                        setModalVisible(false);
                    }}
                    campaignId={selected}
                />
                <CampaignPreviewModal
                    title={texts.preview.value}
                    visible={previewId !== null}
                    handleOk={() => {
                        setPreviewId(null);
                    }}
                    handleCancel={() => {
                        setPreviewId(null);
                    }}
                    campaignId={previewId}
                />
                <CampaignTable
                    onEditClick={(e: any, record: any) => {
                        setSelected(record.id);
                        setModalVisible(true);
                    }}
                    onPreviewClick={(campaignId: number) => {
                        setPreviewId(campaignId);
                    }}
                    onDeleteClick={(e: any, record: any) => {
                        mutate({ campaignId: record.id, jwt: token });
                    }}
                />
            </ContentStyled>
        </TableContainer>
    );
};
