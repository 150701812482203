import React, { useEffect, useState } from 'react';
import { Button, Menu, Checkbox, Dropdown } from 'antd';
import styled from 'styled-components';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { GRID_CONFIG_KEY } from '../../utils/properties';

const ColumnItem = styled(Menu.Item)`
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: 24px !important;
    line-height: 24px !important;
`;

const ColumnList = (props: any) => {
    const { columns, onChange } = props;
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const mapping: any = {
        bill: 'order.bill',
        search: 'order.doctor',
        patient: 'order.patient',
        deadline: 'order.deadline',
        status: 'status',
        status_minivints: 'minivints',
        status_template: 'template',
        status_equipment: 'equipment',
        status_equipment_2d: 'equipment2d',
        status_braces: 'braces',
        status_aligners: 'aligners',
        status_splint: 'splint',
        latest: 'order.date.latest',
        technician: 'role.technician',
        manager: 'role.manager',
        orderversions: 'version.count',
        organisation: 'profile.organisation'
    };
    return (
        <Menu
            style={{
                padding: '16px 8px',
                boxShadow:
                    '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 6px 20px rgba(0, 0, 0, 0.15)'
            }}
        >
            {columns.map((item: any) => (
                <ColumnItem key={item.colId}>
                    <Checkbox
                        checked={!item.hide}
                        onChange={(value: any) => {
                            onChange(item.colId, value.target.checked);
                        }}
                    >
                        {texts[mapping[item.colId]].value}
                    </Checkbox>
                </ColumnItem>
            ))}
        </Menu>
    );
};

export const ColumnsPicker = (props: any) => {
    const queryClient = useQueryClient();
    const [columnState, setColumnState] = useState<any[]>([]);
    const { isLoading, data } = useQuery(
        ['orders-table-config-settings'],
        () =>
            new Promise((resolve: any) => {
                console.log('ColumnPicker');
                resolve(localStorage.getItem(GRID_CONFIG_KEY));
            }).then((res: any) => JSON.parse(res)),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true
        }
    );

    useEffect(() => {
        if (!isLoading) {
            if (data && data.length > 0) {
                setColumnState(data);
            } else {
                const defaultColumnState = [
                    'bill',
                    'search',
                    'patient',
                    'deadline',
                    'status',
                    'status_minivints',
                    'status_template',
                    'status_equipment',
                    'status_equipment_2d',
                    'status_braces',
                    'status_aligners',
                    'status_splint',
                    'latest',
                    'technician',
                    'manager',
                    'orderversions',
                    'organisation'
                ];
                const newState = [];
                for (let i = 0; i < defaultColumnState.length; i += 1) {
                    newState.push({
                        colId: defaultColumnState[i],
                        hide: false
                    });
                }
                setColumnState(newState);
            }
        }
    }, [isLoading, data]);

    const onColumnChange = (columnId: any, value: any) => {
        const newState = [];
        for (let i = 0; i < columnState.length; i += 1) {
            const newItem = { ...columnState[i] };
            if (columnId === columnState[i].colId) {
                newItem.hide = !value;
            }
            newState.push(newItem);
        }
        setColumnState(newState);
    };

    useEffect(() => {
        if (columnState) {
            window.localStorage.setItem(
                GRID_CONFIG_KEY,
                JSON.stringify(columnState)
            );

            queryClient.refetchQueries('orders-table-config');
        }
    }, [columnState]);

    return (
        <Dropdown
            overlay={
                <ColumnList columns={columnState} onChange={onColumnChange} />
            }
            trigger={['click']}
        >
            <Button
                loading={isLoading}
                style={{ width: 30, marginRight: 4 }}
                icon="setting"
            />
        </Dropdown>
    );
};
