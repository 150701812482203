// @ts-nocheck
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
// import grapesjs from 'grapesjs';
// import plugin from 'grapesjs-preset-newsletter';

export const CampaignDetails = () => {
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (mounted) {
            // const editor = grapesjs.init({
            //     container: '#gjs'
            //     // ...
            //     // plugins: [plugin],
            //     // pluginsOpts: {
            //     //   [plugin]: { /* options */ }
            //     // }
            //     // or
            //     // plugins: [
            //     //     (editorRed: any) =>
            //     //         plugin(editorRed, {
            //     //             /* options */
            //     //         })
            //     // ]
            // });
        }
    }, [mounted]);

    if (!mounted) return <Spin />;

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <iframe
                width="100%"
                height="100%"
                title="editor"
                src="http://localhost:3000"
            />
        </div>
    );
};
