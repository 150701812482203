import React from 'react';
import { PageHeader } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { keys } from 'lodash';

const StyledHeader = styled(PageHeader)`
    padding-left: 0;

    .ant-page-header-heading-title {
        font-weight: 500;
    }
`;

const getTemplates = (
    alignersData: any,
    numberOfTemplates: number | undefined,
    templateTitle: string,
    beforeTitle: string
) => {
    const result: any[] = [];
    if (numberOfTemplates) {
        for (let i = 1; i <= numberOfTemplates; i += 1) {
            result.push(
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        paddingLeft: 90,
                        paddingTop: 4
                    }}
                    key={i}
                >
                    <div>{`${templateTitle} №${i} ${beforeTitle}: `}</div>
                    <div style={{ fontWeight: 600 }}>
                        {alignersData[`${i}`]}
                    </div>
                </div>
            );
        }
    }
    return result;
};

export const AlignersInfo = (props: any) => {
    const { order, notitle } = props;
    const texts = useSelector((state: any) => state.CommonReducer.texts);

    if (order.acase || order.atype || order.adata) {
        return (
            <div>
                {notitle ? (
                    <></>
                ) : (
                    <StyledHeader
                        title={texts['order.aligners.addition.full'].value}
                    />
                )}
                <div
                    style={{
                        paddingTop: 4,
                        marginBottom: 4,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px'
                    }}
                >
                    <div>{`${texts['order.labels.aligners.type'].value}:`}</div>
                    <div style={{ fontWeight: 600 }}>
                        {order.atype
                            ? texts[`order.labels.aligners.${order.atype}`]
                                  .value
                            : '-'}
                    </div>
                </div>
                <div
                    style={{
                        paddingTop: 4,
                        marginBottom: 4,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px'
                    }}
                >
                    <div>{`${texts['order.labels.aligners.case'].value}:`}</div>
                    <div style={{ fontWeight: 600 }}>
                        {order.acase
                            ? texts[`order.labels.aligners.case.${order.acase}`]
                                  .value
                            : '-'}
                    </div>
                </div>
                <div style={{ paddingTop: 12, fontWeight: 600 }}>
                    {`${texts['order.labels.aligners.upper'].value}`}
                </div>
                <div
                    style={{
                        paddingTop: 4,
                        marginBottom: 4,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px'
                    }}
                >
                    <div>{`${texts['order.labels.aligners.numberoftemplates'].value}:`}</div>
                    <div style={{ fontWeight: 600 }}>
                        {order.adata ? keys(order.adata).length : 0}
                    </div>
                </div>
                {getTemplates(
                    order.adata,
                    order.adata ? keys(order.adata).length : 0,
                    texts.template.value,
                    texts['order.labels.aligners.beforestep'].value
                )}
                <div style={{ paddingTop: 12, fontWeight: 600 }}>
                    {`${texts['order.labels.aligners.lower'].value}`}
                </div>
                <div
                    style={{
                        paddingTop: 4,
                        marginBottom: 4,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px'
                    }}
                >
                    <div>{`${texts['order.labels.aligners.numberoftemplates'].value}:`}</div>
                    <div style={{ fontWeight: 600 }}>
                        {order.adatalower ? keys(order.adatalower).length : 0}
                    </div>
                </div>
                {getTemplates(
                    order.adatalower,
                    order.adatalower ? keys(order.adatalower).length : 0,
                    texts.template.value,
                    texts['order.labels.aligners.beforestep'].value
                )}
            </div>
        );
    }
    return <div />;
};
