import { Button, Divider } from 'antd';
import React from 'react';
import { PRINTER_HOST } from '../../utils/properties';

interface ILabelsPrinterItemProps {
    title: string;
    doctor: string;
    patient: string;
    printTitle: string;
    subtitle?: boolean;
}

export const LabelsPrinterItem = (props: ILabelsPrinterItemProps) => {
    const { title, doctor, patient, printTitle, subtitle } = props;
    return (
        <>
            <div style={{ fontWeight: 600, marginBottom: 4 }}>{title}</div>
            <Button
                type="primary"
                target="_blank"
                href={`${PRINTER_HOST}?title=${title}&doctor=${doctor}&patient=${patient}${
                    subtitle ? '&subtitle=true' : ''
                }`}
            >
                {printTitle}
            </Button>
            <Divider />
        </>
    );
};
