import { Spin } from 'antd';
import styled from 'styled-components';

export const CenteredSpinner = styled(Spin)`
    display: block;
    height: 100%;
    width: 100%;

    span {
        top: 50%;
    }
`;
