import React from 'react';
import { Button, Dropdown, Icon, Menu } from 'antd';
import { ICellRendererParams } from 'ag-grid-community';

interface CampaignActionParams extends ICellRendererParams {
    texts: any;
    onEditClick: any;
    onActivateClick: any;
    onDeleteClick: any;
}

export const CampaignActionRenderer = (props: CampaignActionParams) => {
    const {
        data: row,
        // value,
        texts,
        onEditClick,
        // onActivateClick,
        onDeleteClick
    } = props;
    if (!row) {
        return null;
    }

    const menu = (
        <Menu>
            {/* <Menu.Item
                key="1"
                hidden={value === true}
                onClick={(e) => onActivateClick(e, row)}
            >
                <Icon type="check-circle" />
                {texts['campaign.enabled'].value}
            </Menu.Item>
            <Menu.Item
                key="2"
                hidden={value !== true}
                onClick={(e) => onActivateClick(e, row)}
            >
                <Icon type="close-circle" />
                {texts['campaign.disabled'].value}
            </Menu.Item> */}
            <Menu.Item key="3" onClick={(e) => onEditClick(e, row)}>
                <Icon type="edit" />
                {texts.edit.value}
            </Menu.Item>
            <Menu.Item key="4" onClick={(e) => onDeleteClick(e, row)}>
                <Icon type="delete" />
                {texts.delete.value}
            </Menu.Item>
        </Menu>
    );
    return (
        <>
            <Dropdown overlay={menu} trigger={['click']}>
                <Button size="small">
                    <Icon type="ellipsis" />
                </Button>
            </Dropdown>
        </>
    );
};
