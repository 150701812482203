import { filter } from 'lodash';
import { actionTypes } from '../actions';

export const primaryInitialState = {
    orders: [],
    ordersLoading: false,
    ordersCount: 0,
    pagination: { current: 1, pageSize: 100 },
    role: 'doctor',
    techs: [],
    managers: [],
    selectedTech: 'all',
    selectedManager: 'all',
    search: '',
    sorter: {
        order: 'descend',
        field: 'latest',
        columnKey: 'latest'
    },
    filters: [
        { field: 'status', value: 'NEW', operator: 'in' },
        { field: 'status', value: 'UNPAID', operator: 'in' },
        { field: 'status', value: 'PAID', operator: 'in' },
        { field: 'status', value: 'REWORK', operator: 'in' }
    ]
};

const OrdersReducer = (state = primaryInitialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_TECH: {
            return {
                ...state,
                ...{
                    selectedTech: action.data
                }
            };
        }
        case actionTypes.SELECT_MANAGER: {
            return {
                ...state,
                ...{
                    selectedManager: action.data
                }
            };
        }
        case actionTypes.LOAD_TECHS_SUCCESS: {
            return {
                ...state,
                ...{
                    techs: filter(
                        action.data,
                        (d) => d.role.name === 'technician'
                    ),
                    managers: filter(
                        action.data,
                        (d) => d.role.name === 'manager'
                    )
                }
            };
        }
        case actionTypes.LOAD_ALL_ORDERS: {
            return {
                ...state,
                ...{
                    ordersLoading: true,
                    search: '',
                    orders: []
                }
            };
        }
        case actionTypes.LOAD_ALL_ORDERS_SEARCH_CHANGE: {
            return {
                ...state,
                ...{
                    ordersLoading: true,
                    search: action.search
                }
            };
        }
        case actionTypes.LOAD_ALL_ORDERS_FILTERS: {
            return {
                ...state,
                ...{
                    pagination: action.pagination,
                    sorter: action.sorter,
                    filters: action.filters,
                    ordersLoading: true
                }
            };
        }
        case actionTypes.LOAD_ALL_ORDERS_SUCCESS: {
            return {
                ...state,
                ...{
                    ordersLoading: false,
                    orders: action.orders.orders,
                    pagination: {
                        current: state.pagination.current,
                        pageSize: state.pagination.pageSize,
                        total: action.orders.ordersCount
                    }
                }
            };
        }
        default:
            return state;
    }
};

export default OrdersReducer;
