import { eventChannel } from 'redux-saga';
import { call, take, select, put } from 'redux-saga/effects';
import io from 'socket.io-client';
import { selectProfile } from '../selector';
import { GATEWAY_HOST } from '../../utils/properties';
import {
    onNewComment,
    onNewMessage,
    onUserEntry,
    onNewOrder,
    onUpdateOrder
} from '../actions';
import { isEmpty } from '../../utils/common';

const wsUrl = GATEWAY_HOST;

function initWebsocket(profile) {
    return eventChannel((emitter) => {
        if (isEmpty(profile.id)) {
            return () => {
                console.log('Profile is empty');
            };
        }

        console.log('INIT: ', profile.id);
        const socket = io(`${wsUrl}`, {
            transports: ["websocket"],
            query: {
                profileId: profile.id,
                role: profile.role.name
            }
        });
        // socket.on('hello', (e) => {
        //     console.log('CLIENT: ', e);
        //     emitter(e);
        // });

        socket.on('newmessage', (message) => {
            emitter({ action: onNewMessage, payload: message });
        });

        socket.on('newcomment', (message) => {
            emitter({ action: onNewComment, payload: message });
        });

        socket.on('neworder', (message) => {
            emitter({ action: onNewOrder, payload: message });
        });

        socket.on('updateorder', (message) => {
            emitter({ action: onUpdateOrder, payload: message });
        });

        socket.on('online', (user) => {
            if (window.location.pathname.indexOf('clients') >= 0) {
                emitter({
                    action: onUserEntry,
                    payload: { userId: user, online: true }
                });
            }
        });

        socket.on('offline', (user) => {
            if (window.location.pathname.indexOf('clients') >= 0) {
                emitter({
                    action: onUserEntry,
                    payload: { userId: user, online: false }
                });
            }
            // emitter({
            //     action: onUserEntry,
            //     payload: { userId: user, online: false }
            // });
        });

        // the subscriber must return an unsubscribe function
        // this will be invoked when the saga calls `channel.close` method
        const unsubscribe = () => {
            socket.off('hello');
        };

        return unsubscribe;
    });
}
export default function* wsSagas() {
    const profile = yield select(selectProfile);
    const channel = yield call(initWebsocket, profile);
    console.log('wsSagas', profile, channel);
    while (true) {
        const { action, payload } = yield take(channel);
        yield put(action(payload));
    }
}
