import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AgDoctorClientTable } from '../../components/Doctor/AgDoctorClientTable';
import DoctorTableToolbar from '../../components/Doctor/DoctorTableToolbar';
import {
    onUsersLoad,
    applyRoleToUser,
    applySearchToUser
} from '../../redux/actions';

const TableContainer = styled.div`
    height: 100%;
`;

export const ClientsPage = () => {
    const dispatch = useDispatch();

    const users = useSelector((state: any) => state.UserReducer.users);
    const role = useSelector((state: any) => state.UserReducer.role);
    const texts = useSelector((state: any) => state.CommonReducer.texts);

    useEffect(() => {
        if (users.length === 0) {
            dispatch(onUsersLoad());
        }
    }, []);

    const onRoleChange = (newRole: string) => {
        dispatch(applyRoleToUser(newRole));
    };

    const onSearchChange = (search: string) => {
        dispatch(applySearchToUser(search));
    };

    return (
        <TableContainer>
            <DoctorTableToolbar
                texts={texts}
                role={role}
                onRoleChange={(value: string) => onRoleChange(value)}
                onSearchChange={(value: string) => onSearchChange(value)}
            />
            <AgDoctorClientTable />
        </TableContainer>
    );
};
