import React, { useEffect, useState } from 'react';
import { DatePicker, Modal, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { useMutation, useQuery } from 'react-query';
import { GATEWAY_HOST } from '../../utils/properties';
import { CenteredSpinner } from '../Spinner/CenteredSpinner';

export const updateOrderDates = async (data: any) => {
    const { orderId, billDate, deadline, jwt } = data;

    const body: any = {
        bill_date: billDate,
        deadline
    };

    return fetch(`${GATEWAY_HOST}/orders/dates/${orderId}`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json'
        }
    });
};

export const EditDatesModal = (props: any) => {
    const { visible, handleOk, handleCancel, orderId } = props;
    const [queryEnabled, setQueryEnabled] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [deadline, setDeadline] = useState<Moment | null>(null);
    const [billdate, setBilldate] = useState<Moment | null>(null);
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const token = useSelector((state: any) => state.AuthReducer.token);

    const { isLoading, data } = useQuery(
        ['order-dates', orderId],
        () =>
            fetch(`${GATEWAY_HOST}/orders?id=${orderId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }).then((res) => res.json()),
        { enabled: queryEnabled }
    );

    useEffect(() => {
        if (data) {
            setBilldate(data[0].bill_date ? moment(data[0].bill_date) : null);
            setDeadline(data[0].deadline ? moment(data[0].deadline) : null);
        }
    }, [data]);

    useEffect(() => {
        if (orderId) {
            setQueryEnabled(true);
        } else {
            setQueryEnabled(false);
        }
    }, [orderId]);

    const { mutate } = useMutation('update-order-dates', updateOrderDates, {
        onSuccess: () => {
            setQueryEnabled(false);
            setIsSaving(false);
            handleOk();
            // queryClient.refetchQueries(['order-messages', orderId]);
            // setIsSending(false);
            // dispatch(onSaveMessageSuccess());
        },
        onError: () => {
            // message.error('Возникла ошибка, попробуйте позже.');
            // setIsSending(false);
        },
        onSettled: () => {
            // queryClient.invalidateQueries('create');
        }
    });

    return (
        <Modal
            title={texts.editdate.value}
            visible={visible}
            onOk={() => {
                setIsSaving(true);
                const body = {
                    jwt: token,
                    orderId,
                    billDate: billdate,
                    deadline
                };

                mutate(body);
            }}
            onCancel={() => {
                setQueryEnabled(false);
                handleCancel();
            }}
            okButtonProps={{ loading: isSaving }}
            okText={texts.save.value}
            cancelText={texts.cancel.value}
        >
            {isLoading || !orderId || !data ? (
                <CenteredSpinner />
            ) : (
                <Row>
                    <Col span={12}>
                        <div style={{ marginBottom: 8 }}>
                            {texts['order.bill.date'].value}
                        </div>
                        <DatePicker
                            placeholder={texts['date.select'].value}
                            value={billdate}
                            onChange={(value) => setBilldate(value)}
                        />
                    </Col>
                    <Col span={12}>
                        <div style={{ marginBottom: 8 }}>
                            {texts['order.deadline'].value}
                        </div>
                        <DatePicker
                            placeholder={texts['date.select'].value}
                            value={deadline}
                            onChange={(value) => setDeadline(value)}
                        />
                    </Col>
                </Row>
            )}
        </Modal>
    );
};
