import React, { Component } from 'react';
import { orderBy } from 'lodash';
import { Card, List, Spin } from 'antd';
import styled from 'styled-components';
import MessageCardTitle from './MessageCardTitle';
import MessageCardBody from './MessageCardBody';
import { isEmpty } from '../../utils/common';

const { Meta } = Card;

const CardMeta = styled(Meta)`
    .ant-card-meta-title {
        font-weight: 300;
    }
`;

const CardWithoutPadding = styled(Card)`
    border-bottom: 1px solid #e8e8e8 !important;
    border-radius: 0;
    background-color: ${(props) =>
        props.selected === true ? '#3C9FD9' : 'none'};

    .ant-card-meta-title {
        color: ${(props) => (props.selected === true ? '#fff' : 'none')};
    }

    .ant-card-meta-description {
        color: ${(props) => (props.selected === true ? '#fffc' : 'none')};
    }

    .ant-card-body {
        padding: 12px;
    }
`;

const SpinStyled = styled(Spin)`
    margin-left: auto;
    margin-right: auto;
    padding: 128px;
    width: 100%;
`;

const BorderedList = styled(List)`
    border: 1px solid #e8e8e8 !important;
`;

class MessageList extends Component {
    render() {
        const {
            orders,
            ordersLoading,
            scope,
            texts,
            onSelectOrder,
            selectedOrder,
            profile
        } = this.props;
        if (ordersLoading) {
            return <SpinStyled />;
        }

        const orderList = orderBy(
            orders,
            [(o) => o.latest_message || ''],
            ['desc']
        );
        return (
            <BorderedList
                dataSource={orderList}
                renderItem={(item) => {
                    let selected = false;
                    if (!isEmpty(selectedOrder)) {
                        selected = selectedOrder.id === item.id;
                    }
                    return (
                        <CardWithoutPadding
                            bordered={false}
                            hoverable
                            style={{ width: '100%' }}
                            onClick={() => onSelectOrder(item)}
                            selected={selected}
                        >
                            <CardMeta
                                title={
                                    <MessageCardTitle
                                        scope={scope}
                                        order={item}
                                        selected={selected}
                                    />
                                }
                                description={
                                    <MessageCardBody
                                        texts={texts}
                                        order={item}
                                        scope={scope}
                                        selected={selected}
                                        profile={profile}
                                    />
                                }
                            />
                        </CardWithoutPadding>
                    );
                }}
            />
        );
    }
}

export default MessageList;
